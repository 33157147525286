<template>
  <div class="form-section my-4 flex px-4">
    <div class="form-section-info w-1/3">
      <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0">
        Services
        <span class="text-red-400">*</span>
      </label>
      <p class="font-sm text-gray-400 text-right">Choose a service</p>
    </div>
    <div class="form-section-content w-2/3 ml-5">
      <div class="mb-3">
        <v-select
          label="main_service_name"
          @change="checkService"
          class="cursor-pointer appearance-none w-full text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white"
          name="select1"
          :options="allservices"
          v-model="service"
        ></v-select>
        <p
          v-if="!addingService"
          class="mt-2 text-gray-400 text-right cursor-pointer"
          @click="showAddServiceInput"
        >
          Can't find a service? Add it here
        </p>

        <CreateUpdateModal 
            v-if="addingService"
            :title="'Suggest a service'"
            :buttonText="'Send'"
            @save="saveServiceSuggestion()"
            @close="closeServiceSuggestion()"
        >
            <div class="flex" slot="content">
              <div class="form-section-info w-1/3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0">
                  Email
                  <span class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">Type your email</p>
              </div>
              <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                  <input
                    type="email"
                    class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control "
                    placeholder="Your email"
                    v-model="email"
                  />
                </div>
              </div>
            </div>
            <div class="flex" slot="content">
              <div class="form-section-info w-1/3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0">
                  Services
                  <span class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">Suggest a service</p>
              </div>
              <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                  <textarea
                    v-if="addingService"
                    type="text"
                    class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control "
                    placeholder="Write in the box the service that you're looking for"
                    v-model="newService"
                  >
                  </textarea>
                </div>
              </div>
            </div>
        </CreateUpdateModal>
        
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { mapActions } from 'vuex';
import CreateUpdateModal from '../CreateUpdateModal.vue'

export default {
  components: { vSelect, CreateUpdateModal },
  props: ['allservices', 'servicesession'],
  data() {
    return {
      service: this.servicesession || '',
      newService: '',
      email: '',
      addingService: false,
      modalData: {},
    };
  },
  methods: {
    ...mapActions('service', ['saveServiceSuggestionAction']),
    pushData() {
      this.$emit('eventListener', this.modalData);
    },
    checkService() {
      if (this.service === 'add_service') {
        this.showAddServiceInput();
      }
    },
    saveServiceSuggestion() {
      if(this.newService == '' || this.newService.length < 3 || this.email == '') {
        Swal.fire({
          toast: true,
          icon: 'error',
          title: 'Please fill all fields',
          animation: true,
          position: 'top-right',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        });  
      }else {
        this.saveServiceSuggestionAction({
          email: this.email,
          newService: this.newService
        }).then(res => {
          Swal.fire({
            icon: 'success',
            animation: true,
            title: "Suggestion sent",
            text: "We have recieved your service suggestion.",
            showConfirmButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok!'
          }).then((result) => {
            this.newService = ''
            this.email = ''
            this.closeServiceSuggestion()
          });
        })
      }
    },
    showAddServiceInput() {
      this.addingService = true;
    },
    closeServiceSuggestion() {
      this.addingService = false
    }
  },
  watch: {
    service: {
      handler(value) {
        this.$emit('updateModalData', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.vue-select-window {
  height: 250px;
}

.form-section {
  height: 150px;
}
</style>
