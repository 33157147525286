const clinicReviewsTableHeads = [
    {
        title: 'Service',
        var: 'serviceAsc',
        sortable: true
    },
    {
        title: 'Review',
        var: 'serviceAsc',
        sortable: false
    },
    {
        title: 'Rating',
        var: 'ratingAsc',
        sortable: true
    },
    {
        title: 'Status',
        var: 'confirmedAsc',
        sortable: true
    },
    {
        title: 'Source',
        var: 'sourceAsc',
        sortable: true
    },
    {
        title: 'Action',
        var: 'serviceAsc',
        sortable: false
    }
]

export default clinicReviewsTableHeads;