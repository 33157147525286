<template>
    <div class="container">
        <div v-if="!loadFormdata" class="form-section flex py-4 pr-4">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.qualifications.components.componentGroup1.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.qualifications.components.componentGroup1.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label> {{ formData.qualifications.components.componentGroup1.inputTitle }}
                        <span v-if="formData.qualifications.components.componentGroup1.required" class="text-red-400">*
                        </span>
                    </label>
                    <v-select label="value" multiple class="vue-select1" name="select1" :options="qualificationsArray" v-model="target.qualifications">
                    </v-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import qualificationFormData from '../../../../../public/json/workflows.json'
import practitionerQualifications from '../../../../../public/json/practitionerQualifications.json'

export default {
    props: ['target'],
    components: {
        vSelect
    },
    data()
    {
        return {
            qualificationOptions: {},
            qualificationsArray: [],
            modalData: {},
            formData: {},
            loadFormdata: true
        }
    },
    computed: {
        ...mapGetters('practitioner', ['GET_ERRORS'])
    },
    methods: {
        pushData(){
            this.$emit('eventListener', this.modalData)
        }
    },
    mounted()
    {
        this.formData = qualificationFormData;
        this.qualificationOptions = practitionerQualifications
        // If editing, pass in target publication values
        if(this.target)
        {
            this.modalData.qualifications = this.target.qualifications;
        }
    },
    created() {
        if(this.target.qualifications[0] == null) {
            this.target.qualifications  = []
        }
        for (const key in practitionerQualifications.qualifications) {
            this.qualificationsArray.push({
                key: key,
                value: practitionerQualifications.qualifications[key]
            })
        }
    },
    watch: {
        formData: {
            handler(newValue) {
            this.loadFormdata = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>

@import "vue-select/dist/vue-select.css";
.vue-select1 {
    z-index: 9999
}
.form-section{
    height: 270px !important;
}
</style>
