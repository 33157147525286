<style scoped lang="scss">
    .timeline-one-side .timeline-content {
        width: auto;
    }

    @media (min-width: 992px) {
        .timeline-one-side .timeline-content {
            max-width: 30rem;
        }
    }
    .timeline {
        position: relative;
    }

    .timeline::before {
        content: "";
        position: absolute;
        top: 0;
        left: 1rem;
        height: 100%;
        border-right: 2px solid #dee2e6;
    }
    .timeline-block {
        position: relative;
    }

    .timeline-block:after {
        content: "";
        display: table;
        clear: both;
    }

    .timeline-block:first-child {
        margin-top: 0;
    }

    .timeline-step {
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        left: 15px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #fff;
        text-align: center;
        transform: translateX(-50%);
        font-size: 1rem;
        font-weight: 600;
        z-index: 1;
    }
    .timeline-step i, .timeline-step box-icon {
        line-height: 1.4;
    }

    .timeline-content {
        width: auto;
        margin-left: 45px;
        padding-top: 0.35rem;
        position: relative;
        top: -6px;
    }

    @media (min-width: 992px){
        .timeline-content {
            width: 38%;
        }
    }

</style>

<template>
    <div class="card border-none shadow-lg p-4 my-4 h-full mr-2">
        <div class="pb-0 card-h flex">
            <h5 class="text-md font-semibold card-title my-auto">Activity Logs</h5>
        </div>
        <div class="card-body overflow-y-scroll">
            <div class="timeline timeline-one-side mt-3">
                <div v-for="log in visibleLogs">
                    <div class="timeline-block mb-3 flex">
                        <div class="timeline-step log-icon-box">
                            <box-icon class="" name='history' color="#f4c11b"></box-icon>
                        </div>
                        <div class="timeline-content log-text-box">
                            <h6 class="text-dark text-sm font-weight-bold mb-0 font-semibold text-cyan-900">{{ JSON.parse(log.entry).title }}</h6>
                            <p class="text-secondary font-weight-bold text-sm mt-1 mb-0">{{ logDate(log.created_at) }} <span class="ml-1 cursor-pointer text-cyan-800" @click.prevent="openLogModal(log)">(view)</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-f flex w-full">
            <a class=" mx-auto cursor-pointer uppercase text-blue-900 hover:text-blue-900 font-semibold text-sm no-underline" @click.prevent="showMoreLogs()" v-if="visibleLogs.length < logs.length">See more logs</a>
        </div>
        <CreateUpdateModal
            v-if="logModal" @close="logModal = false; modalData = {}"
            :title="'Log Detail'"
            :show-action-button="false"
        >
        <template #content>
            <table class="w-full">
                <tr class="my-2">
                    <td class="flex mr-5"><h6 class="mb-0">Name</h6></td>
                    <td class="my-4"><p class="text-muted text-md mb-0">{{ modalData.title }}</p></td>
                </tr>
                <div class="py-2 w-full"></div>
                <tr class="my-2">
                    <td class="flex mr-5"><h6 class="mb-0">Entry</h6></td>
                    <td class="my-4"><p class="text-muted text-md mb-0" v-html="modalData.content"></p></td>
                </tr>
            </table>
        </template>
        </CreateUpdateModal>
    </div>
</template>

<script>
import moment from 'moment';
import CreateUpdateModal from '../../CreateUpdateModal.vue';

export default {
    props: {
        logs: {
            type: Array,
            required: true
        }
    },
    components: {
        CreateUpdateModal
    },
    data() {
        return {
            logModal: false,
            modalData: {},
            visibleLogs: [],
            chunkSize: 5,
            currentIndex: 0
        };
    },
    methods: {
        logDate(date) {
            return moment(date).format("DD-MM-YYYY, h:mm:ss a");
        },
        openLogModal(log)
        {
            this.modalData.title = JSON.parse(log.entry).title;
            this.modalData.content = JSON.parse(log.entry).content;
            this.modalData.time = this.logDate(log.created_at);
            this.logModal = true;
        },
        loadLogs() {
            const endIndex = this.currentIndex + this.chunkSize;
            this.visibleLogs = this.visibleLogs.concat(this.logs.slice(this.currentIndex, endIndex));
            this.currentIndex = endIndex;
        },
        showMoreLogs()
        {
            this.loadLogs();
        }
    },
    mounted()
    {
        this.loadLogs()
    }
}
</script>
