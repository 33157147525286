import { render, staticRenderFns } from "./PractitionerCompliance.vue?vue&type=template&id=12b60689&scoped=true"
import script from "./PractitionerCompliance.vue?vue&type=script&lang=js"
export * from "./PractitionerCompliance.vue?vue&type=script&lang=js"
import style0 from "./PractitionerCompliance.vue?vue&type=style&index=0&id=12b60689&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12b60689",
  null
  
)

export default component.exports