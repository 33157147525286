<template>
    <div class="mt-5">
        <div class="section-title">
            <h5>Regulatory Body</h5>
        </div>
        <form @submit.prevent="saveRegulatoryBody()">
            <div class="bg-white rounded shadow w-full py-5 px-3">
                <div class="info-section">
                    <div class="form-group my-4">
                        <label for="">This practitioner is <span v-if="clinician.has_professional_body === 0 || clinician.has_professional_body === null">not</span> regulated by a regulatory body</label>
                        <!-- <input type="checkbox" name="regulatory_body" v-model="professional_body.has_professional_body" id="" placeholder="" aria-describedby="helpId"> -->
                    </div>
                    <div class="form-group my-4" v-if="professional_body.has_professional_body || professional_body.has_professional_body === 1">
                        <label for="">Regulatory Body</label>
                        <p>{{ clinician.professional_body_name }}</p>
                    </div>
                    <div class="form-group my-4" v-if="clinician.has_professional_body || clinician.has_professional_body === 1">
                        <label for="">Professional Body ID</label>
                        <input type="text" name="professional_body" v-model="clinician.professional_body_ID" ID="" class="form-control" placeholder="" aria-describedby="helpId">
                    </div>
                </div>
              <div>
                <p>Please be aware that we manually verify the registration of practitioners belonging to a regulatory body.</p>
              </div>
                <div v-if="professional_body.has_professional_body" class="flex mt-4">
                    <PrimaryButton :type="'submit'">Update</PrimaryButton>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PrimaryButton from "../../PrimaryButton.vue";
export default {
    components: {PrimaryButton},
    props: ['professional_body'],
    data() {
        return {
            professions: require('../../../../../public/json/healthcareProfessionals.json'),
            regulatory_bodies: require('../../../../../public/json/regulatoryBodies.json'),
        }
    },
    methods: {
        saveRegulatoryBody()
        {
            axios.put('/dashboard/practitioners/save-regulatory-body/'+this.professional_body.ref, this.clinician).then(() => {
                    Swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Regulatory Body Saved',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    });
                }).catch(() => {
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Something went wrong. Contact support',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    });
                });
        }
    },
    computed: {
        clinician(){
            return this.professional_body
        }
    }
}
</script>
