<template>
<div class="" style="height: 300px;">
    <div
      class="page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('../../../../../public/images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="absolute bottom-20 z-10 right-0">
      <ProfileWarnings v-if="!isCompliant && !isAdmin"></ProfileWarnings>
    </div>
        <div class="flex-col w-full p-4 absolute" style="top:0">
            <div class="toptop w-full flex">
                <div class="">
                    <Breadcrumbs :color="'#fffff'" :size="'20px'"></Breadcrumbs>
                </div>
                <div class="ml-auto">
                    <div class="w-full flex ">
                        <ul class="my-auto flex" style="margin-left:auto; list-style: none">
                            <li class="nav-item dropdown">
                                <a id="navbarDropdown" class="text-white font-bold mb-0 nav-link mx-1 uppercase text-sm" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <Icon :icon="'bell'"></Icon>
                                </a>
                                <ul class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4" aria-labelledby="dropdownMenuButton">
                                    <li class="mb-2">
                                        <a href="" class="dropdown-item border-radius-md">No notifications</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item dropdown">
                                <a id="navbarDropdown" class="text-white font-bold mb-0 nav-link  uppercase text-sm" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <Icon :icon="'account_circle'"></Icon>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item border-bottom py-2">
                                        {{ clinicname }}
                                    </a>
                                    <a class="dropdown-item" href="/home">
                                        Dashboard
                                    </a>
                                    <a class="dropdown-item cursor-pointer" @click="logout()">
                                        Logout
                                    </a>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>

            </div>
            <div class="container-fluid" style="margin-top:80px;">
                <h1 class="text-white font-bold">{{ pageDetails.title }}</h1>
                <p class="text-white font-semibold">{{ pageDetails.description }}</p>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Breadcrumbs from '../../Breadcrumbs.vue';
import Icon from '../../Icon';
import ProfileWarnings from "../../Utilities/ProfileWarnings.vue";

export default {
    props: {
      clinicname : {
        type: String
      },
      pages: {
        type: Array | Object
      }
    },
    components: {
      ProfileWarnings,
    Icon,
    Breadcrumbs
},
    data() {
        return {
            pageDetails: {},
            isCompliant: true,
            isAdmin: false
        }
    },
    methods: {
        ...mapActions('commonFunctions', ['logout']),
      checkIfAdmin()
      {
        axios.get('/check-if-admin').then((res) => {
          this.isAdmin = res.data
        });
      }
    },
    mounted()
    {
        this.checkIfAdmin();
        const pageArr = Object.keys(this.pages).map((key) => [Number(key), this.pages[key]]);
        pageArr.forEach(element => {
            if(window.location.pathname == element[1].url)
            {
               this.pageDetails = element[1];
            }
        });
        axios.get('/dashboard/check-if-overall-compliant').then((res) => {
          this.isCompliant = res.data
          console.log(res);
        }).catch((e) => {
          console.log(e)
        });
    }
}

</script>
