<template>
    <div class="relative">
      <Select2
        class="appearance-none w-full"
        :class="{ 'border-none': noBorder }"
        :required="required"
        :disabled="disabled"
        data-in-component
        :settings="{minimumResultsForSearch: minimumResultsForSearch}"
        @change="handleChange"
        v-model="selectedValue"
        :aria-placeholder="placeholderText"
        :placeholder="placeholderText"
        :options="formattedOptions"
      >
        <option
          :value="null"
          v-if="selectedValue === null && !noPlaceholder"
          v-text="placeholderText"
        ></option>
      </Select2>
    </div>
  </template>

  <script>
  import Select2 from 'v-select2-component';

  export default {
    components: { Select2 },
    props: {
      value: [String, Number],
      options: Array,
      placeholderText: {
        type: String,
        default: 'Please select an option',
      },
      noBorder: Boolean,
      noPlaceholder: Boolean,
      required: Boolean,
      disabled: Boolean,
      disableSearch: {
        type: Boolean,
        default: false,
      },
      mappings: {
        type: Object,
        required: true,
      },
    },
    data() {
      let minimumResultsForSearch = 10;
      if (this.disableSearch && this.disableSearch === true) {
        minimumResultsForSearch = 9999999999999;
      }
      return {
        selectedValue: _.cloneDeep(this.value),
        formattedOptions: [],
        minimumResultsForSearch,
      };
    },
    methods: {
      handleChange($value) {
        this.$emit('input', $value);
      },
      formatOptions() {

        this.formattedOptions = this.options.map((option) => {
          if(typeof option !== 'number')
          {
            return {
              id: option.toLowerCase(),
              text: this.mappings[option.toLowerCase()] || option.toString(),
            };
          } else {
            return {
              id: option,
              text: option
            }
          }

        });
      },
    },
    watch: {
      options: {
        handler(updatedOptions) {
          this.formatOptions();
        },
        immediate: true,
      },
      value(updatedValue) {
        this.selectedValue = updatedValue || null;
      },
    },
    beforeMount() {
      this.$on('initialise', (value) => {
        this.selectedValue = value || null;
      });
    },
  };
  </script>

  <style>
  @import "~select2/dist/css/select2.min.css";
  </style>
