<template>
    <div>
        <div class="why-medimob-section">
            <WhyChooseMedimob />
        </div>
        <div class="my-5 md-ex-sp">
            <HowItWorksVue />
        </div>
        <div class="my-5">
            <EitherWays />
        </div>
        <div class="my-5">
            <NeedHealthCare />
        </div>
        <div class="my-5">
            <AreYouaPractitioner />
        </div>
        <div class="my-5">
            <HaveQuestions />
        </div>
        <div class="my-5">
            <NewsletterSignup />
        </div>
        <div class="my-5">
          <HomePageBlogSection />
        </div>

    </div>
</template>
<script>
import HowItWorksVue from './HowItWorks.vue';
import WhyChooseMedimob from './WhyChooseMedimob.vue';
import EitherWays from './EitherWays.vue';
import NeedHealthCare from './NeedHealthCare.vue';
import AreYouaPractitioner from './AreYouaPractitioner.vue';
import HaveQuestions from './HaveQuestions.vue';
import NewsletterSignup from './NewsletterSignup.vue';
import HomePageFooter from './HomePageFooter.vue';
import HomePageBlogSection from './HomePageBlogSection.vue';
export default {
    components: {
        HowItWorksVue,
        WhyChooseMedimob,
        EitherWays,
        NeedHealthCare,
        AreYouaPractitioner,
        HaveQuestions,
        NewsletterSignup,
        HomePageFooter,
        HomePageBlogSection

    }
}
</script>
