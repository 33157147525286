<template>
    <clinic-info-create-update-modal
        modal-name="create-update-clinic-legal-entity"
        modal-title="Update Clinic Legal Entity"
        button-title="Update Legal Entity"
        size="xl"
        category="legal"
        :modal-data="modalData"
        :default-data="defaultData"
        >
        <div class="clinic-page-section">
            <div class="clinic-page-info">
                <div class="clinic-info-title">Legal Entity</div>
                <div class="fz14 text-gray">
                    Enter details of your legal entity
                </div>
                <div class="clinic-page-content">
                    <div class="mb-3">
                        <form @submit.prevent="saveLegalEntity(legal_entity)">
                            <label>
                            Legal Entity
                                <span class="mandatory">*</span>
                            </label>
                                <select name="" id="" v-model="legal_entity">
                                    <option value="" v-for="option in options" :key="option.title">{{option.title}}</option>
                                </select>
                        </form>

                    </div>
                </div>
            </div>

        </div>

    </clinic-info-create-update-modal>

</template>

<script>
import ClinicInfoCreateUpdateModal from './ClinicInfoCreateUpdateModal';

// importing the helpers for calling actions from vuex
import {mapActions} from 'vuex';

export default {
    components: {
        ClinicInfoCreateUpdateModal
    },
    data() {
        return {
            options: require('../../../../../public/json/legalentities.json'),
            modalData: {},
            defaultData: {
                legalEntity: null
            },
            legal_entity: '',
        }
    },
    methods: {
            // importing actions needed from vuex
        ...mapActions('mainClinic', ['saveLegalEntity']),
    },
    computed: {},
    mounted(){
    }
}
</script>
