<template>
    <div>
        <table-framework
            :theads="theads"
            :tableTitle="'Compliance'"
            :showTableActionButton="true"
            :tableButtonText="'Add Compliance File'"
            @tableAction="openUploadFileModal()"
        >
            <tbody slot="table-body">
                <practitionerComplianceTile v-for="(compliance, index) in PractitionerCompliances" :key="compliance.id"  @callPractitonerCompliances="getPractitionerCompliances" :practitionerRef="practitionerRef"  :compliance="compliance" />
            </tbody>
        </table-framework>
        <NewFileModalContent v-if="toggleUploadFileModal" @close="toggleUploadFileModal = false;" @done="doneSuccessfully" :PractitionerCompliances="PractitionerCompliances" :practitionerRef="practitionerRef"  @eventListener="updateModalData($event)"></NewFileModalContent>

    </div>
</template>


<script>
import NewFileModalContent from './NewFileModalContent.vue' 
import complianceTableHeads from '../../../../constants/practitioners/complianceTableHeads'
import complianceParameters from '../../../../constants/practitioners/complianceParameters.js';
import practitionerComplianceTile from './practitionerComplianceTile'
import TableFramework from '../../../Table.vue';
import { mapActions } from 'vuex';
export default {
    props: ['practitionerRef'],
    components: {
        TableFramework,
        practitionerComplianceTile,
        NewFileModalContent
    },
    data()
    {
        return {
            compParameters: complianceParameters,
            theads: complianceTableHeads,
            PractitionerCompliances: {},
            toggleUploadFileModal: false
        }
    },
    methods: {   
        ...mapActions('practitionerCompliances', ['getPractitionerCompliancesAction']),
        getPractitionerCompliances() {

            this.getPractitionerCompliancesAction(this.practitionerRef)
            .then(res => {
                this.PractitionerCompliances = res.data
            })
        },
        openUploadFileModal()
        {
            this.toggleUploadFileModal = true;
        },
        closeModal(){
            this.toggleUploadFileModal = false
        },
        doneSuccessfully(){
            this.toggleUploadFileModal = false
            this.getPractitionerCompliances()
        }
    },
    created(){
        this.getPractitionerCompliances()
    }
}
</script>

<style lang="scss" scoped>
.roundImage{
    width: 50px  !important;
}
.form-check-label {
    font-weight: bold;
    text-transform: uppercase;
  }
  .form-check-input:checked {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
  }
  .form-check-input:checked + .form-check-label {
    color: #0d6efd !important;
  }
</style>