var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-8 h-full container position-relative section-separation"},[_c('div',{staticClass:"hero-inner flex are-you-a-practitioner-section h-full mx-auto"},[_c('div',{staticClass:"w-1/2 section"},[_c('div',{staticClass:"container w-full"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"rounded-lg pt-4 mr-5"},[_c('div',{staticClass:"text-left grid grid-cols-1 gap-4"},[_c('div',{staticClass:"py-2 rounded-lg flex"},[_c('span',{staticClass:"pr-4"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"73","height":"73","viewBox":"0 0 73 73","fill":"none"}},[_c('rect',{attrs:{"width":"73","height":"73","rx":"20","fill":"#F1F4FB"}}),_vm._v(" "),_c('circle',{attrs:{"cx":"36","cy":"37","r":"12","stroke":"#599DE0","stroke-width":"2"}})])]),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('div',{staticClass:"py-2 rounded-lg flex"},[_c('span',{staticClass:"pr-4"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"73","height":"73","viewBox":"0 0 73 73","fill":"none"}},[_c('rect',{attrs:{"width":"73","height":"73","rx":"20","fill":"#F1F4FB"}}),_vm._v(" "),_c('circle',{attrs:{"cx":"36","cy":"37","r":"12","stroke":"#599DE0","stroke-width":"2"}})])]),_vm._v(" "),_vm._m(2)]),_vm._v(" "),_c('div',{staticClass:"py-2 rounded-lg flex"},[_c('span',{staticClass:"pr-4"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"73","height":"73","viewBox":"0 0 73 73","fill":"none"}},[_c('rect',{attrs:{"width":"73","height":"73","rx":"20","fill":"#F1F4FB"}}),_vm._v(" "),_c('circle',{attrs:{"cx":"36","cy":"37","r":"12","stroke":"#599DE0","stroke-width":"2"}})])]),_vm._v(" "),_vm._m(3)])]),_vm._v(" "),_vm._m(4)])])]),_vm._v(" "),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-box mx-auto"},[_c('h3',{staticClass:"howto-sub-title mb-4"},[_vm._v("Are you a")]),_vm._v(" "),_c('h3',{staticClass:"howto-sub-title accent-text",staticStyle:{"line-height":"45px"}},[_vm._v("Healthcare Practitioner "),_c('span',{staticClass:"howto-sub-title"},[_vm._v("?")])]),_vm._v(" "),_c('p',{staticClass:"mod-text mt-5"},[_vm._v("Medimob welcomes you to join our innovative platform that opens up new possibilities for\n                   delivering healthcare services. At Medimob, we understand the importance of empowering\n                   practitioners to provide quality care, and we offer a unique set of advantages to support your\n                   professional journey.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('p',{staticClass:"mod-text my-auto"},[_vm._v("Whether you specialize in telehealth consultations or in-home visits, our\n                            platform connects you with individuals seeking your expertise, breaking down geographical barriers.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('p',{staticClass:"mod-text my-auto"},[_vm._v("Enjoy the flexibility of managing your schedule and services. You have the freedom\n                            to choose when and where you provide your services, allowing you to strike the right balance\n                            between professional and personal commitments.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('p',{staticClass:"mod-text my-auto"},[_vm._v("Benefit from increased visibility in the healthcare community and attract new\n                            patients who are actively seeking your expertise.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn-sec mb-5"},[_c('a',{staticClass:"flex mt-5 blue-home-button btn items-center my-auto w-1/5 bg-blue-500 text-white rounded-lg px-4 py-2",attrs:{"role":"button","href":"/for-practitioners"}},[_c('span',[_vm._v("Find out more")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-1/2 section"},[_c('div',{staticClass:"h-full img-wrap"},[_c('div',{staticClass:"flex w-full h-full"},[_c('div',{staticClass:"flex mt-auto w-full md-handling"},[_c('img',{attrs:{"src":"/images/therapy_img.png","alt":""}})]),_vm._v(" "),_c('div',{staticClass:"flex flex-col my-auto w-full md-handling"},[_c('div',{staticClass:"w-full my-3"},[_c('img',{attrs:{"src":"/images/back_therapy.png","alt":""}})]),_vm._v(" "),_c('div',{staticClass:"w-full my-3"},[_c('img',{attrs:{"src":"/images/geriatric.png","alt":""}})])])])])])
}]

export { render, staticRenderFns }