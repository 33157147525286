<style lang="scss">
    .profile-small-header-section {
        border-bottom: 2px solid #0BD2A4;

        h1 {
            font-size: 25px;
            line-height: 12px;
            color: #717170;
            font-weight: 700;
            padding-bottom: 5px;
        }
    }

    .profile-main-content-section {
        p {
            color: #717170;
            font-size: 17px;
            line-height: 28px;
            white-space: pre-wrap;
        }
    }

    .profile-main-content-section {

        .service-list-box {

            .service-list-item {
                margin-right: 30px;
                background-color: #ffff;
                border-radius: 10px;
                padding: 5px 15px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 4px 0px;




                h5 {
                    color: #717170;
                    font-size: 15px;
                    font-weight: 600;
                }
            }

        }
    }


</style>

<template>
    <div class="section-wrapper w-full ">
        <div class="profile-small-header-section md:flex">
            <h1>Service Delivery Type</h1>
            <small class="md:ml-5 italic text-slate-500">(Click on each tile to find out more)</small>
        </div>
        <div class="profile-main-content-section mt-3">
            <div class="service-list-box flex-wrap flex">
                <li class="flex service-list-item cursor-pointer my-3" v-if="isVirtual || isBoth">
                    <h5 class="my-auto m-0 mx-auto" @click.prevent="showToolTipModal(deliveryTypeList[0])" >{{ deliveryTypeList[0].title }}</h5>
                </li>
                <li class="flex service-list-item cursor-pointer my-3" v-if="isDomiciliary || isBoth">
                    <h5 class="my-auto m-0 mx-auto" @click.prevent="showToolTipModal(deliveryTypeList[1])" >{{ deliveryTypeList[1].title }}</h5>
                </li>
            </div>
        </div>
        <CreateUpdateModal
            v-if="toolTipModal"
            :title="toolTipMessage.title"
            :data="toolTipMessage"
            :buttonText="'Nope'"
            :showActionButton="false"
            @save=""
            @close="toolTipModal = false; toolTipMessage = {}"
        >
                <div class="" slot="content">
                    <div class="" v-html="toolTipMessage.description"></div>
                </div>
        </CreateUpdateModal>
    </div>
</template>


<script>
import serviceDeliveryTypeInfo from '../../../../constants/infobox/serviceDeliveryTypeInfo';
import CreateUpdateModal from '../../../CreateUpdateModal.vue';

    export default {
        components: {CreateUpdateModal},
        props: ['services'],
        data()
        {
            return {
                deliveryTypeList: [],
                isVirtual: false,
                isDomiciliary: false,
                isBoth: false,
                toolTipModal: false,
                toolTipMessage: {
                    title: '',
                    description: '',
                    link: ''
                }
            }
        },
        methods: {
            pickRightDeliveryType()
            {
                this.services.forEach(element => {
                    const domiciliary = element.main.domiciliary;
                    const virtual = element.main.virtual;
                    const both = element.main.both_virtual_and_domiciliary;

                    if(domiciliary)
                    {
                        this.isDomiciliary = true;
                    } else if(virtual)
                    {
                        this.isVirtual = true;
                    } else if(both)
                    {
                        this.isBoth = true;
                    }

                });
            },
            showToolTipModal(method)
            {
                this.toolTipMessage.title = method.title;
                this.toolTipMessage.description = '<p>'+method.clientDescription+'</p>';
                this.toolTipModal = true;
            }
        },
        mounted() {
            this.deliveryTypeList = serviceDeliveryTypeInfo;
            this.pickRightDeliveryType();
        }

    }
</script>
