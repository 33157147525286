<style lang="scss">
    .profile-small-header-section {
        border-bottom: 2px solid #0BD2A4;

        h1 {
            font-size: 25px;
            line-height: 12px;
            color: #717170;
            font-weight: 700;
            padding-bottom: 5px;
        }
    }

    .profile-main-content-section {
        p {
            color: #717170;
            font-size: 17px;
            line-height: 28px;
            white-space: pre-wrap;
        }
    }

    .profile-main-content-section {

        .service-list-box {

            .service-list-item {
                margin-right: 30px;
                background-color: #ffff;
                border-radius: 10px;
                padding: 5px 15px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 4px 0px;




                h5 {
                    color: #717170;
                    font-size: 15px;
                    font-weight: 600;
                }
            }

        }
    }


</style>

<template>
    <div class="section-wrapper w-full ">
        <div class="profile-small-header-section md:flex">
            <h1>Conditions Treated</h1>
        </div>
        <div class="profile-main-content-section mt-3">
            <div class="service-list-box flex-wrap flex">
                <span v-for="condition in conditions" class="shadow px-3 py-2 rounded-full text-white font-bold mr-4 mb-4" :style="{'background-color': condition.hex_color}">
                    {{condition.name}}
                </span>
            </div>
        </div>
    </div>
</template>


<script>
import serviceDeliveryTypeInfo from '../../../../constants/infobox/serviceDeliveryTypeInfo';
import CreateUpdateModal from '../../../CreateUpdateModal.vue';

    export default {
        props: ['conditions'],
        data()
        {
            return {
                toolTipModal: false,
                toolTipMessage: {
                    title: '',
                    description: '',
                    link: ''
                }
            }
        },
        methods: {
            showToolTipModal(method)
            {
                this.toolTipMessage.title = method.title;
                this.toolTipMessage.description = '<p>'+method.clientDescription+'</p>';
                this.toolTipModal = true;
            }
        },
        mounted() {
        }

    }
</script>
