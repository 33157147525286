// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../../../node_modules/vue-select/dist/vue-select.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vue-select1[data-v-4a7a6e28]{z-index:9999}.form-section[data-v-4a7a6e28]{height:270px!important}", "",{"version":3,"sources":["webpack://./resources/js/components/Dashboard/Practitioners/CreateUpdatePractitionerQualification.vue"],"names":[],"mappings":"AAGA,8BACI,YADJ,CAGA,+BACI,sBAAJ","sourcesContent":["\n\n@import \"vue-select/dist/vue-select.css\";\n.vue-select1 {\n    z-index: 9999\n}\n.form-section{\n    height: 270px !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
