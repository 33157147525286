<template>
    <div class="form-group">
        <div class="row pw-checks mb1">
            <div class="col-6" :class="containsUppercase(password) && 'linethrough'" ng-class="{'faded': pwChecks[0]}">
                <i class="fa fa-circle"></i> One uppercase character
            </div>
            <div class="col-6" :class="hasLowerCase(password) && 'linethrough'" ng-class="{'faded': pwChecks[1]}">
                <i class="fa fa-circle"></i> One lowercase character
            </div>
            <div class="col-6" :class="password.length > 8 && 'linethrough'" ng-class="{'faded': pwChecks[2]}">
                <i class="fa fa-circle"></i> 8 characters minimum
            </div>
            <div class="col-6" :class="containsNumbers(password) && 'linethrough'" ng-class="{'faded': pwChecks[3]}">
                <i class="fa fa-circle"></i> One number
            </div>
            <div class="col-6" :class="password === confirm_password && password != '' && 'linethrough'" ng-class="{'faded': passMatch()}">
                <i class="fa fa-circle"></i> Passwords match
            </div>
        </div>
    </div>
</template>
  
<script>

    export default {
        props:{
            password:{
                default: '',
                type: String
            },
            confirm_password:{
                default: '',
                type: String
            }
        },
        data() {
            return {
                
            }
        },
        methods :{
            containsUppercase(str) {
                return /[A-Z]/.test(str);
            },
            hasLowerCase(str) {
                return str.toUpperCase() != str;
            },
            containsNumbers(myString) {
                return /\d/.test(myString);
            }

        },
        watch :{
            password(value) {
                if (!this.containsUppercase(value) || !this.hasLowerCase(value)|| !this.containsNumbers(value) || value.length < 8) {
                    this.$emit('readyTosend', 'false')
                }else {
                    this.$emit('readyTosend', 'true')
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.linethrough{
    opacity: 0.5;
    text-decoration: line-through;
}
</style>
      