import axios from 'axios';
export default {
    namespaced: true,
    state: {
        postcode: '',
        titles: [],
        genders: [],
        isActivePage: true,
        breadCrumbs: {},
        validDate: ''
    },
    getters: {
        // getting the postcode from the state
        GET_POSTCODE(state) {
            return state.postcode;
        },
        GET_TITLES(state)
        {
          return state.titles;
        },
        GET_GENDERS(state)
        {
          return state.genders;
        },
        GET_BREADCRUMBS(state)
        {
          return state.breadCrumbs;
        }
    },
    actions:{
        capitalizeFirstLetter(str) {
          return str.charAt(0).toUpperCase() + str.slice(1);
        },
        // the format postcode action created here to be called anywhere in the project
        formatPostcodeAction({ commit }, postcode) {
            var pcd = postcode.toUpperCase();
            pcd = pcd.replace(/[^0-9a-z]/gi, '');
            var parts = pcd.match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/);

          if(parts != null) {
            parts.shift();
            postcode = parts.join(' ');
          } else {
            alert('The postcode you have entered looks unusual. If you believe it is correct, please proceed anyway.')
          }
        //   setting the mutation in order to update the state
          commit('setPostcode', postcode)
        },
        loadWorkflow({commit}, payload) {
          return new Promise((resolve, reject) => {
            axios.post('/get-workflow', {type: payload})
            .then((res) => {
              resolve(res)
            }).catch((e) => {
              console.log(e)
              reject(e)
            })
          }) 
        },
        // get list of values from config file
        getValues({commit}, payload)
        {
          payload.forEach(async (value, index) => {
           await axios.post('/get-values/'+value)
            .then((res) => {
              const values = res.data;
              // set dynamic commit name
              const commitName = 'SET_'+value.toUpperCase();
              // dynamically commit values of function
              commit(commitName , values)
            }).catch((e) => {
              console.log(e)
            })
          })

        },
        logout()
        {
          axios.post('/logout')
          .then(() => {
            window.location.reload();
          })
        },
        loadBreadcrumbs({commit}, payload)
        {
          commit('SET_BREADCRUMBS', payload);
        },
        redirectAction(context, tabName) {
          const urlParams = new URLSearchParams(window.location.search);
          
          const currentTab = urlParams.get('tab');

          const newUrl = window.location.pathname + '?tab=' +tabName;
          
          window.location.href = newUrl;
        }
    },
    mutations: {
        setPostcode(state, data)
        {
            state.postcode = data;
        },
        SET_TITLES(state, titles)
        {
          state.titles = titles;
        },
        SET_GENDERS(state, genders)
        {
          state.genders = genders;
        },
        SET_BREADCRUMBS(state, breadcrumbs)
        {
          state.breadCrumbs = breadcrumbs;
        }
    },
    
}