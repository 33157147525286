<template>
    <div>
    
        <table-framework
                :theads="theads"
                :tableTitle="'Your Reviews'"
                :showTableActionButton="false"
                :tableButtonText="'Add Review'"
                :orderAscOrDesc="orderAscOrDescData"
                @whichHead="sortHead"
                @tableAction="openAddReviewModal()"
            >
                <tbody slot="table-body">
                    <tr class="" v-if="actualReviews.length == 0">
                        <p class="p-3 mx-2">No reviews yet.</p>
                    </tr>
                    <tr v-else v-for="review in actualReviews">
                        <td class="truncate" style="padding: 12px 25px!important; max-width: 20px ;">{{ review.service_received }}</td>
                        <td class="truncate" style="padding: 12px 25px!important; max-width: 180px ;" >{{ review.review_text}}</td>
                        <!-- <AnonymousEditTile v-if="review.anonymous === 1" :review="review" style="max-width: 35px ;"/>
                        
                         <td v-else class="truncate" style="padding: 12px 25px!important; max-width: 25px ;">{{ review.author}}</td> -->
                         <td style="padding: 12px 25px!important;max-width: 10px ;">{{ review.rating}} /5</td>
                         
                        <td style="padding: 12px 0px!important;max-width: 25px ;">
                            <span v-if="review.confirmed == 1" class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded  dark:text-gray-700 border border-green-400">Published</span>
                            <span v-else-if="review.confirmed == 2" class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded  dark:text-gray-700 border border-yellow-300" style="white-space:nowrap;">Pending Review</span>
                            <span v-else class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded  dark:text-gray-700 border border-red-400">Rejected</span>
                        </td>
                         <td  style="padding: 12px 25px!important">
                            <span v-if="review.import == 0" class="bg-green-100 text-green-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Patient</span>
                            <span v-else class="bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Import</span>
                        </td>
                        <td class="edit-and-bin items-center" style="padding: 12px 25px!important">
                            <button v-if="review.import == 1" @click.prevent="openEditReviewModal(review)" type="button" class="rounded-lg px-3 py-2 text-xs font-medium text-center text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">Edit</button>
                            <div v-else style="width: 55px;"></div>
                            <button @click.prevent="deleteReviewRequest(review.id)" type="button" class="mx-2 px-3 py-2" name="Delete Review">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" style="color:red">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </button>
                             <!-- This sets a fixed width of 40 pixels for the last cell in the row. Adjust as needed. -->
                        </td>
                    </tr>
                </tbody>
             </table-framework>
             <create-update-modal
                v-if="toggleReviewModal"
                @close="toggleReviewModal = false;"
                :title="'Add a new review request'"
                :buttonText="'Request Review'"
                :disabled="getLoadingSubmitButton"
                :loading="getLoadingSubmitButton"
                @save="saveReviewRequest(modalData)"
             >
                <div class="" slot="content">
                        <AddReviewModalContent @eventListener="updateModalData($event)" :target="reviews" :type="'add'"></AddReviewModalContent>
                </div>
    
             </create-update-modal>
             <create-update-modal
                v-if="toggleEditReviewModal"
                @close="toggleEditReviewModal = false;"
                :title="'Edit review '"
                :buttonText="'Edit'"
                :disabled="getLoadingSubmitButton"
                :loading="getLoadingSubmitButton"
                @save="saveEditReviewRequest(selectedReview)"
             >
                <div class="" slot="content">
                        <EditReviewModalContent @eventListener="updateModalData($event)" :target="selectedReview" :type="'edit'"></EditReviewModalContent>
                </div>
    
             </create-update-modal>
    </div>
    </template>
    
    <script>
    import TableFramework from '../../Table.vue';
    import clinicReviewsTableHeads from '../../../constants/services/clinicReviewsTableHeads';
    import clinicReviewsTypes from '../../../constants/services/clinicReviewTypes';
    import CreateUpdateModal from '../../CreateUpdateModal.vue';
    import AddReviewModalContent from './AddReviewModalContent.vue';
    import EditReviewModalContent from './EditReviewModalContent.vue';
    import AnonymousEditTile from './AnonymousEditTile';
    import { mapActions, mapGetters } from 'vuex';
    let orderAscOrDesc = {
        serviceAsc: 0,
        ratingAsc: 0,
        confirmedAsc: 0,
        sourceAsc: 0,
    }
        export default {
            props: ['reviews'],
            components: {
                TableFramework,
                CreateUpdateModal,
                AddReviewModalContent,
                EditReviewModalContent,
                AnonymousEditTile
            },
            data() {
                return {
                    theads: clinicReviewsTableHeads,
                    toggleReviewModal: false,
                    toggleEditReviewModal: false,
                    ReviewsTypes: clinicReviewsTypes,
                    modalData: {},
                    selectedReview: null,
                    actualReviews: {},
                    orderAscOrDescData : orderAscOrDesc
                }
            },
            methods: {
                ...mapActions('reviews', ['saveEditReviewRequest','saveReviewRequest', 'deleteReviewRequest']),
                openAddReviewModal()
                {
                    this.toggleReviewModal = true;
                },
                openEditReviewModal(selectedReview)
                {
                    this.selectedReview = selectedReview
                    this.toggleEditReviewModal = true;
                },
                updateModalData(data)
                {   
                    this.modalData = data;
                },
                sortHead(thead)
                {
                    this.actualReviews = this.reviews
                    switch(thead){
                        case 'Service': 
                            if(orderAscOrDesc.serviceAsc == 1){
                                this.reviews.sort((a,b) => a.service_received.localeCompare(b.service_received)) 
                                orderAscOrDesc.serviceAsc = 2
                            }else {
                                this.reviews.sort((a,b) => b.service_received.localeCompare(a.service_received)) 
                                orderAscOrDesc.serviceAsc = 1
                            }
                        ;break;
                        case 'Rating': 
                            if(orderAscOrDesc.ratingAsc == 1){
                                this.reviews.sort((a,b) => a.rating - b.rating)  
                                orderAscOrDesc.ratingAsc = 2
                            }else {
                                this.reviews.sort((a,b) => b.rating - a.rating) 
                                orderAscOrDesc.ratingAsc = 1
                            }
                        ;break;
                        case 'Status': 
                            if(orderAscOrDesc.confirmedAsc == 1){
                                this.reviews.sort((a,b) => a.confirmed - b.confirmed)  
                                orderAscOrDesc.confirmedAsc = 2
                            }else {
                                this.reviews.sort((a,b) => b.confirmed - a.confirmed) 
                                orderAscOrDesc.confirmedAsc = 1
                            }
                        ;break;
                        case 'Source':  
                        if(orderAscOrDesc.sourceAsc == 1){
                            this.reviews.sort((a,b) => a.import - b.import)  
                            orderAscOrDesc.sourceAsc = 2
                        }else {
                            this.reviews.sort((a,b) => b.import - a.import)  
                            orderAscOrDesc.sourceAsc = 1
                        }
                        ;break;
                    }
                    this.actualReviews = this.reviews
                }
            },
            computed:{
                ...mapGetters('reviews', ['getLoadingSubmitButton'])
            },
            created()
            {
                this.actualReviews = this.reviews
            }
        }
    </script>
    <style lang="scss" scoped>

    @media (min-width: 992px){
        .edit-and-bin{

            display: flex;
        }
    }
    @media (max-width: 991px){
        .edit-and-bin{

            display: contents;
        }
    }
    </style>