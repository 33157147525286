<template>
    <div  v-if="!loadFormdata && !alreadySent" class="px-4 mb-4 container">
        <div class="form-section-info mt-4">
            <h3 class="form-section-info-title font-semibold pb-3">{{ GET_WORKFLOW.title}}</h3>
            <span class="text-gray-400">{{GET_WORKFLOW.description}}</span>
        </div>
        <div class="form-section mt-4 flex px-4">
            <div class="form-section-info w-1/6">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ GET_WORKFLOW.components.reason.title }}
                    <span v-if="GET_WORKFLOW.components.reason.required" class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">{{GET_WORKFLOW.components.reason.description }}</p>
            </div>
            <div class="form-section-content w-1/3 ml-5">
                <div class="mb-3">
                    <select :name="GET_WORKFLOW.components.reason.inputName" id="" v-model="modalData.reason"  :required="GET_WORKFLOW.components.reason.required">
                        <option selected disabled value=""></option>
                        <option v-for="(option, index) in GET_WORKFLOW.components.reason.content" :key="index" :value="option">{{option}}</option>
                    </select>
                    <InputErrorMessage v-if="showErrors && (!modalData.reason || modalData.reason === '')"/>
                </div>
            </div>
        </div>
        <div class="form-section mt-4 flex px-4">
            <div class="form-section-info w-1/6">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ GET_WORKFLOW.components.reason_details.title }}
                    <span v-if="GET_WORKFLOW.components.reason_details.required" class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">{{GET_WORKFLOW.components.reason_details.description }}</p>
            </div>
            <div class="form-section-content w-1/3 ml-5">
                <div class="mb-3">

                    <label>
                        <p class="text-sm italic font-light ml-auto text-right opacity-70">(Characters: {{ reviewTextCharacterCount }}/{{GET_WORKFLOW.components.reason_details.max}})</p>
                    </label>
                    <textarea class="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        rows="4" 
                        cols="50"
                        :id="GET_WORKFLOW.components.reason_details.inputName" 
                        :name="GET_WORKFLOW.components.reason_details.inputName"
                        v-model="modalData.reason_details"
                        @keyup="modificationState()"
                        :placeholder="GET_WORKFLOW.components.reason_details.help"
                        :maxlength="GET_WORKFLOW.components.reason_details.max"
                    ></textarea>
                <InputErrorMessage v-if="showErrors && (!modalData.reason_details || modalData.reason_details === '')"/>
                </div>
            </div>
        </div>

        <div class="ml-5">
            <div class="">
                <button :disabled="loadingSubmitButton" @click="submitForm(GET_WORKFLOW.saveFunction)" type="submit" class="px-4 action-button border cursor-pointer rounded-md submit-button ">
                    <span v-if="loadingSubmitButton" class="spinner-border" role="status" aria-hidden="true"></span>
                    <span v-else>{{ GET_WORKFLOW.saveButton }}</span>  
                </button>
            </div>
        </div> 
    </div>
    <div  v-else-if="!loadConfirmFormdata && alreadySent" class="px-4 mb-4 container">
        <div class="form-section-info mt-4">
            <h3 class="form-section-info-title font-semibold pb-3">{{ GET_WORKFLOW.title}}</h3>
            <div class="bg-red-500 text-white p-4">
                <span>{{GET_WORKFLOW.description}}</span>
            </div>

        </div>
        <div class="form-section mt-4 flex px-4">
            <div class="form-section-info w-1/6">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ GET_WORKFLOW.components.confirm_code.title }}
                    <span v-if="GET_WORKFLOW.components.confirm_code.required" class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">{{GET_WORKFLOW.components.confirm_code.description }}</p>
            </div>
            <div class="form-section-content w-1/3 ml-5">
                <div class="mb-3">

                    <input 
                    :type="GET_WORKFLOW.components.confirm_code.inputType" 
                    class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="modalConfirmData.confirm_code" 
                    :required="GET_WORKFLOW.components.confirm_code.required"
                    :name="GET_WORKFLOW.components.confirm_code.inputName"
                    />
                    <InputErrorMessage v-if="showErrors && (!modalConfirmData.confirm_code || modalConfirmData.confirm_code === '')"/>
                </div>
            </div>
        </div>

        <div class="ml-5">
            <div class="">
                <button :disabled="loadingSubmitButton" @click="submitConfirmForm(GET_WORKFLOW.saveFunction)" type="submit" class="px-4 action-button border cursor-pointer rounded-md submit-button ">
                    <span v-if="loadingSubmitButton" class="spinner-border" role="status" aria-hidden="true"></span>
                    <span v-else>{{ GET_WORKFLOW.saveButton }}</span>  
                </button>
                <button @click="abortCodeDeleteAccount" type="submit" class="px-4 border abort-button cursor-pointer rounded-md submit-button">
                    <span>{{ GET_WORKFLOW.abortButton }}</span>  
                </button>
            </div>
        </div> 
    </div>


</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InputErrorMessage from '../../Utilities/InputErrorMessage.vue'

export default {
    data() {
        return {
            alreadySent: false,

            reviewTextCharacterCount: 0,
            ReadyStatus: false,
            form:{},
            formData: {},
            loadFormdata: true,
            loadConfirmFormdata: true,
            showErrors: false,
            loadingSubmitButton: false,
            loadingAbortButton: false,
            modalData: {
                reason: '',
                reason_details: ''
            },
            modalConfirmData: {
                confirm_code: ''
            }
        }
    },
    components:{InputErrorMessage},
    methods: {
        ...mapActions('account', ['loadWorkflow', 'confirmDeleteAccount', 'setDeleteDetails', 'confirmCodeDeleteAccount', 'abortCodeDeleteAccount']),
        ...mapActions('commonFunctions', ['redirectAction']),
        readyTosend(ReadyStatus) {
            this.ReadyStatus = ReadyStatus
        },
        modificationState()
        {
            this.reviewTextCharacterCount = this.modalData.reason_details.length;
        },
        submitForm(saveFnc)
        {
            this.loadingSubmitButton = true
            this.showErrors = true
            if(this.modalData.reason === ''
            || this.modalData.reason_details === ''){
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please fill the form correctly',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
                this.loadingSubmitButton = false
            }
            else {
                eval('this.'+saveFnc+'(this.modalData)')
                    .then(res => {
                        this.triggerDeleteDetails()
                        this.loadingSubmitButton = false
                    }).catch((e) => {
                        this.loadingSubmitButton = false
                    })
            }
        },
        submitConfirmForm(saveFnc)
        {
            this.loadingSubmitButton = true
            this.showErrors = false
            if(this.modalConfirmData.confirm_code === ''){
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please fill the form correctly',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
                this.loadingSubmitButton = false
            }
            else {
                eval('this.'+saveFnc+'(this.modalConfirmData)')
                    .then(res => {
                        this.loadingSubmitButton = false
                    }).catch((e) => {
                        this.loadingSubmitButton = false
                    })
            }
        },
        triggerDeleteDetails()
        {

            this.loadConfirmFormdata = true
            this.loadFormdata = true
            this.alreadySent = false
            this.showErrors = false
            this.setDeleteDetails()
            .then(res => {
                if(res.data.length > 0) {
                   this.alreadySent = true
                    this.loadWorkflow('confirmDeleteAccount')
                    .then(res => {
                        this.loadConfirmFormdata = false
                    }).catch(e => {
                        console.log(e)
                    })
                }else {
                    this.loadWorkflow('deleteAccount')
                    .then(res => {
                        this.loadFormdata = false
                    }).catch(e => {
                        console.log(e)
                    })
                }
            })
        }

    },
    created() {
        this.triggerDeleteDetails()
        
    },
    computed: {
        ...mapGetters('account', ['GET_WORKFLOW'])
    }
}
</script>

<style lang="scss" scoped>
.action-button{
    width: fit-content;
}
.abort-button{
    height: 52px;
}
</style>