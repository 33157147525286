<template>
        <modal :name="modalName" :size="size">
            <template #title>
                <div>
                    <span v-text="modalTitle" class="capitalize"></span>
                </div>
            </template>

            <template #body>
                <slot></slot>
            </template>

            <template #footer>
                <div class="flex items-center justify-end">
                    <button
                        type="submit"
                        class="btn btn-primary ml-2 p-3"
                        v-text="buttonTitle"
                    ></button>
                </div>
            </template>
        </modal>

</template>

<script>

export default {
    props: {
        modalName: String,
        modalTitle: String,
        buttonTitle: String,
        category: String,
        size: String,
        modalData: Object,
        defaultData: [Object],
        subcategory: [Object],
        subcategoryUpdateArray: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        add()
        {
        }
    }

}
</script>
