<style scoped lang="scss">
.fprofile-img-wrapper {
    margin-bottom: 2rem;
    @media(max-width: 800px){
      background-color: #fff;
      border: 1px solid #e5e5e5;
      padding: 1rem;
      position: relative;
      z-index: 10;
      margin-top: -85px;
      margin-bottom: 1rem;
    }
    .btn-group > * {
      flex-grow: 1;
      @media(max-width: 800px){
        white-space: normal;
        .fa {
          // display: none;
        }
      }
    }
  }
  
  .profile-image-inner {
    margin-top: .5rem;
    position: relative;
    @media(max-width: 800px){
      margin-top: 0;
      display: flex;

    }
  }
.clinic-profile-picture {

  width:  150px;
  height: 150px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0,0,0,.2);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  z-index: 10;
  display: inline-block;
  box-shadow: inset 6px -5px #5CECC0, inset 6px -5px 0px 0px #5CECC0;
  -moz-box-shadow: inset 6px -5px #5CECC0, inset 6px -5px 0px 0px #5CECC0;

  @media(max-width: 800px){
    position: static;
    box-shadow: none;
    border: 1px solid #e5e5e5;
    box-shadow: inset 6px -5px #5CECC0, inset 6px -5px 0px 0px #5CECC0;
  -moz-box-shadow: inset 6px -5px #5CECC0, inset 6px -5px 0px 0px #5CECC0;

  }

}
</style>


<template>
    <div class="profile-img-wrapper flex">
        <div class="profile-image-inner mx-auto">
            <div class="clinic-profile-picture" :style="'background-image: url('+profileImage+')'"></div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            profileImage: {
                type: String
            }
        }
    }
</script>