import axios from 'axios';
import { defaultsDeep } from 'lodash';
export default {
    namespaced: true,
    state: {
        loadingSubmitButton: false,
    },
    getters: {
        getLoadingSubmitButton(state){
            return state.loadingSubmitButton
        },
    },
    actions:{
        getPractitionerCompliancesAction({}, practitionerRef) {
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/practitioners/practitioner-compliances/'+  practitionerRef)
                .then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        complianceFileUploadAction({commit}, formdata){
          return new Promise((resolve, reject) => {
            axios.post('/dashboard/practitioners/compliance-file-upload', formdata)
            .then(response => {
              resolve(response)
            }).catch(error => {
              reject(error)
            });
          })
        }
        
    },
    mutations: {
        setLoadingSubmitButton(state, loadingState) {
            state.loadingSubmitButton = loadingState
        },
        
    },
    
}