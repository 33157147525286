<template>
  <form @submit.prevent="updateClinic(satelite.id)" class="modal-form">
    <div class="block">
        <div class="tabs flex w-full scroll-x justify-around" style="position:relative;">
          <div class="w-full" v-for="(tab, index) in tabs" :key="index">
            <li class="cursor-pointer transition py-3 px-4 tab"
                :class="current_step===index ? 'bg-white text-green-500 border-b-4 border-indigo-500' : 'bg-gray-200 text-gray-500'"
                  v-text="tab">
            </li>
          </div>
        </div>
    </div>
    <div class="modal-body p-8">
      <slot name="body">
        <div class="" v-if="errors">
            <div class="" v-for="(error, index) in errors" :key="index">
                <p class="p-2 bg-red-300 text-red-700 rounded">{{error}}</p>
            </div>
        </div>
          <div class="p-5" v-if="current_step === 0">
            <h5>Clinic Identity</h5>
            <div class="form-input my-4">
                <label class="block text-gray-500 font-bold mb-1 pr-4 my-auto" name="clinic_name">Clinic Name</label>
                <input class="mt-2 appearance-none border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white bg-white focus:border-yellow-500 disabled:bg-slate-500" v-model="clinic.name" id="inline-full-name" type="text" name="clinic_name" :disabled="clinic.use_same_name === true">
                <div v-if="satelite.head_office !== 1" class="flex items-center mb-4 mt-3">
                    <input id="default-checkbox" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" @change="useSameDetails(clinic.use_same_name, 'name')" v-model="clinic.use_same_name" >
                    <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Use the main clinic name</label>
                </div>
            </div>
          </div>
          <div class="" v-if="current_step === 1">
            <h5>Clinic Location</h5>
            <div class="form-input my-4">
                <label class="block text-gray-500 font-bold mb-1  pr-4 my-auto" name="address_line1">Address Line 1</label>
                <input class="appearance-none border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500" v-model="clinic.address_line1" id="inline-full-name" type="text" name="address_line1">
            </div>
            <div class="form-input my-4">
              <label class="block text-gray-500 font-bold mb-1 pr-4 my-auto" name="address_line2">Address Line 2</label>
              <input class="appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500" v-model="clinic.address_line2" id="inline-full-name" type="text" name="address_line2">
            </div>
            <div class="form-input my-4">
              <label class="block text-gray-500 font-bold mb-1 pr-4 my-auto" name="address_line3">Address Line 3</label>
              <input class=" appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500" v-model="clinic.address_line3" id="inline-full-name" type="text" name="address_line3">
            </div>
            <div class="form-input my-4">
              <label class="block text-gray-500 font-bold mb-1 pr-4 my-auto" name="postcode">Postcode</label>
              <input class=" appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500" v-model="clinic.postcode" id="inline-full-name" type="text" name="postcode" @change="formatPostcode()">
            </div>
            <div class="form-input my-4">
              <label class="block text-gray-500 font-bold mb-1 pr-4 my-auto" name="city">City</label>
              <input class=" appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500" v-model="clinic.city" id="inline-full-name" type="text" name="city">
            </div>
            <div class="form-input my-4">
              <label class="block text-gray-500 font-bold mb-1 pr-4 my-auto" name="county">County</label>
              <input class=" appearance-none border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500" v-model="clinic.county" id="inline-full-name" type="text" name="county">
            </div>
          </div>
          <div class="p-5" v-if="current_step === 2">
            <h5>Clinic Contacts</h5>
            <div class="form-input my-4">
              <label class="block text-gray-500 font-bold mb-1 pr-4 my-auto" name="city">Email</label>
              <input class=" appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500" v-model="clinic.email" id="inline-full-name" type="email" name="email" :disabled="clinic.use_same_email === true">
              <div v-if="satelite.head_office !== 1"  class="flex items-center mb-4 mt-3">
                    <input id="default-checkbox" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"  @change="useSameDetails(clinic.use_same_email, 'email')" v-model="clinic.use_same_email" >
                    <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Use the main clinic email</label>
                </div>
            </div>
            <div class="form-input my-4">
              <label class="block text-gray-500 font-bold mb-1 pr-4 my-auto" name="county">Telephone</label>
              <input class=" appearance-none border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500" v-model="clinic.telephone" id="inline-full-name" type="text" name="telephone" :disabled="clinic.use_same_telephone === true">
              <div v-if="satelite.head_office !== 1" class="flex items-center mb-4 mt-3">
                    <input id="default-checkbox" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" @change="useSameDetails(clinic.use_same_telepehone, 'telephone')" v-model="clinic.use_same_telepehone" >
                    <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Use the main clinic telephone</label>
              </div>
            </div>
            <div class="form-input my-4">
              <label class="block text-gray-500 font-bold mb-1 pr-4 my-auto" name="county">Mobile Phone</label>
              <input class=" appearance-none border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500" v-model="clinic.mobile_phone" id="inline-full-name" type="text" name="mobile_phone" :disabled="clinic.use_same_mobile === true">
              <div v-if="satelite.head_office !== 1"  class="flex items-center mb-4 mt-3">
                    <input id="default-checkbox" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" @change="useSameDetails(clinic.use_same_mobile, 'mobile')" v-model="clinic.use_same_mobile" >
                    <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Use the main clinic mobile phone</label>
              </div>
            </div>
          </div>
          <div class="p-5" v-if="current_step === 3">
            <h5>Review & Finish</h5>
            <div class="row flex">
                <p>City</p>
                <p>{{clinic.city}}</p>
            </div>
            <div class="row flex">
                <p>Postcode</p>
                <p>{{clinic.postcode}}</p>
            </div>
            </div>

        </slot>
    </div>

    <div class="modal-footer text-justify">
      <slot name="footer">
        <div class="action-buttons w-full flex container">
          <div class="my-auto">
            <a href="" class="modal-default-button cancel-button text-gray-600" @click="$emit('close')">
              Cancel
            </a>
          </div>
          <div class="actions ml-auto">
            <div class="actions ml-auto">
                <button class="back-button ml-auto py-2 px-4 border rounded-md cursor-pointer  back" @click.prevent="current_step--" v-show="current_step>0">Back</button>
                <button @click.prevent="nextStep()" v-show="current_step<tabs.length-1" class="aciton-button ml-auto py-2 px-4 border rounded-md  cursor-pointer  next">Next</button>
                <button type="submit" v-show="current_step === 3" class="action-button ml-auto border cursor-pointer rounded-md submit-button ">Update</button>
            </div>                      
            </div>
        </div>
      </slot>
    </div>
  </form> 
</template>
    
    <script>
    // importing the helpers for calling actions and getters from vuex
import { mapActions, mapGetters } from 'vuex';
        export default {
            props: ['satelite'],
            data() {
                return {
                    editClinicModal: false,
                    tabs: [
                        'Clinic Identity',
                        'Clinic Location',
                        'Clinic Contact',
                        'Review & Finish'
                    ],
                    current_step: 0,
                    clinic: {
                        name: this.satelite.name,
                        use_same_name: false,
                        use_same_email: false,
                        use_same_telepehone: false,
                        use_same_mobile: false,
                        email: this.satelite.email,
                        telephone: this.satelite.telephone,
                        mobile_phone: this.satelite.mobile_phone,
                        address_line1: this.satelite.address_line1,
                        address_line2: this.satelite.address_line2,
                        address_line3: this.satelite.address_line3,
                        city: this.satelite.city,
                        county: this.satelite.county,
                        postcode: this.satelite.postcode,
                    },
                    errors: [],
                }
            },
            methods: {
              // calling the actions from the modules 
                ...mapActions('commonFunctions', ['formatPostcodeAction']),
                ...mapActions('mainClinic', ['updateClinicAction']),
                nextStep()
                {

                    this.current_step++;
                },
                formatPostcode() 
                {
                  // calling the postcode action from vuex and assigning the value got from the Getter to the local variable 
                  this.formatPostcodeAction(this.clinic.postcode)
                  this.clinic.postcode = this.GET_POSTCODE
                },
                updateClinic(id)
                {
                  // calling the updating clinic action 
                  this.updateClinicAction({
                    id: id,
                    clinic: this.clinic
                  })
                  .then(() => {
                    this.$emit('close')
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Clinic Successfully Updated!',
                        showConfirmButton: false,
                        timer: 1500
                      }).then(() => {
                        window.location.reload();
                    })
                  })
                },
                useSameDetails(parameter, element) {
                  switch(element) {
                    case 'name': 
                      if(parameter === true) {
                        this.clinic.name = this.MAIN_CLINIC.name
                      }
                      ;break;
                    case 'email':
                      if(parameter === true) {
                        this.clinic.email = this.MAIN_CLINIC.email
                      }
                      ;break;
                    case 'telephone': 
                      if(parameter === true) {
                        this.clinic.telephone = this.MAIN_CLINIC.telephone
                      }
                      ;break;
                    case 'mobile': 
                      if(parameter === true) {
                        this.clinic.mobile_phone = this.MAIN_CLINIC.mobile_phone
                      }
                      ;break;
                  }
                }
                
            },
            computed: {
                // getting the postcode stored in vuex
              ...mapGetters('commonFunctions', ['GET_POSTCODE']),
              ...mapGetters('mainClinic', ['MAIN_CLINIC'])
            }

        }
    </script>
    
    <style scoped>

    </style>
    