import axios from 'axios';
export default {
    namespaced: true,
    state: {
        loadingSubmitButton: false,
        tabs: {},
        active_tab: '',
        ShowErrors: false,
        endorsements: []
    },
    mutations: {
        setLoadingSubmitButton(state, loadingState) {
            state.loadingSubmitButton = loadingState
        },
        setReviewsTabs(state, tabs)
        {
            state.tabs = tabs;
        },
        setActiveTab(state, tab)
        {
            state.active_tab = tab
        },
        setShowErrors(state, show)
        {
            state.ShowErrors =  show
        },
        setEndorsements(state, data)
        {
            state.endorsements = data;
        }
    },
    actions: {
        getServicesForReviews({}){
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/reviews/get-services-for-reviews')
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        saveReviewerName({}, payload){
            return new Promise((resolve, reject) => {
                axios.put('/dashboard/reviews/update-reviewer-name/' + payload.Id, {
                    author: payload.author,
                })
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        addReview({}, payload){
        return new Promise((resolve, reject) => {
            axios.post('/dashboard/reviews/add-review-by-clinic', payload)
            .then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        },
        saveReview({}, payload){
        return new Promise((resolve, reject) => {
            axios.post('/clinics/submitreview', payload)
            .then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        },
        resendReviewRequest({}, payload){
            return new Promise((resolve, reject) => {
                axios.post('/reviews/resendReviewRequest', payload)
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // delete review request
        deleteReviewRequest({commit, dispatch}, payload)
            {
                Swal.fire({
                title: "You're deleting this review request",
                text: "Are you sure you want to delete this review request?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete('/dashboard/reviews/delete-review-request/'+payload)
                    .then(() => {
                        Swal.fire({
                            toast: true,
                            icon: 'success',
                            title: 'Review request deleted',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        }).then(() => {
                            dispatch('commonFunctions/redirectAction', 'Existing Reviews', { root: true });
                            window.location.reload()
                        })
                    })
                }
            }).catch((e) => {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: e.response.status+' Error.',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true
                });
            });
        },
        saveEditReviewRequest({commit, dispatch}, payload){
        if(payload.author.length == 0
        || payload.service_received.length == 0
        || payload.rating == 0
        || payload.review_text.length == 0){
            commit('setShowErrors', true)
        }
        else {
            commit('setLoadingSubmitButton', true)
            return new Promise((resolve, reject) => {
                axios.put('/dashboard/reviews/edit-review/'+payload.id, payload)
                .then(response => {
                    Swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Review Data Updated',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true
                    }).then(() => {
                        dispatch('commonFunctions/redirectAction', 'Existing Reviews', { root: true });
                        // window.location.reload()
                    }).then(() => {
                        commit('setShowErrors', false)
                        commit('setLoadingSubmitButton', false)
                    })
                    resolve(response)
                }).catch(error => {
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Please fill all the necessary data',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                        });
                    commit('setShowErrors', false)
                    commit('setLoadingSubmitButton', false)
                    reject(error)
                })
                })
        }

        },
        // save review request
        saveReviewRequest({commit, dispatch}, payload) {
            if(payload.patient_email == ''){
                    commit('setShowErrors', true)
                }else {

                    commit('setLoadingSubmitButton', true)
                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ // email regex pattern
                    if( payload.patient_email && !emailPattern.test(payload.patient_email)){
                        Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Please provide a valid email',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                        });
                        commit('setLoadingSubmitButton', false)
                    }else {
                        axios.post('/dashboard/reviews/save-review-request', payload)
                        .then((res) => {
                            Swal.fire({
                                icon: 'success',
                                title: 'Review Request Sent',
                                animation: true,
                                showConfirmButton: false,
                                timer: 3000,
                            }).then(() => {
                                commit('setLoadingSubmitButton', true)
                                dispatch('commonFunctions/redirectAction', 'New+Reviews', { root: true });
                            })
                        }).catch((e) => {
                            console.log(e)
                        });
                    }
                }
        },
        // Change tab
        changeActiveTab({commit, dispatch}, payload)
        {

            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('tab', payload);
            const newUrl = window.location.origin + window.location.pathname + '?' + searchParams;
            window.history.pushState({path:newUrl},'',newUrl);

            commit('setActiveTab', payload);
        },
        setUrlTab({commit}, payload)
        {
            let a_tab = '';
            if (window.location.search.includes('Qualifications')){
                commit('setActiveTab', 'Qualifications')
            }
        },
        setupReviewsTabs({commit}){
            axios.get('/dashboard/reviews/setup-reveiws-tabs')
            .then((res) => {
                commit('setReviewsTabs', res.data)
            }).catch((e) => {
                console.log(e)
            });
        },
        getEndorsements({commit}) {
            axios.get('/dashboard/reviews/get-endorsements')
                .then((res) => {
                    commit('setEndorsements', res.data)
                }).catch((e) => {
                console.log(e)
            });
        },
        sendEndorsement({commit}, payload)
        {
            axios.post('/dashboard/reviews/send-endorsement', payload)
                .then((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Request Sent',
                        animation: true,
                        showConfirmButton: false,
                        timer: 3000,
                    }).then(() => {
                        window.location.reload();
                    })
                }).catch((e) => {
                    console.log(e)
            });
        }
    },
    getters: {
        getLoadingSubmitButton(state){
            return state.loadingSubmitButton
        },
        GET_PAGE_TABS(state)
        {
           return state.tabs
        },
        GET_ACTIVE_TAB(state)
        {
            return state.active_tab
        },
        GET_SHOW_ERRORS(state)
        {
            return state.ShowErrors
        },
        actualReviews(state)
        {
            return state.endorsements;
        }
    }

}
