var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-section container-fluid"},[_c('div',{staticClass:"overview-cards"},[_c('div',{staticClass:"row",staticStyle:{"margin-top":"-40px"}},[_c('div',{staticClass:"col-xl-3 col-sm-6 mb-xl-0 mb-4"},[_c('mini-statistics-card',{attrs:{"title":_vm.data.complianceReport.status,"value":"Status","icon":{
                      component: _vm.data.complianceReport.component,
                      background: _vm.data.complianceReport.background,
                    },"direction-reverse":""}})],1),_vm._v(" "),_c('div',{staticClass:"col-xl-3 col-sm-6 mb-xl-0 mb-4"},[_c('mini-statistics-card',{attrs:{"title":"Clinics Claimed","value":_vm.data.clinicCount,"icon":{
                      component: 'hospital',
                      background: 'bg-gradient-red',
                    },"direction-reverse":""}})],1),_vm._v(" "),_c('div',{staticClass:"col-xl-3 col-sm-6 mb-xl-0 mb-4"},[_c('mini-statistics-card',{attrs:{"title":"Practitioner's Claimed","value":_vm.data.practitionersCount,"icon":{
                      component: 'users',
                      background: 'bg-gradient-dark',
                    },"direction-reverse":""}})],1),_vm._v(" "),_c('div',{staticClass:"col-xl-3 col-sm-6 mb-xl-0 mb-4"},[_c('mini-statistics-card',{attrs:{"title":"Monthly Profile Visits","value":_vm.data.monthlyVisits,"icon":{
                      component: 'stats_up',
                      background: 'bg-gradient-orange',
                    },"direction-reverse":""}})],1)])]),_vm._v(" "),_c('div',{staticClass:"mt-5"},[_vm._m(0),_vm._v(" "),_c('QuickActions')],1),_vm._v(" "),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"wrapper"},[_c('AnalyticDashboard')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',{staticClass:"text-separation flex mr-5"},[_c('h5',{staticClass:"my-auto font-bold text-slate-500"},[_vm._v("Quick Actions")])]),_vm._v(" "),_c('hr',{staticClass:"flex-grow my-auto opacity-50",staticStyle:{"background-color":"rgba(0,0,0,0.2)!important"}})])
}]

export { render, staticRenderFns }