const singleClinicNameModalForm = 
    {
        "title": "",
        "loadFunction": "",
        "saveAddFunction": "",
        "saveEditFunction": "saveClinicName",
        "buttonCreateText": "",
        "buttonUpdateText": "Update",
        "components":
        {
            "componentGroup1":
            {
                "sectionTitle": "Clinic Name",
                "sectionExplenation": "Please provide a name for this clinic",
                "inputTitle": "Clinic Name",
                "inputType": "text",
                "inputName": "satelite_name",
                "required": true,
                "dataRef": 'name',
                "vModel": "name"

            },

        }
    }


export default singleClinicNameModalForm;