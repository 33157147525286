var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-profile-content p-4 max-height"},[_c('div',{staticClass:"overview-cards mt-5"},[_c('div',{staticClass:"row",staticStyle:{"margin-top":"-40px"}},[_c('div',{staticClass:"col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-xl-0 mb-4"},[_c('mini-action-card',{staticClass:"cursor-pointer",attrs:{"title":"Edit Main Clinic Logo","modalId":'updateClinicLogo',"icon":{
               component: 'image-o',
               background: 'bg-gradient-green',
               },"direction-reverse":""},on:{"openUpdateModal":_vm.openUpdateModal}})],1),_vm._v(" "),_c('div',{staticClass:"col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-xl-0 mb-4"},[_c('mini-action-card',{staticClass:"cursor-pointer",attrs:{"title":"Add Clinic","modalId":'addClinic',"icon":{
               component: 'weight_scale',
               background: 'bg-gradient-green',
               },"direction-reverse":""},on:{"openUpdateModal":_vm.openUpdateModal}})],1),_vm._v(" "),_c('div',{staticClass:"col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-xl-0 mb-4"},[_c('mini-action-card',{staticClass:"cursor-pointer",attrs:{"title":"Add a practitioner","modalId":'addPractitioner',"icon":{
               component: 'location',
               background: 'bg-gradient-green',
               },"direction-reverse":""},on:{"openUpdateModal":_vm.openUpdateModal}})],1),_vm._v(" "),_c('div',{staticClass:"col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-xl-0 mb-4"},[_c('mini-action-card',{staticClass:"cursor-pointer",attrs:{"title":"Update main clinic address","modalId":'updateMainAddress',"icon":{
               component: 'wallet',
               background: 'bg-gradient-green',
               },"direction-reverse":""},on:{"click":function($event){_vm.paymentMethodModal = true},"openUpdateModal":_vm.openUpdateModal}})],1)])]),_vm._v(" "),(_vm.UpdateProfileImagesModal)?_c('UpdateProfileImagesModal',{attrs:{"pictureConcerned":_vm.MAIN_CLINIC.image_path,"chosenModal":_vm.modalId},on:{"closeModal":_vm.closeModal}}):_vm._e(),_vm._v(" "),(_vm.updateCreateClinicModal)?_c('UpdateCreateClinicModal',{attrs:{"chosenModal":_vm.modalId},on:{"closeModal":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }