var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"flex"},[_c('button',{staticClass:"ml-auto p-2 rounded text-white font-bold bg-[#0BD2A4] mb-3",on:{"click":function($event){$event.preventDefault();return _vm.goToServices.apply(null, arguments)}}},[_vm._v("Add New Service")])]),_vm._v(" "),_c('table-framework',{attrs:{"theads":_vm.theads,"tableTitle":'Your Services',"showTableSearch":true,"showTableToggle":true,"tableSearchText":_vm.searchInput,"showTableActionButton":false,"allSelected":_vm.allSelected,"toggle":_vm.toggle,"filteredOptions":_vm.filteredServices,"selectedIds":_vm.selectedIds},on:{"textSearch":_vm.textSearch,"onToggleChange":_vm.onToggleChange}},[(_vm.selectedIds && _vm.filteredServices && _vm.filteredServices.length > 0)?_c('tbody',{attrs:{"slot":"table-body"},slot:"table-body"},_vm._l((_vm.filteredServices),function(service,index){return _c('tr',{key:service.id,class:{
                'selectedRow': _vm.selectedIds.includes(service.ref),
                'bg-white': !_vm.selectedIds.includes(service.ref),
                'border-b': true,
                'dark:bg-gray-800': true,
                'dark:border-gray-700': index !== _vm.filteredServices.length - 1,
                'hover:bg-gray-30': true,
                'dark:hover:bg-gray-600': true,
                }},[_c('th',{staticClass:"ml-2 py-4 px-6 whitespace-nowrap"},[_c('InputToggle',{attrs:{"selectedIds":_vm.selectedIds,"element":service.ref},on:{"toggleSelected":_vm.toggleSelected}})],1),_vm._v(" "),_c('th',{staticClass:"py-4 px-6 whitespace-nowrap",attrs:{"scope":"row"}},[_vm._v("\n                    "+_vm._s(service.main_service_name)+"\n                ")]),_vm._v(" "),_c('th',{staticClass:"py-4 px-6 whitespace-nowrap",attrs:{"scope":"row"}},[_vm._v("\n                    "+_vm._s(service.ref)+"\n                ")])])}),0):_vm._e(),_vm._v(" "),(_vm.filteredServices.length === 0)?_c('div',{staticClass:"mt-5"},[_c('p',[_vm._v("You don't have any services yet.")])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }