<style lang="scss" scoped>
    .quote-calculator-wrapper {
        background-color: #10192d;
        border-radius: 10px!important;

        .title-section {

            h3 {
                font-weight: 800;
                color: #0BD2A4;
            }

            p, label {
                font-weight: 600;
            }
        }
    }

    .price {
        color: #4d5251;
        background-color: #f4c11b;
        border-radius: 5px;
        box-shadow: 0 0 5px 5px #0000;
        padding: 10px 5px;

        p {
            margin: 0;
            padding: 0 10px;;
            color: #ffff;
            font-weight: 800;
            font-size: 18px;

        }
    }

    .btn-theme {
        background-color: #0BD2A4;
        color: #10192d;
        border: none;
    }
</style>

<template>
    <div>
        <div class="quote-calculator-wrapper rounded shadow-md p-4">
            <div class="title-section">
                <h3 class=" text-center font-weight-bolder">Price Calculator</h3>
                <p class="text-white mt-3">Get an instant quote to see how much you're going to pay for the service you are looking for.</p>
                <div class="form-group my-2">
                    <label for="" class="text-white">Select Service</label>
                    <select name="service" id="" v-model="selectedService" @change.prevent="pickVal(selectedService)">
                        <option disabled selected>Please select a service</option>
                        <option v-for="service in services" :value="service">{{ service.main.main_service_name }}</option>
                    </select>
                </div>
                <div class="form-group my-2" v-if="selectedService.main && JSON.parse(selectedService.main.pricings).pricing_modality == 'radius'">
                    <label for="" class="text-white">Insert Your Postcode</label>
                    <input v-model="postcode" type="text" @change="formatPostcode(postcode)">
                </div>
                <div class="" >
                    <div class="form-group my-2" v-if="selectedService && selectedService.children">
                        <label for="" class="text-white">Select the service extra</label>
                        <select name="service" id="" v-model="serviceExtra" @change.prevent="setServiceExtra(serviceExtra)">
                            <option disabled selected>Please select a service extra</option>
                            <option value="0">None</option>
                            <option v-for="serviceEx in selectedService.children" :value="serviceEx">{{ serviceEx.main_service_name }}</option>
                        </select>
                    </div>
                </div>
                <div class="price-output-wrapper my-4" v-if="quotePrice">
                    <p class="text-center text-white">The overall estimated price for this service is </p>


                    <div class="flex">
                        <div class="price mx-auto">
                            <p class="">£ {{ quotePrice }}</p>
                        </div>
                    </div>
                </div>

                <div class="quote-cta mt-4">
                    <p v-if="askQuote" class="text-white text-center">Email the clinic for a quote</p>
                    <button v-else class="btn btn-theme w-full" @click.prevent="calculateQuote()">Calculate Quote</button>
                </div>


            </div>
        </div>
    </div>
</template>


<script>
import Dropdown from '../../Dropdown.vue';
import {mapActions, mapGetters} from 'vuex';

    export default {
        components: {Dropdown},
        props: ['services', 'main', 'satelite'],
        data() {
            return {
                selectedService: {},
                serviceExtra: {},
                quotePrice: "",
                postcode: "",
                distance: "",
                mainPrice: "",
                tier: "",
                extraPrice: "",
                tierArr: "",
                askQuote: false
            }
        },
        computed: {
            ...mapGetters('commonFunctions', ['GET_POSTCODE']),
            mainFixedPrice()
            {
                if(this.selectedService && JSON.parse(this.selectedService.main.pricings))
                {
                    return JSON.parse(this.selectedService.main.pricings).fixed_service_price;
                }
            },
        },
        methods: {
            ...mapActions('commonFunctions', ['formatPostcodeAction']),
            ...mapActions('analytics', ['logAnalytics']),
            formatPostcode(postcode)
            {
              // calling the postcode action from vuex and assigning the value got from the Getter to the local variable
              this.formatPostcodeAction(postcode)
              this.postcode = this.GET_POSTCODE

            },
            pickVal(service)
            {
              if(JSON.parse(service.main.pricings).pricing_modality == 'quote')
              {
                this.askQuote = true;
              } else {
                this.askQuote = false
              }
            },
            setServiceExtra(extra)
            {
                if(this.serviceExtra != 0)
                {
                    this.extraPrice = Number(JSON.parse(this.serviceExtra.pricings).surcharge_service_price);
                } else {
                    this.extraPrice = 0;
                }
            },
            calculateQuote()
            {

                if(this.serviceExtra.pricings)
                {
                    if(JSON.parse(this.serviceExtra.pricings).pricing_modality == 'included')
                    {
                      this.extraPrice = 0;
                    } else {
                        this.extraPrice = Number(JSON.parse(this.serviceExtra.pricings).surcharge_service_price);
                    }
                }

                if(JSON.parse(this.selectedService.main.pricings).pricing_modality == 'fixed')
                {
                  this.mainPrice = Number(JSON.parse(this.selectedService.main.pricings).fixed_service_price);

                } else if(SON.parse(this.selectedService.main.pricings).pricing_modality == 'quote')
                {
                }else if(JSON.parse(this.selectedService.main.pricings).pricing_modality == 'radius')
                {
                  axios.get('https://api.postcodes.io/postcodes/'+this.postcode)
                    .then((res) => {
                        var userLat =  res.data.result.latitude;
                        var userLon = res.data.result.longitude;
                        var clinicLat = this.satelite.lat;
                        var clinicLon = this.satelite.lon;

                        const R = 6371e3; // metres
                        const φ1 = userLat * Math.PI/180; // φ, λ in radians
                        const φ2 = clinicLat * Math.PI/180;
                        const Δφ = (clinicLat-userLat) * Math.PI/180;
                        const Δλ = (clinicLon-userLon) * Math.PI/180;

                        const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                                  Math.cos(φ1) * Math.cos(φ2) *
                                  Math.sin(Δλ/2) * Math.sin(Δλ/2);
                        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

                        const d = R * c; // in metres

                        const distance = (d.toFixed(1)/1000)/1.6;

                        // determine if the distance is greater than 20 miles radius
                        if(distance > 20)
                        {
                            Swal.fire(
                            'Too far!',
                            'This postcode seems to be more than 20 miles radius from this clinic. Please insert a different postcode to calculate the price.!',
                            'error'
                            );
                            return
                        } else {
                            this.distance = distance;
                            // determine the tier 5, 10, 15, 20
                            if(this.distance === 0 || this.distance <= 5)
                            {
                                this.tier = 5;
                            } else if(this.distance >= 5.1|| this.distance <= 10) {
                                this.tier = 10;
                            } else if(this.distance >= 10.1|| this.distance <= 15) {
                                this.tier = 15;
                            } else if(this.distance >= 15.1|| this.distance <= 20) {
                                this.tier = 20;
                            }

                            this.tierArr = JSON.parse(this.selectedService.main.pricings).radius_tier;


                            if(this.tier <= this.tierArr.tier1.radius_miles_tier_1)
                           {
                                this.mainPrice = Number(this.tierArr.tier1.radius_miles_price_1);
                           } else if(this.tier > this.tierArr.tier1.radius_miles_tier_1 && this.tier <= this.tierArr.tier2.radius_miles_tier_2) {
                                this.mainPrice = Number(this.tierArr.tier2.radius_miles_price_2);
                           } else if(this.tier > this.tierArr.tier1.radius_miles_tier_2 && this.tier <= this.tierArr.tier2.radius_miles_tier_3) {
                                this.mainPrice = Number(this.tierArr.tier3.radius_miles_price_3);
                           } else if(this.tier > this.tierArr.tier1.radius_miles_tier_3 && this.tier <= this.tierArr.tier2.radius_miles_tier_4) {
                                this.mainPrice = Number(this.tierArr.tier4.radius_miles_price_4);
                           } else {

                            // TIER AND RADIUS MILES TIER MISMATCH AS YOU CAN SEE FROM CONSOLE LOG ABOVE
                            Swal.fire(
                            'No Coverage!',
                            'Seems like this clinic does not serve in your postcode. Contact the clinic for more details!',
                            'error'
                            );
                           }
                        }

                    })

                }

              if(this.mainPrice)
              {
                this.quotePrice = Number(this.extraPrice+this.mainPrice);
              }




                this.recordAction('quote_generated');
            },
            recordAction(target)
            {
                const action = target;
                const data =  {};
                data.satelite = this.satelite;
                data.selectedService = this.selectedService.main.main_service_name;
                data.postcode = this.postcode;
                data.price = this.quotePrice;

                this.logAnalytics({action: action, data: data});
            }
        }
    }
</script>
