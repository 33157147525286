<template>
    <div class="">
        <div class="container">
        <div class="main-section mt-5">
            <div class="tab-wrapper my-5">
                <div class="tab-section overflow-x-scroll">
                    <ul class="flex p-0">
                        <li v-for="tab in GET_PAGE_TABS" :key="tab" class="mr-4 px-4 py-2 cursor-pointer rounded-lg" :class="{'active-tab': GET_ACTIVE_TAB == tab}" @click="changeActiveTab(tab)">{{tab}}</li>
                    </ul>
                </div>
            </div>
            <div class="inner-page">
                <component :reviews="reviews" :type="'add'" :target="reviews" :is="pageComponent"></component>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import ReviewsTable from './ReviewsTable.vue'
import NewReviews from './NewReviews.vue'
import PeerEndorsementsTable from "./PeerEndorsementsTable.vue";

import { mapActions, mapGetters, mapMutations } from 'vuex';

    export default {
        props: ['reviews'],
        data() {
            return {
            }
        },
        components:{
            ReviewsTable,
            NewReviews,
            PeerEndorsementsTable
        },
        methods: {

            ...mapActions('reviews', ['changeActiveTab', 'setupReviewsTabs']),
            ...mapMutations('reviews', ['setActiveTab'])
        },
        computed:{
            ...mapGetters('reviews', ['GET_PAGE_TABS', 'GET_ACTIVE_TAB']),
            pageComponent()
            {
                if(this.GET_ACTIVE_TAB !== 'undefined'){

                    switch (this.GET_ACTIVE_TAB) {
                        case 'Existing Reviews':
                            return ReviewsTable;
                            break;
                        case 'New Reviews':
                            return NewReviews;
                            break;
                        case 'Peer Endorsements':
                            return PeerEndorsementsTable;
                            break;
                    }
                }
            }

        },
        created(){
            this.setupReviewsTabs()
            const searchParams = new URLSearchParams(window.location.search);
            const tab = searchParams.get('tab') || 'Existing Reviews';
            this.setActiveTab(tab)
        }
    }
</script>
<style scoped lang="scss">
    .active-tab {
        background-color: #d2f7efb3;
        color:  #0EE2B1;
    }
</style>
