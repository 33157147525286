<template>
    <div class="container give-review-card">
        <div class="form-section-info">
            <h3 class="form-section-info-title font-semibold pb-3">{{ review_workflow.title}}</h3>
            <span class="text-gray-400">{{review_data.clinicName}} {{review_workflow.medimobReviewDescription}}</span>
        </div>

        <form method="POST"  @submit.prevent="submitForm(review_workflow.saveFunction)" class="modal-form">

        <div class="form-section my-4 flex px-4">

            <div class="form-section-info w-1/3">
                    <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{review_workflow.components['anonymous']['title']}}
                        <span v-if="review_workflow.components['anonymous'].required" class="text-red-400">*</span>
                    </label>
                    <p class="font-sm text-gray-400 text-right">{{review_workflow.components['anonymous']['description']}}</p>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <!-- is-invalid  -->
                    <label class="relative inline-flex items-center cursor-pointer">
                        <input :type="review_workflow.components['anonymous']['inputType']" v-model="form[review_workflow.components['anonymous'].inputName]"  class="sr-only peer"  :name="review_workflow.components['anonymous']['inputName']" :required="review_workflow.components['anonymous']['required']" >
                        <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        <span v-if="form[review_workflow.components['anonymous'].inputName]" class="ml-3 text-sm font-medium ">Yes</span>
                        <span v-else class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">No</span>
                    </label>
                </div>
            </div>

        </div>
        <div  v-if="!form[review_workflow.components['anonymous'].inputName]" class="form-section my-4 flex px-4">
            <div class="form-section-info w-1/3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ review_workflow.components['author']['title']}}
                        <span v-if="review_workflow.components['author']['required']" class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">{{review_workflow.components['author']['description'] }}</p>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <input
                    :type="review_workflow.components['author']['inputType']"
                    class="w-full"
                    :required="review_workflow.components['author']['required']"
                    :placeholder="review_workflow.components['author']['placeholder']"
                    :name="review_workflow.components['author']['inputName']"
                    v-model="form[review_workflow.components['author'].inputName]"
                    />
                    <InputErrorMessage v-if="ShowErrors && (!form[review_workflow.components['author'].inputName] || form[review_workflow.components['author'].inputName] === undefined)"/>
                </div>
            </div>
        </div>
        <div class="form-section my-4 flex px-4">

            <div class="form-section-info w-1/3">
                    <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{review_workflow.components['service_received'].title}}
                        <span v-if="review_workflow.components['service_received'].required" class="text-red-400">*</span>
                    </label>
                    <p class="font-sm text-gray-400 text-right">{{review_workflow.components['service_received'].description}}</p>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <v-select
                    label="main_service_name"
                    :placeholder="review_workflow.components['service_received']['help']"
                    class="vue-select1"
                    name="select1"
                    :options="services"
                    v-model="form[review_workflow.components['service_received'].inputName]">
                    </v-select>
                    <InputErrorMessage v-if="ShowErrors && (!form[review_workflow.components['service_received'].inputName] || form[review_workflow.components['service_received'].inputName] === undefined)"/>
                </div>
            </div>

        </div>
        <div class="form-section my-4 flex px-4">
            <div class="form-section-info w-1/3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ review_workflow.components['rating'].title }}
                    <span v-if="review_workflow.components['rating'].required" class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">{{review_workflow.components['rating'].description }}</p>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                    <div>
                        <!-- is-invalid  -->
                        <div class="flex" >
                            <div v-for="n in 5" :key="n" @click="setRating(n)">
                                <svg :class="{ 'text-yellow-500': n <= form.rating  }" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current" viewBox="0 0 24 24">
                                    <path d="M12 2l3.09 6.31 6.91.99-5 4.86 1.18 6.88L12 17.25l-6.09 3.09 1.18-6.88-5-4.86 6.91-.99L12 2z" />
                                </svg>
                            </div>
                        </div>
                        <InputErrorMessage v-if="ShowErrors && (!form[review_workflow.components['rating'].inputName] || form[review_workflow.components['rating'].inputName] === undefined)"/>

                    </div>
            </div>

        </div>
        <div class="form-section my-4 flex px-4">
            <div class="form-section-info w-1/3">
                    <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ review_workflow.components['reviewText'].title }}
                        <span v-if="review_workflow.components['reviewText'].required" class="text-red-400">*</span>
                    </label>
                    <p class="font-sm text-gray-400 text-right">{{review_workflow.components['reviewText'].description }}</p>
                </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        <p class="text-sm italic font-light ml-auto text-right opacity-70">(Characters: {{ reviewTextCharacterCount }}/{{review_workflow.components['reviewText'].max}})</p>
                    </label>

                    <textarea class="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        rows="4"
                        cols="50"
                        :id="review_workflow.components['reviewText'].inputName"
                        :name="review_workflow.components['reviewText'].inputName"
                        v-model="form[review_workflow.components['reviewText'].inputName]"
                        @keyup="modificationState()"
                        :placeholder="review_workflow.components['reviewText'].help"
                        :maxlength="review_workflow.components['reviewText'].max"
                    ></textarea>
                    <InputErrorMessage v-if="ShowErrors && (!form[review_workflow.components['reviewText'].inputName] || form[review_workflow.components['reviewText'].inputName] === undefined)"/>
                </div>
            </div>

        </div>

            <div class="text-center">
              <p>By submitting this form, you are deemed to accept our terms and conditions.</p>
                <button type="submit" class="action-button ml-auto border cursor-pointer rounded-md submit-button ">
                    {{ review_workflow.saveButton }}
                </button>
            </div>
        </form>
    </div>
    </template>

    <script>
    import InputErrorMessage from '../../Utilities/InputErrorMessage.vue'
    import { mapGetters, mapActions } from 'vuex';
        export default {
            props: ['review_workflow', 'review_data', 'services'],
            components: {InputErrorMessage},
            data() {
                return {
                  reviewTextCharacterCount: 0,
                  form: {
                    rating: null
                  },
                  ShowErrors: false
                }
            },
            methods: {
                ...mapActions('reviews', ['saveReview']),
                modificationState()
                {
                    this.reviewTextCharacterCount = this.form[this.review_workflow.components['reviewText'].inputName].length;
                },
                setRating(n) {
                    this.form.rating = n;
                },
                submitForm(saveFnc)
                {
                    this.ShowErrors = true
                    this.form[this.review_workflow.components['service_received'].inputName] = this.form[this.review_workflow.components['service_received'].inputName].main_service_name

                    if(this.form[this.review_workflow.components['rating'].inputName] === undefined
                    || this.form[this.review_workflow.components['reviewText'].inputName] === undefined
                    || this.form[this.review_workflow.components['service_received'].inputName] === undefined){
                        Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: 'Please fill the form correctly',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true
                        });
                    }else {

                        this.form.reviewId = this.review_data.id
                        eval('this.'+saveFnc+'(this.form)')
                                .then(res => {
                                    Swal.fire({
                                    icon: 'success',
                                    title: "Review given! Thank you",
                                    animation: true,
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true
                                }).then(() => {
                                    window.location.href = '/home';
                                })

                            }).catch(() => {
                                Swal.fire({
                                    toast: true,
                                    icon: 'error',
                                    title: 'Something went wrong. Contact support',
                                    animation: true,
                                    position: 'top-right',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true
                                });
                            })
                    }
                }
            }
        }
    </script>

    <style lang="scss" scoped>
    .give-review-card{
        margin-top: 70px;
    }
    input, textarea , svg, select{
    cursor: pointer;
    }

    </style>
