// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../../../node_modules/vue-select/dist/vue-select.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (max-width:767px){.orText[data-v-536f3b42]{display:none!important}}@media only screen and (min-width:768px){.secondOrText[data-v-536f3b42]{display:none!important}.orText[data-v-536f3b42]{background:#f0f8ff;margin:-28px 0;z-index:2}}", "",{"version":3,"sources":["webpack://./resources/js/components/Dashboard/Reviews/NewReviews.vue"],"names":[],"mappings":"AAKA,yCACE,yBACI,sBAHJ,CACF,CAKA,yCACE,+BACI,sBAHJ,CAKA,yBAEE,kBAAA,CADA,cAAA,CAEA,SAHF,CACF","sourcesContent":["\n\n@import \"vue-select/dist/vue-select.css\";\n\n\n@media only screen and (max-width: 767px) {\n  .orText{\n      display: none !important;\n  }\n}\n@media only screen and (min-width: 768px) {\n  .secondOrText{\n      display: none !important;\n  }\n  .orText{\n    margin: -28px 0px;\n    background: aliceblue;\n    z-index: 2;\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
