const singleClinicEmailAddressModalForm = 
    {
        "title": "",
        "loadFunction": "",
        "saveAddFunction": "",
        "saveEditFunction": "saveClinicEmailAddress",
        "buttonCreateText": "",
        "buttonUpdateText": "Update",
        "components":
        {
            "componentGroup1":
            {
                "sectionTitle": "Email Address",
                "sectionExplenation": "Please provide an email address for this clinic",
                "inputTitle": "Email Address",
                "inputType": "email",
                "inputName": "satelite_email",
                "required": true,
                "dataRef": 'email',
                "vModel": "email"

            },

        }
    }


export default singleClinicEmailAddressModalForm;