<template>
  <div>
    <slot v-if="!loadworkflow" name="body">
        <div class="section-notice">
          <p>{{ GET_WORKFLOW.description }}</p>
        </div>
          <div class="flex mb-6 pt-4">
            <div class="w-1/4 mr-3">
              <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW.components['addressLine1']['inputName']" :name="GET_WORKFLOW.components['addressLine1']['inputName']">{{ GET_WORKFLOW.components['addressLine1']['description'] }}</label>              
            </div>
              <div class="ml-3 w-2/4">
                  <input 
                  v-model="formData.address_line1" id="inline-full-name" :type="GET_WORKFLOW.components['addressLine1']['inputType']" :name="GET_WORKFLOW.components['addressLine1']['inputName']" :required="GET_WORKFLOW.components['addressLine1'].required">
            </div>
          </div>
          <div class="flex mb-6">
            <div class="w-1/4 mr-3">
              <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW.components['addressLine2']['inputName']" :name="GET_WORKFLOW.components['addressLine2']['inputName']">{{ GET_WORKFLOW.components['addressLine2']['description'] }}</label>
            </div>
              <div class="ml-3 w-2/4">
                  <input 
                  v-model="formData.address_line2" id="inline-full-name" :type="GET_WORKFLOW.components['addressLine2']['inputType']" :name="GET_WORKFLOW.components['addressLine2']['inputName']" :required="GET_WORKFLOW.components['addressLine2'].required">            
                </div>
          </div>
          <div class="flex mb-6">
            <div class="w-1/4 mr-3">
              <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW.components['addressLine3']['inputName']" :name="GET_WORKFLOW.components['addressLine3']['inputName']">{{ GET_WORKFLOW.components['addressLine3']['description'] }}</label>
            </div>
              <div class="ml-3 w-2/4">
                  <input 
                  v-model="formData.address_line3" id="inline-full-name" :type="GET_WORKFLOW.components['addressLine3']['inputType']" :name="GET_WORKFLOW.components['addressLine3']['inputName']" :required="GET_WORKFLOW.components['addressLine3'].required">          
              </div>
          </div>
          <div class="flex mb-6">
            <div class="w-1/4 mr-3">
              <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW.components['postcode']['inputName']" :name="GET_WORKFLOW.components['postcode']['inputName']">{{ GET_WORKFLOW.components['postcode']['description'] }}</label>
            </div>
              <div class="ml-3 w-2/4">
                  <input 
                  v-model="formData.postcode" id="inline-full-name" :type="GET_WORKFLOW.components['postcode']['inputType']" :name="GET_WORKFLOW.components['postcode']['inputName']" :required="GET_WORKFLOW.components['postcode'].required">          
              </div>
          </div>
          <div class="flex mb-6">
            <div class="w-1/4 mr-3">
              <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW.components['city']['inputName']" :name="GET_WORKFLOW.components['city']['inputName']">{{ GET_WORKFLOW.components['city']['description'] }}</label>
            </div>
              <div class="ml-3 w-2/4">
                  <input 
                  v-model="formData.city" id="inline-full-name" :type="GET_WORKFLOW.components['city']['inputType']" :name="GET_WORKFLOW.components['city']['inputName']" :required="GET_WORKFLOW.components['city'].required">          
              </div>
          </div>
          <div class="flex mb-6">
            <div class="w-1/4 mr-3">
              <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW.components['county']['inputName']" :name="GET_WORKFLOW.components['county']['inputName']">{{ GET_WORKFLOW.components['county']['description'] }}</label>
            </div>
              <div class="ml-3 w-2/4">
                  <input 
                  v-model="formData.county" id="inline-full-name" :type="GET_WORKFLOW.components['county']['inputType']" :name="GET_WORKFLOW.components['county']['inputName']" :required="GET_WORKFLOW.components['county'].required">          
              </div>
          </div>
            
      </slot>
  </div>
         
</template>

<script>
// importing the helpers for calling actions and getters from vuex
import { mapGetters, mapActions } from 'vuex'
    export default {
        props: {
          formData: {
              type: Object
            },
          mainClinicInfo: {
              type: Object,
          } 
        },
        data() {
            return {
              loadworkflow: true
            }
        },
        methods: {
            // importing actions and getters needed from vuex
            ...mapActions('commonFunctions', ['formatPostcodeAction']),
            formatPostcode(postcode) 
            {
              // calling the postcode action from vuex and assigning the value got from the Getter to the local variable 
              this.formatPostcodeAction(postcode)
              this.formData.postcode = this.GET_POSTCODE
              
            }
        },
        computed: {
          ...mapGetters('commonFunctions', ['GET_POSTCODE']),
          ...mapGetters('mainClinic', ['MAIN_CLINIC', 'GET_WORKFLOW'])
        },
        watch:{
          mainClinicInfo: {
            handler(newValue) {
              this.formData.address_line1 = this.mainClinicInfo.address_line1
              this.formData.address_line2 = this.mainClinicInfo.address_line2
              this.formData.address_line3 = this.mainClinicInfo.address_line3
              this.formData.postcode = this.mainClinicInfo.postcode
              this.formData.city = this.mainClinicInfo.city
              this.formData.county = this.mainClinicInfo.county
            }
          },
          GET_WORKFLOW: {
            handler(newValue) {
              this.loadworkflow = false
            }
          }
        },
        created() {
          this.formData.address_line1 = this.mainClinicInfo.address_line1
          this.formData.address_line2 = this.mainClinicInfo.address_line2
          this.formData.address_line3 = this.mainClinicInfo.address_line3
          this.formData.postcode = this.mainClinicInfo.postcode
          this.formData.city = this.mainClinicInfo.city
          this.formData.county = this.mainClinicInfo.county
        }
    }
</script>

<style scoped>

</style>
