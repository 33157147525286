<template>
    <div class="form-group my-auto flex">
        <form @submit.prevent="newsletterSignUp" class="w-full search-box-form">


            <div class=" w-full form-group mx-2 practitioner-group flex-col  h-12 my-auto">
                <input type="text" v-model="email" name="email" placeholder="Enter your email address" class="bg-transparent border-none focus:border-none active:border-none">
            </div>
            <button v-if="!completedSubscription" type="submit" class="flex items-center btn my-auto bg-blue-500 text-white rounded-lg px-4 py-2">
                <span>Sign Up</span>
            </button>


        </form>
    </div>
</template>

<script>

export default {
    data() {
        return {
            email: '',
            completedSubscription: false
        }
    },
    methods: {
        newsletterSignUp() {
          axios.post('/newsletter/subscribe', {email: this.email})
              .then((res) => {
                  this.email = 'Thank you for subscribing to Medimob.'
                  this.completedSubscription = true;
              }).catch((e) => {
                alert('An error has occurred');
          })

        }
    }
}
</script>
