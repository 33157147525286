<template>
    <div>
        <div class="progress-wrapper">
            <div id="progress-bar-container" class="mx-auto">

                <div class="tabs flex w-full scroll-x" style="position:relative;">
                    <div class="step mx-auto" v-for="(tab, index) in tabs" :key="index">
                        <li class="cursor-pointer step-inner transition py-3 px-4 tab" @click.prevent="goToStep(index)"
                            :class="{'text-green-500 active': current_step===index, 'firstStep': firstStep===true}"

					        v-text="tab">
                        </li>
                    </div>
                </div>
                  <div id="line">
                    <div id="line-progress" :class="{'firstStep': firstStep===true, 'secondStep': secondStep===true, 'thirdStep': thirdStep===true}"></div>
                  </div>
                  <!-- progress-bar-container -->
                  <div id="progress-content-section" class="card shadow mx-auto">
                    <div class="section-content step1 active" v-show="current_step===0">
                      <h2 class="mb-3">Step 1</h2>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat,
                        impedit! Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      </p>
                    </div>
                    <div class="section-content step2" v-show="current_step===1">
                      <h2 class="mb-3">Step 2</h2>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat,
                        impedit! Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      </p>
                    </div>
                    <div class="section-content step3" v-show="current_step===2">
                      <h2 class="mb-3">Step 3</h2>
                      <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat,
                        impedit! Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                      </p>
                    </div>
                </div>
            </div>
                <!-- progress-wrapper -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            current_step: 0,
            max_step: 2,
            tabs: [
              "1",
              "2",
              "3",
            ],
            firstStep: false,
            secondStep: false,
            thirdStep: false,
        }
    },
    methods: {
        nextStep() {
            this.current_step++;
            if(this.max_step < this.current_step) {
              this.max_step = this.current_step
            }
        },

        goToStep(value) {
            this.current_step = value

            if(this.current_step === 0){
                this.firstStep = true
                this.secondStep = false
                this.thirdStep = false
            } else if(this.current_step === 1){
                this.firstStep = true
                this.secondStep = true
                this.thirdStep = false
            } else if(this.current_step === 2){
                this.firstStep = true
                this.secondStep = true
                this.thirdStep = true
            }
        },
    }
}
</script>
