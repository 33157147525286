const paymentMethodsInfo = [
    {
        "title": "Online Payments",
        "key": "online_payment",
        "group": "method",
        "clientDescription": "Pay for your service on a dedicated online portal.",
        "userDerscription": "Get paid from your online payment portal if you have one",
        "link": "",
        "icon": "",
        "color": "#ffff"
    },
    {
        "title": "Card Payments",
        "key": "card_payment",
        "group": "method",
        "clientDescription": "Pay for your service by card.",
        "userDerscription": "Get paid using a card payment method. Usually requires a card machine.",
        "link": "",
        "icon": "",
        "color": "#ffff"
    },
    {
        "title": "Cash",
        "key": "cash",
        "group": "method",
        "clientDescription": "Pay for your service by cash.",
        "userDerscription": "Get paid by cash",
        "link": "",
        "icon": "",
        "color": "#ffff"
    },
    {
        "title": "Cheques",
        "key": "cheques",
        "group": "method",
        "clientDescription": "Pay for your service by cheque.",
        "userDerscription": "Get paid by cheque",
        "link": "",
        "icon": "",
        "color": "#ffff"
    },
    {
        "title": "Bank Transfers",
        "key": "bank_transfer",
        "group": "method",
        "clientDescription": "Pay for your service by transferring the money directly into the practitioner's or clinic's bank account.",
        "userDerscription": "Get paid by receiving the money from the patient directly in your back account",
        "link": "",
        "icon": "",
        "color": "#ffff"
    },
    {
        "title": "PayPal",
        "key": "pay_pal",
        "group": "method",
        "clientDescription": "Pay with Paypal. You could also benefit from the 'PayPal Buy Now Pay Later' option",
        "userDerscription": "Get paid by receiving the money directly into your Paypal account. Buy doing so you could also give your clients the benefit of the 'PayPal Buy Now Pay Later' scheme.",
        "link": "",
        "icon": "",
        "color": "#ffff"
    },
    {
        "title": "Cryptocurrency",
        "key": "crypto",
        "group": "method",
        "clientDescription": "Pay for your service with cryptocurrencies",
        "userDerscription": "Get paid by receiving cryptocurrencies",
        "link": "",
        "icon": "",
        "color": "#ffff"
    },
    {
        "title": "PayPal Buy Now Pay Later",
        "key": "pay_pal",
        "group": "support",
        "clientDescription": "Spread the cost of the service received in instalments with Paypal credit option. 0% finance available.",
        "userDerscription": "Let your clients know if you accept payments via paypal, and if you do they can benefit from the 'Buy Now Pay Later' option, which lets them spread the cost of the treatment into instalments. 0% finance available.",
        "link": "https://www.paypal.com/uk/webapps/mpp/paypal-virtual-credit?kid=p29450638893&gclid=EAIaIQobChMIzfK3lsiJ_wIV0cztCh1woAwFEAAYASAAEgKKE_D_BwE&gclsrc=aw.ds",
        "icon": "",
        "color": "#ffff"
    },
    {
        "title": "Klarna",
        "key": "klarna",
        "group": "support",
        "clientDescription": "Spread the cost of the service received in instalments with Klarna option. 0% finance available.",
        "userDerscription": "Let your clients know if they have the possibility to spread the cost of the service via Klarna.",
        "link": "https://www.klarna.com/uk/",
        "icon": "",
        "color": "#ffff"
    },
    {
        "title": "Clearpay",
        "key": "clearpay",
        "group": "support",
        "clientDescription": "Spread the cost of the service received in instalments with Clearpay credit option. 0% finance available.",
        "userDerscription": "Let your clients know if they have the possibility to spread the cost of the service via Clearpay.",
        "link": "https://www.clearpay.co.uk/en-GB",
        "icon": "",
        "color": "#ffff"
    },
    {
        "title": "Pay in full when booking appointment",
        "key": "pay_in_full_before",
        "group": "terms",
        "clientDescription": "This clinic expects you to pay for your appointment in full when booking your appointment.",
        "userDerscription": "Clients will be expected to pay in full when booking their appointment.",
        "link": "",
        "icon": "",
        "color": "#ffff"
    },
    {
        "title": "Pay deposit and settle later",
        "key": "pay_deposit",
        "group": "terms",
        "clientDescription": "This clinic expects you to pay an initial deposit when booking the appointment. You will settle the remaining amount later.",
        "userDerscription": "Clients will be expected to pay an initial deposit when booking the appointment and will settle the remaining amount later.",
        "link": "",
        "icon": "",
        "color": "#ffff"
    },
    {
        "title": "Pay on appointment completion",
        "key": "pay_on_completion",
        "group": "terms",
        "clientDescription": "You can pay for the service received once your appointment has been completed.",
        "userDerscription": "Clients can pay at the end of their appointment.",
        "link": "",
        "icon": "",
        "color": "#ffff"
    },
]

export default paymentMethodsInfo;