<template>
    <div class="container">

        <div class="main-page mt-5">
            <div class="display-all-container mt-5">
                <all-settings ></all-settings>
            </div>
        </div>
</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import AllSettings from './AllSettings.vue';

export default {
    components: {
        AllSettings
    },
    data() {
        return {
            crumbs: [
                {name: 'Account', url: '/dashboard/account'},
            ],
            
        }
    },
    methods: {
        ...mapActions('commonFunctions', ['loadBreadcrumbs']),
        ...mapActions('account', ['setupAccountTabs']),
        ...mapMutations('account', ['setActiveTab'])
    },
    mounted()
    {
        this.loadBreadcrumbs(this.crumbs);
        this.setupAccountTabs()
        const searchParams = new URLSearchParams(window.location.search);
        const tab = searchParams.get('tab') || 'Update Password';
        this.setActiveTab(tab)
    }

}
</script>