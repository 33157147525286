<template>
    <div>
        <table-framework
            :theads="theads"
            :tableTitle="'Clinic Profile'"
            :showTableActionButton="false"
            :tableButtonText="'Clinic Profile'"
        >
          
            <tbody slot="table-body">
                <tr>
                    <td class="px-4 py-3 font-semibold opacity-70">Clinic Reference</td>
                    <td class="px-4 py-3 opacity-30">{{ clinic.ref }}</td>
                    <td class="px-4 py-3 text-sm italic opacity-30">Non Editable</td>
                </tr>
                <tr>
                    <td class="px-4 py-3 font-semibold opacity-70">Main Clinic Reference</td>
                    <td class="px-4 py-3 opacity-30">{{ clinic.clinic_ref }}</td>
                    <td class="px-4 py-3 text-sm italic opacity-30">Non Editable</td>
                </tr>
                <tr>
                    <td class="px-4 py-3 font-semibold opacity-70">Is Main Clinic</td>
                    <td class="px-4 py-3 opacity-30" v-text="clinic.head_office ? 'Yes' : 'No'"></td>
                    <td class="px-4 py-3 text-sm italic opacity-30">Non Editable</td>
                </tr>
                <tr>
                    <td class="px-4 py-3 font-semibold opacity-70">Email</td>
                    <td class="px-4 py-3" :class="{'opacity-30': clinic.head_office === 1}">{{ clinic.email }}</td>
                    <td class="px-4 py-3 text-sm italic opacity-30" v-if="clinic.head_office === 1">Non Editable On Main Clinic </td>
                    <td class="px-4 py-3 " v-else><button class="btn-blue btn-sm font-bold" @click.prevent="modalType = 'email'; singleClinicInfoModal = true">Edit</button> </td>
                </tr>
                <tr>
                    <td class="px-4 py-3 font-semibold opacity-70">Clinic Name</td>
                    <td class="px-4 py-3">{{ clinic.name }}</td>
                    <td class="px-4 py-3 "> <button class="btn-blue btn-sm font-bold" @click.prevent="modalType = 'name'; singleClinicInfoModal = true">Edit</button> </td>
                </tr>
                <tr>
                    <td class="px-4 py-3 font-semibold opacity-70">Telephone</td>
                    <td class="px-4 py-3" :class="{'opacity-30 text-sm italic': !clinic.telephone}" v-text="clinic.telephone ? clinic.telephone : 'Not Provided'"></td>
                    <td class="px-4 py-3 "><button class="btn-blue btn-sm font-bold" @click.prevent="modalType = 'telephone'; singleClinicInfoModal = true">Edit</button> </td>
                </tr>
                <tr>
                    <td class="px-4 py-3 font-semibold opacity-70">Mobile Phone</td>
                    <td class="px-4 py-3" :class="{'opacity-30 text-sm italic': !clinic.mobile_phone}" v-text="clinic.mobile_phone ? clinic.mobile_phone : 'Not Provided'"></td>
                    <td class="px-4 py-3 "> <button class="btn-blue btn-sm font-bold" @click.prevent="modalType = 'mobile phone'; singleClinicInfoModal = true">Edit</button> </td>
                </tr>
            </tbody>

          
         </table-framework>
         <CreateUpdateModal 
            v-if="singleClinicInfoModal" 
            :title="'Edit '+ modalType"
            :data="targetClinic"
            :buttonText="formData.buttonUpdateText"
            @save="triggerSave($event)"
            @close="wipeAndToggle()"
        >
                <div class="" slot="content">
                    <component :is="singleClinicProfileModal" @eventListener="updateModalData($event)" :target="targetClinic" :clinic="clinic" :formData="formData"></component>
                </div>
        </CreateUpdateModal>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import TableFramework from '../../Table.vue';
import clinicProfileTableHeads from '../../../constants/satelites/clinicProfileTableHeads';
import CreateUpdateModal from '../../CreateUpdateModal.vue';
import EditProfileSingleClinicModalContent from './EditProfileSingleClinicModalContent.vue';
import EditSingleClinicEmailFormStructure from '../../../constants/satelites/modalForms/singleClinicEmailAddressModalForm';
import EditSingleClinicNameFormStructure from '../../../constants/satelites/modalForms/singleClinicNameModalForm';
import EditSingleClinicTelephoneFormStructure from '../../../constants/satelites/modalForms/singleClinicTelephoneModalForm';
import EditSingleClinicMobilePhoneFormStructure from '../../../constants/satelites/modalForms/singleClinicMobilePhoneModalForm';


    export default {
        props: ['clinic'],
        components: {
            TableFramework,
            CreateUpdateModal,
            EditProfileSingleClinicModalContent        
        },
        data() {
            return {
                theads: clinicProfileTableHeads,
                targetClinic: {},
                modalType: '',
                singleClinicInfoModal: false,
                formData: {},
                modalData: {}
            }
        },
        methods: {
            ...mapActions('satelite', ['singleClinicModalSaveAction']),
            wipeAndToggle()
            {
                this.singleClinicInfoModal = false;
            },
            updateModalData(data)
            {
                this.modalData = data;
            },
            triggerSave()
            {

                let data = {
                    type: this.modalType, 
                    data: this.modalData,
                    clinic: this.clinic.id,
                    action: this.formData.saveEditFunction
                }
                this.singleClinicModalSaveAction(data);
            },
        },
        computed: {
            singleClinicProfileModal()
            {
                switch(this.modalType)
                {
                    case 'email':
                        this.formData = EditSingleClinicEmailFormStructure;
                        return EditProfileSingleClinicModalContent;
                    case 'name':
                        this.formData = EditSingleClinicNameFormStructure;
                        return EditProfileSingleClinicModalContent;
                    case 'telephone':
                        this.formData = EditSingleClinicTelephoneFormStructure;
                        return EditProfileSingleClinicModalContent;
                    case 'mobile phone':
                        this.formData = EditSingleClinicMobilePhoneFormStructure;
                        return EditProfileSingleClinicModalContent;
                    
                }
            }
        }
    }
</script>