<template>
    <div  v-if="!loadFormdata" class="container">
        <div class="form-section py-4 pr-4">
            <div v-if="modalData.anonymous === 0" class="form-section my-4 flex px-4">
                <div class="form-section-info w-1/3">
                    <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ formData.components.author.title }}
                        <span v-if="formData.components.author.required" class="text-red-400">*</span>
                    </label>
                    <p class="font-sm text-gray-400 text-right">{{formData.components.author.description }}</p>
                </div>
                <div class="form-section-content w-2/3 ml-5">
                    <div class="mb-3">
                        <input 
                        :type="formData.components.author.inputType" 
                        class="w-full"
                        :required="formData.components.author.required"
                        :placeholder="formData.components.author.placeholder"
                        :name="formData.components.author.inputName"
                        v-model="modalData.author"
                        />
                    <InputErrorMessage v-if="GET_SHOW_ERRORS && (!modalData.author || modalData.author === undefined)"/>
                    </div>
                </div>
            </div>

            <div class="form-section my-4 flex px-4">

                <div class="form-section-info w-1/3">
                        <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ formData.components.reviewType.title }}</label>
                        <p class="font-sm text-gray-400 text-right">{{formData.components.reviewType.description }}</p>
                </div>
                <div class="form-section-content w-2/3 ml-5">
                    <div class="mb-3">
                        <p>
                            {{ formData.components.reviewType.content[modalData.review_type] }}
                        </p>
                    </div>
                </div>

            </div>

            <div v-if="modalData.url_of_origin" class="form-section my-4 flex px-4">

                <div v-if="modalData.url_of_origin.length > 0" class="form-section-info w-1/3">
                        <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ formData.components.url_of_origin.title }}</label>
                        <p class="font-sm text-gray-400 text-right">{{formData.components.url_of_origin.description }}</p>
                </div>
                <div class="form-section-content w-2/3 ml-5">
                    <div class="mb-3">
                        <a :href="modalData.url_of_origin" target="_blank">
                            {{ modalData.url_of_origin }}
                        </a>
                    </div>
                </div>

            </div>

        <div class="form-section my-4 flex px-4">

            <div class="form-section-info w-1/3">
                    <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ formData.components.service_received.title }}
                        <span v-if="formData.components.service_received.required" class="text-red-400">*</span>
                    </label>
                    <p class="font-sm text-gray-400 text-right">{{formData.components.service_received.description }}</p>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <v-select  v-if="!loadingServices" 
                    label="main_service_name" 
                    :placeholder="formData.components.service_received.help"  
                    class="vue-select1" 
                    name="select1" 
                    @input="setServiceReceived()"
                    :options="services" 
                    v-model="modalData.service_received">
                    </v-select>
                    <InputErrorMessage v-if="GET_SHOW_ERRORS && (modalData.service_received || modalData.service_received === undefined)"/>
                </div>
            </div>

            </div>
            <div class="form-section my-4 flex px-4">
                <div class="form-section-info w-1/3">
                    <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ formData.components.rating.title }}
                        <span v-if="formData.components.rating.required" class="text-red-400">*</span>
                    </label>
                    <p class="font-sm text-gray-400 text-right">{{formData.components.rating.description }}</p>
                </div>

                <div class="form-section-content w-2/3 ml-5">
            

                    <!-- is-invalid  -->
                    <div class="flex" >
                        <div v-for="n in 5" :key="n" @click="setRating(n)">
                            <svg :class="{ 'text-yellow-500': n <= modalData.rating  }" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current" viewBox="0 0 24 24">
                                <path d="M12 2l3.09 6.31 6.91.99-5 4.86 1.18 6.88L12 17.25l-6.09 3.09 1.18-6.88-5-4.86 6.91-.99L12 2z" />
                            </svg>
                        </div>
                    </div>
                    <InputErrorMessage v-if="GET_SHOW_ERRORS && (!modalData.rating || modalData.rating === undefined)"/>
                    
                </div>

            </div>

            <div class="form-section my-4 flex px-4">
                <div class="form-section-info w-1/3">
                        <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ formData.components.reviewText.title }}
                            <span v-if="formData.components.reviewText.required" class="text-red-400">*</span>
                        </label>
                        <p class="font-sm text-gray-400 text-right">{{formData.components.reviewText.description }}</p>
                    </div>
                <div class="form-section-content w-2/3 ml-5">
                    <div class="mb-3">
                        
                        <textarea class="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                            rows="4" 
                            cols="50"
                            :id="formData.components.reviewText.inputName" 
                            :name="formData.components.reviewText.inputName"
                            v-model="modalData.review_text"
                            @keyup="modificationState()"
                            :placeholder="formData.components.reviewText.help"
                            :maxlength="formData.components.reviewText.max"
                        ></textarea>
                        <InputErrorMessage v-if="GET_SHOW_ERRORS && (!modalData.review_text || modalData.review_text === undefined)"/>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InputErrorMessage from '../../Utilities/InputErrorMessage.vue'
import EditReviewModalForm from '../../../constants/services/modalForms/EditReviewModalForm'

export default {
    props: ['target', 'type'],
    components:{InputErrorMessage},
    data()
    {
        return {
            reviewTextCharacterCount: 0,
            modalData: {
                patient_email: '',
                rating: null
            },
            formData: {},
            loadFormdata: true,
            radius_tier_options: 1,
            loadingServices: true,
            services: {}
        }
    },
    computed: {
        ...mapGetters('mainClinic', ['MAIN_CLINIC']),
        ...mapGetters('practitioner', ['GET_ERRORS']),
        ...mapGetters('reviews', ['GET_SHOW_ERRORS']),
    },
    methods: {
        ...mapActions('reviews', ['getServicesForReviews']),
        modificationState()
        {
            this.reviewTextCharacterCount = this.modalData.review_text.length;
        },
        pushData(){
            this.modalData.clinic_ref = this.MAIN_CLINIC.ref
            this.$emit('eventListener', this.modalData)
            this.$forceUpdate();
        },
        setRating(n) {
            this.modalData.rating = n;
        },
        setServiceReceived(){
            this.modalData.service_received = this.modalData.service_received.main_service_name
        }
    },
    mounted()
    {
        this.formData = EditReviewModalForm;
        // If editing, pass in target services values
        if(this.target)
        {
            this.modalData.mainClinic = this.target.mainClinic;

            if(this.type == 'edit')
            {
                this.modalData = this.target
                this.modalData.id = this.target.id
                this.modalData.anonymous = this.target.anonymous,
                this.modalData.review_type = this.target.review_type,
                this.modalData.url_of_origin = this.target.url_of_origin,
                this.modalData.author = this.target.author || '';
                this.modalData.service_received = this.target.service_received;
                this.modalData.rating = this.target.rating;
                this.modalData.review_text = this.target.review_text;
                this.reviewTextCharacterCount = this.modalData.review_text.length;

            }
        }
    },
    watch: {
        formData: {
            handler(newValue) {
            this.loadFormdata = false
            }
        }
    },
    created() {
        this.getServicesForReviews()
        .then(res => {
            this.services = res.data
            this.loadingServices = false
        }).catch(err => {
            console.log(err)
            this.loadingServices = false
        })
    }
}
</script>


<style lang="scss" scoped>

@import "vue-select/dist/vue-select.css";
input, textarea , svg{
    cursor: pointer;
}
</style>