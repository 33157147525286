<template>
  <div class="main-section  container-fluid">
    <div class="overview-cards">
      <div class="row" style="margin-top: -40px;">
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <mini-statistics-card
              :title="'Total users'"
              :value="data.totalClinics"
              :icon="{
                          component: 'users',
                          background: 'bg-gradient-green',
                        }"
              direction-reverse
          ></mini-statistics-card>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <mini-statistics-card
              title="Total satellites"
              :value="data.totalSatellites"
              :icon="{
                          component: 'hospital',
                          background: 'bg-gradient-red',
                        }"
              direction-reverse
          ></mini-statistics-card>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <mini-statistics-card
              title="Last Registered"
              :value="data.lastRegistered"
              :icon="{
                          component: 'info',
                          background: 'bg-gradient-dark',
                        }"
              direction-reverse
          ></mini-statistics-card>
        </div>
        <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
          <mini-statistics-card
              title="Monthly Revenues"
              :value="'£ ' + data.monthlyRevenues"
              :icon="{
                          component: 'wallet',
                          background: 'bg-gradient-orange',
                        }"
              direction-reverse
          ></mini-statistics-card>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <div class="flex">
        <div class="text-separation flex mr-5">
          <h5 class="my-auto font-bold text-slate-500">Quick Actions</h5>
        </div>
        <hr class="flex-grow my-auto opacity-50" style="background-color: rgba(0,0,0,0.2)!important">

      </div>
    </div>
    <div class="mt-5">
      <div class="wrapper">
        <SearchTable></SearchTable>
      </div>
    </div>
  </div>

</template>

<script>
import MiniStatisticsCard from '../MiniStatisticsCard.vue'
import SearchTable from "./SearchTable.vue";

import { mapActions } from 'vuex';
export default {
  props: ['data'],
  components: {
    MiniStatisticsCard,
    SearchTable
  },
  data() {
    return {
      iconBackground: 'bg-red-300',
      crumbs: [
        {name: 'Home', url: '/dashboard'},
      ]
    }
  },
  methods: {
    ...mapActions('commonFunctions', ['loadBreadcrumbs'])
  },
  mounted()
  {
    this.loadBreadcrumbs(this.crumbs);
  }

}
</script>
