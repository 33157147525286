const AddServiceModalForm =
    {
        "title": "",
        "loadFunction": "",
        "saveAddFunction": "",
        "saveEditFunction": "saveService",
        "buttonCreateText": "",
        "buttonUpdateText": "Update",
        "components":
        {
            "componentGroup1":
            {
                "sectionTitle": "Add New Service",
                "sectionExplenation": "Give your service a specific name. For example: GP Initial Consultation. You can add sub-services and pricing in the next page.",
                "inputTitle": "Service Name",
                "inputType": "text",
                "inputName": "service_name",
                "required": true,
                "dataRef": 'service name',
                "vModel": "service_name",
                "placeholder": "i.e. Medical General Consultation"
            },
            "componentGroup2":
            {
                "sectionTitle": "Delivery Type",
                "sectionExplenation": "How will this service be delivered?",
                "inputTitle": "Service Delivery Type",
                "inputType": "select",
                "option": [{
                    "": 'Please select an option',
                    0: "Online",
                    1: "Patient's House",
                    2: "Both"
                }],
                "inputName": "service_delivery_type",
                "required": true,
                "dataRef": 'delivery_type',
                "vModel": "service_delivery_type",
                "placeholder": ""
            },
            "componentGroup3":
            {
                "sectionTitle": "Pricing Modality",
                "sectionExplenation": "Fixed fee or radius-based?",
                "inputTitle": "Pricing Modality",
                "inputType": "select",
                "option": [{
                    "": 'Please select an option',
                    0: "Fixed Fee",
                    1: "Radius Based",
                    2: "Quote Based",
                }],
                "inputName": "service_pricing_modality",
                "required": true,
                "dataRef": 'pricing_modality',
                "vModel": "pricing_modality",
                "placeholder": ""
            },
            "componentGroup4":
            {
                "sectionTitle": "Fixed Service Price",
                "sectionExplenation": "Specify the fixed price for this service.",
                "inputTitle": "Fixed Service Price",
                "inputType": "number",
                "inputName": "fixed_service_price",
                "required": true,
                "dataRef": 'fixed service price',
                "vModel": "fixed_service_name",
                "placeholder": ""
            },



        }
    }


export default AddServiceModalForm;
