<template>
    <div class="mt-3">
        <div class="container">
            <div class="flex w-full" style="height: 100vh">
                <div class="cards-result-page w-2/3">
                    <div class="pre-result-bar">
                        <div class="flex my-2 pb-4" style="border-bottom: 2px solid rgba(0,0,0,0.2)">

                            <div class="filters">
                                <button v-if="isResultReturned"  :method="data.method " @click="toggleDrawer"  class="btn text-lg flex">Filters</button>
                            </div>
                            <div class="ml-auto md:mr-16 flex">
                                <p class="my-auto ml-auto font-semibold text-lg">{{GET_FILTERED_SEARCH_RESULTS.length}} Result<span v-if="GET_FILTERED_SEARCH_RESULTS.length > 1">s</span> Found</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="data.illogical_input" >
                        Something is wrong with the town/city requested, please type again carefully
                    </div>
                    <div v-if="isSuggestedPostcodesExist" class="py-2 px-4 bg-gray-100">
                        Did you mean:
                        <span v-for="(onePlace, index) in data.suggested_results" :key="index">
                            <a :href="suggestedWordUrl(onePlace)" target="_blank" class="ml-2 text-blue-500" >
                            {{ onePlace }}
                            </a>
                        </span> ?
                    </div>
                    <div v-if="isSuggestedCounties" class="py-2 px-4 bg-gray-100">
                        Try These:
                        <span v-for="(onePlace, index) in data.suggested_results" :key="index">
                            <a :href="suggestedWordUrl(onePlace.url)" target="_blank" class="ml-2 text-blue-500" >
                            {{ onePlace.display_name }}
                            </a>
                        </span>
                    </div>
                    <filter-drawer :searchResults="GET_FILTERED_SEARCH_RESULTS" :method="data.method" :isDrawerOpen="isDrawerOpen" @closeDrawer="closeDrawer" />
                    <div class="search-result-container" style="max-height: 37.5rem; overflow-y: auto; padding: 15px;">
                        <div class="">
                            <div class="md:mr-5" @mouseenter="changeCoords([searchItem.lat, searchItem.lon]); passClinic(searchItem)" @mouseleave="oldCoords([searchItem.lat, searchItem.lon])"  v-for="(searchItem, index) in GET_FILTERED_SEARCH_RESULTS" :key="index">
                                <single-clinic-search-card :searchItem="searchItem"></single-clinic-search-card>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mapbox-result-page w-1/3 relative">
                    <search-mapbox :coords="coords" :oldCords="oldCoordinates" :targetClinic="targetClinic"></search-mapbox>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import SingleServiceSearchCard from './SearchCards/SingleServiceSearchCard'
import SingleClinicSearchCard from './SearchCards/SingleClinicSearchCard'
import SinglePractitionerSearchCard from './SearchCards/SinglePractitionerSearchCard'
import SearchMapbox from './SearchMapbox.vue';
import FilterDrawer from './SearchWidjets/FilterDrawer.vue'

    export default {
        components: {
            SingleServiceSearchCard,
            SingleClinicSearchCard,
            SinglePractitionerSearchCard,
            SearchMapbox,
            FilterDrawer
        },
        props: ['data'],
        data() {
            return {
                isDrawerOpen: false,
                countySuggestions: false,
                coords: {},
                oldCoordinates: {},
                targetClinic: {}
            }
        },
        methods: {
            toggleDrawer() {
            this.isDrawerOpen = !this.isDrawerOpen;
            },
            closeDrawer() {
            this.isDrawerOpen = false;
            },
            ...mapActions('search', ['searchProviders', 'CHANGE_ACTIVE_COORDINATES']),
            changeCoords(payload)
            {
                this.coords = payload;
            },
            oldCoords(payload)
            {
                this.oldCoordinates = payload;
            },
            passClinic(clinic)
            {
                this.targetClinic = clinic;
            },
            suggestedWordUrl(suggestion){
                const url = window.location.href;
                const replacedUrl = url.replace(/\/([^\/]*)$/, "/" + suggestion);
                return replacedUrl;
            }
        },
        computed:
        {
        ...mapGetters('search', ['GET_FILTERED_SEARCH_RESULTS', 'GET_ACTIVE_COORDS']),
        isSuggestedPostcodesExist() {
            // check if there are word suggestions
            if(typeof this.data.suggested_results !== 'undefined') {
                // make sure there arent location suggestions
                if(typeof this.data.suggested_results[0].id === 'undefined'){
                    // return if there are word suggestions
                    return typeof this.data.suggested_results !== 'undefined'
                }else {
                    return false
                }
            }else {
                return false
            }
        },
        isSuggestedCounties() {
            if(typeof this.data.suggested_results !== 'undefined') {
                if(typeof this.data.suggested_results[0].id === 'undefined'){
                    return typeof this.data.suggested_results[0].id !== 'undefined'
                }
            }
        },
        isResultReturned() {
            if(this.GET_FILTERED_SEARCH_RESULTS.length === 0) {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: '0 Result found',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
                return false
            }else{
                if(typeof this.GET_FILTERED_SEARCH_RESULTS[0]?.ref  !== 'undefined') {
                    return typeof this.GET_FILTERED_SEARCH_RESULTS[0].ref !== 'undefined'
                }else {
                    return false
                }
            }
        }
        },
        mounted()
        {
            this.searchProviders(this.data);
            //console.log(this.GET_FILTERED_SEARCH_RESULTS);
        }
    }
</script>
<style lang="scss" scoped>
/* Tailwind CSS classes */
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.bg-gray-100 {
  background-color: #f7fafc;
}
.ml-2 {
  margin-left: 0.5rem;
}
.text-blue-500 {
  color: #3b82f6;
}
</style>
