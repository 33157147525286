import axios from 'axios';
export default {
    namespaced: true,
    state: {
        actualSetupStep: null,
        userData: null,
        clinicHeadofficeData: null
    },
    getters: {
      GET_ACTUAL_STEP(state)
      {
        return state.actualSetupStep
      },
      GET_USER_DATA(state)
      {
        return state.userData
      },
      GET_USER_CLINIC_HEADOFFICE_DATA(state)
      {
        return state.clinicHeadofficeData
      }
    },
    actions:{
        manageStep(context, payload) {
          return new Promise((resolve, reject) => {
            axios.post('/dashboard/manage-setup', {
              payload: payload
            }).then(response => {
                resolve(response)
              }).catch(error => {
                reject(error)
              })
            })
        },
        manageResendCodeButtonAction(context, payload) {
          return new Promise((resolve, reject) => {
            axios.post('/setup/resend-verification-email')
            .then(response => {
                resolve(response)
              }).catch(error => {
                reject(error)
              })
            })
        },
        getUserData(context) {
          return new Promise((resolve, reject) => {
            axios.get('/setup/get-user-data')
            .then(response => {
                resolve(response)
              }).catch(error => {
                reject(error)
              })
            })
        },
        getUserClinicHeadofficeData(context) {
          return new Promise((resolve, reject) => {
            axios.get('/setup/get-user-clinic-headoffice-data')
            .then(response => {
              context.commit('setUserClinicHeadofficeData', response.data)
                resolve(response)
              }).catch(error => {
                reject(error)
              })
            })
        }
    },
    mutations: {
        setActualSetupStep(state, actualStep) {
            state.actualSetupStep = actualStep
        },
        setUserData(state, userData) {
          state.userData = userData
        },
        setUserClinicHeadofficeData(state, clinicData) {
          state.clinicHeadofficeData = clinicData
        }
    },

}
