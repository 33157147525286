<template>
    <div>
        <div class="flex items-center mb-2 bg-emerald-50 p-2 rounded">
          <!-- Reviewer's Name -->
          <div class="font-semibold capitalize-first">Author: <span class="font-normal">{{endorsement.peer_full_name}}</span></div>
          <div class="font-semibold capitalize-first ml-5">Profession: <span class="font-normal">{{endorsement.peer_profession}}</span></div>
          <div class="font-semibold capitalize-first ml-auto">Relationship: <span class="font-normal">{{endorsement.relationshipStr}}</span></div>
        </div>
        <!-- Review Text -->
        <p class="review-text p-2">
            {{ truncatedReview(endorsement.endorsement_text, 50) }}
            <span
                v-if="showmore"
                class="text-green-500 cursor-pointer"
                @click="showFullReview()"
            >
                Show More
            </span>
        </p>
        <show-more-text :fullText="endorsement.endorsement_text" :is-open="showmoremodal" @close="showmoremodal = false" />
  </div>
</template>

<script>
import ShowMoreText from './ShowMoreText'
export default {
    data() {
        return {
            showmore: false,
            showmoremodal: false
        }
    },
    components: {ShowMoreText},
    props:['endorsement'],
    methods: {
        truncatedReview(text, wordLimit) {
            const words = text.split(' ');
            if (words.length > wordLimit) {
                this.showmore = true
                return words.slice(0, wordLimit).join(' ') + '...';
            } else {
                return text;
            }
            },
            showFullReview() {
                this.showmoremodal = true
            },
    }
}
</script>
