var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-wrapper w-full pb-4"},[_c('div',{staticStyle:{"margin-top":"-65px"}},[_c('profile-topbar',{attrs:{"mainClinic":_vm.head_office}})],1),_vm._v(" "),_c('div',{staticClass:"main-profile-content p-4 max-height"},[_c('div',{staticClass:"overview-cards mt-5"},[_c('div',{staticClass:"row",staticStyle:{"margin-top":"-40px"}},[_c('div',{staticClass:"col-xl-3 col-sm-6 mb-xl-0 mb-4"},[_c('mini-action-card',{staticClass:"cursor-pointer",attrs:{"title":"Update Clinic Logo","modalId":'updateClinicLogo',"icon":{
                      component: 'image-o',
                      background: 'bg-gradient-green',
                    },"direction-reverse":""},on:{"openUpdateModal":_vm.openUpdateModal}})],1),_vm._v(" "),_c('div',{staticClass:"col-xl-3 col-sm-6 mb-xl-0 mb-4"},[_c('mini-action-card',{staticClass:"cursor-pointer",attrs:{"title":"Update Legal Entity","modalId":'updateLegalEntity',"icon":{
                      component: 'weight_scale',
                      background: 'bg-gradient-green',
                    },"direction-reverse":""},on:{"openUpdateModal":_vm.openUpdateModal}})],1),_vm._v(" "),_c('div',{staticClass:"col-xl-3 col-sm-6 mb-xl-0 mb-4"},[_c('mini-action-card',{staticClass:"cursor-pointer",attrs:{"title":"Update Main Address","modalId":'updateMainAddress',"icon":{
                      component: 'location',
                      background: 'bg-gradient-green',
                    },"direction-reverse":""},on:{"openUpdateModal":_vm.openUpdateModal}})],1),_vm._v(" "),_c('div',{staticClass:"col-xl-3 col-sm-6 mb-xl-0 mb-4"},[_c('mini-action-card',{staticClass:"cursor-pointer",attrs:{"title":"Update Payment Methods","modalId":'updatePaymentMethodModal',"icon":{
                      component: 'wallet',
                      background: 'bg-gradient-green',
                    },"direction-reverse":""},on:{"click":function($event){_vm.paymentMethodModal = true},"openUpdateModal":_vm.openUpdateModal}})],1)])]),_vm._v(" "),(_vm.updateCreateClinicModal)?_c('UpdateCreateClinicModal',{attrs:{"chosenModal":_vm.modalId},on:{"closeModal":_vm.closeModal}}):_vm._e(),_vm._v(" "),(_vm.UpdateProfileImagesModal)?_c('UpdateProfileImagesModal',{attrs:{"pictureConcerned":_vm.MAIN_CLINIC.image_path,"chosenModal":_vm.modalId},on:{"closeModal":_vm.closeModal}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex my-4"},[_c('div',{staticClass:"w-1/2 mr-3"},[_c('profile-clinic-full-bio',{staticClass:"my-4 h-full",attrs:{"head_office":_vm.head_office}})],1),_vm._v(" "),_c('div',{staticClass:"w-1/2 ml-3"},[_c('profile-clinic-contacts',{staticClass:"my-4",attrs:{"head_office":_vm.head_office}})],1)]),_vm._v(" "),(_vm.logs.length > 0)?_c('div',{staticClass:"flex my-4"},[_c('div',{staticClass:"w-2/8 mr-3"},[_c('LogsCard',{attrs:{"logs":_vm.logs}})],1)]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }