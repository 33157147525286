<template>
  <div>
    <div class="form-input flex p-5">
      <label class="block text-gray-700 text-sm font-bold  mr-2 w-1/3 my-auto" name="legal_entity">Specify your legal entity</label>
      <select name="legal_entity" id="" v-model="formData.legal_entity" class="w-2/3 block appearance-none bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
        <option  value="" :selected="mainClinicInfo.legal_entity === ''" ></option>
        <option v-for="option in options" :key="option.title" :value="option.title" :selected="option.title === mainClinicInfo.legal_entity" >{{option.title}}</option>
      </select>
    </div>
  </div>
</template>

<script>
// importing the helpers for calling actions and getters from vuex
import { mapActions, mapGetters } from 'vuex';
    export default {
        props: {
          formData: {
              type: Object
            },
          mainClinicInfo: {
              type: Object,
          }
        },
        data() {
            return {
                options: require('../../../../../public/json/legalentities.json'),
                legal_entity: '',
            }
        },
        computed: {
          ...mapGetters('mainClinic', ['MAIN_CLINIC'])
        },
        watch:{
          mainClinicInfo: {
            handler(newValue) {
              this.formData.legal_entity = this.mainClinicInfo.legal_entity
            }
          }
        }
    }
</script>

<style scoped>

</style>
