<template>
    <td style="padding: 12px 25px!important">
        <span v-if="!editMode">Anonymous</span> 
        <input 
            type="text" 
            class="mb-1 tb-clinic-name appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" 
            v-if="editMode" 
            placeholder="Author"
            v-model="author"
            >
    </td>
</template>
<script>
import Icon from '../../Icon';
import { mapActions } from 'vuex';
export default {
    data(){
        return {
            editMode: false,
            editIcon: 'edit-square',
            author: ''
        }
    },
    components:{Icon},
    props: ['review'],
    methods:{
        ...mapActions('reviews', ['saveReviewerName']),
        ...mapActions('commonFunctions', ['redirectAction']),
        
        toggleEdit()
        {
            if(!this.editMode)
            {
            this.editMode = true;
            this.editIcon = 'check_circle';

            } else {
            this.editMode = false;
            this.editIcon = 'edit-square';
            }
        },
        sendChange(){
            if(!this.editMode){
                this.saveReviewerName({
                    author: this.author,
                    Id: this.review.id
                }).then(res => {
                    this.redirectAction('Existing Reviews')
                })
            }
        }
    }
}
</script>