var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4 card blurr shadow-blurr border-none"},[_c('div',{staticClass:"p-3 card-body"},[_c('div',{staticClass:"d-flex",class:_vm.directionReverse ? _vm.reverseDirection : ''},[_c('div',[_c('div',{staticClass:"text-center shadow icon icon-shape rounded-xl w-12 h-12 flex",class:typeof _vm.icon === 'object' ? _vm.icon.background : ''},[_c('Icon',{staticClass:"text-lg h-full flex mx-auto my-auto text-white",class:typeof _vm.icon === 'string' ? _vm.icon : _vm.icon.component,attrs:{"aria-hidden":"true","icon":_vm.icon.component}})],1)]),_vm._v(" "),_c('div',{class:_vm.classContent},[_c('div',{staticClass:"numbers"},[_c('p',{staticClass:"mb-0 text-sm text-capitalize font-weight-bold",class:_vm.title.color},[_vm._v("\n            "+_vm._s(typeof _vm.title === "string" ? _vm.title : _vm.title.text)+"\n          ")]),_vm._v(" "),_c('h5',{staticClass:"mb-0 font-weight-bolder",class:_vm.value.color},[_vm._v("\n            "+_vm._s(typeof _vm.value === "string" || typeof _vm.value === "number"
                ? _vm.value
                : _vm.value.text)+"\n            "),_c('span',{staticClass:"text-sm font-weight-bolder",class:_vm.percentage.color},[_vm._v("\n              "+_vm._s(typeof _vm.percentage === "number" ||
                typeof _vm.percentage === "string"
                  ? `${_vm.percentage}`
                  : "")+"\n\n              "+_vm._s(_vm.percentage && _vm.percentage === "object"
                  ? `${_vm.percentage.value}`
                  : "")+"\n            ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }