const serviceDeliveryTypeInfo = [
    {
        "title": "Virtual Consultations",
        "key": "virtual",
        "clientDescription": "This clinic offers virtual consultations. These types of consultations are carried through a dedicated portal.",
        "icon": "",
        "color": "#ffff"
    },
    {
        "title": "At the patient's house",
        "key": "domiciliary",
        "clientDescription": "This clinic delivers their services at the patient's house. A qualified practitioner will come to your house to deliver the chosen service. You will establish the date and the time directly with the clinic when you book your appointment.",
        "icon": "",
        "color": "#ffff"
    }
]

export default serviceDeliveryTypeInfo;