<template>
  <transition name="fade">
     <div class="modal-overlay fixed inset-0 w-full h-screen flex items-center justify-center bg-semi-75" style="background-color:rgba(0,0,0,0.6);z-index:99;" @click="$emit('close')">
        <div class="card-det modal-card  modal modal-wrapper w-full max-w-2xl bg-white shadow-lg rounded-lg addModal"  style="z-index:99;max-height: 600px; overflow-y: auto; position:relative">
           <div class="modal-container" @click.stop>
              <div class="modal">
                 <!--<Loader v-if="GET_LOADER"/>-->
                 <div class="modal-header block-title">
                    <slot name="header">
                       <div class="header-bit w-full flex">
                          <!-- placing the header we got from the computed property -->
                          <p class="card-title font-bold uppercase my-auto flex-grow mb-0"> Upload {{compliance.parameter}} File </p>
                          <a href="">
                             <svg xmlns="http://www.w3.org/2000/svg"  @click.prevent="$emit('close')" class=" ml-auto h-9 w-9 pt-3 pr-4 close-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                             </svg>
                          </a>
                       </div>
                    </slot>
                 </div>
                 <div class="modal-body">
                    <div  class="container">
                       <div class="form-section py-4 pr-4">
                          <span class="text-green-600 py-10" v-if="compliance.mandatory === 'no'">This is not mandatory</span>
                          <div class="flex mb-6">
                             <div class="w-1/3 mr-3">
                                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="'filetitle'">Select a File type<span class="text-red" v-if="true">*</span></label>
                                <p class="text-gray-500 text-right text-sm" v-if="true" v-text="'Choose a type'"></p>
                             </div>
                             <div class="ml-3 w-2/3">
                                <select :disabled="disabledSelect" :name="'filetitle'" id="" v-model="formData.title" >
                                   <option  value=""></option>
                                   <option v-for="option in fileTypesOptions" :key="option.title" :value="option.id">{{option.name}}</option>
                                </select>
                                <InputErrorMessage v-if="(!formData.title || formData.title === undefined)"/>
                             </div>
                          </div>
                          <div class="flex  mt-9 items-center justify-center w-full">
                             <label for="file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50">
                                <div v-if="file" class="flex flex-col items-center justify-center pt-5 pb-6">
                                   <p >{{ file.name }}</p>
                                </div>
                                <div v-else class="flex flex-col items-center justify-center pt-5 pb-6">
                                   <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                                   </svg>
                                   <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                   <p class="text-xs text-gray-500">PDF, PNG or JPG  </p>
                                </div>
                                <input id="file" type="file" accept="image/png,image/jpeg,application/pdf" ref="file" class="hidden" @change="onFileChange" />
                             </label>
                          </div>
                          <div v-if="validityDate === 'yes'" class="flex items-center justify-center w-full">
                             <div class="w-1/3 mr-3">
                                <label class="block text-gray-500 font-bold text-left mb-1 md:mb-0">Validity date</label>
                             </div>
                             <div date-rangepicker class="flex items-center">
                                <div class="relative">
                                   <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                      <svg aria-hidden="true" class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                         <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path>
                                      </svg>
                                   </div>
                                   <input name="start" type="date"  v-model="start_date"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 " placeholder="Select date start">
                                </div>
                                <span class="mx-4 text-gray-500">to</span>
                                <div class="relative">
                                   <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                      <svg aria-hidden="true" class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                         <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path>
                                      </svg>
                                   </div>
                                   <input name="end" type="date"  v-model="end_date"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 " placeholder="Select date end">
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                    <!-- here ends dynamic content -->
                 </div>
                 <div class="modal-footer text-justify">
                    <slot name="footer">
                       <div class="action-buttons w-full flex container">
                          <div class="my-auto">
                             <a href="" class="modal-default-button cancel-button text-gray-600" @click.prevent="$emit('close')">
                             Cancel
                             </a>
                          </div>
                          <div class="actions ml-auto">
                             <div class="actions ml-auto">
                                <button :disabled="disableUploadButton" @click="uploadFile" type="submit" class="bg-[#0EE2B1] ml-auto border cursor-pointer rounded-md submit-button px-3 py-2 text-white">
                                <span v-if="loadingUploadButton" class="spinner-border" role="status" aria-hidden="true"></span>
                                <span v-else>Upload</span>
                                </button>
                             </div>
                          </div>
                       </div>
                    </slot>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import complianceUploadDropdownOptions from '../../../../constants/practitioners/complianceUploadDropdownOptions'
import InputErrorMessage from '../../../Utilities/InputErrorMessage.vue'

export default {
    props: ['compliance', 'practitionerRef'],
    components:{InputErrorMessage},
    data()
    {
        return {
            fileTypesOptions: complianceUploadDropdownOptions,
            modalData: {
            },
            formData: {},
            file: null,
            start_date: null,
            end_date: null,
            disabledSelect: false,
            loadingUploadButton: false,
            disableUploadButton: false,
            ifValidDate: true,
        }
    },
    computed: {
    },
    methods: {
        ...mapActions('practitionerCompliances', ['complianceFileUploadAction']),
        onFileChange(e) {
            this.file = e.target.files[0];
        },
        uploadFile() {
            this.disableUploadButton = true
            this.loadingUploadButton = true
            const formData = new FormData();
            formData.append('file', this.$refs.file.files[0]);
            formData.append('validityDate', this.validityDate);
            formData.append('compRef', this.compliance.ref);
            formData.append('compStatus', this.compliance.status)
            formData.append('pracRef', this.practitionerRef);
            if(this.validityDate === 'yes'){

                const startDateObj = new Date(this.start_date)
                const endDateObj = new Date(this.end_date)
                if(this.start_date == null || this.end_date == null){
                  Swal.fire({
                      toast: true,
                      icon: 'error',
                      title: 'Please provide a valid date.',
                      animation: true,
                      position: 'top-right',
                      showConfirmButton: false,
                      timer: 3000,
                      timerProgressBar: true
                  });
                  this.disableUploadButton = false
                  this.loadingUploadButton = false
                  this.ifValidDate = false
                }
                else if (startDateObj.getTime() > endDateObj.getTime()) {

                  Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please mention Start date before End date.',
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                  });
                  this.disableUploadButton = false
                  this.loadingUploadButton = false
                  this.ifValidDate = false
                }
                else {

                  this.ifValidDate = true
                  formData.append('start_date', this.start_date);
                  formData.append('end_date', this.end_date);


                }

            }
            if(this.ifValidDate) {
              if(this.file !== null) {
                this.complianceFileUploadAction(formData)
                .then(res => {
                  Swal.fire({
                    toast: true,
                    icon: 'success',
                    title: "File Uploaded",
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                  }).then(() => {

                    this.disableUploadButton = false
                    this.loadingUploadButton = false
                    this.$emit('done')
                  }
                  );
                }).catch(err => {
                  Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Not the correct file extension please upload a PDF, PNG or Jpeg file.',
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                  });

                  this.disableUploadButton = false
                  this.loadingUploadButton = false
                })
              }else {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please upload a valid file.',
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                  });

               this.disableUploadButton = false
               this.loadingUploadButton = false
              }
            }
        },

        pushData(){
            this.modalData.clinic_ref = this.MAIN_CLINIC.ref
            this.$emit('eventListener', this.modalData)
            this.$forceUpdate();
        }
    },
    mounted()
    {
    },
    watch: {
    },
    created() {
        if(this.compliance.file_type.length > 0){
            this.formData.title = this.compliance.file_type
            this.disabledSelect = true
        }
    },
    computed:{
        validityDate() {
            let x = this.fileTypesOptions[this.formData.title]
            return x.validity_date;
        },
        ...mapGetters('commonFunctions', ['GET_IF_VALID_DATE'])
    }
}
</script>


<style lang="scss" scoped>

@import "vue-select/dist/vue-select.css";
input, textarea , svg{
    cursor: pointer;
}
</style>
