<template>
    <div class="container">

        <div class="main-page mt-5">

            <div class="tab-wrapper my-5">
                <div class="tab-section overflow-x-scroll">
                    <ul class="flex p-0">
                        <li v-for="tab in service_page_tabs" :key="tab" class="mr-4 px-4 py-2 cursor-pointer rounded-lg" :class="{'active-tab': GET_ACTIVE_TAB == tab}" @click="changeActiveTab(tab)">{{tab}}</li>
                    </ul>
                </div>
            </div>
            
            <div class="display-all-container mt-5">
                <component :services="services" :is="pageComponent"></component>
            </div>
        </div>
</div>
</template>

<script>
import { mapActions, mapMutations,mapGetters  } from 'vuex';
import AllServices from './AllServices.vue';
import ConditionsTreated from './ConditionsTreated.vue';

export default {
    props: ['services', 'service_page_tabs'],
    components: {
        AllServices
    },
    data() {
        return {
            crumbs: [
                {name: 'Services', url: '/dashboard/services'},
            ],
            
        }
    },
    methods: {
        ...mapActions('commonFunctions', ['loadBreadcrumbs']),
        ...mapActions('service', ['setUrlTab', 'changeActiveTab', 'setUpServicesPage']),
        ...mapMutations('service', ['SET_ACTIVE_TAB']),
    },
    computed: {
        ...mapGetters('service', ['GET_ACTIVE_TAB']),
        pageComponent()
        {
            if(this.GET_ACTIVE_TAB !== 'undefined'){

                switch (this.GET_ACTIVE_TAB) {
                    case 'Services':
                        return AllServices;break;
                    case 'Conditions Treated':
                        return ConditionsTreated;break;
                    
                }
            }
        }
    },
    mounted()
    {
        this.loadBreadcrumbs(this.crumbs);
    },
    created(){
        const searchParams = new URLSearchParams(window.location.search);
        const tab = searchParams.get('tab') || 'Services';
        this.SET_ACTIVE_TAB(tab)
    }


}
</script>
<style scoped lang="scss">
    .active-tab {
        background-color: #d2f7efb3;
        color:  #0EE2B1;
    }
</style>