const EmailEnquiryFormData =
    {
        "title": "",
        "loadFunction": "",
        "saveAddFunction": "sendEnquiryEmail",
        "saveEditFunction": "",
        "buttonCreateText": "",
        "buttonUpdateText": "Send Enquiry",
        "components":
        {
            "componentGroup1":
            {
                "sectionTitle": "To",
                "sectionExplenation": "Clinic Email",
                "inputTitle": "Clinic Email",
                "inputType": "email",
                "inputName": "clinic_email",
                "required": true,
                "dataRef": 'clinic email',
                "vModel": "clinic_email",
                "placeholder": ""
            },
            "componentGroup2":
            {
                "sectionTitle": "Your Name",
                "sectionExplenation": "Please enter your full name",
                "inputTitle": "Patient's Name",
                "inputType": "text",
                "inputName": "patient_name",
                "required": true,
                "dataRef": "patient_name",
                "vModel": "patient_name",
                "placeholder": "i.e. Sara Smith"
            },
            "componentGroup3":
            {
                "sectionTitle": "Your Email",
                "sectionExplenation": "Please enter your email address",
                "inputTitle": "Patient's Email",
                "inputType": "email",
                "inputName": "patient_email",
                "required": true,
                "dataRef": "patient_email",
                "vModel": "patient_email",
                "placeholder": "i.e. sara@smith.co.uk"
            },
            "componentGroup4":
            {
                "sectionTitle": "Your Telephone",
                "sectionExplenation": "Please enter your telephone",
                "inputTitle": "Patient's Telephone (optional)",
                "inputType": "number",
                "inputName": "patient_phone",
                "required": false,
                "dataRef": "patient_phone",
                "vModel": "patient_phone",
                "placeholder": "i.e. 07000 123456"
            },
            "componentGroup5":
            {
                "sectionTitle": "Service",
                "sectionExplenation": "Which service are you interested in?",
                "inputTitle": "Service",
                "inputType": "select",
                "inputName": "service",
                "required": false,
                "dataRef": "enquiry_service",
                "vModel": "enquiry_service",
                "placeholder": ""
            },
            "componentGroup6":
            {
                "sectionTitle": "Subject",
                "sectionExplenation": "Please select a subject",
                "inputTitle": "Subject",
                "inputType": "select",
                "inputName": "email_subject",
                "required": true,
                "dataRef": "email_subject",
                "vModel": "email_subject",
                "placeholder": "",
                "options": [
                    "Please select a subject",
                    "Request an appointment",
                    "Enquiry about a service",
                    "Complain",
                    "General enquiry",
                ]
            },
            "componentGroup7":
            {
                "sectionTitle": "Your message",
                "sectionExplenation": "Write your message",
                "inputTitle": "Your message",
                "inputType": "textarea",
                "inputName": "email_message",
                "required": true,
                "dataRef": "email_message",
                "vModel": "email_message",
                "placeholder": "",
                "options": [
                    "",
                     "Hi, Can I please request an appointment for {startsWithVowel} {enquiryService} at your earliest availability? You can contact me on this email address{telephoneOption}. Looking forward to hearing from you. Kind Regards, {patientName}.",
                     "Hi, I am looking for {startsWithVowel} {enquiryService} service. Can I please have more information about prices, availability, and what the service consists of? You can contact me on this email address {telephoneOption}. Looking forward to hearing from you. Kind Regards, {patientName}.",
                ]
            },

        }
    }


export default EmailEnquiryFormData;
