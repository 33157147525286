<template>
    <div  v-if="!loadFormdata" class="px-4 mb-4 container">
        <div class="form-section-info">
            <h3 class="form-section-info-title font-semibold pb-3">{{ formData.title}}</h3>
            <span class="text-gray-400">{{formData.description}}</span>
        </div>
        <div class="form-section mt-4 flex px-4">
            <div class="form-section-info w-1/3">
                <!-- <h3 class="form-section-info-title">{{ formData.components.componentGroup1.sectionTitle }}</h3>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup1.sectionExplenation }}</small> -->

                <label class="block text-gray-500 font-bold text-right md:mb-0" >{{ formData.components.componentGroup1.inputTitle }}
                    <span v-if="formData.components.componentGroup1.required" class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">{{formData.components.componentGroup1.sectionExplenation }}</p>

            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <!-- <label>
                        {{ formData.components.componentGroup1.inputTitle }}
                        <span v-if="formData.components.componentGroup1.required" class="text-red-400">*</span>
                    </label> -->
                    <input 
                    :type="formData.components.componentGroup1.inputType" 
                    class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="modalData.patient_email" 
                    :required="formData.components.componentGroup1.required"
                    :placeholder="formData.components.componentGroup1.placeholder"
                    :name="formData.components.componentGroup1.inputName"
                    @input="pushData"
                    :autofocus="emailFocus"
                    />
                <InputErrorMessage v-if="GET_SHOW_ERRORS && (!modalData.patient_email || modalData.patient_email === '')"/>
                </div>
            </div>
        </div>
        <span class="px-4 " v-if="services.length === 0" >You currently have no services. <br> </span>
        
        <span class="px-4 " v-if="services.length === 0" >Please <a href="/dashboard/services?action=add">add a service </a> first to request a review</span>

        <div v-else-if="!loadingServices"  class="px-4  pb-4 text-end">
            <div class=" offset-md-4">
                <button :disabled="getLoadingSubmitButton" @click="saveReviewRequest(modalData)" type="submit" class="px-4 action-button ml-auto border cursor-pointer rounded-md submit-button ">
                    <span v-if="getLoadingSubmitButton" class="spinner-border" role="status" aria-hidden="true"></span>
                    <span v-else>{{ formData.buttonCreateText }}</span>
                </button>
            </div>
        </div>
    </div>


</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import InputErrorMessage from '../../../Utilities/InputErrorMessage.vue'
import AddReviewRequestModalForm from '../../../../constants/services/modalForms/CreateReviewRequestModalForm'

export default {
    props: ['target', 'type'],
    components:{InputErrorMessage},
    data()
    {
        return {
            modalData: {
                patient_email: ''
            },
            formData: {},
            loadFormdata: true,
            radius_tier_options: 1,
            loadingServices: true,
            services: {},
            emailFocus: false
        }
    },
    computed: {
        ...mapGetters('mainClinic', ['MAIN_CLINIC']),
        ...mapGetters('reviews', ['getLoadingSubmitButton', 'GET_SHOW_ERRORS']),
    },
    methods: {
        ...mapActions('reviews', ['saveReviewRequest', 'getServicesForReviews']),
        pushData(){
            this.modalData.clinic_ref = this.MAIN_CLINIC.ref
            this.$emit('eventListener', this.modalData)
            this.$forceUpdate();
        }
    },
    mounted()
    {
        this.formData = AddReviewRequestModalForm;
        // If editing, pass in target services values
        if(this.target)
        {
            this.modalData.mainClinic = this.target.mainClinic;

            if(this.type == 'edit')
            {
               this.modalData.services = this.target.main_service_name;
               this.modalData.ref = this.target.ref;
               this.modalData.type = "service"
            }
        }
    },
    watch: {
        formData: {
            handler(newValue) {
            this.loadFormdata = false
            }
        }
    },
    created() {
        this.getServicesForReviews()
        .then(res => {
            this.services = res.data
            this.loadingServices = false
        }).catch(err => {
            console.log(err)
            this.loadingServices = false
        })

        const searchParams = new URLSearchParams(window.location.search);
        const email = searchParams.get('email') || '';
        if(email.length > 0) {
            this.emailFocus = true
            this.modalData.patient_email = email
        }
    }

}
</script>


<style lang="scss" scoped>

@import "vue-select/dist/vue-select.css";
input, textarea , svg{
    cursor: pointer;
}
.action-button{
    width: fit-content !important;
}
</style>