<template>
    <div class="stepper mx-auto my-atuo">
        <div class="stepper-progress">
            <div class="stepper-progress-bar" :style="'width:' + stepperProgress"></div>
        </div>
        <div class="stepper-item" :class="{ 'current': GET_ACTIVE_TAB == index || actualStep.stepTitle === step.stepTitle, 'success': GET_ACTIVE_TAB > index || actualStepStop > index}" v-for="(step, index) in steps" :key="index">
            <div class="stepper-item-counter">
                <img class="icon-success" src="https://www.seekpng.com/png/full/1-10353_check-mark-green-png-green-check-mark-svg.png" alt="">
                <span class="number">
                    {{ index }}
                </span>
            </div>
            <span class="stepper-item-title">
                {{ step.stepTitle }}
            </span>
        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';

    export default
    {
        props: {
            steps: Object,
            actualStep: Object,
            actualStepStop: Number
        },
        data() {
            return {
                
            }
        },
        computed: {
            ...mapGetters('mainClinic', ['GET_ACTIVE_TAB']),
            stepperProgress() {
                return ( 100 / this.steps.length  ) * ( this.GET_ACTIVE_TAB +1 ) + '%'
            }
        }
        
    }
</script>