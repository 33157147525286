<template>
    <div class="form-section my-4 flex px-4">
      <div class="form-section-info w-1/3">
        <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0">
          Conditions
          <span class="text-red-400">*</span>
        </label>
        <p class="font-sm text-gray-400 text-right">Choose a condition</p>
      </div>
      <div class="form-section-content w-2/3 ml-5">
        <div class="mb-3">
          <v-select
            label="name"
            class="cursor-pointer appearance-none w-full text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white"
            name="select1"
            :options="allconditions"
            v-model="condition"
          ></v-select>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import vSelect from 'vue-select';
  import { mapActions } from 'vuex';
  
  export default {
    components: { vSelect },
    props: ['allconditions', 'conditionsession'],
    data() {
      return {
        condition: this.conditionsession || '',
        modalData: {},
      };
    },
    methods: {
      ...mapActions('condition', ['saveServiceSuggestionAction']),
      pushData() {
        this.$emit('eventListener', this.modalData);
      },
    },
    watch: {
      condition: {
        handler(value) {
          this.$emit('updateModalData', value);
        },
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .vue-select-window {
    height: 250px;
  }
  
  .form-section {
    height: 150px;
  }
  </style>
  