const clinicPeerEndorsementsTableHeads = [
    {
        title: 'Name',
        var: 'peerNameAsc',
        sortable: true
    },
    {
        title: 'Email',
        var: 'emailAsc',
        sortable: true
    },
    {
        title: 'Status',
        var: 'statusAsc',
        sortable: false
    }
    ,
    {
        title: '',
        var: '',
        sortable: false
    }
]

export default clinicPeerEndorsementsTableHeads;
