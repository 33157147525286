<style lang="scss">
    ul {
        padding: 0px;
    }
    .profile-small-header-section {
        border-bottom: 2px solid #0BD2A4;

        h1 {
            font-size: 25px;
            line-height: 12px;
            color: #717170;
            font-weight: 700;
            padding-bottom: 5px;
        }
    }

    .profile-main-content-section {
        p {
            color: #717170;
            font-size: 17px;
            line-height: 28px;
            white-space: pre-wrap;
        }
    }
    .profile-main-content-section {
        .practitioner-list-box {
            .practitioner-list-item {
                list-style: none!important;
                margin-right: 80px;

                img {
                    border-radius: 50%;
                    height: 180px;
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 7px 0px;
                }

            }

        }
    }

    h5 {
        color: #838382;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
    }
    .capitalize-first {
        text-transform: capitalize;
    }
    .capitalize-first:first-letter {
        text-transform: uppercase;
    }
    .overflow-auto {
  overflow: auto;
}


.top-div {
  background-color: #ffffff;
  padding: 2px;
  position: sticky;
  top: 0;
}

.bottom-div {
  padding: 10px;
}

.overflow-shadow {
  /* Add your custom styles for the overflow shadow here */
  /* For example: */
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
}


</style>
<template>
    <div class="section-wrapper w-full">
       <div class="flex justify-between items-center profile-small-header-section">
          <!-- Left-side content (h1) -->
          <div>
             <h1 class="text-lg font-bold">Peer Endorsements</h1>
          </div>
       </div>
       <div class="profile-main-content-section mt-3">
          <div class="practitioner-list-box flex-wrap flex">
             <h5 class="my-auto m-0 mx-aut text-center">{{ endorsements.length }} Endorsement<span v-if="endorsements.length > 1">s</span></h5>
          </div>
       </div>
       <div class="mt-3">
        <div class="overflow-auto max-h-96">
            <div class="top-div overflow-shadow"></div>
                <div class="bottom-div">
                    <!-- Content of the bottom div goes here -->
                    <one-profile-endorsement-tile
                        v-for="endorsement in endorsements"
                        :endorsement="endorsement"
                        :key="endorsement.id"
                        class="my-3"
                    />

                </div>
            </div>

       </div>
    </div>
 </template>
<script>

import filterOptions from '../../../../constants/search/profile/filterOptions'
import OneProfileEndorsementTile from './OneProfileEndorsementTile'
    export default {
        data() {
            return {

            }
        },
        props: ['endorsements'],
        components: {OneProfileEndorsementTile},

        methods: {
             addShadowOnScroll() {
                  const container = document.querySelector('.overflow-auto');
                  const scrollPosition = container.scrollTop;

                  const shadowElement = document.querySelector('.overflow-shadow');
                  if (scrollPosition > 0) {
                    shadowElement.style.display = 'block';
                  } else {
                    shadowElement.style.display = 'none';
                  }
             },
        },
        created() {

        },
        computed: {

        }
    }
</script>
