import axios from 'axios';
export default {
    namespaced: true,
    state: {

    },
    mutations: {

    },
    actions: {
        logAnalytics({commit}, payload)
        {
            axios.post('/api/analytics/log', payload)
            .then((res) => {
            });
        }
    },
    getters: {

    }

}


