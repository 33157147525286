<template>
    <div class="">
        <LogsCard :logs="logs" v-if="logs.length > 0"></LogsCard>
        <div class="" v-else>
            <p>No logs to show</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LogsCard from '../Logs/LogsCard.vue';

export default {
    components: {LogsCard},
    data()
    {
        return {
            logs: []
        }
    },
    methods: {
        ...mapGetters('log', ['GET_LOGS'])
    },
    mounted(){
        this.logs = this.GET_LOGS();
    }
}
</script>