<template>
    <div class="card-wrap">
        <div class="card bg-white p-4 flex flex-col">
            <div v-if="icon" class="icon-box mx-auto">
                <div class="iconBg flex" :style="'background-color:'+iconBgColor">
                    <box-icon class="mx-auto my-auto" :name='icon' :color='iconColor' size='md'></box-icon>
                </div>
            </div>
            <div v-if="title" class="card-title my-2 mx-auto">
                <h3 v-text="title" class="text-center"></h3>
            </div>
            <div v-if="content" class="card-content my-2 mx-auto">
                <p v-text="content" class="text-center"></p>
            </div>
            <div v-if="footerText" class="card-btm my-2 mx-auto">
                <a class="" :href="footerLink">{{footerText}}</a>
            </div>
        </div>
    </div>
</template>s

<script>
    export default {
        props: {
            icon: {
                type: String,
                required: false,
            },
            iconColor: {
                type: String,
                required: false,
                default: "#599DE0"
            },
            iconBgColor: {
                type: String,
                required: false,
                default: "#F1F4FB"
            },
            title: {
                type: String,
                required: false,
            },
            content: {
              type: String,
              required: false,
            },
            footerText: {
                type: String,
                required: false
            },
            footerLink: {
                type: String,
                required: false
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "../../sass/abstracts/_variables.scss";

    .card {
        border-radius: 30px;
        box-shadow: 0 10px 30px 0 rgba(0,0,0,0.25);
        width: 100%;

        .icon-box {
            .iconBg {
                width: 74px;
                height: 74px;
                padding: 18px;
                border-radius: 50%;
            }
        }

        .card-title h3 {
            font-size: 35px;
            font-weight: 700;
            line-height: 60px;

            @media(max-width: 768px)
            {
                font-size: 30px;
            }
        }

        .card-content {
            p {
                color: #212429;
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
            }
        }

        .card-btm {
            a {
                text-decoration: none;
                color: $primary_green;
                font-size: 21px;
            }
        }


    }
</style>
