<template>
    <div @click="openUpdateModal()" class="mb-4 card blurr shadow-blurr border-none">
      <div class="p-3 card-body ">
        <div class="d-flex" :class="directionReverse ? reverseDirection : ''">
          <div>
            <div
              class="text-center shadow icon icon-shape rounded-xl w-12 h-12 flex"
              :class="typeof icon === 'object' ? icon.background : ''"
            >
              <Icon
                class="text-lg h-full flex mx-auto my-auto text-white"
                :class="typeof icon === 'string' ? icon : icon.component"
                aria-hidden="true"
                :icon="icon.component"
              ></Icon>
            </div>
          </div>
          <div :class="classContent">
            <div class="numbers flex h-full">
              <h6 class="font-weight-bolder my-auto text-md ">
                {{ title }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Icon from './Icon'
  export default {
    name: "MiniActionCard",
    props: {
      modalId: {
        type: String
      },
      directionReverse: {
        type: Boolean,
        default: false,
      },
      title: {
        type: [Object, String],
        default: null,
        text: {
          type: String,
        },
        color: {
          type: String,
        },
      },
      icon: {
        type: [String, Object],
        component: {
          type: String,
        },
        background: {
          type: String,
        },
        default: () => ({
          background: "bg-white",
        }),
      },
      classContent: {
        type: String,
        default: "",
      },
    },
    components: {
        Icon
    },
    data() {
      return {
        reverseDirection: "flex-row-reverse justify-content-between"
      };
    },
    methods:{
      openUpdateModal() {
        
        this.$emit('openUpdateModal', this.modalId)
        this.updateClinicModal = true
      }
    }
  };
  </script>
  