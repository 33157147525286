const EditReviewModalForm =
    {
        "title": "Edit a review",
        "loadFunction": "",
        "saveAddFunction": "",
        "saveEditFunction": "editReview",
        "buttonCreateText": "",
        "buttonUpdateText": "Update",
        "components":
        {
            "author" :{
                "title": "Author",
                "required" :false,
                "inputType" :"text",
                "inputName" :"author",
                "description" :"These will be shown  as the author of the review. If you display the full name of the author, please make sure you have the consent to do so. ",
                "placeholder" :" i.e. J. Smith",
                "help" :"i.e.  Patient, A.V., Antonello, Antonello.v",
                "content" :{}
            },
            "reviewType" : {
                "required" : true,
                "inputType" : "select",
                "inputName" : "review_type",
                "description" : "Review type",
                "help" : "",
                "content" : {
                    "online_review": "Other platform review",
                    "medimob_review": "Medimob review",
                },
            },
            "url_of_origin" : {
                "required" : false,
                "inputType" : "url",
                "inputName" : "url_of_origin",
                "description" : "Origin of this review",
                "help" : "https://www.example.com.....",
                "content" : {},

            },
            "rating" :{
                "title": "Rating",
                "required" :true,
                "inputType" :"text",
                "inputName" :"rating",
                "description" :"Please indicate how much this service was rated",
                "help" :"",
                "content": {},
            },
            "service_received" :{
                "title": "Service Received",
                "required" :true,
                "inputType" :"text",
                "inputName" :"service_received",
                "description" :"Please indicate the service this review is about. If the service is not listed, please add it in    the services section (the service word should be a link href that goes to the services page).",
                "max" :1500,
                "help" :"Please provide the service received",
                "content" :{},
            },
            "reviewText" :{
                "title": "Review Text",
                "required" :true,
                "inputType" :"textarea",
                "inputName" :"review_text",
                "description" :"Please copy and paste the text of the review (or part of it).",
                "max" :1500,
                "help" :" Please write here the review (max 1500 characters)",
                "content" :{},
            },
        }
    }


export default EditReviewModalForm;
