<template>
    <div class="">
        <div class="w-full mb-2">
            <edit-publications-single-practitioner :practitioner="GET_PRACTITIONER"></edit-publications-single-practitioner>
        </div>
    </div>

</template>

<script>
import EditPublicationsSinglePractitioner from './EditPublicationsSinglePractitioner.vue';
import { mapGetters } from 'vuex';

export default {

    components: {
        EditPublicationsSinglePractitioner
    },
    computed: {
        ...mapGetters('practitioner', ['GET_PRACTITIONER']),
    }

}
</script>