<script>
import SearchForm from "../SearchForm.vue";
import VueTypedJs from 'vue-typed-js'


export default {
  name: "SearchFormWhiteWrap",
  components: {SearchForm, VueTypedJs},
  props: ['last_query']
}
</script>

<template>
  <div class="">
    <vue-typed-js :strings="['Doctor', 'Phlebotomist', 'Nurse', 'Nutritionist']" :loop="true" :shuffle="true" :startDelay="1000" :typeSpeed="100" :backSpeed="100" :smartBackspace="true" :backDelay="2000" :showCursor="true">
      <h5 class="mx-auto mb-4 text-center search-box-title">Look for a trusted <span class="typing" style="color:#0BD2A4">Doctor</span></h5>
    </vue-typed-js>
    <div class="form-group my-auto">
      <SearchForm :last_query="last_query"></SearchForm>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
