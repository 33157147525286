<style lang="scss">
    .profile-small-header-section {
        border-bottom: 2px solid #0BD2A4;

        h1 {
            font-size: 25px;
            line-height: 12px;
            color: #717170;
            font-weight: 700;
            padding-bottom: 5px;
        }
    }

    .profile-main-content-section {
        p {
            color: #717170;
            font-size: 17px;
            line-height: 28px;
            white-space: pre-wrap;
        }
    }
</style>

<template>
    <div class="section-wrapper w-full">
        <div class="profile-small-header-section">
            <h1>About</h1>
        </div>
        <div class="profile-main-content-section mt-3">
            <p v-html="profileBio"></p>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['profileBio']
    }
</script>