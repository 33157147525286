<style scoped>

.image-cover {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 20px;

  object-fit: cover;
  object-position: center right;
  z-index: 1;

}

</style>

<template>
    <div class="img-wrapper">
        <img v-if="image" class="image-cover" :src="image" :width="width" :height="height" :class="customClass">
        <img v-else class="image-cover" src="../../../public/images/placeHolderUser.png" :width="width" :height="height" :class="customClass">
    </div>
</template>

<script>

export default {
    props: ['image', 'width', 'height', 'customClass']
}
</script>
