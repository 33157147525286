import { render, staticRenderFns } from "./HaveQuestions.vue?vue&type=template&id=351768aa&scoped=true"
import script from "./HaveQuestions.vue?vue&type=script&lang=js"
export * from "./HaveQuestions.vue?vue&type=script&lang=js"
import style0 from "./HaveQuestions.vue?vue&type=style&index=0&id=351768aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "351768aa",
  null
  
)

export default component.exports