const singleClinicTelephoneModalForm = 
    {
        "title": "",
        "loadFunction": "",
        "saveAddFunction": "",
        "saveEditFunction": "saveClinicMobile",
        "buttonCreateText": "",
        "buttonUpdateText": "Update",
        "components":
        {
            "componentGroup1":
            {
                "sectionTitle": "Clinic Mobile Phone",
                "sectionExplenation": "Please provide a mobile phone for this clinic",
                "inputTitle": "Clinic Mobile Phone",
                "inputType": "number",
                "inputName": "mobile_phone",
                "required": false,
                "dataRef": 'mobile phone',
                "vModel": "mobile_phone"

            },

        }
    }


export default singleClinicTelephoneModalForm;