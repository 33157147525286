
<template>

<div class="last-saved-badge rounded-lg">
    <p class="mb-0 font-semibold">Last updated: {{LastUpdated}} </p>
</div>
</template>

<script>
import moment from 'moment';

export default {
    props: ['lastUpdated'],

    data() {
        return {
        }
    },
    computed:
    {
        LastUpdated()
        {
            return moment(this.lastUpdated).fromNow();
        }
    }
}
</script>