<template>
    <section class="w-full container section-separation bg-cover bg-center">
        <div class="p-8 text-center need-health-care-banner left-pom my-auto flex flex-col items-center">
            <div class="container text-center title title-complex">
                <h1 class="why-choose-title-white text-white mb-3">Need a <span class="whychoose-title">Healthcare Practitioner</span> Today?</h1>
                <div class="flex w-full">
                    <p class="text-white mt-3 white-paragraph-col-bg mx-auto w-4/6">Whether you need medical advice or treatment for a health concern,
                        book an appointment to speak to a doctor online.</p>
                </div>

                <a href="/" class="flex dark-home-button my-auto text-decoration-none items-center w-1/5 bg-blue-500 text-white rounded-lg px-4 py-3 mt-2 mx-auto" style="border:none">
                    <span>Find them now!</span>
                </a>
            </div>
        </div>
    </section>
</template>
<script>
export default {

}
</script>
