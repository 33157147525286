<template>
    <div class="main-profile-content p-4 max-height">
   <div class="overview-cards mt-5">
      <div class="row" style="margin-top: -40px;">
         <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-xl-0 mb-4">
            <mini-action-card
               class="cursor-pointer"
               title="Edit Main Clinic Logo"
               :modalId="'updateClinicLogo'"
               @openUpdateModal="openUpdateModal"
               :icon="{
               component: 'image-o',
               background: 'bg-gradient-green',
               }"
               direction-reverse
               ></mini-action-card>
         </div>
         <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-xl-0 mb-4">
            <mini-action-card
               class="cursor-pointer"
               title="Add Clinic"
               :modalId="'addClinic'"
               @openUpdateModal="openUpdateModal"
               :icon="{
               component: 'weight_scale',
               background: 'bg-gradient-green',
               }"
               direction-reverse
               ></mini-action-card>
         </div>
         <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-xl-0 mb-4">
            <mini-action-card
               class="cursor-pointer"
               title="Add a practitioner"
               :modalId="'addPractitioner'"
               @openUpdateModal="openUpdateModal"
               :icon="{
               component: 'location',
               background: 'bg-gradient-green',
               }"
               direction-reverse
               ></mini-action-card>
         </div>
         <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-xl-0 mb-4">
            <mini-action-card
               @click="paymentMethodModal = true"
               class="cursor-pointer"
               title="Update main clinic address"
               :modalId="'updateMainAddress'"
               @openUpdateModal="openUpdateModal"
               :icon="{
               component: 'wallet',
               background: 'bg-gradient-green',
               }"
               direction-reverse
               ></mini-action-card>
         </div>
      </div>
   </div>
   <UpdateProfileImagesModal @closeModal="closeModal" v-if="UpdateProfileImagesModal" :pictureConcerned="MAIN_CLINIC.image_path" :chosenModal="modalId"/>
   <UpdateCreateClinicModal @closeModal="closeModal" v-if="updateCreateClinicModal" :chosenModal="modalId"/>
</div>

 </template>
<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import UpdateProfileImagesModal from '../Clinics/UpdateProfileImagesModal.vue'
import UpdateCreateClinicModal from '../Clinics/UpdateCreateClinicModal.vue'
export default {
    components: { 
        UpdateProfileImagesModal,
        UpdateCreateClinicModal
    },
    data() {
        return {
            errors: '',
            crumbs: [
                {name: 'Profile', url: '/dashboard/profile'},
            ],
            paymentMethodModal: false,
            updateCreateClinicModal: false,
            UpdateProfileImagesModal: false,
            modalId: ''
        }
    },
    methods:{
        ...mapActions('practitioner', ['setUserPractitioners']),
        ...mapActions('mainClinic', ['getUserSateliteClinics']),
        openUpdateModal(modalId){
            if(modalId === 'updateClinicLogo'){
                this.UpdateProfileImagesModal = true
                this.modalId = modalId
            } else {
                this.updateCreateClinicModal = true
                this.modalId = modalId
            }
        },
        closeModal(){
            this.updateCreateClinicModal = false
        }
    },
    computed: {
        ...mapGetters('mainClinic', ['MAIN_CLINIC'])
    },
    created() {
        this.setUserPractitioners()
        this.getUserSateliteClinics()
    }
}
</script>

<style lang="scss" scoped>

</style>