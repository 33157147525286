import axios from 'axios';
export default {
    namespaced: true,
    state: {
        headOffice: {},
        savedShortBio: false,
        savedFullBio: false,
        savedEmail: false,
        savedWebsite: false,
        savedTelephone: false,
        savedMobile: false,
        savedInstagram: false,
        savedFacebook: false,
        savedTwitter: false,
        savedLinkedIn: false,
        lastUpdated: '',
        formErrors: '',
        errorModal: false,
    },
    mutations: {
        setHeadOfficeData(state, headOffice)
        {
            state.headOffice = headOffice;
            state.lastUpdated = headOffice.updated_at;
        },
        shortBioSaved(state, lastUpdated)
        {
            state.savedShortBio = true;
            state.lastUpdated = lastUpdated;
        },
        shortBioNotSaved(state)
        {
            state.savedShortBio = false;
        },
        fullBioSaved(state, lastUpdated)
        {
            state.savedFullBio = true;
            state.lastUpdated = lastUpdated;
        },
        fullBioNotSaved(state)
        {
            state.savedFullBio = false;
        },
        emailSaved(state, lastUpdated)
        {
            state.savedEmail = true;
            state.errorModal = false;
            state.lastUpdated = lastUpdated;
        },
        websiteSaved(state, lastUpdated)
        {
            state.savedWebsite = true;
            state.errorModal = false;
            state.lastUpdated = lastUpdated;
        },
        telephoneSaved(state, lastUpdated)
        {
            state.savedTelephone = true;
            state.errorModal = false;
            state.lastUpdated = lastUpdated;
        },
        mobileSaved(state, lastUpdated)
        {
            state.savedMobile = true;
            state.errorModal = false;
            state.lastUpdated = lastUpdated;
        },
        instagramSaved(state, lastUpdated)
        {
            state.savedInstagram = true;
            state.errorModal = false;
            state.lastUpdated = lastUpdated;
        },
        facebookSaved(state, lastUpdated)
        {
            state.savedFacebook = true;
            state.errorModal = false;
            state.lastUpdated = lastUpdated;
        },
        twitterSaved(state, lastUpdated)
        {
            state.savedTwitter = true;
            state.errorModal = false;
            state.lastUpdated = lastUpdated;
        },
        linkedinSaved(state, lastUpdated)
        {
            state.savedLinkedIn = true;
            state.errorModal = false;
            state.lastUpdated = lastUpdated;
        },
        emailNotSaved(state)
        {
            state.savedEmail = false;
        },
        websiteNotSaved(state)
        {
            state.savedWebsite = false;
        },
        telephoneNotSaved(state)
        {
            state.savedTelephone = false;
        },
        mobileNotSaved(state)
        {
            state.savedMobile = false;
        },

        instagramNotSaved(state)
        {
            state.savedInstagram = false;
        },
        facebookNotSaved(state)
        {
            state.savedFacebook = false;
        },
        twitterNotSaved(state)
        {
            state.savedTwitter = false;
        },
        linkedinNotSaved(state)
        {
            state.savedLinkedIn = false;
        },
        linkError(state, {error, technicalError, noPermission, invalidURL})
        {
            if (_.includes([419, 401, 500], error.response.status)) {
                state.errorModal = true,
                state.formErrors = technicalError;
            } else if (error.response.status === 403) {
                state.errorModal = true,
                state.formErrors = noPermission;
            } else if (error.response.status === 422) {
                state.errorModal = true,
                state.formErrors = invalidURL;
            }
        }
    },
    actions: {
        getHeadOffice({commit}, payload) {

            return new Promise((resolve, reject) => {
            let url = '/dashboard/profile/get-head-office';

                axios.post(url, payload)
                .then(response => {
                    commit('setHeadOfficeData', response.data);
                  resolve(response)
                }).catch(error => {
                  reject(error)
                })
            })

        },
        saveShortBio({commit}, payload) {

            let url = '/dashboard/profile/update-short-bio';
            try{
                axios.put(url, payload).then(res => {

                    commit('shortBioSaved', res.data);
                })
            } catch(e){}
        },
        alterShortBioSavedStatus({commit}) {
            commit('shortBioNotSaved');
        },
        saveFullBio({commit}, payload) {

            let url = '/dashboard/profile/update-full-bio';

            axios.put(url, payload).then(res => {
                Swal.fire({
                    toast: true,
                    icon: 'success',
                    title: 'Profile Information Updated',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true
                  })
                commit('fullBioSaved', res.data);
            })
        },
        alterFullBioSavedStatus({commit}) {
            commit('fullBioNotSaved');
        },
        saveWebsite({commit}, payload) {
            let url =  '/dashboard/profile/update-website';
            try{
                axios.put(url, payload)
                .then(
                    res => {
                        commit('websiteSaved', res.data);
                    },
                    error => {
                        console.log(error.response.status);
                        let technicalError = 'An error has occured. Please try again later or contact Medimob support';
                        let noPermission = "You don't have the right permsission to perform this action. Please contact Medimob support";
                        let invalidURL = "Please insert a valid URL. A valid URL should start with 'https://' or 'http://'. Please contact Medimob support should you need assistance";
                        commit('linkError', { error, technicalError, noPermission, invalidURL });
                    })
            } catch(e){}
        },
        alterWebsiteSavedStatus({commit}) {
            commit('websiteNotSaved');
        },
        saveInstagram({commit}, payload) {
            let url =  '/dashboard/profile/update-instagram';
            try{
                axios.put(url, payload)
                .then(
                    res => {
                        commit('instagramSaved', res.data);
                    },
                    error => {
                        console.log(error.response.status);
                        let technicalError = 'An error has occured. Please try again later or contact Medimob support';
                        let noPermission = "You don't have the right permsission to perform this action. Please contact Medimob support";
                        let invalidURL = "Please insert a valid URL. A valid URL should start with 'https://' or 'http://'. Please contact Medimob support should you need assistance";
                        commit('linkError', { error, technicalError, noPermission, invalidURL });
                    })
            } catch(e){}
        },
        alterInstagramSavedStatus({commit}) {
            commit('instagramNotSaved');
        },
        saveFacebook({commit}, payload) {
            let url =  '/dashboard/profile/update-facebook';
            try{
                axios.put(url, payload)
                .then(
                    res => {
                        commit('facebookSaved', res.data);
                    },
                    error => {
                        console.log(error.response.status);
                        let technicalError = 'An error has occured. Please try again later or contact Medimob support';
                        let noPermission = "You don't have the right permsission to perform this action. Please contact Medimob support";
                        let invalidURL = "Please insert a valid URL. A valid URL should start with 'https://' or 'http://'. Please contact Medimob support should you need assistance";
                        commit('linkError', { error, technicalError, noPermission, invalidURL });
                    })
            } catch(e){}
        },
        alterFacebookSavedStatus({commit}) {
            commit('facebookNotSaved');
        },
        saveTwitter({commit}, payload) {
            let url =  '/dashboard/profile/update-twitter';
            try {
                axios.put(url, payload)
                .then(
                    res => {
                        commit('twitterSaved', res.data);
                    },
                    error => {
                        console.log(error.response.status);
                        let technicalError = 'An error has occured. Please try again later or contact Medimob support';
                        let noPermission = "You don't have the right permsission to perform this action. Please contact Medimob support";
                        let invalidURL = "Please insert a valid URL. A valid URL should start with 'https://' or 'http://'. Please contact Medimob support should you need assistance";
                        commit('linkError', { error, technicalError, noPermission, invalidURL });
                    })
            } catch(e) {}
        },
        alterTwitterSavedStatus({commit}){
            commit('twitterNotSaved');
        },
        saveLinkedIn({commit}, payload) {
            let url =  '/dashboard/profile/update-linkedin';
            try{
                axios.put(url, payload)
                .then(
                    res => {
                        commit('linkedinSaved', res.data);
                    },
                    error => {
                        console.log(error.response.status);
                        let technicalError = 'An error has occured. Please try again later or contact Medimob support';
                        let noPermission = "You don't have the right permsission to perform this action. Please contact Medimob support";
                        let invalidURL = "Please insert a valid URL. A valid URL should start with 'https://' or 'http://'. Please contact Medimob support should you need assistance";
                        commit('linkError', { error, technicalError, noPermission, invalidURL });
                    })
            } catch(e){}
        },
        alterLinkedInSavedStatus({commit}) {
            commit('linkedinNotSaved');
        },
        saveTelephone({commit}, payload) {
            let url =  '/dashboard/profile/update-telephone';
            try {
                axios.put(url, payload)
                .then(
                    res => {
                        commit('telephoneSaved', res.data);
                    },
                    error => {
                        console.log(error.response.status);
                        let technicalError = 'An error has occured. Please try again later or contact Medimob support';
                        let noPermission = "You don't have the right permsission to perform this action. Please contact Medimob support";
                        let invalidURL = "Please insert a valid UK number";
                        commit('linkError', { error, technicalError, noPermission, invalidURL });
                    })
            } catch(e){}
        },
        alterTelephoneSavedStatus({commit}) {
            commit('telephoneNotSaved');
        },
        saveMobile({commit}, payload) {
            let url =  '/dashboard/profile/update-mobile';
            try{
                axios.put(url, payload)
                .then(
                    res => {
                        commit('mobileSaved', res.data);
                    },
                    error => {
                        console.log(error.response.status);
                        let technicalError = 'An error has occured. Please try again later or contact Medimob support';
                        let noPermission = "You don't have the right permsission to perform this action. Please contact Medimob support";
                        let invalidURL = "Please insert a valid UK number";
                        commit('linkError', { error, technicalError, noPermission, invalidURL });
                    })
            } catch(e){}
        },
        alterMobileSavedStatus({commit}) {
            commit('mobileNotSaved');
        },
        saveEmail({commit}, payload) {
            let url =  '/dashboard/profile/update-email';
            try {
                axios.put(url, payload)
                .then(
                    res => {
                        commit('emailSaved', res.data);
                    },
                    error => {
                        console.log(error.response.status);
                        let technicalError = 'An error has occured. Please try again later or contact Medimob support';
                        let noPermission = "You don't have the right permsission to perform this action. Please contact Medimob support";
                        let invalidURL = "Please enter a valid Email Address";
                        commit('linkError', { error, technicalError, noPermission, invalidURL });
                    })
            } catch(e){}
        },
        alterEmailSavedStatus({commit}) {
            commit('emailNotSaved');
        },
        //  confirm user account after sending email
        confirmuser ({ commit, dispatch }, credentials) {
        return new Promise((resolve, reject) => {
            axios.post('/confirm-user', {
                verification_code: credentials.token,
                email: credentials.email
            })
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
        },
    },
    getters: {
        headOffice(state)
        {
            return state.headOffice;
        },
        savedShortBio(state)
        {
            return state.savedFullBio;
        },
        savedWebsite(state)
        {
            return state.savedWebsite;
        },
        lastUpdated(state)
        {
            return state.lastUpdated;
        },
    }

}


