<style lang="scss">
  @import "../../sass/components/modal";

  .disabled-btn {
    background-color: rgb(168, 168, 168)!important;
    color: rgb(125, 125, 125)!important;

  }
</style>

<template>
  <transition name="fade">
    <div class="modal-overlay fixed inset-0 w-full h-screen flex items-center justify-center bg-semi-75" style="background-color:rgba(0,0,0,0.6);z-index:101;" @click="$emit('close')">
      <div class="card-det modal-card  modal modal-wrapper w-full max-w-2xl bg-white shadow-lg rounded-lg addModal"  style="z-index:101;max-height: 600px; overflow-y: auto; position:relative">
        <div class="modal-container" @click.stop>
          <div class="modal">
            <!--<Loader v-if="GET_LOADER"/>-->
            <div class="modal-header block-title">
              <slot name="header">
                <div class="header-bit w-full flex">
                  <!-- placing the header we got from the computed property -->
                  <p class="text-left card-title font-bold uppercase my-auto flex-grow mb-0"> {{title}}</p>
                  <a href=""><svg xmlns="http://www.w3.org/2000/svg"  @click.prevent="$emit('close')" class=" ml-auto h-9 w-9 pt-3 pr-4 close-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg></a>
                </div>
              </slot>
            </div>

              <div class="modal-body">
                <!-- here starts dynamic content -->
                <slot name="content"></slot>
                <!-- here ends dynamic content -->
              </div>
              <div class="modal-footer text-justify">
                <slot name="footer">
                  <div class="action-buttons w-full flex container">
                    <div class="my-auto">
                      <a href="" class="modal-default-button cancel-button text-gray-600" @click.prevent="$emit('close')">
                        Cancel
                      </a>
                    </div>
                    <div v-if="showActionButton" class="actions ml-auto">
                      <div class="actions ml-auto">
                          <button
                              class="rounded capitalize px-4 py-2 text-white font-semibold text-[20px] bg-[#0EE2B1]"
                            type="submit"
                            @click.prevent="$emit('save')"
                          >
                              <span v-if="loading" class="spinner-border" role="status" aria-hidden="true"></span>
                              <span v-else>{{buttonText}}</span>
                          </button>
                      </div>
                      </div>
                  </div>
                </slot>
              </div>

          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
    import PrimaryButton from "./PrimaryButton.vue";

    export default {
        components: {PrimaryButton},
      props: {
        title: String,
        buttonText: String,
        show_action_button: {
          type: Boolean,
          default: true
        },
        disabled: {
          type: Boolean,
          default: false
        },
        loading: {
          type: Boolean,
          default: false
        },
        showActionButton: {
          type: Boolean,
          default: true
        }
      }
    }
</script>

<style scoped>

.action-button{
  width: fit-content !important;
}
</style>
