<template>
    <div>
        <form @submit.prevent="savePractitionerBio(GET_PRACTITIONER.id)">
            <div class="top-section flex">
                <h5 class="my-auto">Edit {{GET_PRACTITIONER.first_name}}'s biography</h5>
                <div class="ml-auto my-auto">
                  <button class="bg-[#0EE2B1] text-white rounded px-3 py-2 mr-5">Save</button>
                </div>
            </div>
            <div class="mt-5">
              <textarea placeholder="Write bio here..." v-model="bio"></textarea>
            </div>
        </form>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

    export default {
      name: "PractitionerBio",
      data()
      {
          return {
              bio: ''
          }
      },
      computed: {
        ...mapGetters('practitioner', ['GET_PRACTITIONER']),
      },
      methods: {
        ...mapActions('commonFunctions', ['redirectAction']),
        savePractitionerBio(id)
        {
          axios.post('/dashboard/practitioners/'+id+'/update-bio', {bio: this.bio})
              .then((res) => {
                Swal.fire({
                  toast: true,
                  icon: 'success',
                  title: 'Bio Saved',
                  animation: true,
                  position: 'top-right',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true
                }).then(() => {
                  this.redirectAction('Bio')
                });
              }).catch((e) => {
                Swal.fire({
                  toast: true,
                  icon: 'error',
                  title: 'Something went wrong. Contact support',
                  animation: true,
                  position: 'top-right',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true
                });
          })
        }
      },
      mounted()
      {
        this.bio = this.GET_PRACTITIONER.bio
      }
    }
</script>

<style scoped lang="scss">

</style>
