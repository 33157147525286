const EditReviewModalForm =
    {
        "title": "Import an existing review",
        "description": "You can import existing reviews from other platforms: i.e. Google, Trustpilot. You need to prove the link so that we can check that this review exists. Once you submit this review, we will check the true source this review and, once approved, it will be shown on your profile.",
        "loadFunction": "",
        "saveAddFunction": "addReview",
        "saveEditFunction": "",
        "buttonCreateText": "Import Review",
        "buttonUpdateText": "",
        "components":
        {
            "author" :{
                "title": "Author",
                "required" :true,
                "inputType" :"text",
                "inputName" :"author",
                "description" :"These will be shown  as the author of the review. If you display the full name of the author, please make sure you have the consent to do so. ",
                "placeholder" :" i.e. J. Smith",
                "content" :{}
            },
            "reviewType" : {
                "required" : true,
                "inputType" : "select",
                "inputName" : "review_type",
                "description" : "Review type",
                "help" : "",
                "content" : {
                    "online_review": "Other platform review",
                    "medimob_review": "Medimob review",
                },
            },
            "url_of_origin" : {
                "title": "Review Link",
                "required" : true,
                "inputType" : "url",
                "inputName" : "url_of_origin",
                "description" : "Please copy and paste the link of the review you are importing. This will be manually verified.",
                "placeholder" : "https://www.example.com.....",
                "content" : {},

            },
            "rating" :{
                "title": "Rating",
                "required" :true,
                "inputType" :"text",
                "inputName" :"rating",
                "description" :"Please indicate how much this service was rated",
                "help" :"",
                "content": {},
            },
            "service_received" :{
                "title": "Service Received",
                "required" :true,
                "inputType" :"text",
                "inputName" :"service_received",
                "description" :"Please indicate the service this review is about. If the service is not listed, please add it in    the services section (the service word should be a link href that goes to the services page).",
                "max" :1500,
                "help" :"Please provide the service received",
                "content" :{},
            },
            "reviewText" :{
                "title": "Review Text",
                "required" :true,
                "inputType" :"textarea",
                "inputName" :"review_text",
                "description" :"Please copy and paste the text of the review (or part of it).",
                "max" :1500,
                "help" :" Please write here the review (max 1500 characters)",
                "content" :{},
            },
            "anonymous" : {
                "required" : false,
                "inputType" : "checkbox",
                "inputName" : "anonymous",
                "description" : "Would you like this to be anonymous ?",
                "help" : "",
                "content" : {},

            }
        }
    }


export default EditReviewModalForm;
