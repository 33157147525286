<template>
    <div class="">
        <div class="inner-section-wrapper bg-white rounded p-4">
            <CreateUpdateTable 
            :title="practitioner.first_name+`'s Publications`"
            :refID="practitioner.ref"
            :buttonText="'Add Publications'"
            :data="practitioner.publications"
            :ifempty="practitioner.first_name+' does not have any publications yet. Please add some.'"
            :theads="['Publication Name']"
            @triggerNewEntry="addPublicationModal($event)"
            @editEntry="editPublicationModal($event)"
            >
            <!-- Table body populated dynamically -->
            <div class="table-body" slot="table-body">
                <tr class="bg-white transition duration-300 ease-in-out hover:bg-gray-700 flex pl-4 py-3" v-if="!practitioner.publications.length">{{ practitioner.first_name+' does not have any publications yet. Please add some.' }}</tr>
                <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-700 w-full flex" v-else v-for="publication in practitioner.publications" :key="publication.id">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex-grow"><a target="blank" :href="publication.publication_url">{{ publication.publication_name }}</a></td>
                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap flex">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" 
                        class="w-6 h-6 ml-auto dropdown-toggle" 
                        @click.prevent="toggleDropdown = true"
                        type="button" 
                        id="dropDownActionButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        >
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                        </svg>
                        <edit-delete-dropdown-options 
                        id="dropdown" 
                        aria-labelledby="dropDownActionButton"
                        @editAction="editPublicationModal(publication)"
                        @deleteAction="triggerDelete(publication.id)"
                        ></edit-delete-dropdown-options>

                    </td>
                </tr>
            </div>
        </CreateUpdateTable>
        </div>

        <CreateUpdateModal 
            v-if="GET_PUBLICATION_MODAL_STATUS" 
            :title="modalType+' publication'"
            :data="targetPublication"
            :buttonText="modalButtonTitle"
            @save="triggerSave($event)"
            @close="wipeAndToggle()"
        >
                <div class="" slot="content">
                    <CreateUpdatePractitionerPublication @eventListener="updateModalData($event)" :target="targetPublication"></CreateUpdatePractitionerPublication>
                </div>
        </CreateUpdateModal>
        
    </div>
</template>
<script>
import CreateUpdateTable from '../../CreateUpdateTable.vue';
import CreateUpdateModal from '../../CreateUpdateModal.vue';
import CreateUpdatePractitionerPublication from './CreateUpdatePractitionerPublication.vue';
import EditDeleteDropdownOptions from '../../EditDeleteDropdownOptions.vue';
import { mapActions, mapGetters} from 'vuex';

export default {
    props: ['practitioner'],
    components: {
        CreateUpdateTable,
        CreateUpdateModal,
        CreateUpdatePractitionerPublication,
        EditDeleteDropdownOptions 
    },
    data() {
        return {
            modalButtonTitle: '',
            editPublication: false,
            modalType: '',
            modalData: {},
            targetPublication: {},
            
        }
    },
    computed:
    {
        ...mapGetters('practitioner', ['GET_PRACTITIONER', 'GET_PUBLICATION_MODAL_STATUS']),
    },
    methods: {
        ...mapActions('practitioner', ['savePublication', 'deletePublication', 'togglePublicationModal']),
        
        // opens modal to add publication
        addPublicationModal()
        {
            this.togglePublicationModal()
            this.updateCreatePublication = true;
            this.modalType="add";
            this.modalButtonTitle="Create"
        },

        // opens modal to edit publication
        editPublicationModal(target)
        {
            this.togglePublicationModal()
            this.updateCreatePublication = true;
            this.modalType="edit";
            this.targetPublication = target;
            this.modalData.id = target.id;
            this.modalData.ref = target.ref;
            this.modalData.name = target.publication_name;
            this.modalData.url = target.publication_url;
            this.modalButtonTitle = "Update"
        },
        updateModalData(data)
        {
            this.modalData = data;
        },
        triggerSave()
        {
            let data = {
                type: this.modalType, 
                data: this.modalData,
                practitioner: this.GET_PRACTITIONER.id
            }
            this.savePublication(data);
        },
        triggerDelete(id)
        {
            this.deletePublication(id);
        },

        // Wipes out forms when closing modal
        wipeAndToggle()
        {
            this.targetPublication = null
            this.togglePublicationModal()
        }

    },
}
</script>