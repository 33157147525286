<template>
    <div class="container give-review-card">
        <div class="form-section-info">
            <h3 class="form-section-info-title font-semibold pb-3">{{ review_workflow.title}}</h3>
            <span class="text-gray-400">{{review_workflow.description}} {{review_data.clinicName}} </span>
        </div>

        <form method="POST"  @submit.prevent="submitForm(review_workflow.saveFunction)" class="modal-form">
        <div  class="form-section my-4 flex px-4">
            <div class="form-section-info w-1/3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ review_workflow.components['email']['title']}}
                        <span v-if="review_workflow.components['email']['required']" class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">{{review_workflow.components['email']['description'] }}</p>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <input 
                    :type="review_workflow.components['email']['inputType']" 
                    class="w-full"
                    :required="review_workflow.components['email']['required']"
                    :placeholder="review_workflow.components['email']['placeholder']"
                    :name="review_workflow.components['email']['inputName']"
                    v-model="form[review_workflow.components['email'].inputName]"
                    />
                    <InputErrorMessage v-if="ShowErrors && (!form[review_workflow.components['email'].inputName] || form[review_workflow.components['email'].inputName] === undefined)"/>
                </div>
            </div>
        </div>
        
            <div class="text-center">
                <button type="submit" class="px-4 action-button ml-auto border cursor-pointer rounded-md submit-button ">
                    {{ review_workflow.saveButton }}
                </button>
            </div>
        </form> 
    </div>
    </template>
      
    <script>
    import InputErrorMessage from '../../Utilities/InputErrorMessage.vue'
    import { mapGetters, mapActions } from 'vuex';
        export default {
            props: ['review_workflow', 'review_data', 'services'],
            components: {InputErrorMessage},
            data() {
                return {
                  form: {},
                  ShowErrors: false
                }
            },
            methods: {
                ...mapActions('reviews', ['resendReviewRequest']),
                isValidEmail(email) {
                    const emailRegex = /^[^\s@]+@[^\s@]+.[^\s@]+$/
                    return emailRegex.test(email)
                },
                submitForm(saveFnc)
                {
                    this.ShowErrors = true
                    if(this.form[this.review_workflow.components['email'].inputName] === undefined
                    || !this.isValidEmail(this.form[this.review_workflow.components['email'].inputName])){
                        Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: 'Please fill the form correctly',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true
                        });
                    }else {
                        
                        this.form.reviewId = this.review_data.id
                        eval('this.'+saveFnc+'(this.form)')
                                .then(res => {
                                    Swal.fire({
                                    toast: true,
                                    icon: 'success',
                                    title: "Your request has been sent",
                                    animation: true,
                                    position: 'top-right',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true
                                });
    
                                window.location.href = '/home';
                            }).catch(() => {
                                Swal.fire({
                                    toast: true,
                                    icon: 'error',
                                    title: 'Something went wrong. Contact support',
                                    animation: true,
                                    position: 'top-right',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true
                                });   
                            })
                    }
                }
            }
        }
    </script>
    
    <style lang="scss" scoped>
    .give-review-card{
        margin-top: 70px;
    }
    input, textarea , svg, select{
    cursor: pointer;
    }
    .action-button {
        width: fit-content;
    }
    </style>
          