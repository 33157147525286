<template>
    <div class="modal-body">
        <slot v-if="loadingPaymentMethods">

            <div class="">
                <div class="row">
                    <div class="col">
                        <Skeleton :count="3"  />
                    </div>
                    <div class="col">
                        <Skeleton :count="3" />
                    </div>

                    <!-- <label v-for="n in 6" :key="n" class="inline-flex relative items-center cursor-pointer mr-4 my-3 w-1/3"> -->
                    <!-- </label> -->
                </div>
            </div>
        </slot>
        <slot v-else-if="!loadworkflow" name="body">
        <div class="" v-if="current_step===0">
            <div class="flex flex-wrap justify-center">
                <label class="inline-flex relative items-center cursor-pointer mr-4 my-3 w-1/3">
                    <input :type="GET_WORKFLOW.steps[1].components['payment_modality']['inputType']" :value="formData.online_payment" class="sr-only peer" v-model="formData.online_payment">
                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span class="ml-3 text-sm font-medium ">{{GET_WORKFLOW.steps[1].components['payment_modality']['content']['online_payments']}}</span>
                </label>
                <label class="inline-flex relative items-center cursor-pointer mr-4 my-3 w-1/3">
                    <input :type="GET_WORKFLOW.steps[1].components['payment_modality']['inputType']" value="" class="sr-only peer" v-model="formData.card_payment">
                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span class="ml-3 text-sm font-medium ">{{GET_WORKFLOW.steps[1].components['payment_modality']['content']['card_payments']}}</span>
                </label>
                <label class="inline-flex relative items-center cursor-pointer mr-4 my-3 w-1/3">
                    <input :type="GET_WORKFLOW.steps[1].components['payment_modality']['inputType']" value="" class="sr-only peer" v-model="formData.cash">
                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span class="ml-3 text-sm font-medium ">{{GET_WORKFLOW.steps[1].components['payment_modality']['content']['cash']}}</span>
                </label>
                <label class="inline-flex relative items-center cursor-pointer mr-4 my-3 w-1/3">
                    <input :type="GET_WORKFLOW.steps[1].components['payment_modality']['inputType']" value="" class="sr-only peer" v-model="formData.cheques">
                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span class="ml-3 text-sm font-medium ">{{GET_WORKFLOW.steps[1].components['payment_modality']['content']['cheques']}}</span>
                </label>
                <label class="inline-flex relative items-center cursor-pointer mr-4 my-3 w-1/3">
                    <input :type="GET_WORKFLOW.steps[1].components['payment_modality']['inputType']" value="" class="sr-only peer" v-model="formData.bank_transfers">
                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span class="ml-3 text-sm font-medium ">{{GET_WORKFLOW.steps[1].components['payment_modality']['content']['bank_transfers']}}</span>
                </label>
                <label class="inline-flex relative items-center cursor-pointer mr-4 my-3 w-1/3">
                    <input :type="GET_WORKFLOW.steps[1].components['payment_modality']['inputType']" value="" class="sr-only peer" v-model="formData.cryptocurrency">
                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span class="ml-3 text-sm font-medium ">{{GET_WORKFLOW.steps[1].components['payment_modality']['content']['cryptocurrency']}}</span>
                </label>
            </div>
        </div>
        <div class="" v-if="current_step === 1">
            <div class="flex flex-wrap justify-center">
                <label class="inline-flex relative items-center cursor-pointer mr-4 my-3">
                    <input :type="GET_WORKFLOW.steps[2].components['payment_modality']['inputType']" value="" class="sr-only peer" v-model="formData.pay_pal">
                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span class="ml-3 text-sm font-medium ">{{GET_WORKFLOW.steps[2].components['payment_modality']['content']['paypal_buy_now_pay_later']}}</span>
                </label>
                <label class="inline-flex relative items-center cursor-pointer mr-4 my-3">
                    <input :type="GET_WORKFLOW.steps[2].components['payment_modality']['inputType']" value="" class="sr-only peer" v-model="formData.klarna">
                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span class="ml-3 text-sm font-medium ">{{GET_WORKFLOW.steps[2].components['payment_modality']['content']['klarna']}}</span>
                </label>
                <label class="inline-flex relative items-center cursor-pointer mr-4 my-3">
                    <input :type="GET_WORKFLOW.steps[2].components['payment_modality']['inputType']" value="" class="sr-only peer" v-model="formData.clearpay">
                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span class="ml-3 text-sm font-medium ">{{GET_WORKFLOW.steps[2].components['payment_modality']['content']['clearpay']}}</span>
                </label>
            </div>
        </div>
        <div class="" v-if="current_step === 2">
            <div class="flex flex-wrap justify-center">
                <label class="inline-flex relative items-center cursor-pointer mr-4 my-3">
                    <input :type="GET_WORKFLOW.steps[3].components['payment_modality']['inputType']" value="" class="sr-only peer" v-model="formData.pay_in_full_before">
                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span class="ml-3 text-sm font-medium ">{{GET_WORKFLOW.steps[3].components['payment_modality']['content']['pay_in_full_when_booking']}}</span>
                </label>
                <label class="inline-flex relative items-center cursor-pointer mr-4 my-3">
                    <input :type="GET_WORKFLOW.steps[3].components['payment_modality']['inputType']" value="" class="sr-only peer" v-model="formData.pay_deposit">
                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span class="ml-3 text-sm font-medium ">{{GET_WORKFLOW.steps[3].components['payment_modality']['content']['pay_deposit_settle_later']}}</span>
                </label>
                <label class="inline-flex relative items-center cursor-pointer mr-4 my-3">
                    <input :type="GET_WORKFLOW.steps[3].components['payment_modality']['inputType']" value="" class="sr-only peer" v-model="formData.pay_on_completion">
                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                    <span class="ml-3 text-sm font-medium ">{{GET_WORKFLOW.steps[3].components['payment_modality']['content']['pay_on_appointment_completion']}}</span>
                </label>
            </div>
        </div>
        </slot>
    </div>
</template>

<script>
// importing the helpers for calling actions and getters from vuex
import { Skeleton } from 'vue-loading-skeleton';
import { mapGetters, mapActions } from 'vuex'
export default {
    components:{Skeleton},
    props: ['formData','payment_options', 'mainClinicInfo', 'current_step'],

    data()
    {
        return {
            loadingPaymentMethods: true,
            updatePaymentMethodModal: false,
            payment: {
                methods: {
                    online_payment: this.payment_options.online_payment,
                    card_payment: this.payment_options.card_payment,
                    bank_transfers: this.payment_options.bank_transfers,
                    cash: this.payment_options.cash,
                    cheques: this.payment_options.cheques,
                    cryptocurrency: this.payment_options.cryptocurrency
                },
                support: {
                    pay_pal: this.payment_options.pay_pal,
                    klarna: this.payment_options.klarna,
                    clearpay: this.payment_options.clearpay
                },
                terms: {
                    pay_in_full_before: this.payment_options.pay_in_full_before,
                    pay_deposit: this.payment_options.pay_deposit,
                    pay_on_completion : this.payment_options.pay_on_completion
                }
            },
            loadworkflow: true
        }
    },
    methods: {
        // importing actions needed from vuex
        ...mapActions('mainClinic', ['savePaymentMethodsAction']),
        savePaymentMethods(){
            // calling the action saveLegalEntityAction of mainClinic
            this.savePaymentMethodsAction(this.payment)
            .then(() => {
                  this.$emit('close')
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Payment Methods Successfully Updated!',
                    showConfirmButton: false,
                    timer: 1500
                  }).then(() => {
                    window.location.reload();
                  })
                })
        },
        nextStep()
        {
            this.current_step++
        }
    },
    computed: {
        ...mapGetters('mainClinic', ['MAIN_CLINIC', 'GET_WORKFLOW'])
    },
        watch:{
            payment_options: {
                handler(newValue) {
                this.formData.online_payment = this.payment_options.online_payment,
                this.formData.card_payment = this.payment_options.card_payment,
                this.formData.bank_transfers = this.payment_options.bank_transfers,
                this.formData.cash = this.payment_options.cash,
                this.formData.cheques = this.payment_options.cheques,
                this.formData.cryptocurrency = this.payment_options.cryptocurrency
                this.formData.pay_pal = this.payment_options.pay_pal,
                this.formData.klarna = this.payment_options.klarna,
                this.formData.clearpay = this.payment_options.clearpay
                this.formData.pay_in_full_before = this.payment_options.pay_in_full_before,
                this.formData.pay_deposit = this.payment_options.pay_deposit,
                this.formData.pay_on_completion  = this.payment_options.pay_on_completion
                this.loadingPaymentMethods = false
                }
            },
            GET_WORKFLOW: {
                handler(newValue) {
                this.loadworkflow = false
                }
            }
        }
}

</script>


<style scoped>

</style>