var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-section container-fluid"},[_c('div',{staticClass:"overview-cards"},[_c('div',{staticClass:"row",staticStyle:{"margin-top":"-40px"}},[_c('div',{staticClass:"col-xl-3 col-sm-6 mb-xl-0 mb-4"},[_c('mini-statistics-card',{attrs:{"title":'Total users',"value":_vm.data.totalClinics,"icon":{
                        component: 'users',
                        background: 'bg-gradient-green',
                      },"direction-reverse":""}})],1),_vm._v(" "),_c('div',{staticClass:"col-xl-3 col-sm-6 mb-xl-0 mb-4"},[_c('mini-statistics-card',{attrs:{"title":"Total satellites","value":_vm.data.totalSatellites,"icon":{
                        component: 'hospital',
                        background: 'bg-gradient-red',
                      },"direction-reverse":""}})],1),_vm._v(" "),_c('div',{staticClass:"col-xl-3 col-sm-6 mb-xl-0 mb-4"},[_c('mini-statistics-card',{attrs:{"title":"Last Registered","value":_vm.data.lastRegistered,"icon":{
                        component: 'info',
                        background: 'bg-gradient-dark',
                      },"direction-reverse":""}})],1),_vm._v(" "),_c('div',{staticClass:"col-xl-3 col-sm-6 mb-xl-0 mb-4"},[_c('mini-statistics-card',{attrs:{"title":"Monthly Revenues","value":'£ ' + _vm.data.monthlyRevenues,"icon":{
                        component: 'wallet',
                        background: 'bg-gradient-orange',
                      },"direction-reverse":""}})],1)])]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"wrapper"},[_c('SearchTable')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"text-separation flex mr-5"},[_c('h5',{staticClass:"my-auto font-bold text-slate-500"},[_vm._v("Quick Actions")])]),_vm._v(" "),_c('hr',{staticClass:"flex-grow my-auto opacity-50",staticStyle:{"background-color":"rgba(0,0,0,0.2)!important"}})])])
}]

export { render, staticRenderFns }