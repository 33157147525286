<script>

export default {
  name: "ProfileCompliancePage",
}
</script>

<template>
  <div class="container">

    <div class="main-page my-10">
      <div class="table-wrapper p-4 bg-white shadow-lg rounded">
        <table class="w-full table-auto">
          <thead class="border-bottom">
            <th class="p-2 font-bold">Action</th>
            <th class="p-2 font-bold">Severity</th>
            <th class="p-2 font-bold">Details</th>
          </thead>
          <tbody>
            <tr>
              <td class="p-2">Acdd</td>
              <td class="p-2">High</td>
              <td class="p-2">i</td>
            </tr>

          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
