<template>
    <div>
      <p class="mx-2">Peer endorsements refer to recommendations or affirmations provided by other healthcare professionals regarding a practitioner's skills, expertise, or qualifications. These endorsements serve as endorsements or validations of a practitioner's abilities, helping patients make informed decisions when selecting healthcare providers.</p>
        <table-framework
                :theads="theads"
                :tableTitle="'Your Peer Endorsements'"
                :showTableActionButton="true"
                :tableButtonText="'Request Peer Endorsement'"
                :orderAscOrDesc="orderAscOrDescData"
                @whichHead="sortHead"
                @tableAction="openAddReviewModal()"
            >
                <tbody slot="table-body">
                    <tr class="" v-if="actualReviews.length == 0">
                        <p class="p-3 mx-2">No peer endorsements yet.</p>
                    </tr>
                    <tr v-else v-for="review in actualReviews">
                        <td class="truncate capitalize" style="max-width: 20px ;" v-if="review.peer_full_name">{{ review.peer_full_name}}</td>
                        <td class="truncate" style="max-width: 20px ;" v-else>TBC</td>
                        <td class="truncate" style="max-width: 180px ;" >{{ review.peer_email}}</td>
                        <td>
                            <span :style="{'background-color': review.statusColor}" class="px-2 py-1 rounded capitalize">{{ review.statusStr}}</span>
                        </td>
                        <td class="edit-and-bin items-center flex" style="padding: 12px 25px!important">
                            <div class="flex">
                                <button @click.prevent="viewReview(review)" type="button" class="border rounded text-[14px] mx-2 p-2" name="View" v-if="review.status !== 1 && review.status !== 5 && review.status !== 6">
                                    View
                                </button>
                                <button @click.prevent="updateStatus({'id': review.id, 'status': 5})" type="button" class="border rounded text-[16px] mx-2 px-3 py-2" name="Revoke" v-if="review.status == 1 || review.status == 6">
                                    Revoke
                                </button>
                              <button disabled type="button" class="border rounded text-[14px] mx-2 p-2" name="Revoke" v-if="review.status == 5">
                                You have revoked the link
                              </button>
                                <button @click.prevent="updateStatus({'id': review.id, 'status': 6})" type="button" class="border rounded text-[14px] mx-2 p-2" name="Revoke" v-if="review.status == 1">
                                    Re-send Invite
                                </button>
                                <button @click.prevent="updateStatus({'id': review.id, 'status': 3})" class="border rounded text-[14px] mx-2 p-2" name="Approve" v-if="review.status == 2">
                                    Approve
                                </button>
                                <button @click.prevent="updateStatus({'id': review.id, 'status': 4})" type="button" class="border rounded text-[14px] mx-2 p-2" name="Reject" v-if="review.status == 2">
                                    Reject
                                </button>
                                <button @click.prevent="updateStatus({'id': review.id, 'status': 2})" type="button" class="border rounded text-[14px] mx-2 p-2" name="Unapprove" v-if="review.status == 3">
                                    Unapprove
                                </button>
                            </div>

                             <!-- This sets a fixed width of 40 pixels for the last cell in the row. Adjust as needed. -->
                        </td>
                    </tr>
                <CreateUpdateModal
                    v-if="showTargetModal"
                    @close="showTargetModal = false"
                    :show-action-button="false"
                    :title="'View Peer Review'"
                >
                    <template #content>
                        <div class="p-4 container">
                            <table class="table-auto w-full border-none">
                                <tbody>
                                    <tr>
                                        <th class="font-sm text-blue-950 font-bold">Written By</th>
                                        <td class="capitalize">{{targetReview.peer_full_name}}</td>
                                        <th class="font-sm text-blue-950 font-bold">Email</th>
                                        <td>{{targetReview.peer_email}}</td>
                                    </tr>
                                    <tr>
                                        <th class="font-sm text-blue-950 font-bold py-4">Profession</th>
                                        <td  class="capitalize">{{targetReview.peer_profession}}</td>
                                        <th class="font-sm text-blue-950 font-bold">Relationship</th>
                                        <td class="capitalize">{{targetReview.relationshipStr}}</td>
                                    </tr>
                                <tr>
                                    <th class="font-sm text-blue-950 font-bold">Status</th>
                                    <td>
                                        <span :style="{'background-color': targetReview.statusColor}" class="px-2 py-1 rounded capitalize text-[13px]">{{ targetReview.statusStr}}</span>
                                    </td>
                                    <th class="font-sm text-blue-950 font-bold">Date</th>
                                    <td class="capitalize">{{targetReview.updated_at}}</td>
                                </tr>
                                <tr>
                                    <th class="font-sm text-blue-950 font-bold py-4">Recommendation</th>
                                </tr>
                                </tbody>
                            </table>
                            <p class="w-full">{{targetReview.endorsement_text}}</p>

                        </div>
                    </template>
                </CreateUpdateModal>
                </tbody>
             </table-framework>
             <create-update-modal
                v-if="toggleEndorsementModal"
                @close="toggleEndorsementModal = false;"
                :title="'Request Peer Endorsement'"
                :buttonText="'Send'"
                :disabled="getLoadingSubmitButton"
                :loading="getLoadingSubmitButton"
                @save="sendEndorsement(modalData)"
             >
                <div class="" slot="content">
                        <AddEndorsementModalContent @eventListener="updateModalData($event)" :target="reviews" :type="'add'"></AddEndorsementModalContent>
                </div>

             </create-update-modal>
    </div>
    </template>

    <script>
    import TableFramework from '../../Table.vue';
    import clinicReviewsTypes from '../../../constants/services/clinicReviewTypes';
    import CreateUpdateModal from '../../CreateUpdateModal.vue';
    import AddReviewModalContent from './AddReviewModalContent.vue';
    import AnonymousEditTile from './AnonymousEditTile';
    import clinicPeerEndorsementsTableHeads from "../../../constants/services/clinicPeerEndorsementsTableHeads";
    import AddEndorsementModalContent from "./AddEndorsementModalContent.vue";

    import { mapActions, mapGetters } from 'vuex';
    import axios from "axios";
    let orderAscOrDesc = {
        serviceAsc: 0,
        ratingAsc: 0,
        confirmedAsc: 0,
        sourceAsc: 0,
    }
        export default {
            props: ['reviews'],
            components: {
                TableFramework,
                CreateUpdateModal,
                AddReviewModalContent,
                AnonymousEditTile,
                AddEndorsementModalContent
            },
            data() {
                return {
                    theads: clinicPeerEndorsementsTableHeads,
                    toggleEndorsementModal: false,
                    toggleEditReviewModal: false,
                    ReviewsTypes: clinicReviewsTypes,
                    modalData: {},
                    selectedReview: null,
                    orderAscOrDescData : orderAscOrDesc,
                    targetReview: {},
                    showTargetModal: false
                }
            },
            methods: {
                ...mapActions('reviews', ['saveEditReviewRequest','saveReviewRequest', 'deleteReviewRequest', 'getEndorsements', 'sendEndorsement']),
                openAddReviewModal()
                {
                    this.toggleEndorsementModal = true;
                },
                openEditReviewModal(selectedReview)
                {
                    this.selectedReview = selectedReview
                    this.toggleEditReviewModal = true;
                },
                updateModalData(data)
                {
                    this.modalData = data;
                },
                sortHead(thead)
                {
                    this.actualReviews = this.reviews
                    switch(thead){
                        case 'Name':
                            if(orderAscOrDesc.peerNameAsc == 1){
                                this.reviews.sort((a,b) => a.service_received.localeCompare(b.service_received))
                                orderAscOrDesc.peerNameAsc = 2
                            }else {
                                this.reviews.sort((a,b) => b.service_received.localeCompare(a.service_received))
                                orderAscOrDesc.peerNameAsc = 1
                            }
                        ;break;
                        case 'Email':
                            if(orderAscOrDesc.emailAsc == 1){
                                this.reviews.sort((a,b) => a.rating - b.rating)
                                orderAscOrDesc.emailAsc = 2
                            }else {
                                this.reviews.sort((a,b) => b.rating - a.rating)
                                orderAscOrDesc.emailAsc = 1
                            }
                        ;break;
                        case 'Status':
                            if(orderAscOrDesc.statusAsc == 1){
                                this.reviews.sort((a,b) => a.confirmed - b.confirmed)
                                orderAscOrDesc.statusAsc = 2
                            }else {
                                this.reviews.sort((a,b) => b.confirmed - a.confirmed)
                                orderAscOrDesc.statusAsc = 1
                            }
                        ;break;
                    }
                    this.actualReviews = this.getEndorsements
                },
                viewReview(review)
                {
                    this.targetReview = review;
                    this.showTargetModal = true;
                },
                updateStatus(data)
                {
                  var message = 'Are you sure you want to update the status of this review?';
                  if(data.status == 2)
                  {
                    message = 'Are you sure you want to un-approve this review?'
                  } else if(data.status == 3)
                  {
                    message = 'Are you sure you want to approve this review?'
                  } else if(data.status == 4)
                  {
                    message = 'Are you sure you want to reject this review?'
                  } else if(data.status == 5)
                  {
                    message = 'Are you sure you want to revoke this review? The link provided in the email to write the endorsement will no longer be accessible.'
                  } else if(data.status == 6)
                  {
                    message = 'Are you sure you want to resend the invitation link?'
                  }
                    Swal.fire({
                        title: "",
                        text: message,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            axios.post('/peer-endorsement/update-status/'+data.id+'/'+data.status)
                                .then(() => {
                                    Swal.fire({
                                        toast: true,
                                        icon: 'success',
                                        title: 'Review Status Updated',
                                        animation: true,
                                        position: 'top-right',
                                        showConfirmButton: false,
                                        timer: 2000,
                                        timerProgressBar: true
                                    }).then(() => {
                                        window.location.reload()
                                    })
                                })
                        }
                    }).catch((e) => {
                        Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: e.response.status+' Error.',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true
                        });
                    });
                }
            },
            computed:{
                ...mapGetters('reviews', ['getLoadingSubmitButton', 'actualReviews'])
            },
            created()
            {
                this.getEndorsements();

            }
        }
    </script>
    <style lang="scss" scoped>

    @media (min-width: 992px){
        .edit-and-bin{

            display: flex;
        }
    }
    @media (max-width: 991px){
        .edit-and-bin{

            display: contents;
        }
    }
    </style>
