<template>
    <div class="container">
        <div class="form-section flex py-4 pr-4">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup1.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup1.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup1.inputTitle }}
                        <span v-if="formData.components.componentGroup1.required" class="text-red-400">*</span>
                    </label>
                    <input
                    :type="formData.components.componentGroup1.inputType"
                    class="w-full" :value="target.satelite.email"
                    :required="formData.components.componentGroup1.required"
                    :placeholder="formData.components.componentGroup1.placeholder"
                    :name="formData.components.componentGroup1.inputName"
                    disabled
                    />
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup2.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup2.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup2.inputTitle }}
                        <span v-if="formData.components.componentGroup2.required" class="text-red-400">*</span>
                    </label>
                    <input
                    :type="formData.components.componentGroup2.inputType"
                    class="w-full" v-model="modalData.patient_name"
                    :required="formData.components.componentGroup2.required"
                    :placeholder="formData.components.componentGroup2.placeholder"
                    :name="formData.components.componentGroup2.inputName"
                    @input="pushData"
                    />
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup3.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup3.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup3.inputTitle }}
                        <span v-if="formData.components.componentGroup3.required" class="text-red-400">*</span>
                    </label>
                    <input
                    :type="formData.components.componentGroup3.inputType"
                    class="w-full" v-model="modalData.patient_email"
                    :required="formData.components.componentGroup3.required"
                    :placeholder="formData.components.componentGroup3.placeholder"
                    :name="formData.components.componentGroup3.inputName"
                    @input="pushData"
                    />
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4" v-if="modalData.patient_name && modalData.patient_email">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup4.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup4.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup4.inputTitle }}
                        <span v-if="formData.components.componentGroup4.required" class="text-red-400">*</span>
                    </label>
                    <input
                    :type="formData.components.componentGroup4.inputType"
                    class="w-full" v-model="modalData.patient_phone"
                    :required="formData.components.componentGroup4.required"
                    :placeholder="formData.components.componentGroup4.placeholder"
                    :name="formData.components.componentGroup4.inputName"
                    @input="pushData"
                    />
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4" v-if="modalData.patient_name && modalData.patient_email">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup5.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup5.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup5.inputTitle }}
                        <span v-if="formData.components.componentGroup5.required" class="text-red-400">*</span>
                    </label>
                    <select
                    v-model="modalData.service"
                    @change.prevent="setService()"
                    >
                      <option v-for="option in clinicServices">{{option}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4" v-if="modalData.patient_name && modalData.patient_email">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup6.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup6.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup6.inputTitle }}
                        <span v-if="formData.components.componentGroup6.required" class="text-red-400">*</span>
                    </label>
                    <select
                    v-model="modalData.subject"
                    @change.prevent="setMessage(modalData.subject)"
                    >
                      <option disabled>Pick an option</option>
                      <option v-for="option in formData.components.componentGroup6.options">{{ option }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4" v-if="modalData.patient_name && modalData.patient_email">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup7.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup7.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup7.inputTitle }}
                        <span v-if="formData.components.componentGroup7.required" class="text-red-400">*</span>
                    </label>
                    <textarea cols="30" rows="10" v-model="modalData.message" v-html="modalData.message" @input="pushData"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    props: ['formData', 'target'],
  data() {
        return {
            modalData: {},
            clinicServices: []
        }
    },
    methods: {
        pushData(){
            this.$emit('eventListener', this.modalData);
            this.$forceUpdate();
        },
        setMessage(sbj)
        {
            var srv = this.modalData.service.toLowerCase().trim();

            if(sbj == 'Please select a subject')
            {
                this.modalData.message = '';
            } else if(sbj == 'Request an appointment')
            {
                let rawText = this.formData.components.componentGroup7.options[1];

                if(srv.startsWith('a') || srv.startsWith('e') || srv.startsWith('i') || srv.startsWith('o') || srv.startsWith('u'))
                {
                   var vowelClean =  rawText.replace('{startsWithVowel}', 'an')
                } else {
                    var vowelClean =  rawText.replace('{startsWithVowel}', 'a')

                }


            } else if(sbj == 'Enquiry about a service')
            {
                let rawText = this.formData.components.componentGroup7.options[2];

                if(srv.startsWith('a') || srv.startsWith('e') || srv.startsWith('i') || srv.startsWith('o') || srv.startsWith('u'))
                {
                   var vowelClean =  rawText.replace('{startsWithVowel}', 'an')
                } else {
                    var vowelClean =  rawText.replace('{startsWithVowel}', 'a')

                }
            } else if(sbj == 'Complain') {
                this.modalData.message = 'Please write your complaint here...';
                this.pushData();
            } else {
                this.modalData.message = 'Please write your message here...';
                this.pushData();
            }

            if(vowelClean)
            {
                let replService = vowelClean.replace('{enquiryService}', srv)
                var message = replService;

                this.polishEmail(message)
            }

        },
        setService()
        {
            if(this.modalData.service && this.modalData.subject)
            {

                let rawText = this.formData.components.componentGroup7.options[1];
                let srv = this.modalData.service.toLowerCase().trim();
                if(srv.startsWith('a') || srv.startsWith('e') || srv.startsWith('i') || srv.startsWith('o') || srv.startsWith('u'))
                {
                    var vowelClean =  rawText.replace('{startsWithVowel}', 'an')
                } else {
                    var vowelClean =  rawText.replace('{startsWithVowel}', 'a')
                }
                let message = vowelClean.replace('{enquiryService}', srv)
                this.polishEmail(message);
            }

        },
        polishEmail(message) {
            if(this.modalData.patient_phone)
            {
                var telClean =  message.replace('{telephoneOption}', ', or you can call me at '+this.modalData.patient_phone)
            } else {
                var telClean =  message.replace('{telephoneOption}', '');
            }

            var finalEmail =  telClean.replace('{patientName}', this.modalData.patient_name);

            if(this.modalData.service && this.modalData.subject)
            {
                this.modalData.message = finalEmail;

            }
            this.pushData();

        }
    },
    mounted()
    {
        this.modalData.email = this.target.satelite.email,
        this.modalData.sateliteId = this.target.satelite.id,
        this.modalData.sateliteRef = this.target.satelite.ref,
        this.modalData.clinicId = this.target.mainClinic.id,
        this.modalData.clinicRef = this.target.mainClinic.ref,
        this.modalData.toAddress = this.target.satelite.email
        this.target.services.forEach(element => {
            if(element.main.is_service_category === 1)
            {
                this.clinicServices.push(element.main.main_service_name)

            }
        });
    }

}
</script>
