<template>
    <div class="">
        <div class="w-full mb-2">
            <associate-practitioner-to-clinic :clinicRef="clinicRef"></associate-practitioner-to-clinic>
        </div>
    </div>

</template>

<script>
import AssociatePractitionerToClinic from './TabComponents/AssociatePractitionerToClinic.vue';
import { mapGetters } from 'vuex';

export default {
    props:['clinicRef'],
    components: {
        AssociatePractitionerToClinic
    },
    computed: {
        // ...mapGetters('practitioner', ['GET_PRACTITIONER']),
    }

}
</script>