<template>
    <div class="">
        <div class="w-full mb-2">
            <edit-qualifications-single-practitioner :practitioner="GET_PRACTITIONER"></edit-qualifications-single-practitioner>
        </div>
    </div>

</template>

<script>
import EditQualificationsSinglePractitioner from './EditQualificationsSinglePractitioner.vue';
import { mapGetters } from 'vuex';

export default {

    components: {
        EditQualificationsSinglePractitioner
    },
    computed: {
        ...mapGetters('practitioner', ['GET_PRACTITIONER']),
    }

}
</script>