<template>
    <transition
      enter-active-class="transition-opacity duration-300"
      leave-active-class="transition-opacity duration-300"
    >
      <div
        v-if="isOpen"
        class="fixed on-top-of-profile-page inset-0 flex items-center justify-center bg-black bg-opacity-50"
      >
        <div
          class="bg-white rounded p-4 w-3/4 max-w-xl shadow-lg"
          @click.stop
        >
          <p>{{ fullText }}</p>
          <div class="float-right">
            <button
                class="px-4 action-button ml-auto border cursor-pointer rounded-md submit-button " 
                @click="closeModal"
            >
                Close
            </button>
        </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    props: {
      fullText: String,
      isOpen: Boolean,
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
    },
  };
  </script>

<style lang="scss" scoped>
.on-top-of-profile-page{
    z-index: 99999 !important;
}

</style>
  