<template>
    <div  v-if="!loadFormdata" class="container">
        <div class="form-section flex py-4 pr-4">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup1.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup1.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup1.inputTitle }}
                        <span v-if="formData.components.componentGroup1.required" class="text-red-400">*</span>
                    </label>
                    <input 
                    :type="formData.components.componentGroup1.inputType" 
                    class="w-full" v-model="modalData.single_service" 
                    :required="formData.components.componentGroup1.required"
                    :placeholder="formData.components.componentGroup1.placeholder"
                    :name="formData.components.componentGroup1.inputName"
                    @input="pushData"
                    />
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup2.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup2.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup2.inputTitle }}
                        <span v-if="formData.components.componentGroup2.required" class="text-red-400">*</span>
                    </label>
                    <select
                    class="w-full" v-model="modalData.pricing_modality" 
                    :required="formData.components.componentGroup2.required"
                    :name="formData.components.componentGroup2.inputName"
                    @change="pushData"
                    >
                    <option  v-for="option, index in formData.components.componentGroup2.options" :value="index">{{ option }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4" v-if="modalData.pricing_modality == 'surcharge' ">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup3.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup3.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup3.inputTitle }}
                        <span v-if="formData.components.componentGroup3.required" class="text-red-400">*</span>
                    </label>
                    <input
                    :type="formData.components.componentGroup3.inputType" 
                    class="w-full" v-model="modalData.surcharge_service_price" 
                    :required="formData.components.componentGroup3.required"
                    :placeholder="formData.components.componentGroup3.placeholder"
                    :name="formData.components.componentGroup3.inputName"
                    @input="pushData"
                    />

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddChildServiceModalForm from '../../../constants/services/modalForms/addChildServiceModalForm'

export default {
    props: ['target'],
    data()
    {
        return {
            modalData: {},
            formData: {},
            loadFormdata: true
        }
    },
    computed: {
        ...mapGetters('practitioner', ['GET_ERRORS'])
    },
    methods: {
        pushData(){
            this.$emit('eventListener', this.modalData);
            this.$forceUpdate();
        }
    },
    mounted()
    {
        this.formData = AddChildServiceModalForm;
        // If editing, pass in target publication values
        if(this.target)
        {
            this.modalData.mainClinic = this.target.clinic_ref;
            this.modalData.parent_service_id = this.target.id;
            this.modalData.parent_service_ref = this.target.ref;
            this.modalData.type = "extra";
        }
        if(this.target.is_child_service == 1)
        {
            this.modalData.id = this.target.id;
            this.modalData.single_service = this.target.main_service_name;
            this.modalData.pricing_modality = JSON.parse(this.target.pricings).pricing_modality;
            this.modalData.surcharge_service_price = JSON.parse(this.target.pricings).surcharge_service_price;

        }
    },
    watch: {
        formData: {
            handler(newValue) {
                this.loadFormdata = false
            }
        }
    }
}
</script>


<style lang="scss" scoped>

@import "vue-select/dist/vue-select.css";

</style>