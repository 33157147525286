<template>
    <div>
        <input type="checkbox" :checked="selectedIds.includes(element)" v-model="togglee" @change="$emit('toggleSelected', element)" class="checkbox-toggle checkbox-lg"/>

    </div>
</template>
<script>
export default {
    props: ['toggle', 'element', 'selectedIds'],
    computed: {
        togglee: {
            get() {
                return this.selectedIds.includes(this.element)
            },
            set(value) {
                
            }
        }
    },
    watch: {
    }
}
</script>