const complianceUploadDropdownOptions = {
    'MAL_PRAC_INS' : {
        'id' : 'MAL_PRAC_INS',
        'name' : 'Malpractice insurance',
        'validity_date': 'yes',
        "mandatory": "yes"
    },
    'PUB_LIB_INS' : {
        'id' : 'PUB_LIB_INS',
        'name' : 'Public liability insurance',
        'validity_date': 'yes',
        "mandatory": "yes"
    },
    'CQC' : {
        'id' : 'CQC',
        'name' : 'CQC registration',
        'validity_date': 'yes',
        "mandatory": "yes"
    },
    'SKILL_TEST' : {
        'id' : 'SKILL_TEST',
        'name' : 'Skill test',
        'validity_date': 'no',
        "mandatory": "yes"
    },
    'REG_PROOF' : {
        'id' : 'REG_PROOF',
        'name' : 'Proof of registration',
        'validity_date': 'no',
        "mandatory": "yes"
    },
    'FIRST_AID_COURSE' : {
        'id' : 'FIRST_AID_COURSE',
        'name' : 'First Aid course',
        'validity_date': 'no',
        "mandatory": "yes"
    },
    'OTHER' : {
        'id' : 'OTHER',
        'name' : 'Other',
        'validity_date': 'no',
        "mandatory": "yes"
    }
}

export default complianceUploadDropdownOptions;
