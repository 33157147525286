<style scoped>
    .avatar-xl {
        width: 74px!important;
        height: 74px!important;
    }

    .avatar {
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        border-radius: 0.75rem;
        height: 48px;
        width: 48px;
        transition: all .2s ease-in-out;
    }

    .tb-clinic-name {
        color: #344767;
    }
    
</style>

<template>
    <div class="mx-4 overflow-hidden card card-body blurr shadow-blurr mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            {{ clinician }}
            <img
              :src="clinician.image_path"
              @click="openUpdateModal(clinician.image_path, clinician.id)"
              alt="profile_image"
              class="shadow-sm w-100 h-100 rounded-xl"
              style="object-fit: cover"
            />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <div class="clinic-name flex">
              <h5 class="mb-1 tb-clinic-name">{{ clinician.first_name }} {{ clinician.last_name }}</h5>
            </div>
            <p class="mb-0 text-sm font-weight-bold">{{ clinician.profession }}</p>
          </div>
        </div>
        <div
          class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 flex"
        >
          <div class="nav-wrapper position-relative end-0 ml-auto">
            <LastSavedBadge :lastUpdated="clinician.updated_at"></LastSavedBadge>
          </div>
        </div>
      </div>
      <UpdateProfileImagesModal @closeModal="closeModal" v-if="UpdateProfileImagesModal" :idConcerned="idConcerned" :pictureConcerned="PractitionerImagePath" :chosenModal="modalId"/>
    </div>
</template>

<script>
import LastSavedBadge from '../../Utilities/Badges/LastSavedBadge.vue';
import UpdateProfileImagesModal from '../Clinics/UpdateProfileImagesModal.vue'
import Icon from '../../Icon';


    export default {
    components: { LastSavedBadge, Icon, UpdateProfileImagesModal },
    props: ['clinician'],
    data()
    {
      return {
        PractitionerImagePath: '',
        modalId: '',
        idConcerned: '',
        UpdateProfileImagesModal: false

      }
    },
    methods: {
      openUpdateModal(PractitionerImagePath, idConcerned){
        this.UpdateProfileImagesModal = true
        this.PractitionerImagePath = PractitionerImagePath
        this.idConcerned = idConcerned
        this.modalId = 'updateSatelliteClinicLogo'
      },
      closeModal(){
          this.updateCreateClinicModal = false
      }
    }

}
</script>