<template>
    <div class="md:mx-5">
        <div class="my-auto w-full bg-white rounded !md:rounded-full p-2">
          <search-form :servicesession="servicesession" :last_query="last_query" :last_query_method="last_query_method"></search-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {

    props: ['last_query', 'servicesession', 'last_query_method'],
    data() {
        return {
            // attributes necessary for the city autocomplete
            showCityResults: false,
            autocompleteCityOptions: [],
            service: this.servicesession,
            search_type: this.last_query_method,
            location: this.last_query,
            practitioner: this.last_query,
            clinic: this.last_query,
            condition: this.last_query,
            location_group: true,
            practitioner_group: false,
            clinic_group: false,
            service_group: true,
            condition_group: false,
            // variable for storing all services
            allservices: [],
            ChooseService: false,
            // variable for storing all conditions
            allconditions: [],
            ChooseCondition: false,
            // storing user coordiantes
            userLat: null,
            userLng: null,
            loadingData: false,
            showConditions: false,
            showServices: false,
            serviceStr: ''
        }
    },
    methods: {
        ...mapActions('search', ['getServicesForSearch', 'getConditionsForSearch']),
        triggerConditionsDD()
        {
          this.showConditions = true;
          document.addEventListener('click', this.hideConditionDropdown);
        },
        selectCondition(condition)
        {
          this.condition = condition
        },
        hideConditionDropdown(event)
        {
          if (!event.target.closest('#conditionSelection')) {
            this.showConditions = false;
            // Remove the click event listener from the document
            document.removeEventListener('click', this.hideConditionDropdown);
          }
        },
        selectService(service)
        {
          this.service = service.ref
          this.serviceStr = service.main_service_name
        },
        triggerServicesDD()
        {
          this.showServices = true;
          document.addEventListener('click', this.hideServiceDropdown);
        },
        hideServiceDropdown(event)
        {
          if (!event.target.closest('#serviceSelection')) {
            this.showServices = false;
            // Remove the click event listener from the document
            document.removeEventListener('click', this.hideServiceDropdown);
          }
        },
        getSearchType() {
            if (this.search_type == 'service') {
                this.location_group = true;
                this.service_group = true,
                    this.practitioner_group = false;
                this.clinic_group = false;
                this.condition_group = false;
            } else if (this.search_type == 'clinic') {
                this.location_group = false;
                this.service_group = false,
                    this.practitioner_group = false;
                this.clinic_group = true;
                this.condition_group = false;
            } else if (this.search_type == 'practitioner') {
                this.location_group = false;
                this.service_group = false,
                    this.practitioner_group = true;
                this.clinic_group = false;
                this.condition_group = false;
            } else if (this.search_type == 'condition') {
                this.location_group = false;
                this.service_group = false,
                    this.practitioner_group = false;
                this.clinic_group = false;
                this.condition_group = true;
            }
        },
        toggleChooseService() {
            this.ChooseService = true
        },
        toggleChooseCondition() {
            this.ChooseCondition = true
        },
        closeChooseService() {
            this.ChooseService = false
        },
        closeChooseCondition() {
            this.ChooseCondition = false
        },
        setServiceChosen(value) {
            this.service = value.main_service_name
        },
        setConditionChosen(value) {
            this.condition = value.name
        },
        getCurrentPosition() {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    position => resolve(position),
                    error => reject(error)
                );
            })
        },
        initSearch() {
            if (this.location) {
                var location = this.location
            } else {
                var location = "&location=null"
            }
            if (this.service) {
                var service = this.service
            } else {
                var service = "&service=null"
            }
            if (this.practitioner) {
                var practitioner = this.practitioner
            } else {
                var practitioner = "&practitioner=null"
            }
            if (this.clinic) {
                var clinic = this.clinic
            } else {
                var clinic = "&clinic=null"
            }
            if (this.condition) {
                var condition = this.condition
            } else {
                var condition = "&condition=null"
            }
            // checking if it's s service and the form is invalid
            if (this.search_type === 'service' && this.service === '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please choose a service',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            } else if (this.search_type === 'service' && this.location === '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please enter a valid postcode or a city',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            } else if (this.search_type === 'clinic' && this.clinic === '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please enter a valid clinic name',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            } else if (this.search_type === 'practitioner' && this.practitioner === '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please enter a valid practitioner name',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            } else if (this.search_type === 'condition' && this.condition === '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please choose a valid condition name',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            }
            else {
                if (this.userLat !== null && this.userLng !== null) {
                    window.location.href = "/init-search/" + this.search_type + "/" + location + "/" + practitioner + "/" + clinic + "/" + condition + "/" + service + "/" + this.userLat + "/" + this.userLng;
                } else {
                    window.location.href = "/init-search/" + this.search_type + "/" + location + "/" + practitioner + "/" + clinic + "/" + condition + "/" + service;
                }
            }
        },

        // method to handle the city input
        async handleCityInput() {
            const city = this.location.trim();
            if (city === '' || city.includes('/')) {
                this.showCityResults = false;
            } else {
                if (this.location.length >= 2) {
                    try {
                        if (/\d/.test(this.location)) {
                            // Calling the postcode API for autocomplete postcodes
                            const responsePostcodes = await axios.get(`https://api.postcodes.io/postcodes/${encodeURIComponent(city)}/autocomplete`);
                            const result = responsePostcodes.data.result;
                            const autocompleteOptions = result && Array.isArray(result) ? result.slice(0, 5) : [];
                            this.autocompleteCityOptions = autocompleteOptions;
                        } else {
                            // Calling the API for getting the cities
                            const responseCities = await axios.get('/search/get-cities/' + city);
                            let displayNames = null;
                            // Assigning only the display_name column
                            if (Array.isArray(responseCities.data)) {
                                displayNames = responseCities.data.map(function (item) {
                                    return item.display_name;
                                });
                            }
                            // Limit to 5 options
                            this.autocompleteCityOptions = displayNames ? displayNames.slice(0, 5) : [];
                        }
                        this.showCityResults = true;
                    } catch (error) {
                        console.error(error);
                    }

                }
            }

        },
        selectCityResult(result) {
            this.location = result;
            this.showCityResults = false;
        },
    },
    watch: {
        search_type: {
            handler(newValue) {
                if (newValue === 'practitioner' || newValue === 'clinic' || newValue === 'condition') {
                    this.loadingData = true
                    this.getCurrentPosition()
                        .then(position => {
                            this.userLat = position.coords.latitude;
                            this.userLng = position.coords.longitude;
                            this.loadingData = false
                        })
                        .catch(error => {
                            console.error(error);
                            this.loadingData = false
                        });
                }
            },
        },
    },
    computed: {
      filteredServices() {
        // Filter services based on input text
        return this.allservices.filter(service => service.main_service_name.toLowerCase().includes(this.serviceStr.toLowerCase())).slice(0, 10);
      },
      filteredConditions() {
        // Filter conditions based on input text
        return this.allconditions.filter(condition => condition.name.toLowerCase().includes(this.condition.toLowerCase())).slice(0, 10);
      }
    },
    created() {
        this.getSearchType()
        this.getServicesForSearch()
            .then(res => {
                this.allservices = res.data
                const updatedResponse = this.allservices.map(item => {
                    const capitalizedServiceName = item.main_service_name.charAt(0).toUpperCase() + item.main_service_name.slice(1);
                    return {
                        ...item,
                        main_service_name: capitalizedServiceName
                    };
                });
                this.allservices = updatedResponse
            })
        this.getConditionsForSearch()
            .then(res => {
                this.allconditions = res.data
                const updatedResponse = this.allconditions.map(item => {
                    const capitalizedServiceName = item.name.charAt(0).toUpperCase() + item.name.slice(1);
                    return {
                        ...item,
                        name: capitalizedServiceName
                    };
                });
                this.allconditions = updatedResponse
            })
        if (this.search_type === 'practitioner' || this.search_type === 'clinic' || this.search_type === 'condition') {
            this.loadingData = true
            this.getCurrentPosition()
                .then(position => {
                    this.userLat = position.coords.latitude;
                    this.userLng = position.coords.longitude;
                    this.loadingData = false
                })
                .catch(error => {
                    console.error(error);
                    this.loadingData = false
                });
        }
    },
    mounted() {
        document.addEventListener('click', (event) => {
            if (!this.$el.contains(event.target)) {
                this.showCityResults = false;
            }
        });
    },
}
</script>

<style lang="scss" scoped>.cityresult {
    position: relative;
    z-index: 99999 !important;
}</style>
