<template>
    <div class="flex flex-col items-center justify-center">
      <span class="text-center text-xl font-bold orText">OR</span>
      <div class="grid grid-cols-1 md:grid-cols-2 divide-x">
        <import-existing-review :target="target" :type="type"></import-existing-review>
        <span class="text-center text-xl font-bold secondOrText">OR</span>
        <review-request :target="target" :type="type"></review-request>
      </div>
    </div>
  </template>
<script>
import ImportExistingReview from './NewReviewsFolder/ImportExistingReview.vue'
import ReviewRequest from './NewReviewsFolder/ReviewRequest.vue'
export default {
    props: ['target', 'type'],
    components:{ImportExistingReview, ReviewRequest},
    data()
    {
        return {
        }
    },
}
</script>


<style lang="scss" scoped>

@import "vue-select/dist/vue-select.css";


@media only screen and (max-width: 767px) {
  .orText{
      display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .secondOrText{
      display: none !important;
  }
  .orText{
    margin: -28px 0px;
    background: aliceblue;
    z-index: 2;

  }
}
</style>