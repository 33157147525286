<style scoped>
    .avatar-xl {
        width: 74px!important;
        height: 74px!important;
    }

    .avatar {
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        border-radius: 0.75rem;
        height: 48px;
        width: 48px;
        transition: all .2s ease-in-out;
    }

    .tb-clinic-name {
        color: #344767;
    }

</style>

<template>
    <div class="mx-4 overflow-hidden card card-body blurr shadow-blurr mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img
              :src="mainClinic.image_path"
              alt="profile_image"
              class="shadow-sm w-100 h-100 rounded-xl"
              style="object-fit: cover"
            />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <div class="clinic-name flex">
              <h5 class="mb-1 tb-clinic-name" v-if="!editMode">{{ clinicName }}</h5>
              <input
                type="text"
                class="mb-1 tb-clinic-name appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                v-if="editMode"
                v-model="clinicName"
                @change="saveClinicName(clinicName); editMode = false; editIcon = 'edit-square'"
                >
              <div class="edit-icon ml-2" @click="toggleEdit()" style="width: 17px;">
                <Icon class="cursor-pointer" :icon="editIcon"></Icon>
              </div>
            </div>
            <p class="mb-0 text-sm font-weight-bold">Main Clinic ({{ mainClinic.postcode }})</p>
          </div>
        </div>
        <div
          class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 flex"
        >
          <div class="nav-wrapper position-relative end-0 ml-auto">
            <LastSavedBadge :lastUpdated="mainClinic.updated_at"></LastSavedBadge>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import LastSavedBadge from '../../Utilities/Badges/LastSavedBadge.vue';
import Icon from '../../Icon';
import { mapActions } from 'vuex';

    export default {
    components: { LastSavedBadge, Icon },
    props: ['mainClinic'],
    data()
    {
      return {
        clinicName: this.mainClinic.name,
        editMode: false,
        editIcon: 'edit-square'

      }
    },
    methods:
    {
      ...mapActions('mainClinic', ['saveClinicName']),
      toggleEdit()
      {
        if(!this.editMode)
        {
          this.editMode = true;
          this.editIcon = 'check_circle';

        } else {
          this.editMode = false;
          this.editIcon = 'edit-square';
        }
      }
    }
}
</script>
