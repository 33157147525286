<script>
  import {mapActions} from "vuex";

  export default {
    methods: {
      ...mapActions('commonFunctions', ['loadBreadcrumbs']),
      triggerShowHelp(help)
      {
        // Close all other help sections
        Object.keys(this.$data).forEach(key => {
          if (key.startsWith('showHelp') && key !== `showHelp${help}`) {
            this[key] = false;
          }
        });

        const dynamicParam = `showHelp${help}`;
        this[dynamicParam] = !this[dynamicParam];
      }
    },
    data()
    {
      return {
        crumbs: [{name: 'Help Centre', url: '/dashboard/help-centre'}],
        showHelpProfileNotShowing: false,
        showHelpVerifiedBadge: false,
        showHelpBeingFound: false,
      }
    },
    mounted()
    {
      this.loadBreadcrumbs(this.crumbs);
    }
  }
</script>

<template>
  <div class="mt-5">
    <div class="hc-row border">
      <div class="hc-title cursor-pointer p-3" @click.prevent="triggerShowHelp('ProfileNotShowing')">
        <h6 class="underline">Why is my profile not showing in the search result page?</h6>
      </div>
      <div class="p-4" v-if="showHelpProfileNotShowing">
        <p>In order for your profile to be displayed on the search result page in needs to have the following:</p>
        <ul class="pl-4 list-disc">
          <li>
            <p>At least one service associated to it:</p>
            <p>Clinics must have at least one service associated to it. To associate a service to a clinic, follow the steps below:</p>
            <ul class="pl-4 list-decimal">
              <li>Head over to the <a href="/dashboard/clinic" target="_blank">clinic section</a> by clicking the clinic icon from the sidebar.</li>
              <li>Locate the clinic that is not displaying in the search result from the list in the table below.</li>
              <li>Click on 'Edit'.</li>
              <li>Navigate to the 'Services' section using the tabs displayed on the page.</li>
              <li>Select the services you want to associate to the clinic.</li>
              <li>The selection saves automatically.</li>
            </ul>
          </li>
          <li class="mt-5">
            <p>At least one practitioner associated to it:</p>
            <p>Clinics must have at least one practitioner associated to it. To associate a practitioner to a clinic, follow the steps below:</p>
            <ul class="pl-4 list-decimal">
              <li>Head over to the <a href="/dashboard/clinic" target="_blank">clinic section</a> by clicking the clinic icon from the sidebar.</li>
              <li>Locate the clinic that is not displaying in the search result from the list in the table below.</li>
              <li>Click on 'Edit'.</li>
              <li>Navigate to the 'Practitioners' section using the tabs displayed on the page.</li>
              <li>Select the practitioners you want to associate to the clinic.</li>
              <li>The selection saves automatically.</li>
              <p class="mt-[30px]">Or</p>
              <li>Head over to the <a href="/dashboard/practitioners" target="_blank">practitioners section</a> by clicking the practitioner icon from the sidebar.</li>
              <li>Locate the practitioner you would like to associate to a clinic.</li>
              <li>Click on 'Edit'.</li>
              <li>Navigate to the 'Clinics' section using the tabs displayed on the page.</li>
              <li>Select the clinics you want to associate to the clinic.</li>
              <li>The selection saves automatically.</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <div class="hc-row border">
      <div class="hc-title cursor-pointer p-3" @click.prevent="triggerShowHelp('VerifiedBadge')">
        <h6 class="underline">How do I get the 'Verified Profile' badge?</h6>
      </div>
      <div class="p-4" v-if="showHelpVerifiedBadge">
        <p>To obtain a verified profile badge on Medimob, users must undergo an annual verification process. This involves providing proof of registration for medical indemnity insurance, proof of registration for business liability insurance, and proof of registration with the relevant regulatory body, if applicable. These checks ensure that the information we hold is accurate and up-to-date, enhancing trust and credibility within our community.</p>
        <p>The compliance at a clinic level is achieved ONLY when the documentation for each practitioner has been submitted and approved. For example, if you have 5 practitioners within your team, you must send the compliance document for all 5 practitioners in order to be accredited the badge.</p>
        <p>To submit the relevant compliance documentation:</p>
        <ul class="pl-4 list-decimal">
          <li>Head over to the <a href="/dashboard/compliance" target="_blank">Compliance section</a> by clicking the compliance icon from the sidebar.</li>
          <li>Click on the 'Practitioners' tab.</li>
          <li>Select the practitioner you want to send the compliance documentation for.</li>
          <li>Click on the link supplied in the modal.</li>
          <li>Upload the relevant documentation.</li>
        </ul>
        <p>Once you upload your documentation, it can take up to 5 business days for us to process the documentation.</p>
      </div>
    </div>
    <div class="hc-row border">
      <div class="hc-title cursor-pointer p-3" @click.prevent="triggerShowHelp('BeingFound')">
        <h6 class="underline">How can I boost my chances of being found?</h6>
      </div>
      <div class="p-4" v-if="showHelpBeingFound">
          <p></p>
        <ul class="pl-4 list-decimal">
          <li>Complete to great length and depth all the descriptive bits of your profile. This include, clinic biography, practitioner's biography, services, conditions treated etc.
            <br>
            The more complete your profile looks the more trustworthy it will be for both Google and your potential client.
          </li>
          <li>Give descriptive names to your services:<br>
            The name of your service will be one of the search criteria that potential clients can use to search for potential providers.<br>
            For example, if you are a thyroid specialist and one of your services is 'Initial Consultation', don't write 'Initial Consultation' as your service. Instead, try something like 'Thyroid Specialist Initial Consultation'.
          </li>
          <li>Fill in your contact details: add your email, telephone and social media.</li>
          <li>Upload a clinic logo and a professional picture for your practitioners.</li>
          <li>Add more clinics to cover more locations.</li>
          <li>Link your online booking system if you have one.</li>
          <li>Apply for a Verified Profile badge.</li>
          <li>Share your Medimob profile on social media.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
