<template>
    <div class="w-full bg-white">
      <div class="flex flex-col items-center pb-10">
        <img class="w-24 h-24 mb-3 rounded-full shadow-lg" :src="practitioner.image_path" alt="Bonnie image" />
        <h5 class="mb-1 text-xl font-medium dark:text-gray-900">{{ capitalizedTitle }} {{ practitioner.first_name }} {{ practitioner.last_name }}</h5>
        <span class="italic text-sm text-gray-500 dark:text-gray-400">{{ capitalizedProfession }}</span>

        <div class="mt-4" v-if="checkingCompliance === 'NON-COMPLIANT'">
          <span class="pb-2">Important compliance documentations are missing for this practitioner</span>
          <a class="block text-[#0EE2B1] underline font-semibold mt-2 mx-2" :href="'/dashboard/practitioners/get-practitioner/'+practitioner.ref+'?tab=Compliance'">Go To Compliance Section</a>
        </div>
      </div>
      <div>
        <table>
          <tbody>
            <complianceDetailsModalFiles v-for="onefile in practitioner.compliances" :key="onefile.id" :onefile="onefile" />
          </tbody>
        </table>
      </div>
    </div>
  </template>

  <script>
  import complianceDetailsModalFiles from './complianceDetailsModalFiles';

  export default {
    props: ['practitioner', 'checkingCompliance'],
    components: { complianceDetailsModalFiles },
    computed: {
      capitalizedTitle() {

        if (!this.practitioner.title) {
          return '';
        }
        const title = this.practitioner.title.charAt(0).toUpperCase() + this.practitioner.title.slice(1);
        return title;
      },
      capitalizedProfession() {
        if (!this.practitioner.profession) {
          return 'Not yet assigned';
        }
        const profession = this.practitioner.profession.charAt(0).toUpperCase() + this.practitioner.profession.slice(1);
        return profession;
      },
    },
  };
  </script>

  <style lang="scss" scoped>
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
  </style>
