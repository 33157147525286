<style lang="scss">
  @import "../../../../sass/components/modal";
</style>

<template>
  <transition name="fade">
    <div class="modal-overlay fixed inset-0 w-full h-screen flex items-center justify-center bg-semi-75" style="background-color:rgba(0,0,0,0.6);z-index:99;" @click="$emit('close')">
      <div class="card-det modal-card  modal modal-wrapper w-full max-w-2xl bg-white shadow-lg rounded-lg addModal"  style="z-index:99;max-height: 600px; overflow-y: auto; position:relative">
        <div class="modal-container" @click.stop>
          <div class="modal">
            <!--<Loader v-if="GET_LOADER"/>-->
            <div class="modal-header block-title">
              <slot name="header">
                  <p class="float-left card-title font-bold uppercase my-auto mb-0"> {{title}}</p>
                <div class="header-bit flex">
                  <!-- placing the header we got from the computed property -->
                  <p class="card-title font-bold uppercase my-auto flex-grow mb-0"> </p>
                  <a href=""><svg xmlns="http://www.w3.org/2000/svg"  @click.prevent="$emit('close')" class=" ml-auto h-9 w-9 pt-3 pr-4 close-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg></a>
                </div>
              </slot>
            </div>

              <div class="modal-body">
                <!-- here starts dynamic content -->
                <slot name="content">
                  <p class="float-left font-bold my-auto mb-0"> Your monthly price will be re-adjusted accordingly</p>
                    <!--
                    <TotalMonthlyPrice :whichCase="deleteType"/>
                    -->
                </slot>
                <!-- here ends dynamic content -->
              </div>
              <div class="modal-footer text-justify">
                <slot name="footer">
                  <div class="action-buttons w-full flex container">
                    <div class="my-auto">
                      <a href="" class="modal-default-button cancel-button text-gray-600" @click.prevent="$emit('close')">
                        Cancel
                      </a>
                    </div>
                    <div class="actions ml-auto">
                      <div class="actions ml-auto">
                          <button type="submit" class="action-button ml-auto border cursor-pointer rounded-md submit-button" @click="deleteId">Delete</button>
                      </div>
                      </div>
                  </div>
                </slot>
              </div>

          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// importing the helpers for calling actions and getters from vuex
import { mapActions } from 'vuex';
import axios from 'axios'
import TotalMonthlyPrice from '../../Utilities/TotalMonthlyPrice.vue';
    export default {
      components: { TotalMonthlyPrice },
      props: {
        title: String,
        deleteType: String,
        idToDelete: String
      },
      methods: {
        ...mapActions('practitioner', ['setUserPractitioners']),
        deleteId()
        {
            let apiEndpoint = '';
            if(this.deleteType == 'removeClinic'){
                apiEndpoint = '/dashboard/clinic/delete-clinic/';
            }else if(this.deleteType == 'removePractitioner'){
                apiEndpoint = '/dashboard/practitioners/delete-practitioner/';
            }
            axios.delete(apiEndpoint + this.idToDelete)
            .then(res => {
                Swal.fire({
                    toast: true,
                    icon: 'success',
                    title: "Deleted successfully",
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
                window.location.reload();

            }).catch(er => {
                Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Error deleting please try again in a moment',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    });
                window.location.reload();
            })

        }
      },
      created() {
        this.setUserPractitioners();
      }
    }
</script>
