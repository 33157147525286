<template>
    <div class="">
        <div class="w-full mb-2">
            <analytic-dashboard :reference="clinic.ref"></analytic-dashboard>
        </div>
    </div>

</template>

<script>
import AnalyticDashboard from '../Dashboard/AnalyticDashboard.vue';

export default {
    props:['clinic'],
    components: {
        AnalyticDashboard
    },
    computed: {

    }

}
</script>