<template>
    <div  v-if="!loadFormdata" class="container">
        <div class="form-section-info">
            <h3 class="form-section-info-title font-semibold pb-3">{{ formData.title}}</h3>
            <span class="text-gray-400">{{formData.description}}</span>
        </div>
        <div  v-if="!form[formData.components.anonymous.inputName]" class="form-section my-4 flex px-4">
            <div class="form-section-info w-1/3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ formData.components.author.title }}
                        <span v-if="formData.components.author.required" class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">{{formData.components.author.description }}</p>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <input 
                    :type="formData.components.author.inputType" 
                    class="w-full"
                    :required="formData.components.author.required"
                    :placeholder="formData.components.author.placeholder"
                    :name="formData.components.author.inputName"
                    v-model="form[formData.components.author.inputName]"
                    />
                <InputErrorMessage v-if="ShowErrors && (!form[formData.components.author.inputName] || form[formData.components.author.inputName] === undefined)"/>
                </div>
            </div>
        </div>
        <div class="form-section my-4 flex px-4">

            <div class="form-section-info w-1/3">
                    <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ formData.components.service_received.title }}
                        <span v-if="formData.components.service_received.required" class="text-red-400">*</span>
                    </label>
                    <p class="font-sm text-gray-400 text-right">{{formData.components.service_received.description }}</p>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <v-select  v-if="!loadingServices && services.length !== 0" 
                    label="main_service_name" 
                    :placeholder="formData.components.service_received.help"  
                    class="vue-select1" 
                    name="select1" 
                    :options="services" 
                    v-model="form[formData.components.service_received.inputName]">
                    </v-select>
                    <span v-if="services.length === 0" >You currently have no services. <br> Please <a href="/dashboard/services?action=add">add a service </a> first to import a review about the service</span>
                    <InputErrorMessage v-if="ShowErrors && (!form[formData.components.service_received.inputName] || form[formData.components.service_received.inputName] === undefined)"/>
                </div>
            </div>

        </div>
        <div class="form-section my-4 flex px-4">
            <div class="form-section-info w-1/3">
                    <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ formData.components.url_of_origin.title }}
                        <span v-if="formData.components.url_of_origin.required" class="text-red-400">*</span>
                    </label>
                    <p class="font-sm text-gray-400 text-right">{{formData.components.url_of_origin.description }}</p>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div>
                    <!-- is-invalid  -->
                    <input 
                    :id="formData.components.url_of_origin.inputName" 
                    v-model="form[formData.components.url_of_origin.inputName]" 
                    :type="formData.components.url_of_origin.inputType" 
                    :placeholder="formData.components.url_of_origin.placeholder"
                    class="appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500 form-control  " 
                    :name="formData.components.url_of_origin.inputName" 
                    :required="formData.components.url_of_origin.required" 
                    >
                    <InputErrorMessage v-if="ShowErrors && (!form[formData.components.url_of_origin.inputName] || form[formData.components.url_of_origin.inputName] === undefined)"/>

                </div>
            </div>

        </div>
        <div class="form-section my-4 flex px-4">
            <div class="form-section-info w-1/3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ formData.components.rating.title }}
                    <span v-if="formData.components.rating.required" class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">{{formData.components.rating.description }}</p>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                    <div>
                        <!-- is-invalid  -->
                        <div class="flex" >
                            <div v-for="n in 5" :key="n" @click="setRating(n)">
                                <svg :class="{ 'text-yellow-500': n <= modalData.rating  }" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current" viewBox="0 0 24 24">
                                    <path d="M12 2l3.09 6.31 6.91.99-5 4.86 1.18 6.88L12 17.25l-6.09 3.09 1.18-6.88-5-4.86 6.91-.99L12 2z" />
                                </svg>
                            </div>
                        </div>
                        <InputErrorMessage v-if="ShowErrors && (!form.rating || form.rating === undefined)"/>
                    
                    </div>
            </div>

        </div>
        <div class="form-section my-4 flex px-4">
            <div class="form-section-info w-1/3">
                    <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ formData.components.reviewText.title }}
                        <span v-if="formData.components.reviewText.required" class="text-red-400">*</span>
                    </label>
                    <p class="font-sm text-gray-400 text-right">{{formData.components.reviewText.description }}</p>
                </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        <p class="text-sm italic font-light ml-auto text-right opacity-70">(Characters: {{ reviewTextCharacterCount }}/{{formData.components.reviewText.max}})</p>
                    </label>
                    
                    <textarea class="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        rows="4" 
                        cols="50"
                        :id="formData.components.reviewText.inputName" 
                        :name="formData.components.reviewText.inputName"
                        v-model="form[formData.components.reviewText.inputName]"
                        @keyup="modificationState()"
                        :placeholder="formData.components.reviewText.help"
                        :maxlength="formData.components.reviewText.max"
                    ></textarea>
                    <InputErrorMessage v-if="ShowErrors && (!form[formData.components.reviewText.inputName] || form[formData.components.reviewText.inputName] === undefined)"/>
                </div>
            </div>

        </div>
            <div class="modal-footer text-justify">
                <div class="col-md-6 offset-md-4">
                    <button @click="submitForm(formData.saveAddFunction)" type="submit" class="px-4 action-button border cursor-pointer rounded-md submit-button ">
                        {{ formData.buttonCreateText }}
                    </button>
                </div>
            </div> 
        </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InputErrorMessage from '../../../Utilities/InputErrorMessage.vue'
import AddReviewModalForm from '../../../../constants/services/modalForms/AddReviewModalForm'

export default {
    props: ['target', 'type'],
    components:{InputErrorMessage},
    data()
    {
        return {
            reviewTextCharacterCount: 0,
            modalData: {
                patient_email: '',
                rating: null
            },
            ShowErrors: false,
            form:{},
            formData: {},
            loadFormdata: true,
            radius_tier_options: 1,
            loadingServices: true,
            services: {}
        }
    },
    computed: {
        ...mapGetters('mainClinic', ['MAIN_CLINIC']),
        ...mapGetters('practitioner', ['GET_ERRORS']),
    },
    methods: {
        ...mapActions('reviews', ['addReview', 'getServicesForReviews']),
        ...mapActions('commonFunctions', ['redirectAction']),
        modificationState()
        {
            this.reviewTextCharacterCount = this.form[this.formData.components.reviewText.inputName].length;
        },
        setRating(n) {
            this.modalData.rating = n;
        },
        submitForm(saveFnc)
        {
            
            this.form.rating = this.modalData.rating
            this.ShowErrors = true
            if(this.form[this.formData.components.service_received.inputName] === undefined
            || this.form.rating === null
            || this.form[this.formData.components.author.inputName] === undefined
            || this.form[this.formData.components.reviewText.inputName] === undefined
            || this.form[this.formData.components.url_of_origin.inputName] === undefined
            || this.form[this.formData.components.service_received.inputName] === undefined){
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please fill the form correctly',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            }
            else {
                
                // Regular expression to test if the URL is valid
                const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

                // Test if the URL matches the regular expression
                if(!urlRegex.test(this.form[this.formData.components.url_of_origin.inputName])){
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Please fill a valid Url',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    });
                }else {

                    this.form[this.formData.components.service_received.inputName] = this.form[this.formData.components.service_received.inputName].main_service_name

                    eval('this.'+saveFnc+'(this.form)')
                            .then(res => {
                                Swal.fire({
                                icon: 'success',
                                animation: true,
                                title: "Review Submitted",
                                text: "Your review has been submitted and is now pending approval to make sure that this review is legit. The Medimob Team will let you know when your review has been approved or if further information is required.",
                                showConfirmButton: true,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'Ok!'
                            }).then((result) => {
                                this.redirectAction('Existing+Reviews')
                            });

                        }).catch(() => {
                            Swal.fire({
                                toast: true,
                                icon: 'error',
                                title: 'Something went wrong. Contact support',
                                animation: true,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true
                            });   
                        })
                }
            }
        }
    },
    mounted()
    {
        this.formData = AddReviewModalForm;
    },
    watch: {
        formData: {
            handler(newValue) {
            this.loadFormdata = false
            }
        }
    },
    created() {
        this.getServicesForReviews()
        .then(res => {
            this.services = res.data
            this.loadingServices = false
        }).catch(err => {
            console.log(err)
            this.loadingServices = false
        })
    }
}
</script>


<style lang="scss" scoped>

@import "vue-select/dist/vue-select.css";
input, textarea , svg{
    cursor: pointer;
}
.modal-footer{
    justify-content: center;
    border-top: 0px solid #dee2e6;
}
.action-button{
    width: fit-content !important;
}
</style>