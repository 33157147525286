<template>
  <div>
    <input type="text" v-model="searchQuery" @input="search" placeholder="Search...">
    <table v-if="results.length > 0" class="w-full table-auto mt-4 p-4">
      <thead>
      <tr>
        <th class="strong">Name</th>
        <th class="strong">Email</th>
        <th class="strong">Ref</th>
        <th class="strong">Source</th>
        <th class="strong"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(result, index) in results" :key="index">
        <td>{{ result.name }}</td>
        <td>{{ result.email }}</td>
        <td>{{ result.ref }}</td>
        <td>{{ result.source }}</td>
        <td>
          <a :href="'/admin/view/' + result.main_clinic_id" class="btn">View</a>
        </td>
      </tr>
      </tbody>
    </table>
    <p v-else>No results found.</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      searchQuery: '',
      results: []
    };
  },
  methods: {
    search() {
      if (this.searchQuery.trim() !== '') {
        axios.get(`/admin/search?q=${this.searchQuery}`)
            .then((response) => {
              this.results = response.data;
            })
            .catch(error => {
              console.error('Error fetching search results:', error);
            });
      } else {
        this.results = [];
      }
    }
  }
};
</script>
