<style lang="scss" scoped>
    .search-box-title {
        color: #27292D;
        text-align: center;
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
    }

    .search-form-comp {
        @media (max-width: 470px)
        {
            > div {
                flex-direction: column;
            }
        }
    }

    label {
        color: #212429;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 183.333% */
        text-transform: capitalize;
        top: 7px;
        left: 15px;
    }

    select, input {
        color: #838382;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        padding-top:30px;
        padding-bottom:18px;
        background-color: #F2F2F2!important;
        border-radius: 100px;


    }

    button {
        background-color: #599DE0;
        border-radius: 100px;
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
    }

    @media (min-width: 992px)
    {
        .form-wrap {
            display: flex!important;
        }

        .form-bt-md {
            display: flex!important;
            margin-left: auto;
            width: 25%!important;

        }
    }

    @media (max-width: 768px)
    {
        .form-wrap {
            display: block;
        }

        .form-bt-md {
            width: 100%;
            display: block;

            button {
                padding: 14px!important;
            }

        }
    }

    #searchBtn {
      &:hover {
        background-color: #0EE2B1 !important;
      }
    }


</style>

<template>
  <div>
      <form @submit.prevent="initSearch()" class="w-full flex form-wrap">
          <div class="flex w-full search-form-comp">
              <div class="flex w-full">
                  <div class="relative bg-white p-2 w-full form-inputs rounded-full">
                      <select name="search_type" v-model="search_type" id="" class="rounded-full cursor-pointer block px-4 pt-[30px] w-full text-sm text-gray-900 bg-gray-100 dark:bg-gray-700 border-0 border-b-2 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer h-full" @change="getSearchType()">
                          <option disabled selected>Search By</option>
                          <option value="profession">Profession</option>
                          <option value="service">Service</option>
                          <option value="clinic">Clinic</option>
                          <option value="practitioner">Professional</option>
                          <option value="condition">Condition</option>
                      </select>
                      <label for="text-input" class="absolute text-sm p-4 text-green-600 dark:text-green-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5">Search By</label>
                  </div>
                  <div class="relative bg-white p-2 w-full form-inputs rounded-full" v-if="service_group">
                      <input style="border-radius: 9999px;" type="text" id="serviceSelection" v-model="serviceStr" @focus="triggerServicesDD" name="service" class="rounded-full cursor-pointer block p-4 w-full text-sm text-gray-900 bg-gray-100 dark:bg-gray-700 border-0 border-b-2 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder="Select a service">
                      <label for="text-input" class="absolute text-sm p-4 text-green-600 dark:text-green-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5">What</label>
                      <div v-if="showServices" class="absolute z-50 bg-white border border-gray-300 rounded-lg mt-1 w-full">
                          <div
                              v-if="filteredServices.length > 0"
                              v-for="(service, index) in filteredServices"
                              :key="index"
                              class="py-2 px-4 cursor-pointer hover:bg-blue-100"
                              @click="selectService(service)"
                          >
                              {{ service.main_service_name }}
                          </div>
                          <div v-if="filteredServices.length === 0" class="py-2 px-4 cursor-pointer hover:bg-blue-100" @click="showAddServiceInput"
                          >
                              <p>Can't find what you're looking for? Click here.</p>
                          </div>
                      </div>
                  </div>
                  <div class="relative bg-white p-2 w-full form-inputs rounded-full" v-if="profession_group">
                    <input style="border-radius: 9999px;" type="text" id="professionsSelection" v-model="professionStr" @focus="triggerProfessionsDD" name="profession" class="rounded-full cursor-pointer block p-4 w-full text-sm text-gray-900 bg-gray-100 dark:bg-gray-700 border-0 border-b-2 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder="Select a profession">
                    <label for="text-input" class="absolute text-sm p-4 text-green-600 dark:text-green-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5">What</label>
                    <div v-if="showProfessions" class="absolute z-50 bg-white border border-gray-300 rounded-lg mt-1 w-full">
                      <div
                          v-if="filteredProfessions.length > 0"
                          v-for="(profession, index) in filteredProfessions"
                          :key="index"
                          class="py-2 px-4 cursor-pointer hover:bg-blue-100 capitalize"
                          @click="selectProfession(profession)"
                      >
                        {{ profession }}
                      </div>
                      <div v-if="filteredProfessions.length === 0" class="py-2 px-4 cursor-pointer hover:bg-blue-100" @click="showAddServiceInput"
                      >
                        <p>Can't find what you're looking for? Click here.</p>
                      </div>
                    </div>
                  </div>
                  <div class="relative bg-white p-2 w-full form-inputs rounded-full" v-if="condition_group">
                      <input style="border-radius: 9999px;" type="text" id="conditionSelection" v-model="condition" @focus="triggerConditionsDD" name="condition" class="rounded-full cursor-pointer block p-4 w-full text-sm text-gray-900 bg-gray-100 dark:bg-gray-700 border-0 border-b-2 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder="Select a condition">
                      <label for="text-input" class="absolute text-sm p-4 text-green-600 dark:text-green-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5">What</label>
                      <div v-if="showConditions" class="absolute z-10 bg-white border border-gray-300 rounded-lg mt-1 w-full">
                          <div
                              v-for="condition in filteredConditions"
                              class="py-2 px-4 cursor-pointer hover:bg-blue-100"
                              @click="selectCondition(condition.name)"
                          >
                              {{ condition.name }}
                          </div>
                      </div>
                  </div>
                  <div class="relative bg-white p-2 w-full form-inputs rounded-full" v-if="location_group">
                      <input style="border-radius: 9999px;" type="text" v-model="location" @input="handleCityInput" name="location" class="rounded-full cursor-pointer block p-4 w-full text-sm text-gray-900 bg-gray-100 dark:bg-gray-700 border-0 border-b-2 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder="Enter postcode or city">
                      <label for="text-input" class="absolute text-sm p-4 text-green-600 dark:text-green-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5">Where</label>

                     <div v-if="showCityResults" class="absolute z-10 bg-white border border-gray-300 rounded-lg mt-1 w-full">
                        <div
                           v-for="(result, index) in autocompleteCityOptions"
                           :key="index"
                           class="py-2 px-4 cursor-pointer hover:bg-blue-100 capitalize"
                           @click="selectCityResult(result)"
                           >
                           {{ result }}
                        </div>
                     </div>
                  </div>
                  <div class="relative bg-white p-2 w-full form-inputs rounded-full" v-if="practitioner_group">
                      <input style="border-radius: 9999px;" type="text" v-model="practitioner" name="practitioner" class="rounded-full cursor-pointer block p-4 w-full text-sm text-gray-900 bg-gray-100 dark:bg-gray-700 border-0 border-b-2 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder="Enter a practitioner">
                      <label for="text-input" class="absolute text-sm p-4 text-green-600 dark:text-green-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5">Who</label>
                  </div>
                  <div class="relative bg-white p-2 w-full form-inputs rounded-full" v-if="clinic_group">
                      <input style="border-radius: 9999px;" type="text" v-model="clinic" name="clinic" class="rounded-full cursor-pointer block p-4 w-full text-sm text-gray-900 bg-gray-100 dark:bg-gray-700 border-0 border-b-2 border-green-600 dark:border-green-500 appearance-none dark:text-white dark:focus:border-green-500 focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder="Enter a clinic">
                      <label for="text-input" class="absolute text-sm p-4 text-green-600 dark:text-green-500 duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-2.5">Clinic</label>
                  </div>
            </div>
          </div>
          <div class="flex w-full md:w-1/4 ml-auto my-1">
              <button v-if="!loadingData" type="submit" id="searchBtn" class="rounded-full w-full flex h-full my-auto items-center btn text-white shadow px-4 py-4 !hover:bg-teal-500" style="width: 100%;">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                      <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" clip-rule="evenodd" />
                  </svg>
                  <span>Search</span>
              </button>
          </div>
      </form>
        <CreateUpdateModal
            v-if="addingService"
            :title="'Suggest a service'"
            :buttonText="'Send'"
            @save="saveServiceSuggestion()"
            @close="closeServiceSuggestion()"
        >
            <div class="flex" slot="content">
                <div class="form-section-info w-1/3">
                    <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0">
                        Email
                        <span class="text-red-400">*</span>
                    </label>
                    <p class="font-sm text-gray-400 text-right">Type your email</p>
                </div>
                <div class="form-section-content w-2/3 ml-5">
                    <div class="mb-3">
                        <input
                            type="email"
                            class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control p-3"
                            placeholder="Your email"
                            v-model="email"
                        />
                    </div>
                </div>
            </div>
            <div class="flex" slot="content">
                <div class="form-section-info w-1/3">
                    <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0">
                        Services
                        <span class="text-red-400">*</span>
                    </label>
                    <p class="font-sm text-gray-400 text-right">Suggest a service</p>
                </div>
                <div class="form-section-content w-2/3 ml-5">
                    <div class="mb-3">
                  <textarea
                      v-if="addingService"
                      type="text"
                      class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control "
                      placeholder="Write in the box the service that you're looking for"
                      v-model="newService"
                  >
                  </textarea>
                    </div>
                </div>
            </div>
        </CreateUpdateModal>
    </div>
 </template>

<script>
import axios from 'axios';
import VueTypedJs from 'vue-typed-js'
import { mapActions } from 'vuex';
import CreateUpdateModal from './CreateUpdateModal.vue'
import SearchServicesChoosingModalContent from './Search/SearchServicesChoosingModalContent.vue'
import SearchConditionChoosingModalContent from './Search/SearchConditionChoosingModalContent.vue'
import conditions from '../constants/home/data/conditions';
import services from '../constants/home/data/services';
import professions from '../constants/home/data/professions';
import Dropdown from "./Dropdown.vue";

export default {
    components: {
        CreateUpdateModal,
        SearchServicesChoosingModalContent,
        SearchConditionChoosingModalContent,
        VueTypedJs,
        Dropdown
    },
    props: ['last_query', 'servicesession', 'last_query_method'],
    data() {
        return {
            // attributes necessary for the city autocomplete
            showCityResults: false,
            autocompleteCityOptions: [],
            serviceStr: '',
            professionStr: '',
            service: '',
            profession: '',
            search_type: 'profession',
            location: '',
            practitioner: '',
            clinic: '',
            condition: '',
            location_group: true,
            practitioner_group: false,
            clinic_group: false,
            service_group: false,
            profession_group: true,
            condition_group: false,
            // variable for storing all services
            allservices: services,
            allprofessions: [],
            ChooseService: false,
            ChooseProfession: false,
            // variable for storing all conditions
            allconditions: conditions,
            ChooseCondition: false,
            // storing user coordiantes
            userLat: null,
            userLng: null,
            loadingData: false,
            showServices: false,
            showProfessions: false,
            showConditions: false,
            addingService: false,
            newService: '',
            email: ''
        }
    },
    methods: {
        ...mapActions('search', ['getServicesForSearch', 'getConditionsForSearch', 'getProfessionsForSearch']),
        saveServiceSuggestion() {
            if(this.newService == '' || this.newService.length < 3 || this.email == '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please fill all fields',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            }else {
                this.saveServiceSuggestionAction({
                    email: this.email,
                    newService: this.newService
                }).then(res => {
                    Swal.fire({
                        icon: 'success',
                        animation: true,
                        title: "Suggestion sent",
                        text: "We have recieved your service suggestion.",
                        showConfirmButton: true,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok!'
                    }).then((result) => {
                        this.newService = ''
                        this.email = ''
                        this.closeServiceSuggestion()
                    });
                })
            }
        },
        showAddServiceInput() {
            this.addingService = true;
        },
        closeServiceSuggestion() {
            this.addingService = false
        },
        selectCondition(condition)
        {
            this.condition = condition
        },
        selectProfession(profession)
        {
          this.profession = profession.toLowerCase()
          this.professionStr = profession
          this.getProfessionLocation(this.profession)

        },
        getProfessionLocation(profession)
        {
          axios.post('/search-get-profession-locations', {'profession':profession})
              .then((res) => {
                this.autocompleteCityOptions = res.data
              }).catch((e) => {
                console.log(e)
          })
        },
        triggerConditionsDD()
        {
            this.showConditions = true;
            document.addEventListener('click', this.hideConditionDropdown);
        },
        triggerProfessionsDD()
        {
          this.showProfessions = true;
          document.addEventListener('click', this.hideProfessionsDropdown);
        },
        hideProfessionsDropdown(event)
        {
          if (!event.target.closest('#professionsSelection')) {
            this.showProfessions = false;
            // Remove the click event listener from the document
            document.removeEventListener('click', this.hideProfessionsDropdown);
          }
        },
        hideConditionDropdown(event)
        {
            if (!event.target.closest('#conditionSelection')) {
                this.showConditions = false;
                // Remove the click event listener from the document
                document.removeEventListener('click', this.hideConditionDropdown);
            }
        },
        selectService(service)
        {
            this.service = service.ref
            this.serviceStr = service.main_service_name
        },
        triggerServicesDD()
        {
            this.showServices = true;
            document.addEventListener('click', this.hideServiceDropdown);
        },
        hideServiceDropdown(event)
        {
            if (!event.target.closest('#serviceSelection')) {
                this.showServices = false;
                // Remove the click event listener from the document
                document.removeEventListener('click', this.hideServiceDropdown);
            }
        },
        getSearchType() {
            if(this.search_type == 'profession') {
              this.location_group = true;
              this.service_group = false;
              this.profession_group = true;
              this.practitioner_group = false;
              this.clinic_group = false;
              this.condition_group = false;
            } else if(this.search_type == 'service') {
                this.location_group = true;
                this.service_group = true;
                this.profession_group = false;
                this.practitioner_group = false;
                this.clinic_group = false;
                this.condition_group = false;

            } else if (this.search_type == 'clinic') {
                this.location_group = false;
                this.service_group = false;
                this.profession_group = false;
                this.practitioner_group = false;
                this.clinic_group = true;
                this.condition_group = false;
            } else if (this.search_type == 'practitioner')
            {
                this.location_group = false;
                this.service_group = false;
                this.profession_group = false;
                this.practitioner_group = true;
                this.clinic_group = false;
                this.condition_group = false;

            } else if (this.search_type == 'condition')
            {
                this.location_group = false;
                this.service_group = false;
                this.profession_group = false;
                this.practitioner_group = false;
                this.clinic_group = false;
                this.condition_group = true;
            }
        },
        toggleChooseProfession(){
          this.ChooseProfession = true
        },
        toggleChooseService(){
            this.ChooseService = true
        },
        toggleChooseCondition(){
            this.ChooseCondition = true
        },
        closeChooseProfession(){
          this.ChooseProfession = false
        },
        closeChooseService(){
            this.ChooseService = false
        },
        closeChooseCondition(){
            this.ChooseCondition = false
        },
        setProfessionChosen(value){
          this.profession = value
        },
        setServiceChosen(value){
            this.service = value.main_service_name
        },
        setConditionChosen(value){
            this.condition = value.name
        },
        getCurrentPosition(){
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    position => resolve(position),
                    error => reject(error)
                );
            })
        },
        initSearch()
        {
            if(this.location)
            {
                var location = this.location
            } else {
                var location = "&location=null"
            }
            if(this.service)
            {
                var service = this.service
            } else {
                var service = "&service=null"
            }
            if(this.profession)
            {
              var profession = this.profession
            } else {
              var profession = "&profession=null"
            }
            if(this.practitioner)
            {
                var practitioner = this.practitioner
            } else {
                var practitioner = "&practitioner=null"
            }
            if(this.clinic)
            {
                var clinic = this.clinic
            } else {
                var clinic = "&clinic=null"
            }
            if(this.condition)
            {
                var condition = this.condition
            } else {
                var condition = "&condition=null"
            }
            if(this.profession)
            {
              var profession = this.profession
            } else {
              var profession = "&profession=null"
            }
            // checking if it's s service and the form is invalid
            if(this.search_type === 'service' && this.service === ''){
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please choose a service',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            }else if(this.search_type === 'service' && this.location === ''){
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please enter a valid postcode or a city',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            } else if(this.search_type === 'profession' && this.profession === ''){
              Swal.fire({
                toast: true,
                icon: 'error',
                title: 'Please select a profession',
                animation: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true
              });
            } else if(this.search_type === 'clinic' && this.clinic === '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please enter a valid clinic name',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            } else if(this.search_type === 'practitioner' && this.practitioner === '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please enter a valid practitioner name',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            } else if(this.search_type === 'condition' && this.condition === '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please choose a valid condition name',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            }
            else {
                if((this.search_type === 'service' || this.search_type === 'profession') && this.userLat !== null && this.userLng !== null) {
                    window.location.href="/init-search/"+this.search_type+"/"+profession+"/"+location+"/"+practitioner+"/"+clinic+"/"+condition+"/"+service+"/"+profession+"/"+this.userLat+"/"+this.userLng;
                }else {
                    window.location.href="/init-search/"+this.search_type+"/"+location+"/"+practitioner+"/"+clinic+"/"+condition+"/"+service+"/"+profession;
                }
            }
        },

        // method to handle the city input
        async handleCityInput() {
                const city = this.location.trim();
                if (city === '' || city.includes('/')) {
                  this.showCityResults = false;
                } else {
                    if(this.profession_group)
                    {
                      this.showCityResults = true;
                      let displayNames = null;
                      if (Array.isArray(this.autocompleteCityOptions)) {
                        displayNames = this.autocompleteCityOptions.map(function(item) {
                          return item;
                        });
                      }
                      this.autocompleteCityOptions = displayNames ? displayNames.slice(0, 5) : [];

                    } else if(this.location.length >= 2) {
                        try {
                            if (/\d/.test(this.location)) {
                                // Calling the postcode API for autocomplete postcodes
                                const responsePostcodes = await axios.get(`https://api.postcodes.io/postcodes/${encodeURIComponent(city)}/autocomplete`);
                                const result = responsePostcodes.data.result;
                                const autocompleteOptions = result && Array.isArray(result) ? result.slice(0, 5) : [];
                                this.autocompleteCityOptions = autocompleteOptions;
                            } else {
                                // Calling the API for getting the cities
                                const responseCities = await axios.get('/search/get-cities/' + city);
                                let displayNames = null;
                                // Assigning only the display_name column
                                if (Array.isArray(responseCities.data)) {
                                    displayNames = responseCities.data.map(function(item) {
                                    return item.display_name;
                                    });
                                }
                                // Limit to 5 options
                                this.autocompleteCityOptions = displayNames ? displayNames.slice(0, 5) : [];
                            }






                            this.showCityResults = true;
                        } catch (error) {
                            console.error(error);
                        }

                    }
                }

          },
        selectCityResult(result) {
            this.location = result;
            this.showCityResults = false;
        },
    },
    watch: {
        search_type: {
            handler(newValue) {
                if(newValue === 'practitioner' || newValue === 'clinic' || newValue === 'condition' ) {
                    this.loadingData = true
                    this.getCurrentPosition()
                    .then(position => {
                        this.userLat = position.coords.latitude;
                        this.userLng = position.coords.longitude;
                        this.loadingData = false
                    })
                    .catch(error => {
                        console.error(error);
                        this.loadingData = false
                    });
                }
            },
        },
    },
    computed: {
        filteredProfessions() {
          if (!this.professionStr) {
            return this.allprofessions; // Return an empty array if allprofessions or professionStr is undefined or null
          }

          // Filter services based on input text
          return this.allprofessions
              .map(obj => obj) // Extract profession names from each object
              .filter(obj => obj.toLowerCase().includes(this.professionStr.toLowerCase())) // Filter items based on input text
              .slice(0, 10); // Limit the result to the first 10 items
        },

        filteredServices() {
            // Filter services based on input text
            return this.allservices.filter(service => service.main_service_name.toLowerCase().includes(this.serviceStr.toLowerCase())).slice(0, 10);
        },
        filteredConditions() {
            // Filter services based on input text
            return this.allconditions.filter(condition => condition.name.toLowerCase().includes(this.condition.toLowerCase())).slice(0, 10);
        }
    },
    created(){

        let updatedResponse = this.allservices.map(item => {
            const capitalizedServiceName = item.main_service_name.charAt(0).toUpperCase() + item.main_service_name.slice(1);
            return {
                ...item,
                main_service_name: capitalizedServiceName
            };
        });
        this.allservices = updatedResponse

        updatedResponse = conditions.map(item => {
            const capitalizedServiceName = item.name.charAt(0).toUpperCase() + item.name.slice(1);
            return {
                ...item,
                name: capitalizedServiceName
            };
        });
        this.allconditions = updatedResponse

      this.allprofessions = this.allprofessions.map(item => {
        const capitalizedProfessionName = item.charAt(0).toUpperCase() + item.slice(1);
        return capitalizedProfessionName;
      });

        this.getServicesForSearch()
        .then(res => {
            this.allservices = res.data
            const updatedResponse = this.allservices.map(item => {
                const capitalizedServiceName = item.main_service_name.charAt(0).toUpperCase() + item.main_service_name.slice(1);
                return {
                    ...item,
                    main_service_name: capitalizedServiceName
                };
            });
            this.allservices = updatedResponse
        })
      this.getProfessionsForSearch()
          .then(res => {
            // Assuming res.data is already an array of professions
            this.allprofessions = res.data;

            // Convert the object into an array of values
            const professionNames = Object.values(this.allprofessions);

            // Capitalize the first letter of each profession name
            const updatedProfessions = professionNames.map(profession => {
              const capitalizedProfessionName = profession.charAt(0).toUpperCase() + profession.slice(1);
              return capitalizedProfessionName;
            });

            // Update the allprofessions array with the capitalized professions
            this.allprofessions = updatedProfessions;
          })
          .catch(error => {
            // Handle errors
            console.error("Error fetching professions:", error);
          });

      this.getConditionsForSearch()
          .then(res => {
            // Assuming res.data is already an array of professions
            this.allconditions = res.data;

            // Convert the object into an array of values
            const conditionNames = Object.values(this.allconditions);

            // Capitalize the first letter of each profession name
            const updatedConditions = conditionNames.map(condition => {
              const capitalizedConditionName = condition.charAt(0).toUpperCase() + condition.slice(1);
              return capitalizedConditionName;
            });

            // Update the allprofessions array with the capitalized professions
            this.allconditions = updatedConditions;
          })
          .catch(error => {
            // Handle errors
            console.error("Error fetching conditions:", error);
          });


        if(this.search_type === 'practitioner' || this.search_type === 'clinic'  || this.search_type === 'condition') {
            this.loadingData = true
            this.getCurrentPosition()
            .then(position => {
                this.userLat = position.coords.latitude;
                this.userLng = position.coords.longitude;
                this.loadingData = false
            })
            .catch(error => {
                console.error(error);
                this.loadingData = false
            });
        }
    },
    mounted() {
        document.addEventListener('click', (event) => {
        if (!this.$el.contains(event.target)) {
            this.showCityResults = false;
        }
        });
    },
}
</script>
