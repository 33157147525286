<template>
    <div class="fz12 text-gray flex truncate mob-breadcrumb ">
        <a href="/home" class="inline-flex mr-1 truncate text-white">
            <span class="mx-1 my-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                </svg>

            </span>
        </a>

        <span v-if="GET_BREADCRUMBS" class="mx-1 cursor-default my-auto text-white">›</span>


        <span 
            class="truncate flex" 
            v-for="(link, linkKey) in GET_BREADCRUMBS"
            :key="linkKey"
        >
            <a v-if="linkKey != GET_BREADCRUMBS.length -1" :href="link.url" class="mr-1 truncate my-auto text-white" v-text="link.name"></a>
            <span v-else class="mx-1 my-auto text-white" v-text="link.name"></span>
            <span class="mx-1 cursor-default my-auto text-white" v-if="linkKey != GET_BREADCRUMBS.length -1">›</span>

        </span>


    </div>
</template>

<script>
import { mapGetters } from 'vuex';


export default {
    components: { },
    props: {
        size: {
            type: String
        },
        color: {
            type: String
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters('commonFunctions', ['GET_BREADCRUMBS']),
        current()
        {
            return this.breadcrumbs[this.breadcrumbs.length -1];
        }
    },

};
</script>
