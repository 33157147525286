<template>

    <div>
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                <th scope="col" class="py-3 px-6">
                    Unit
                </th>
                <th scope="col" class="py-3 px-6">
                    Price per unit
                </th>
                <th scope="col" class="py-3 px-6">
                    Total
                </th>
                </tr>
            </thead>
            <tbody>
            <tr>
                <td class="py-2 px-6">
                {{ actualClinics }} x ({{ prices.base.clinic }} Clinic and {{ prices.base.practitioner }} practitioner)
                </td>
                <td class="py-2 px-6">
                {{ prices.base.price }} £
                </td>
                <td class="py-2 px-6">
                {{ allClinicsPrice }} £
                </td>
            </tr>

            <tr v-if="additionalPractitioners > 0">
                <td class="py-2 px-6">
                    {{ additionalPractitioners }} Practitioner (s)
                </td>
                <td class="py-2 px-6">
                    {{ prices.addPractitioner.price }} £
                </td>
                <td class="py-2 px-6">
                    {{ additionalPractitionersPrice }} £
                </td>
            </tr>
            </tbody>

        </table>

        <div style="float:right;" class="flex mb-1 mr-5">
            <div class="w-1/3 mr-5">
            <p class="font-bold text-right">Total</p>
            </div>
            <div class="w-2/3">
            <p class="">{{ completePayment }} £ </p>
            </div>
        </div>

    </div>
  </template>

    <script>
import { mapActions, mapGetters } from 'vuex';

        export default {
            data() {
                return {
                    actualClinics: 0,
                    allClinicsPrice: 0,
                    additionalPractitioners: 0,
                    additionalPractitionersPrice: 0,

                    prices: require('../../../../public/json/prices.json'),
                }
            },
            props: ['whichCase'],
            computed: {
              // ...mapGetters('profile', ['getUserSateliteClinics']),
              ...mapGetters('practitioner', ['GET_ALL_PRACTITIONERS']),
              ...mapGetters('mainClinic', ['GET_WORKFLOW', 'GET_WORKFLOW_STEPS', 'GET_ACTIVE_TAB', 'MAIN_CLINIC', 'GET_SATELITE_CLINICS']),
              completePayment()
              {
                // assign actual practitioners and clinics
                this.actualPractitioners = this.GET_ALL_PRACTITIONERS.length
                this.actualClinics = this.GET_SATELITE_CLINICS.length
                switch(this.whichCase) {
                    // adding the actual practitioner
                    case 'addPractitioner': this.actualPractitioners = this.actualPractitioners + 1; break;
                    // adding the actual clinic
                    case 'addClinic': this.actualClinics = this.actualClinics + 1; break;
                    // remove clinic
                    case 'removeClinic': this.actualClinics = this.actualClinics - 1; break;
                    // remove practitioner
                    case 'removePractitioner': this.actualPractitioners = this.actualPractitioners - 1; break;
                }


                // calculating practitioners that come with clinic
                let clinicPractitioners = this.actualClinics * this.prices.base.practitioner

                // calculating if the number of practtioners is more than the practitioner of the clinics
                this.additionalPractitioners = this.actualPractitioners - clinicPractitioners
                this.additionalPractitionersPrice = 0

                if(this.additionalPractitioners > 0) {
                  // if there are more practitioners calculate the price for them
                  this.additionalPractitionersPrice = this.additionalPractitioners * this.prices.addPractitioner.price
                }

                // calculate the clinics price
                this.allClinicsPrice =  this.actualClinics * this.prices.base.price

                // calculating the final price of all clinics and practitioners
                let resultPrice = this.additionalPractitionersPrice + this.allClinicsPrice
                return resultPrice
              }
            },

        }
    </script>

    <style scoped>

    </style>
