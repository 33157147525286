<template>
    <div  v-if="!loadFormdata" class="px-4 mb-4 container">
        <div class="form-section-info mt-4">
            <h3 class="form-section-info-title font-semibold pb-3">{{ GET_WORKFLOW.title}}</h3>
            <span class="text-gray-400">{{GET_WORKFLOW.description}}</span>
        </div>
        <div class="form-section mt-4 flex px-4">
            <div class="form-section-info w-1/6">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ GET_WORKFLOW.components.old_password.title }}
                    <span v-if="GET_WORKFLOW.components.old_password.required" class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">{{GET_WORKFLOW.components.old_password.description }}</p>
            </div>
            <div class="form-section-content w-1/3 ml-5">
                <div class="mb-3">
                    <input 
                    :type="GET_WORKFLOW.components.old_password.inputType" 
                    class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="modalData.ActualPassword" 
                    :required="GET_WORKFLOW.components.old_password.required"
                    :name="GET_WORKFLOW.components.old_password.inputName"
                    />
                <InputErrorMessage v-if="showErrors && (!modalData.ActualPassword || modalData.ActualPassword === '')"/>
                </div>
            </div>
        </div>
        <div class="form-section mt-4 flex px-4">
            <div class="form-section-info w-1/6">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ GET_WORKFLOW.components.new_password.title }}
                    <span v-if="GET_WORKFLOW.components.new_password.required" class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">{{GET_WORKFLOW.components.new_password.description }}</p>
            </div>
            <div class="form-section-content w-1/3 ml-5">
                <div class="mb-3">
                    <input 
                    :type="GET_WORKFLOW.components.new_password.inputType" 
                    class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="modalData.NewPassword" 
                    :required="GET_WORKFLOW.components.new_password.required"
                    :name="GET_WORKFLOW.components.new_password.inputName"
                    />
                <InputErrorMessage v-if="showErrors && (!modalData.NewPassword || modalData.NewPassword === '')"/>
                </div>
            </div>
        </div>
        <div class="form-section mt-4 flex px-4">
            <div class="form-section-info w-1/6">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ GET_WORKFLOW.components.confirm_new_password.title }}
                    <span v-if="GET_WORKFLOW.components.confirm_new_password.required" class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">{{GET_WORKFLOW.components.confirm_new_password.description }}</p>
            </div>
            <div class="form-section-content w-1/3 ml-5">
                <div class="mb-3">
                    <input 
                    :type="GET_WORKFLOW.components.confirm_new_password.inputType" 
                    class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="modalData.ConfirmNewPassword" 
                    :required="GET_WORKFLOW.components.confirm_new_password.required"
                    :name="GET_WORKFLOW.components.confirm_new_password.inputName"
                    />
                <InputErrorMessage v-if="showErrors && (!modalData.ConfirmNewPassword || modalData.ConfirmNewPassword === '')"/>
                </div>
            </div>
        </div>
        <div class="my-3 w-1/2 py-3 ml-5 pl-5">
            <password-validator @readyTosend="readyTosend" :password="modalData.NewPassword" :confirm_password="modalData.ConfirmNewPassword"></password-validator >
        </div>

        <div class="ml-5">
            <div class="">
                <button :disabled="loadingSubmitButton" @click="submitForm(GET_WORKFLOW.saveFunction)" type="submit" class="px-4 action-button border cursor-pointer rounded-md submit-button ">
                    <span v-if="loadingSubmitButton" class="spinner-border" role="status" aria-hidden="true"></span>
                    <span v-else>{{ GET_WORKFLOW.saveButton }}</span>  
                </button>
            </div>
        </div> 
    </div>


</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InputErrorMessage from '../../Utilities/InputErrorMessage.vue'

export default {
    data() {
        return {
            ReadyStatus: false,
            form:{},
            formData: {},
            loadFormdata: true,
            showErrors: false,
            loadingSubmitButton: false,
            modalData: {
                ActualPassword: '',
                NewPassword: '',
                ConfirmNewPassword: '',
                LogoutFromOtherDevices: false
            }
        }
    },
    components:{InputErrorMessage},
    methods: {
        ...mapActions('account', ['loadWorkflow', 'saveNewPassword']),
        ...mapActions('commonFunctions', ['redirectAction']),
        readyTosend(ReadyStatus) {
            this.ReadyStatus = ReadyStatus
        },
        submitForm(saveFnc)
        {
            this.loadingSubmitButton = true
            this.showErrors = true
            if(this.modalData.ActualPassword === ''
            || this.modalData.NewPassword === ''
            || this.modalData.ConfirmNewPassword === ''){
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please fill the form correctly',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
                this.loadingSubmitButton = false
            }
            if(this.modalData.NewPassword !== this.modalData.ConfirmNewPassword ) {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Passwords do not match',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });   
                this.loadingSubmitButton = false
            }else if(this.ReadyStatus === 'false')
                {
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Please respect the password rules',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    });
                    this.loadingSubmitButton = false
                }
            else {
                eval('this.'+saveFnc+'(this.modalData)')
                    .then(res => {
                        Swal.fire({
                        icon: 'success',
                        animation: true,
                        title: "Password Updated",
                        text: "Your new password has been submitted.",
                        showConfirmButton: true,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok!'
                    }).then((result) => {
                        this.redirectAction('Update+Password')
                    });

                }).catch((e) => {
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: e.response.data,
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    });   
                    this.loadingSubmitButton = false
                })
                
            }
        }
    },
    created() {
        this.loadWorkflow()
        .then(res => {
            this.loadFormdata = false
        }).catch(e => {
            console.log(e)
        })
    },
    computed: {
        ...mapGetters('account', ['GET_WORKFLOW'])
    }
}
</script>

<style lang="scss" scoped>
.action-button{
    width: fit-content;
}
</style>