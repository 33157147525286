const sateliteTabs = [
    'Profile',
    'Location',
    'Services',
    'Practitioners',
    'Online Booking',
    'Analytics',
    'History Logs'
]

export default sateliteTabs;
