<style lang="scss">
    .profile-small-header-section {
        border-bottom: 2px solid #0BD2A4;

        h1 {
            font-size: 25px;
            line-height: 12px;
            color: #717170;
            font-weight: 700;
            padding-bottom: 5px;
        }
    }

    .profile-main-content-section {
        p {
            color: #717170;
            font-size: 17px;
            line-height: 28px;
            white-space: pre-wrap;
        }
    }

    .profile-main-content-section {

        .service-list-box {

            .service-list-item {
                margin-right: 30px;
                background-color: #ffff;
                border-radius: 10px;
                padding: 5px 15px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 4px 0px;


  

                h5 {
                    color: #717170;
                    font-size: 15px;
                    font-weight: 600;
                }
            }

        }
    }


</style>

<template>
    <div class="section-wrapper w-full">
        <div class="profile-small-header-section md:flex">
            <h1>Payment Details</h1>
            <small class="md:ml-5 italic text-slate-500">(Click on each tile to find out more)</small>
        </div>
        <div class="profile-main-content-section mt-3">
            <div class="service-list-box flex-wrap flex">
                <li class="flex service-list-item cursor-pointer my-3" v-for="clinicMPaymentMethod in pMethods">
                    <h5 class="my-auto m-0 mx-auto" @click.prevent="showToolTipModal(clinicMPaymentMethod)" >{{ clinicMPaymentMethod.title }}</h5>
                </li>
            </div>
        </div>
        <CreateUpdateModal 
            v-if="toolTipModal" 
            :title="toolTipMessage.title"
            :data="toolTipMessage"
            :buttonText="'Nope'"
            :showActionButton="false"
            @save=""
            @close="toolTipModal = false; toolTipMessage = {}"
        >
                <div class="" slot="content">
                    <div class="" v-html="toolTipMessage.description"></div>
                </div>
        </CreateUpdateModal>
    </div>
</template>


<script>
import paymentMethodsInfo from '../../../../constants/infobox/paymentMethodsInfo';
import CreateUpdateModal from '../../../CreateUpdateModal.vue';

    export default {
        components: {CreateUpdateModal},
        props: ['payment_methods'],
        data() 
        {
            return {
                paymentList: [],
                pMethods: [],
                toolTipModal: false,
                toolTipMessage: {
                    title: '',
                    description: '',
                    link: ''
                }
            }
        },
        methods: {
            pickRightServices()
            {
                this.paymentList.forEach(element => {
                    const key = element.key;
                    if(JSON.parse(this.payment_methods)[key])
                    {
                        this.pMethods.push(element)
                    }
                });
            },
            showToolTipModal(method)
            {
                if(method.link && this.toolTipMessage.link != 'undefined')
                {
                    this.toolTipMessage.link = 'Click <a href="'+method.link+'" target="blank"> here </a>for more info'
                } else {
                    this.toolTipMessage.link = '';
                }
                this.toolTipMessage.title = method.title;
                this.toolTipMessage.description = '<p>'+method.clientDescription+'<br><br>'+this.toolTipMessage.link+'</p>';
                this.toolTipModal = true;
            }
        },
        mounted() {
            this.paymentList = paymentMethodsInfo;
            this.pickRightServices();
        }

    }
</script>