<template>
    <div class="">
        <div class="w-full mb-2">
            <associate-service-to-clinic :clinicRef="clinicRef"></associate-service-to-clinic>
        </div>
    </div>

</template>

<script>
import AssociateServiceToClinic from './TabComponents/AssociateServiceToClinic.vue';
import { mapGetters } from 'vuex';

export default {
    props:['clinicRef'],
    components: {
        AssociateServiceToClinic
    },
    computed: {
        // ...mapGetters('practitioner', ['GET_PRACTITIONER']),
    }

}
</script>