<style scoped>

.max-reached {
    border: 2px solid rgb(213, 44, 38);
}

</style>

<template>
    <div class="short-bio-wrap">
        <form>
            <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                Short Bio
            </label>
            <div class="field-secription flex">
                <p class="text-sm italic font-light">(This short description will be displayed on the search result page)</p>
                <p class="text-sm italic font-light flex ml-auto">(Characters: {{short_bio.characters_count}}/{{short_bio.max_char}})</p>
            </div>
            <textarea class="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
            rows="4" 
            cols="50"
            id="short_bio" 
            name="short_bio"
            v-model="short_bio.short_bio_text"
            placeholder="This is your chance to get your patients' attention when your clinic is displayed in the search result page. Make it short and catchy."
            @change="saveShortBio(short_bio)"
            @keyup="modificationState(); alterShortBioSavedStatus()"
            :class="{'saved-input' : this.savedShortBio}"
            :maxlength="short_bio.max_char"
            ></textarea>
        </form>
    </div>
</template>

<script>
import {mapActions, mapState } from 'vuex';
import LastSavedBadge from '../../Utilities/Badges/LastSavedBadge.vue'

export default {
    props: ['head_office'],
    components: {'last-saved-badge': LastSavedBadge},

    data() {
        return {
            short_bio: {
                clinic_id: this.head_office.id,
                short_bio_text: this.head_office.short_bio,
                max_char: 250,
                characters_count: 0,
            }

        }
    },
    methods: {
        ...mapActions('profile', ['saveShortBio', 'alterShortBioSavedStatus']),
        modificationState()
        {
            this.short_bio.characters_count = this.short_bio.short_bio_text.length;
        }
    },
    computed: {
        ...mapState('profile', ['headOffice', 'savedShortBio', 'lastUpdated']),
        setMainClinicData()
        {
            return this.headOffice;
        },

    },
    mounted()
    {
        this.short_bio.characters_count = this.short_bio.short_bio_text.length;
    }   

}
</script>