<template>
    <div>
        <div class="flex items-center mb-2">
        <!-- Stars -->
            <ul v-if="review.rating > 0" class="flex p-0 mr-auto my-0">
                <li v-for="star in review.rating" class="mx-1 star-list" style="color:#0BD2A4"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill review-icon" viewBox="0 0 16 16">
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                    </svg>
                </li>
            </ul>
        <!-- Reviewer's Name -->
        <div class="font-semibold capitalize-first">Service: {{review.service_received}}</div>

        </div>
        <div v-if="review.anonymous == 0" class="font-semibold capitalize-first">{{ review.author }}</div>
        <div v-else class="font-semibold">Anonymous</div>
        <!-- Review Text -->
        <p class="review-text">
            {{ truncatedReview(review.review_text, 50) }}
            <span
                v-if="showmore"
                class="text-green-500 cursor-pointer"
                @click="showFullReview()"
            >
                Show More
            </span>
        </p>
        <show-more-text :fullText="review.review_text" :is-open="showmoremodal" @close="showmoremodal = false" />
  </div>
</template>

<script>
import ShowMoreText from './ShowMoreText'
export default {
    data() {
        return {
            showmore: false,
            showmoremodal: false
        }
    },
    components: {ShowMoreText},
    props:['review'],
    methods: {
        truncatedReview(text, wordLimit) {
            const words = text.split(' ');
            if (words.length > wordLimit) {
                this.showmore = true
                return words.slice(0, wordLimit).join(' ') + '...';
            } else {
                return text;
            }
            },
            showFullReview() {
                this.showmoremodal = true
            },
    }
}
</script>