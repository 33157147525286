<template>
    <section class="w-full">
        <div class="homepage-footer p-8">
            <div class="text-white py-8">
                <div class="container mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">

                    <div class="text-left  sm:col-span-2 md:col-span-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="45" viewBox="0 0 253 45" fill="none">
                          <g clip-path="url(#clip0_578_65)">
                            <path d="M140.34 37.48C140 37.14 139.61 36.87 139.15 36.68C138.7 36.48 138.21 36.39 137.69 36.39C137.18 36.39 136.7 36.49 136.25 36.68C135.8 36.88 135.4 37.14 135.06 37.48C134.72 37.82 134.45 38.21 134.25 38.67C134.05 39.12 133.95 39.61 133.95 40.12C133.95 40.63 134.05 41.11 134.25 41.57C134.45 42.02 134.72 42.42 135.06 42.76C135.4 43.1 135.79 43.37 136.25 43.56C136.7 43.76 137.18 43.85 137.69 43.85C138.21 43.85 138.69 43.75 139.15 43.56C139.6 43.36 140 43.1 140.34 42.76C140.68 42.42 140.94 42.02 141.13 41.57C141.32 41.12 141.42 40.63 141.42 40.12C141.42 39.6 141.32 39.12 141.13 38.67C140.94 38.21 140.67 37.81 140.34 37.48Z" fill="#0BD2A4"/>
                            <path d="M179.31 38.66C179.12 38.21 178.85 37.81 178.52 37.47C178.18 37.13 177.79 36.86 177.33 36.67C176.88 36.47 176.39 36.38 175.87 36.38C175.36 36.38 174.88 36.48 174.43 36.67C173.98 36.87 173.58 37.13 173.24 37.47C172.9 37.81 172.63 38.2 172.43 38.66C172.23 39.11 172.13 39.6 172.13 40.11C172.13 40.62 172.23 41.1 172.43 41.56C172.63 42.01 172.9 42.41 173.24 42.75C173.58 43.09 173.97 43.36 174.43 43.55C174.88 43.75 175.36 43.84 175.87 43.84C176.39 43.84 176.87 43.74 177.33 43.55C177.78 43.35 178.18 43.09 178.52 42.75C178.86 42.41 179.12 42.01 179.31 41.56C179.5 41.11 179.6 40.62 179.6 40.11C179.6 39.6 179.5 39.11 179.31 38.66Z" fill="#0BD2A4"/>
                            <path d="M158.14 16.2401C157.72 15.5001 157.29 14.8501 156.87 14.2901C156.64 14.0201 156.4 13.7601 156.16 13.5001L154.74 12.1901C154.64 12.1201 154.54 12.0401 154.44 11.9701C154.37 11.9201 154.3 11.8701 154.23 11.8201C153.54 11.3501 152.81 10.9501 152.02 10.6101C150.43 9.91006 148.72 9.56006 146.91 9.56006C146.22 9.56006 145.55 9.62006 144.89 9.72006C144 9.85006 143.13 10.0901 142.29 10.4301C140.57 11.1201 139.08 12.0801 137.8 13.3201C136.61 14.4701 135.68 15.8201 134.99 17.3701C134.3 18.9201 133.96 20.5601 133.96 22.3101V35.6001C133.97 35.5901 133.99 35.5701 134 35.5601C134.48 35.0801 135.03 34.7101 135.67 34.4301C136.3 34.1501 136.98 34.0201 137.7 34.0201C138.42 34.0201 139.11 34.1601 139.74 34.4301C140.38 34.7001 140.93 35.0801 141.41 35.5601C141.44 35.5901 141.47 35.6301 141.5 35.6601V22.1601C141.5 21.4401 141.65 20.7701 141.93 20.1501C142.22 19.5201 142.61 18.9701 143.11 18.5001C143.61 18.0301 144.19 17.6501 144.85 17.3901C145.51 17.1201 146.21 16.9901 146.95 16.9901C147.69 16.9901 148.39 17.1201 149.03 17.3701C149.68 17.6301 150.25 17.9801 150.73 18.4301C151.14 18.8101 151.48 19.2601 151.75 19.7701C151.8 19.8701 151.85 19.9601 151.9 20.0601C152.19 20.7001 152.35 21.3901 152.37 22.1301L152.44 23.1801V32.7801C152.44 33.2801 152.54 33.7501 152.75 34.1901C152.95 34.6301 153.23 35.0101 153.58 35.3301C153.93 35.6501 154.33 35.9001 154.79 36.0901C155.24 36.2801 155.72 36.3801 156.21 36.3801C157.23 36.3801 158.08 36.0401 158.77 35.3401C159.46 34.6501 159.81 33.8001 159.81 32.7801V24.8301L159.83 24.7201C159.84 24.6201 159.85 24.5301 159.86 24.4301V21.6201C159.86 21.6101 159.86 21.6001 159.86 21.5901C159.72 20.2301 159.43 18.4901 158.14 16.2401Z" fill="#0BD2A4"/>
                            <path d="M175.76 34.2901C176.49 34.2901 177.17 34.43 177.8 34.7001C178.44 34.9801 178.99 35.3501 179.47 35.8301C179.5 35.8601 179.57 35.92 179.6 35.9501V27.1101V26.1101V22.29C179.6 20.54 179.26 18.9 178.57 17.35C177.88 15.8 176.95 14.45 175.76 13.3C174.57 12.15 173.19 11.23 171.62 10.56C170.05 9.89005 168.39 9.55005 166.65 9.55005C164.83 9.55005 162.58 9.90005 160.98 10.6C160.43 10.84 159.78 11.11 159.05 11.61C158.7 11.85 158.32 12.13 157.88 12.56L158.08 12.74C158.32 12.99 158.56 13.26 158.79 13.53C159.21 14.1 159.64 14.75 160.06 15.48C160.89 16.93 161.3 18.16 161.53 19.22C162.16 18.69 163.38 17.92 164.45 17.5C164.7 17.4 165.48 17.11 166.54 17.12C167.58 17.12 168.36 17.4 168.65 17.52C169.31 17.79 169.9 18.16 170.4 18.64C170.9 19.12 171.3 19.67 171.59 20.3C171.88 20.93 172.03 21.6 172.03 22.32V26.1V35.87C172.04 35.85 172.06 35.84 172.07 35.82C172.55 35.34 173.1 34.97 173.74 34.69C174.37 34.42 175.04 34.2901 175.76 34.2901Z" fill="#0BD2A4"/>
                            <path d="M7.37 40.16C7.37 41.18 7.02 42.03 6.33 42.72C5.64 43.41 4.79 43.76 3.77 43.76C3.27 43.76 2.8 43.66 2.34 43.47C1.89 43.28 1.49 43.02 1.13 42.71C0.78 42.39 0.5 42.01 0.3 41.57C0.1 41.12 0 40.65 0 40.16V24.17C0 22.47 0.33 20.87 1 19.37C1.67 17.87 2.58 16.55 3.73 15.43C4.88 14.31 6.22 13.42 7.75 12.77C9.28 12.11 10.89 11.79 12.59 11.79C14.35 11.79 16.01 12.13 17.56 12.81C19.11 13.49 20.47 14.42 21.65 15.59C22.83 14.41 24.2 13.49 25.77 12.81C27.34 12.13 29.01 11.79 30.78 11.79C32.5 11.79 34.11 12.11 35.6 12.74C37.09 13.37 38.41 14.24 39.54 15.35C40.67 16.46 41.58 17.75 42.26 19.22C42.94 20.69 43.31 22.26 43.38 23.94C43.38 23.99 43.39 24.04 43.4 24.09C43.41 24.15 43.42 24.2 43.42 24.24V40.16C43.42 40.66 43.32 41.13 43.13 41.57C42.94 42.01 42.68 42.39 42.35 42.71C42.02 43.03 41.64 43.28 41.21 43.47C40.78 43.66 40.32 43.76 39.82 43.76C39.32 43.76 38.84 43.66 38.38 43.47C37.92 43.28 37.51 43.02 37.17 42.71C36.83 42.39 36.56 42.01 36.36 41.57C36.16 41.13 36.06 40.66 36.06 40.16V24.13C36.04 23.41 35.88 22.74 35.58 22.13C35.29 21.52 34.9 20.99 34.41 20.55C33.92 20.11 33.37 19.76 32.75 19.51C32.13 19.26 31.45 19.14 30.73 19.14C30.01 19.14 29.32 19.28 28.66 19.55C28 19.82 27.43 20.19 26.95 20.65C26.46 21.11 26.08 21.66 25.8 22.28C25.52 22.9 25.38 23.58 25.38 24.3V40.15C25.38 41.17 25.03 42.02 24.34 42.71C23.65 43.4 22.8 43.75 21.78 43.75C21.28 43.75 20.81 43.65 20.35 43.46C19.9 43.27 19.5 43.01 19.15 42.7C18.8 42.38 18.52 42 18.32 41.56C18.12 41.12 18.01 40.65 18.01 40.15V24.17C17.99 23.45 17.83 22.77 17.55 22.15C17.27 21.53 16.89 21 16.41 20.55C15.94 20.11 15.38 19.76 14.75 19.51C14.12 19.26 13.44 19.14 12.71 19.14C11.99 19.14 11.3 19.27 10.66 19.53C10.02 19.79 9.45 20.15 8.96 20.62C8.47 21.08 8.09 21.62 7.81 22.23C7.53 22.84 7.39 23.5 7.39 24.2V40.16H7.37Z" fill="#212429"/>
                            <path d="M55.26 31.26C55.55 32.1 55.97 32.86 56.5 33.55C57.03 34.24 57.65 34.83 58.35 35.31C59.05 35.8 59.82 36.17 60.66 36.43C61.5 36.69 62.38 36.82 63.31 36.82C65.64 36.82 67.47 36.21 68.81 34.99C69.26 34.67 69.7 34.44 70.13 34.29C70.56 34.14 70.97 34.07 71.35 34.07C71.98 34.07 72.55 34.23 73.05 34.56C73.55 34.89 73.96 35.31 74.27 35.83C74.5 36.19 74.66 36.58 74.76 37C74.86 37.42 74.88 37.84 74.83 38.27C74.77 38.7 74.64 39.12 74.42 39.54C74.2 39.96 73.88 40.34 73.45 40.68C72.95 41.13 72.39 41.57 71.75 41.99C71.12 42.41 70.39 42.78 69.56 43.09C68.73 43.41 67.81 43.66 66.78 43.85C65.75 44.04 64.59 44.14 63.3 44.14C61.06 44.14 58.95 43.72 56.99 42.87C55.02 42.02 53.3 40.86 51.83 39.39C50.36 37.92 49.19 36.2 48.33 34.25C47.47 32.29 47.04 30.19 47.04 27.95C47.04 25.71 47.47 23.6 48.33 21.64C49.19 19.67 50.35 17.96 51.83 16.5C53.3 15.04 55.02 13.89 56.99 13.04C58.96 12.19 61.06 11.77 63.3 11.77C65.52 11.77 67.61 12.19 69.56 13.03C71.52 13.87 73.24 15 74.72 16.44C76.2 17.88 77.38 19.57 78.25 21.52C79.12 23.47 79.57 25.55 79.59 27.77C79.59 28.81 79.26 29.65 78.59 30.3C77.92 30.95 77.05 31.27 75.96 31.27H55.26V31.26ZM72.03 25.15C71.78 24.22 71.39 23.39 70.86 22.64C70.33 21.89 69.69 21.26 68.94 20.74C68.19 20.22 67.36 19.82 66.43 19.55C65.5 19.28 64.52 19.14 63.48 19.14C62.44 19.14 61.46 19.28 60.54 19.56C59.62 19.84 58.8 20.25 58.06 20.78C57.32 21.31 56.7 21.95 56.18 22.68C55.66 23.42 55.27 24.24 55.03 25.14H72.03V25.15Z" fill="#212429"/>
                            <path d="M114.05 28.11C114.03 30.31 113.59 32.38 112.73 34.34C111.87 36.3 110.71 38 109.25 39.45C107.79 40.9 106.08 42.05 104.12 42.9C102.16 43.75 100.07 44.17 97.86 44.17C95.62 44.17 93.52 43.75 91.56 42.9C89.6 42.05 87.89 40.89 86.43 39.42C84.97 37.95 83.82 36.23 82.97 34.26C82.12 32.29 81.7 30.2 81.7 27.98C81.7 25.74 82.12 23.63 82.97 21.67C83.82 19.7 84.97 17.99 86.43 16.53C87.89 15.07 89.6 13.92 91.56 13.07C93.52 12.22 95.62 11.8 97.86 11.8C99.49 11.8 101.05 12.03 102.53 12.5C104.01 12.96 105.4 13.61 106.69 14.45V3.84C106.69 3.3 106.78 2.79 106.96 2.33C107.14 1.87 107.4 1.46 107.72 1.11C108.05 0.76 108.43 0.49 108.87 0.3C109.32 0.1 109.81 0 110.35 0C110.87 0 111.36 0.1 111.81 0.29C112.26 0.48 112.65 0.75 112.98 1.1C113.31 1.45 113.57 1.86 113.76 2.32C113.95 2.78 114.05 3.29 114.05 3.83V28.11ZM106.69 27.97C106.69 26.75 106.46 25.6 105.99 24.52C105.53 23.45 104.89 22.51 104.09 21.72C103.29 20.93 102.35 20.3 101.29 19.84C100.23 19.38 99.08 19.14 97.86 19.14C96.64 19.14 95.5 19.37 94.43 19.84C93.37 20.3 92.43 20.93 91.63 21.72C90.83 22.51 90.2 23.45 89.75 24.52C89.3 25.59 89.07 26.74 89.07 27.97C89.07 29.17 89.3 30.31 89.75 31.38C90.2 32.45 90.82 33.39 91.62 34.2C92.41 35 93.35 35.64 94.42 36.1C95.49 36.56 96.64 36.8 97.87 36.8C99.09 36.8 100.23 36.57 101.3 36.1C102.36 35.64 103.3 35 104.1 34.2C104.9 33.4 105.54 32.46 106 31.38C106.46 30.31 106.69 29.17 106.69 27.97Z" fill="#212429"/>
                            <path d="M128.34 40.0901C128.34 40.5901 128.24 41.0601 128.05 41.5201C127.86 41.9701 127.6 42.3601 127.27 42.6901C126.94 43.0201 126.56 43.2801 126.12 43.4701C125.68 43.6601 125.2 43.7601 124.68 43.7601C124.18 43.7601 123.71 43.6601 123.25 43.4701C122.8 43.2801 122.4 43.0201 122.06 42.6901C121.72 42.3601 121.45 41.9701 121.26 41.5201C121.07 41.0701 120.97 40.5901 120.97 40.0901V15.8501C120.97 15.3501 121.07 14.8801 121.28 14.4401C121.48 14.0001 121.76 13.6101 122.09 13.2701C122.43 12.9301 122.83 12.6701 123.28 12.4701C123.73 12.2801 124.22 12.1801 124.74 12.1801C125.26 12.1801 125.74 12.2801 126.17 12.4701C126.6 12.6601 126.98 12.9301 127.31 13.2701C127.64 13.6101 127.89 14.0001 128.07 14.4401C128.25 14.8801 128.34 15.3501 128.34 15.8501V40.0901Z" fill="#212429"/>
                            <path d="M183.16 27.97C183.16 25.73 183.59 23.63 184.45 21.67C185.31 19.71 186.47 18 187.95 16.53C189.42 15.06 191.14 13.9 193.09 13.05C195.05 12.2 197.14 11.78 199.35 11.78C201.59 11.78 203.69 12.2 205.66 13.05C207.63 13.9 209.34 15.06 210.8 16.53C212.26 18 213.41 19.72 214.25 21.67C215.09 23.63 215.51 25.73 215.51 27.97C215.51 30.19 215.09 32.28 214.25 34.25C213.41 36.22 212.26 37.94 210.8 39.41C209.34 40.88 207.63 42.04 205.66 42.89C203.69 43.74 201.59 44.16 199.35 44.16C197.13 44.16 195.04 43.74 193.09 42.89C191.13 42.04 189.42 40.88 187.95 39.41C186.48 37.94 185.31 36.22 184.45 34.25C183.59 32.28 183.16 30.19 183.16 27.97ZM190.53 27.97C190.53 29.17 190.76 30.31 191.23 31.38C191.69 32.45 192.33 33.39 193.13 34.2C193.93 35 194.87 35.64 195.93 36.1C196.99 36.56 198.14 36.8 199.36 36.8C200.58 36.8 201.73 36.57 202.81 36.1C203.88 35.64 204.82 35 205.61 34.2C206.4 33.4 207.02 32.46 207.48 31.38C207.93 30.31 208.16 29.17 208.16 27.97C208.16 26.75 207.93 25.6 207.48 24.52C207.03 23.45 206.4 22.51 205.61 21.72C204.82 20.93 203.88 20.3 202.81 19.84C201.73 19.38 200.59 19.14 199.36 19.14C198.16 19.14 197.02 19.37 195.95 19.84C194.88 20.3 193.94 20.93 193.13 21.72C192.33 22.51 191.69 23.45 191.23 24.52C190.76 25.6 190.53 26.75 190.53 27.97Z" fill="#212429"/>
                            <path d="M219.96 3.84C219.96 3.3 220.06 2.79 220.25 2.33C220.44 1.87 220.7 1.46 221.03 1.11C221.36 0.76 221.75 0.49 222.2 0.3C222.65 0.1 223.14 0 223.66 0C224.2 0 224.7 0.1 225.14 0.29C225.58 0.48 225.97 0.75 226.29 1.1C226.62 1.45 226.87 1.86 227.05 2.32C227.23 2.78 227.32 3.29 227.32 3.83V14.42C228.61 13.58 230 12.93 231.48 12.47C232.96 12.01 234.52 11.77 236.15 11.77C238.39 11.77 240.49 12.19 242.45 13.04C244.41 13.89 246.12 15.04 247.58 16.5C249.04 17.96 250.19 19.67 251.04 21.64C251.89 23.61 252.31 25.71 252.31 27.95C252.31 30.17 251.89 32.26 251.04 34.23C250.19 36.2 249.04 37.92 247.58 39.39C246.12 40.86 244.41 42.02 242.45 42.87C240.49 43.72 238.39 44.14 236.15 44.14C233.93 44.14 231.84 43.72 229.87 42.87C227.9 42.02 226.18 40.86 224.71 39.39C223.24 37.92 222.08 36.2 221.23 34.23C220.38 32.26 219.96 30.17 219.96 27.95V3.84ZM227.32 27.97C227.32 29.17 227.55 30.31 228.02 31.38C228.48 32.45 229.12 33.39 229.92 34.2C230.72 35 231.66 35.64 232.72 36.1C233.78 36.56 234.93 36.8 236.15 36.8C237.37 36.8 238.51 36.57 239.58 36.1C240.64 35.64 241.58 35 242.38 34.2C243.18 33.4 243.81 32.46 244.26 31.38C244.71 30.31 244.94 29.17 244.94 27.97C244.94 26.75 244.71 25.6 244.26 24.52C243.81 23.45 243.18 22.51 242.38 21.72C241.58 20.93 240.64 20.3 239.58 19.84C238.52 19.38 237.37 19.14 236.15 19.14C234.93 19.14 233.79 19.37 232.72 19.84C231.66 20.3 230.72 20.93 229.92 21.72C229.12 22.51 228.48 23.45 228.02 24.52C227.55 25.6 227.32 26.75 227.32 27.97Z" fill="#212429"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_578_65">
                              <rect width="252.31" height="44.16" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                        <div class="flex mt-4 space-x-4">
                              <a href="#" class="footer-social flex items-center justify-center w-10 h-10 rounded-full min-w-10 min-h-10">
                                <box-icon name='facebook' type='logo' color='#ffffff' ></box-icon>
                              </a>
                              <a href="#" class="footer-social flex items-center justify-center w-10 h-10 rounded-full min-w-10 min-h-10">
                                <box-icon name='twitter' type='logo' color='#ffffff'></box-icon>
                              </a>
                              <a href="#" class="footer-social flex items-center justify-center w-10 h-10 rounded-full min-w-10 min-h-10">
                                <box-icon name='linkedin' type='logo' color='#ffffff'></box-icon>
                              </a>
                              <a href="#" class="footer-social flex items-center justify-center w-10 h-10 rounded-full min-w-10 min-h-10">
                                <box-icon name='instagram' type='logo' color='#ffffff'></box-icon>
                              </a>
                        </div>
                    </div>

                    <div class="text-left  sm:col-span-2 md:col-span-1">
                        <h4 class="text-lg font-bold mb-4 footer-section-heading">Medimob</h4>
                        <ul class="text-sm">
                            <li><a href="/about-medimob" class="footer-link py-3">About Medimob</a></li>
                            <li><a href="/for-practitioners" class="footer-link py-3">For Practitioners</a></li>
                            <li><a href="/for-patients" class="footer-link py-3">For Patients</a></li>
                            <li><a href="/blog" class="footer-link py-3">Blog</a></li>
                            <li><a href="/contact-us/complaints" class="footer-link py-3">Complaints</a></li>
                            <li><a href="/contact-us" class="footer-link py-3">Contact</a></li>
                            <hr>
                            <li><a href="/medimob-reviews" class="footer-link py-3">Reviews</a></li>
                            <li><a href="/medimob-peer-endorsements" class="footer-link py-3">Peer Endorsements</a></li>
                        </ul>
                    </div>

                    <div class="text-left  sm:col-span-2 md:col-span-1">
                        <h4 class="text-lg font-bold mb-4 footer-section-heading">Portal</h4>
                        <ul class="text-sm">
                            <li><a href="/login" class="footer-link py-3">Login</a></li>
                            <li><a href="/register" class="footer-link py-3">Register</a></li>
                        </ul>
                        <h4 class="text-lg font-bold mt-5 mb-4 footer-section-heading">Site</h4>
                        <ul class="text-sm">
                          <li><a href="/accessibility-policy" class="footer-link py-3">Accessibility</a></li>
                          <li><a href="https://www.medimob.co.uk/sitemap.xml" class="footer-link py-3">Sitemap</a></li>
                        </ul>
                    </div>
                  <div class="text-left  sm:col-span-2 md:col-span-1">
                    <h4 class="text-lg font-bold mb-4 footer-section-heading">Legal</h4>
                    <ul class="text-sm">
                      <li><a href="/terms-and-conditions" class="footer-link py-3">Terms</a></li>
                      <li><a href="/privacy-policy" class="footer-link py-3">Privacy Policy</a></li>
                      <li><a href="/cookie-policy" class="footer-link py-3">Cookie Policy</a></li>
                    </ul>
                    <!--
                    <h4 class="text-lg font-bold mb-4 footer-section-heading">Help</h4>
                    <ul class="text-sm">
                      <li><a href="/help-centre/providers" class="footer-link py-3">Help for Providers</a></li>
                      <li><a href="/help-centre/general-users" class="footer-link py-3">Help for General Users</a></li>
                    </ul>
                    -->
                  </div>

                </div>
                <div class="copyright-box container mt-4">
                  <p class="mb-0">Medimob Ltd is a company registered in England & Wales (No 14224012) at 167-169 Great Portland Street, London, England, W1W 5PF. | &copy; {{new Date().getFullYear()}} Medimob Ltd. All rights reserved. | Medimob &reg; is a registered trademark.| Medimob &reg; is also registered with the Information Commissioner's Office to protect your data.</p>
                </div>
            </div>

        </div>
    </section>
</template>
<script>
import NewsletterSignupInput from './InputComponents/NewsletterSignupInput.vue'
export default {
    components: { NewsletterSignupInput }
}
</script>
