<style lang="scss">
  @import "../../../sass/components/modal";
  .modal-body {
    padding: 0px !important;
  }
  .disabled-btn {
    background-color: rgb(168, 168, 168)!important;
    color: rgb(125, 125, 125)!important;

  }
</style>

<template>
  <transition name="fade">
    <div class="modal-overlay fixed inset-0 w-full h-screen flex items-center justify-center bg-semi-75" style="background-color:rgba(0,0,0,0.6);z-index:101;" @click="$emit('close')">
      <div class="card-det modal-card  modal modal-wrapper w-full max-w-2xl bg-white shadow-lg rounded-lg addModal"  style="z-index:101;max-height: 600px; overflow-y: auto; position:relative">
        <div class="modal-container" @click.stop>
          <div class="modal">

              <div class="modal-body">
                <!-- here starts dynamic content -->
                <slot name="content"></slot>
                <!-- here ends dynamic content -->
              </div>
              <div class="modal-footer text-justify">
                <slot name="footer">
                  <div class="action-buttons w-full flex container">
                    <div class="my-auto">
                      <a href="" class="modal-default-button cancel-button text-gray-600" @click.prevent="$emit('close')">
                        Cancel
                      </a>
                    </div>
                  </div>
                </slot>
              </div>

          </div>
        </div>         
      </div>
    </div>
  </transition>
</template>

<script>
    export default {
      props: {
        title: String,
        buttonText: String,
        showActionButton: {
          type: Boolean,
          required: false,
          default: true
        },
        show_action_button: {
          type: Boolean,
          default: true
        },
        disabled: {
          type: Boolean,
          default: false
        },
        loading: {
          type: Boolean,
          default: false
        }
      }
    }
</script>

<style scoped>

.action-button{
  width: fit-content !important;
}
</style>