<template>
    <div  v-if="!loadFormdata" class="px-4 mb-4 container">
       
        <div class="flex items-center mt-2">
            <input
            v-model="newTag"
            @keyup.enter="addTag"
            class="border border-gray-300 rounded px-3 py-1"
            placeholder="Tag name"
            />
            <input
            type="color"
            v-model="newTagColor"
            class="ml-2 h-8 w-8 rounded focus:outline-none"
            />
            <button
            @click="addTag"
            class="ml-2 px-3 py-1 rounded bg-blue-500 text-white focus:outline-none"
            >
            Add Tag
            </button>
        </div>
        <div class="flex flex-wrap ">
            <div v-if="tags.length == 0" class="mt-4">
                <span>No conditions added yet</span> 
            </div>
          <div
            v-else
             v-for="(tag, index) in tags"
             :key="index"
             :style="{ backgroundColor: tag.hex_color }"
             class="text-white rounded-full mt-4 px-3 py-1 mr-2 mt-2 flex items-center"
             >
             {{ tag.name }}
             <button
                @click="removeTag(index)"
                class="ml-2 focus:outline-none"
                aria-label="Remove Tag"
                >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-4 w-4">
                   <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
             </button>
          </div>
       </div>
       <div class="mt-4 ma-5">
          <div class="">
             <button :disabled="loadingSubmitButton" @click="submitForm(GET_WORKFLOW.saveFunction)" type="submit" class="px-4 action-button border cursor-pointer rounded-md submit-button ">
             <span v-if="loadingSubmitButton" class="spinner-border" role="status" aria-hidden="true"></span>
             <span v-else>{{ GET_WORKFLOW.saveButton }}</span>  
             </button>
          </div>
       </div>
    </div>
 </template>
<script>
import { mapActions, mapGetters } from 'vuex'
import InputErrorMessage from '../../Utilities/InputErrorMessage.vue'

export default {
    data() {
        return {

            tags: [],
      newTag: '',
      newTagColor: '#424244', 
            form:{},
            formData: {},
            loadFormdata: true,
            showErrors: false,
            loadingSubmitButton: false,
            modalData: {
                name: '',
                hex_color: ''
            }
        }
    },
    components:{InputErrorMessage},
    methods: {
        addTag() {
      if (this.newTag && !this.isTagExist(this.newTag)) {
        const tag = {
          name: this.newTag,
          hex_color: this.newTagColor,
        };
        this.tags.push(tag);
      }else if(this.isTagExist(this.newTag)) {
        Swal.fire({
            toast: true,
            icon: 'error',
            title: 'Condition already added',
            animation: true,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
        });
      }
      this.newTag = '';
    },
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    isTagExist(tagName) {
      return this.tags.some(tag => tag.name.toLowerCase() === tagName.toLowerCase());
    },
        ...mapActions('account', ['loadWorkflow']),
        ...mapActions('satelite', ['saveNewCondition', 'getCondition']),
        ...mapActions('commonFunctions', ['redirectAction']),
        submitForm(saveFnc)
        {
            this.loadingSubmitButton = true
            this.showErrors = true
            if(this.modalData.reason === ''
            || this.modalData.reason_details === ''){
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please fill the form correctly',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
                this.loadingSubmitButton = false
            }
            else {
                eval('this.'+saveFnc+'(this.tags)')
                    .then(res => {
                        Swal.fire({
                            icon: 'success',
                            animation: true,
                            title: "Conditions saved",
                            showConfirmButton: true,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Ok!'
                        })
                        this.triggerConditionsTreated()
                        this.loadingSubmitButton = false
                    }).catch((e) => {
                        this.loadingSubmitButton = false
                    })
            }
        },
        triggerConditionsTreated()
        {

            this.loadFormdata = true
            this.showErrors = false
            this.loadWorkflow('conditionsTreated')
            .then(res => {
                this.loadFormdata = false
            }).catch(e => {
                console.log(e)
            })
        }

    },
    created() {
        this.triggerConditionsTreated()
        this.getCondition()
        .then(res => {
            this.tags = res.data
        })
    },
    computed: {
        ...mapGetters('account', ['GET_WORKFLOW'])
    }
}
</script>

<style lang="scss" scoped>
.action-button{
    width: fit-content;
}
.abort-button{
    height: 52px;
}
</style>