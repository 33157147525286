<template>
    <tr>
        <td class="text-left min-w-0">
            <span class="text-left text-sm font-medium text-gray-900 truncate ">
                {{ onefile.parameter }}
            </span>
        </td>
        <td class="text-left flex-2 min-w-0">

            <span :class="colorClass">{{ compStatus[onefile.status].name }}</span>
        </td>
        <td v-if="onefile.status != 0" class="inline-flex items-center text-base font-semibold text-gray-900 ">
            <a :href="actualUrlDomain + '/storage/uploads/' + onefile.name_of_file" target="_blank">Download</a>
        </td>
        <td v-else-if="onefile.status == 0" class="inline-flex items-center text-base font-semibold text-gray-900 ">
            No file Yet
        </td>
    </tr>
</template>

<script>
import complianceStatus from '../../../constants/practitioners/complianceStatus.js';

  export default {
    props: ['onefile'],
    data() {
        return {
            compStatus: complianceStatus,
            open: true
        }
    },
    computed: {
        colorClass() {
            return 'bg-'+ this.compStatus[this.onefile.status].color +'-100 text-'+ this.compStatus[this.onefile.status].color +'-800 text-xs font-medium px-2.5 py-0.5 rounded  dark:text-gray-700 border border-'+ this.compStatus[this.onefile.status].color +'-400'
        },
        actualUrlDomain() {
            return window.location.origin
        }
    }
  }
  </script>

<style lang="scss" scoped>
td {
    text-align: left;
    padding: 0px 30px;
    margin: 10px;
}
</style>
