<template>
    <div v-if="!loadworkflow" class="py-2 px-5 flex-col">
        <div class="" v-if="GET_ACTIVE_TAB === 0">
          <div class="info-modal-box">
              <p
              class="step-description text-center"
              v-text="GET_WORKFLOW['steps'][GET_ACTIVE_TAB+1]['description']"
              ></p>
          </div>
        </div>
        <div class="modal-form w-full">
          <div class="" v-if="GET_ACTIVE_TAB === 0">
            <div class="flex mb-6">
              <div class="w-1/4 mr-3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW['steps'][1]['components']['title']['inputName']">{{ GET_WORKFLOW['steps'][1]['components']['title']['description'] }}<span class="text-red" v-if="GET_WORKFLOW['steps'][1]['components']['title']['required']">*</span></label>
                <p class="text-gray-500 text-right text-sm" v-if="GET_WORKFLOW['steps'][1]['components']['title']['help']" v-text="GET_WORKFLOW['steps'][1]['components']['title']['help']"></p>

              </div>
              <div class="ml-3 w-2/4">
                <select :name="GET_WORKFLOW['steps'][1]['components']['title']['inputName']" id="" v-model="formData.title" >
                    <option  value=""></option>
                    <option v-for="option in GET_WORKFLOW['steps'][1]['components']['title']['content']" :key="option.title" :value="option">{{option}}</option>
                </select>
                <InputErrorMessage v-if="GET_ERROR_NEXT && (!formData.title || formData.title === undefined)"/>
              </div>
            </div>
            <div class="flex mb-6">
              <div class="w-1/4 mr-3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW['steps'][1]['components']['firstName']['inputName']">{{ GET_WORKFLOW['steps'][1]['components']['firstName']['description'] }}<span class="text-red" v-if="GET_WORKFLOW['steps'][1]['components']['firstName']['required']">*</span></label>
                <p class="text-gray-500 text-right text-sm" v-if="GET_WORKFLOW['steps'][1]['components']['firstName']['help']" v-text="GET_WORKFLOW['steps'][1]['components']['firstName']['help']"></p>

              </div>
              <div class="ml-3 w-2/4">
                  <input
                  :type="GET_WORKFLOW['steps'][1]['components']['firstName']['inputType']"
                  :name="GET_WORKFLOW['steps'][1]['components']['firstName']['inputName']"
                  :placeholder="GET_WORKFLOW['steps'][1]['components']['firstName']['placeholder']"
                  :disabled="formData.use_same_name === true"
                  :required="GET_WORKFLOW['steps'][1]['components']['firstName']['required']"
                  v-model="formData.first_name"
                  >
                <InputErrorMessage v-if="GET_ERROR_NEXT && (!formData.first_name || formData.first_name === undefined)"/>
              </div>
            </div>
            <div class="flex mb-6">
              <div class="w-1/4 mr-3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW['steps'][1]['components']['lastName']['inputName']">{{ GET_WORKFLOW['steps'][1]['components']['lastName']['description'] }}<span class="text-red" v-if="GET_WORKFLOW['steps'][1]['components']['lastName']['required']">*</span></label>
                <p class="text-gray-500 text-right text-sm" v-if="GET_WORKFLOW['steps'][1]['components']['lastName']['help']" v-text="GET_WORKFLOW['steps'][1]['components']['lastName']['help']"></p>

              </div>
              <div class="ml-3 w-2/4">
                  <input
                  :type="GET_WORKFLOW['steps'][1]['components']['lastName']['inputType']"
                  :name="GET_WORKFLOW['steps'][1]['components']['lastName']['inputName']"
                  :placeholder="GET_WORKFLOW['steps'][1]['components']['lastName']['placeholder']"
                  :disabled="formData.use_same_name === true"
                  :required="GET_WORKFLOW['steps'][1]['components']['lastName']['required']"
                  v-model="formData.last_name"
                  >
                <InputErrorMessage v-if="GET_ERROR_NEXT && (!formData.last_name || formData.last_name === undefined)"/>
              </div>
            </div>
          </div>
          <div class="" v-if="GET_ACTIVE_TAB === 1">
            <div class="info-modal-box">
              <p
              class="step-description text-center"
              v-text="GET_WORKFLOW['steps'][2]['description']"
              ></p>
            </div>
            <div class="mt-3">
              <div class="flex mb-1">
                <div class="w-1/4 mr-3">
                  <p class="font-bold text-right">Title</p>
                </div>
                <div class="ml-3 w-2/4">
                  <p class="">{{ formData.title }}</p>
                </div>
              </div>
              <div class="flex mb-1">
                <div class="w-1/4 mr-3">
                  <p class="font-bold text-right">First Name</p>
                </div>
                <div class="ml-3 w-2/4">
                  <p class="">{{ formData.first_name }}</p>
                </div>
              </div>
              <div class="flex mb-1">
                <div class="w-1/4 mr-3">
                  <p class="font-bold text-right">Last Name</p>
                </div>
                <div class="ml-3 w-2/4">
                  <p class="">{{ formData.last_name }}</p>
                </div>
              </div>
              <hr>
                <!--
              <TotalMonthlyPrice :whichCase="'addPractitioner'" />
              -->
            </div>
          </div>
        </div>
    </div>
  </template>

    <script>
    // importing the helpers for calling actions and getters from vuex
    import { mapActions, mapGetters } from 'vuex';
    import TotalMonthlyPrice from '../../Utilities/TotalMonthlyPrice.vue'
    import InputErrorMessage from '../../Utilities/InputErrorMessage.vue'
        export default {
          components: {
            TotalMonthlyPrice,
            InputErrorMessage
          },
            props: {
              formData: {
                type: Object,
              },
              practitioner: {
                default (rawProps) {
                  return {first_name: ''}
                }
              },
            },
            data() {
                return {

                    addClinicModal: false,
                    current_step: 0,
                    errors: [],
                    prices: require('../../../../../public/json/prices.json'),
                    loadworkflow: true
                }
            },
            methods: {
              // calling the actions from the modules
                ...mapActions('mainClinic', ['saveClinic']),

                saveForm(evt)
                {
                  this.$refs.form.submit()
                }

            },
            computed: {
              // ...mapGetters('profile', ['getUserSateliteClinics']),
              ...mapGetters('practitioner', ['GET_ALL_PRACTITIONERS']),
              ...mapGetters('mainClinic', ['GET_WORKFLOW', 'GET_WORKFLOW_STEPS', 'GET_ACTIVE_TAB', 'MAIN_CLINIC', 'GET_SATELITE_CLINICS', 'GET_ERROR_NEXT']),
            },
            watch: {
              GET_WORKFLOW: {
                  handler(newValue) {
                  this.loadworkflow = false
                  }
              }
            }

        }
    </script>

    <style scoped>

    </style>
