<template>
    <div class="text-center container w-full section-separation items-center">

        <div class="flex-col">
            <div class="why-choose-paragraph container title title-complex pt-2 pb-6">
                <h3 class="font-bold howto-title">We're Here To Help</h3>
                <h3 class="font-extrabold howto-sub-title font-bold">Have Questions?</h3>
            </div>

            <div class="mt-4">

                <button v-for="(section, index) in sections" :key="index" class="faq-accordion accordion" @click="toggleSection(index)">
                    <span>
                        {{ section.title }}
                    </span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#0BD2A4"
                        viewBox="0 0 24 24"
                        :class="section.active && 'rotate-180'"
                        class="float-right w-4 h-4"
                        >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                        />
                        </svg>

                    <div :key="'panel-' + index" class="panel" :style="{ display: section.active ? 'block' : 'none' }">
                        <p>{{ section.content }}</p>
                    </div>
                </button>

            </div>
        </div>
    </div>
  </template>

  <script>
  export default {
    data() {
      return {
        sections: [
          {
            title: "Who's Medimob for?",
            content: 'Medimob is designed for everyone seeking accessible and convenient healthcare. Whether you’re a\n' +
                'busy professional, a parent, or anyone with healthcare needs, Medimob welcomes you. Our\n' +
                'platform connects you with a diverse range of healthcare practitioners to ensure personalized and\n' +
                'timely care for all.',
            active: true
          },
          {
            title: 'Is Medimob free?',
            content: 'Yes! Signing up to Medimob is free of charge.',
            active: false
          },
          {
            title: 'Can I book appointments on Medimob?',
            content: 'Sure! With Medimob, you can easily make appointments with healthcare providers. If they have\n' +
                'their own booking system, you can quickly choose a time and date for your virtual or in-person visit.\n' +
                'Even if they don&#39;t have a booking system, no worries! You can reach out and schedule an\n' +
                'appointment using their contact details (email, phone, etc.). Medimob offers flexible options so you\n' +
                'can conveniently book the healthcare services you need.',
            active: false
          },
          {
            title: 'I am a Clinic/Practitioner, Can I Join Medimob??',
            content: 'Yes, absolutely! Medimob welcomes dedicated healthcare practitioners to join our platform. By\n' +
                'becoming a part of Medimob, you gain access to a diverse patient base and the opportunity to\n' +
                'provide virtual consultations and home services. Join us in redefining healthcare accessibility and\n' +
                'offering your expertise to those seeking convenient and personalized medical care.',
            active: false
          }
        ]
      };
    },
    methods: {
      toggleSection(index) {
        this.sections.forEach((section, i) => {
          section.active = i === index ? !section.active : false;
        });
      }
    }
  };
  </script>

  <style lang="scss" scoped>
      .accordion {
        margin: 20px;
        padding: 18px 60px;
        color: #444;
        cursor: pointer;
        width: 90%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 15px;
        transition: 0.4s;
        border-radius: 30px;
        background: #FFF;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
        span {
            color: #0BD2A4;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;

            @media (min-width: 1200px) {
                font-size: 23px;
            }

            @media (min-width: 992px) {
                font-size: 21px;
            }

            @media (min-width: 768px) {
                font-size: 19px;
            }
        }
        .panel{
            margin: 10px 0px 0px;
            display: none;
            background-color: white;
            overflow: hidden;

            p {
                color: #212429;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
            }
        }
      }
  </style>
