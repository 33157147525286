<template>
<div class="container-fluid">
    <div class=" flex">
        <div class="left-container-img-box left w-full">
            <img class="background-patterns" src="../../../../public/images/curved14.jpg" alt="medimob-background-wave-pattern">
            <div class="flex h-full">
                <div class="img-bg-section rounded-md my-auto flex-col">
                    <img class="background-illustration" src="../../../../public/images/illustrations/3d/rocket-white.png" alt="">
                    <div class="left-section-title-wrapper">
                        <h4>{{ GET_GUEST_WORKFLOW.title }}</h4>
                    </div>
                    <div class="left-section-description-wrapper">
                        <p>{{ GET_GUEST_WORKFLOW.descritpion }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!loadingRegister" class="right w-full flex">
            <div class="stepper-header tabs flex w-full scroll-x my-auto">
                <div class="mx-auto">
                    <div class="card">
                         <div class="card-body">
                            <form method="POST"  @submit.prevent="submitForm(GET_GUEST_WORKFLOW.saveFunction)" class="modal-form">
                                <div class="row mb-3">
                                    <label :for="GET_GUEST_WORKFLOW.componentsGroup['clinicName']['inputName']" class="block text-gray-500 font-bold mb-1 pr-4 my-auto">{{ GET_GUEST_WORKFLOW.componentsGroup['clinicName']['title'] }}</label>

                                    <div>
                                        <!-- is-invalid  -->
                                        <input :id="GET_GUEST_WORKFLOW.componentsGroup['clinicName']['inputName']" v-model="form[GET_GUEST_WORKFLOW.componentsGroup['clinicName'].inputName]" :type="GET_GUEST_WORKFLOW.componentsGroup['clinicName']['inputType']" class="appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500 form-control  " :name="GET_GUEST_WORKFLOW.componentsGroup['clinicName']['inputName']" :required="GET_GUEST_WORKFLOW.componentsGroup['clinicName']['required']" autofocus>
                                        <small class="my-2">* If you are an individual, you can use your full name.</small>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <label for="email" class="block text-gray-500 font-bold mb-1 pr-4 my-auto">{{GET_GUEST_WORKFLOW.componentsGroup['email'].title}}</label>

                                    <div>
                                        <input :id="GET_GUEST_WORKFLOW.componentsGroup['email'].inputName" v-model="form[GET_GUEST_WORKFLOW.componentsGroup['email'].inputName]" :type="GET_GUEST_WORKFLOW.componentsGroup['email'].inputType" class="appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500 form-control" :name="GET_GUEST_WORKFLOW.componentsGroup['email'].inputName" :required="GET_GUEST_WORKFLOW.componentsGroup['email'].required">

                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <label :for="GET_GUEST_WORKFLOW.componentsGroup['firstName'].inputName" class="block text-gray-500 font-bold mb-1 pr-4 my-auto">{{GET_GUEST_WORKFLOW.componentsGroup['firstName'].title}}</label>

                                    <div>
                                        <input :id="GET_GUEST_WORKFLOW.componentsGroup['firstName'].inputName" v-model="form[GET_GUEST_WORKFLOW.componentsGroup['firstName'].inputName]" :type="GET_GUEST_WORKFLOW.componentsGroup['firstName'].inputType" class="appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500 form-control " :name="GET_GUEST_WORKFLOW.componentsGroup['firstName'].inputName"  :required="GET_GUEST_WORKFLOW.componentsGroup['firstName'].required">

                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <label :for="GET_GUEST_WORKFLOW.componentsGroup['lastName'].inputName" class="block text-gray-500 font-bold mb-1 pr-4 my-auto">{{GET_GUEST_WORKFLOW.componentsGroup['lastName'].title}}</label>

                                    <div>
                                        <input :id="GET_GUEST_WORKFLOW.componentsGroup['lastName'].inputName" v-model="form[GET_GUEST_WORKFLOW.componentsGroup['lastName'].inputName]"  :type="GET_GUEST_WORKFLOW.componentsGroup['lastName'].inputType" class="appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500 form-control " :name="GET_GUEST_WORKFLOW.componentsGroup['lastName'].inputName" :required="GET_GUEST_WORKFLOW.componentsGroup['lastName'].required">

                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <label :for="GET_GUEST_WORKFLOW.componentsGroup['password'].inputName" class="block text-gray-500 font-bold mb-1 pr-4 my-auto">{{ GET_GUEST_WORKFLOW.componentsGroup['password'].title }}</label>

                                    <div>
                                        <input id="password" v-model="form[GET_GUEST_WORKFLOW.componentsGroup['password'].inputName]" :type="GET_GUEST_WORKFLOW.componentsGroup['password'].inputName" class="appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500 form-control " :name="GET_GUEST_WORKFLOW.componentsGroup['password'].inputName" :required="GET_GUEST_WORKFLOW.componentsGroup['password'].required">

                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <label :for="GET_GUEST_WORKFLOW.componentsGroup['confirmPassword'].inputName" class="block text-gray-500 font-bold mb-1 pr-4 my-auto">{{ GET_GUEST_WORKFLOW.componentsGroup['confirmPassword'].title }}</label>

                                    <div>
                                        <input :id="GET_GUEST_WORKFLOW.componentsGroup['confirmPassword'].inputName" v-model="form[GET_GUEST_WORKFLOW.componentsGroup['confirmPassword'].inputName]" :type="GET_GUEST_WORKFLOW.componentsGroup['confirmPassword'].inputType" class=" appearance-none border rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500" :name="GET_GUEST_WORKFLOW.componentsGroup['confirmPassword'].inputName" :required="GET_GUEST_WORKFLOW.componentsGroup['confirmPassword'].required">
                                    </div>
                                </div>
                                <password-validator @readyTosend="readyTosend" :password="form[GET_GUEST_WORKFLOW.componentsGroup['password'].inputName]" :confirm_password="form[GET_GUEST_WORKFLOW.componentsGroup['confirmPassword'].inputName]"></password-validator >

                                    <br>
                                <div class="row mb-3">
                                    <div class="flex">
                                        <input :id="GET_GUEST_WORKFLOW.componentsGroup['agreeWithTerms'].inputName" v-model="form[GET_GUEST_WORKFLOW.componentsGroup['agreeWithTerms'].inputName]" :type="GET_GUEST_WORKFLOW.componentsGroup['agreeWithTerms'].inputType" class="cursor-pointer border rounded text-gray-700" :name="GET_GUEST_WORKFLOW.componentsGroup['agreeWithTerms'].inputName" :required="GET_GUEST_WORKFLOW.componentsGroup['agreeWithTerms'].required" style="width: 20px; height: 20px;">
                                        <label :for="GET_GUEST_WORKFLOW.componentsGroup['agreeWithTerms'].inputName" class="block text-gray-500 font-bold mb-1 ml-3 my-auto pb-0 cursor-pointer" v-html="GET_GUEST_WORKFLOW.componentsGroup['agreeWithTerms'].title"></label>
                                    </div>
                                </div>
                              <div class="cf-turnstile my-4 w-full"
                                   :data-sitekey="cfkey"
                                   data-callback="onTurnstileSuccess"
                              ></div>
                                <div class="form-footer">
                                    <div class="">

                                        <button type="submit" :disabled="isLoading" class="action-button ml-auto border cursor-pointer rounded-md submit-button w-full" :class="{'!bg-emerald-400': isLoading}">
                                           <span v-if="isLoading" class="w-full py-2 text-center">Creating Profile ...</span>
                                           <span v-else>{{ GET_GUEST_WORKFLOW.saveButton }}</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from "../Loader.vue";

    export default {
        props: ['registration-workflow', 'cfkey'],
        components: {Loader},
        data() {
            return {
              form: {},
              ReadyStatus: false,
              loadingRegister: true,
                isLoading: false
            }
        },
        methods: {
            ...mapActions('guestFunctions', ['loadGuestWorkflow']),
            ...mapActions('auth', ['register']),
            readyTosend(ReadyStatus) {
                this.ReadyStatus = ReadyStatus
            },
            submitForm(saveFnc)
            {
                this.isLoading = true;
                if(this.form['password'] !== this.form['password-confirm'] ) {
                    this.isLoading = false;
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Passwords do not match',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    });
                }else if(this.ReadyStatus === 'false'){
                    this.isLoading = false;
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Please respect the password rules',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    });
                }
                else {
                    eval('this.' + saveFnc + '(this.form)')
                        .then(res => {
                            this.isLoading = false;
                            Swal.fire({
                            toast: true,
                            icon: res.status === 201 ? 'success' : 'error',
                            title: res.status === 201 ? "You're Registered" : 'Something went wrong. Contact support',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: res.status === 201 ? 1500 : 1500,
                            timerProgressBar: true
                            });


                            window.location.href = '/setup/filling-informations';

                        })
                        .catch(() => {
                            this.isLoading = false;
                            Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: 'Something went wrong. Contact support',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true
                            });
                        });
                }
            }
        },
        created() {
            this.loadGuestWorkflow('register')
            .then(() => {
                this.loadingRegister = false
            }).catch(err => {
                this.loadingRegister = false
            })
        },
        computed: {
        // getting the postcode stored in vuex
        ...mapGetters('guestFunctions', ['GET_GUEST_WORKFLOW']),
        },
    }
</script>

<style lang="scss" scoped>
</style>
