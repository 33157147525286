<template>
  <div class="py-2 md:px-5 flex-col mt-5">
    <div class="row justify-content-center">
        <div class="info-modal-box w-full">
          <!-- progress stepper section -->
          <div class="stepper-section w-8/12 p-4 mx-auto mb-3 !hidden flex !md:flex" v-if="[1,2,3,4,5]">
              <progress-stepper-vertical :actualStepStop="actualStep" :steps="allSteps" :actualStep="actualStepContent" ></progress-stepper-vertical>
            </div>
            <div class="setup-content w-10/12 md:w-8/12 mt-5 mx-auto card shadow-md rounded-2xl">
              <div class="w-full">
                <div class="card h-full">
                  <form  @submit.prevent="executeDynamic(actualStepContent.saveFunction)">
                    <div class="card-header">
                      <h6 class="text-lg font-weight-bolder m-0">{{actualStepContent.pageTitle}} </h6>
                    </div>
                    <div class="card-body h-full py-4">
                      <p class="text-center mt-2">{{actualStepContent.description}}</p>
                      <div class="p-3" v-for="(input,indexx) in actualStepContent.components"  :key="input.id" >
                        <label class="block text-gray-500 font-bold mb-1 pr-4 my-auto" :name="input.inputName">{{ input.description }}</label>
                        <div v-if="input.inputType === 'file' && indexx === 'clinicLogo'" class="flex items-center">
                          <img v-if="!user_clinic_data.img.toLowerCase().includes('default')" class="roundImage mr-4 ml-2 w-20 bg-light" width="100" height="100" :src="user_clinic_data.img" />
                          <span @click="openUpdateModal(user_clinic_data.img, user_clinic_data.id, indexx)" class="cursor-pointer action-button py-2 px-4 border rounded-md">
                            <span v-if="user_clinic_data.img.toLowerCase().includes('default')">Upload Image</span>
                            <span v-else>Upload another image</span>
                          </span>
                        </div>
                        <div v-else-if="input.inputType === 'file'" class="flex items-center">
                          <img v-if="!user_practitioner_data[0].img.toLowerCase().includes('default')" class="roundImage mr-4 ml-2 w-20 bg-light" width="100" height="100" :src="user_practitioner_data[0].img" />
                          <span @click="openUpdateModal(user_practitioner_data[0].img, user_practitioner_data[0].id)" class="cursor-pointer action-button py-2 px-4 border rounded-md">
                            <span v-if="user_practitioner_data[0].img.toLowerCase().includes('default')">Upload Image</span>
                            <span v-else>Upload another image</span>
                          </span>
                        </div>


                        <div v-else-if="input.inputType === 'select'">
                          <select v-model="form[indexx]" class="appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500" :required="input.required">
                            <option value=""></option>
                            <!-- looping through the options of different elements -->
                            <template v-for="(oneontent,index) in input.content" >
                              <!-- if the element contains the attribute sigular , show it -->
                              <option :value="index" v-if="(typeof oneontent.singular !== 'undefined')" >{{oneontent.singular}}</option>
                              <!-- if not just show normally -->
                              <option v-else :value="index">{{input.content[index]}}</option>
                            </template>
                           </select>
                        </div>
                        <div v-else>
                          <!-- checking if the input is a postcode input  -->
                          <div v-if="input.inputName === 'postcode'" class="relative">
                            <div class="relative mt-2">
                              <input
                                v-model="form.postcode"

                                @change="formatPostcode"

                                type="text"
                                class="py-2 px-4 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                                autocomplete="off"
                                @input="handleInput"
                              />
                              <div v-if="showResults" class="absolute z-10 bg-white border border-gray-300 rounded-lg mt-1 w-full">
                                <div
                                  v-for="(result, index) in autocompleteOptions"
                                  :key="index"
                                  class="py-2 px-4 cursor-pointer hover:bg-blue-100"
                                  @click="selectResult(result)"
                                >
                                  {{ result }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- end if the input is a postcode input  -->
                          <!-- checking if the input is a postcode input  -->
                          <div v-else-if="input.inputName === 'city'" class="relative">
                            <div class="relative mt-2">
                              <input
                                v-model="form.city"
                                type="text"
                                class="py-2 px-4 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                                autocomplete="off"
                                :disabled="input.disabled"
                                @input="handleCityInput"
                              />
                              <div v-if="showCityResults" class="absolute z-10 bg-white border border-gray-300 rounded-lg mt-1 w-full">
                                <div
                                  v-for="(result, index) in autocompleteCityOptions"
                                  :key="index"
                                  class="py-2 px-4 cursor-pointer hover:bg-blue-100"
                                  @click="selectCityResult(result.display_name)"
                                >
                                  {{ result.display_name }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- end if the input is a postcode input  -->

                          <input v-else :placeholder="input.placeholder" :type="input.inputType" v-model="form[input.inputName]" :class="input.inputType !== 'checkbox' && 'appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500'" :required="input.required"  />
                        </div>
                        <div v-if="input.help.length > 0 ">hint: {{input.help}}</div>

                      </div>
                    </div>

                    <div class="card-footer text-justify md:flex">

                      <button type="button" v-if="actualStepContent.resendCodeFunction && actualStepContent.resendCodeFunction.length > 0" :disabled="pausedFor30Seconds" @click="manageResendCodeButton()"  class="py-2 px-4 border rounded-md  cursor-pointer">
                        <span v-if="pausedFor30Seconds" role="status" aria-hidden="true">{{ timeWaited }}s left, wait to resend</span>
                        <span v-else>{{ actualStepContent.resendCode }}</span>
                      </button>
                      <button type="button" v-if="actualStep > 2" @click="goBack()"  class="py-2 px-4 border rounded-md  cursor-pointer">
                        <span v-if="loadingGoingBack" class="spinner-border" role="status" aria-hidden="true"></span>
                        <span v-else>Go back</span>
                      </button>
                      <button type="submit" :disabled="nextButtonLoading" class="action-button md:ml-auto py-2 px-4 border rounded-md cursor-pointer next mt-3 md:mt-0">
                        <span v-if="nextButtonLoading" class="spinner-border" role="status" aria-hidden="true"></span>
                        <span v-else>{{ actualStepContent.nextButton }}</span>
                      </button>

                    </div>
                  </form>

                <div>
              </div>
            </div>
          </div>
        </div>

        </div>
    </div>
    <UpdateProfileImagesModal @closeModal="closeModal" v-if="UpdateProfileImagesModal" :idConcerned="idConcerned" :pictureConcerned="practitionerImagePath" :chosenModal="modalId"/>

  </div>
</template>

<script>
// importing the helpers for calling actions and getters from vuex
import Vue from 'vue';

import UpdateProfileImagesModal from '../../Clinics/UpdateProfileImagesModal.vue';
import ProgressStepperVertical from '../../../Utilities/ProgressStepperVertical.vue';
import { mapActions, mapGetters } from 'vuex';


import Select2 from 'v-select2-component';
    export default {
        components:{ProgressStepperVertical, Select2, UpdateProfileImagesModal},
        props: ['actualStepContentProp', 'clinicName', 'allSteps', 'user_practitioner_data', 'user_clinic_data'],
        data() {
            return {
              loadingGoingBack: false,
              // attributes necessary for the city autocomplete
              showCityResults: false,
              autocompleteCityOptions: [],

              // attributes necessary for the poscode autocomplete
              showResults: false,
              autocompleteOptions: [],

              form: {},
              userFuncs: {},
              actualStep: null,
              // loading for the submit buttons
              nextButtonLoading: false,

              // 30 seconds timer for the resend code
              pausedFor30Seconds: false,
              timeWaited: 30,
              timerEnabled: false,

              // getting actualStep content
              actualStepContent: this.actualStepContentProp,

              // practitioner image
              UpdateProfileImagesModal: false,
              practitionerImagePath: '',
              idConcerned: null,
              modalId: null
            }
        },


        created () {
            // getting the user data with the actual step
            this.getUserData()
                .then(response => {
                  this.actualStep = response.data.actual_setup_step
                  // calling the action that gets the user clinic headoffice and applying the result to the form
                  this.getUserClinicHeadofficeData()
                    .then(response => {
                      if(this.actualStep == 4) {
                        this.form = response.data[0]
                      }else if(this.actualStep != 1) {
                        this.form = this.GET_USER_DATA
                        this.form.img = this.GET_USER_DATA.image_path
                      }
                  })
                })
            // calling the function to initialize the methods from the workflow
            this.initializingDynamicFunctions()

            this.$set(this.userFuncs, this.actualStepContent.loadFunction, () => {
              // console.log(this.actualStepContent.loadFunction)
            })
            this.$set(this.userFuncs, this.actualStepContent.backFunction, () => {
              // console.log(this.actualStepContent.backFunction)
            })
            this.executeDynamic(this.actualStepContent.loadFunction)
            // this.form.first_name = this.GET_USER_DATA.first_name
            // this.form.last_name = this.GET_USER_DATA.last_name
            // setting the form details
            // this.form = this.GET_USER_DATA

        },

        methods: {
          ...mapActions('commonFunctions', ['formatPostcodeAction']),
          ...mapActions('setup', ['manageStep', 'getUserData', 'getUserClinicHeadofficeData', 'manageResendCodeButtonAction']),
          executeDynamic (name) {
            if (this.userFuncs[name]) {
              this.userFuncs[name]()
            } else {
              console.warn(`${name} was not yet defined!`)
            }
          },
          goBack() {
            this.loadingGoingBack = true
            axios.post('/setup/manage-setup-go-back')
            .then(res => {
              window.location.reload();
            })
          },
          initializingDynamicFunctions() {

            this.$set(this.userFuncs, this.actualStepContent.saveFunction, () => {
                this.nextButtonLoading = true
              // calling the manage step from setup api
              this.manageStep(this.form)
              .then(response => {
                // getting the user updated data
                this.getUserData()
                .then(response => {
                  // setting up the new content
                  this.actualStepContent = this.allSteps[response.data.actual_setup_step]
                  this.nextButtonLoading = false
                  this.actualStep = response.data.actual_setup_step

                  // setting up the form details
                  this.getUserClinicHeadofficeData()
                    .then(response => {
                      if(this.actualStep == 4) {
                        this.form = response.data[0]
                      }else if(this.actualStep != 1)  {
                        this.form = this.GET_USER_DATA
                        this.form.img = this.GET_USER_DATA.image_path
                      }
                      // calling the format poscode
                      this.formatPostcode()
                  })

                  if(response.data.actual_setup_step > 5) {
                    window.location.href = '/home'
                  }
                  Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Saved!',
                      showConfirmButton: false,
                      timer: 1500
                    })
                  // setting the new content
                  this.initializingDynamicFunctions()
                }).catch(error => {
                  console.log(error)
                })
              }).catch(error =>{
                this.nextButtonLoading = false
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: this.actualStepContent.failMessage,
                  showConfirmButton: false,
                  timer: 1500
                })
              })
            })
          },
          // resend verification code function
          manageResendCodeButton() {
            this.manageResendCodeButtonAction()
            .then(() => {
              // turning on the timer for resend code
              this.pausedFor30Seconds = true
              this.timeWaited = 30
              this.timerEnabled = true
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Code sent!',
                    showConfirmButton: false,
                    timer: 3000
                  })
              // setting the new content
            }).catch(error => {
              console.log(error)
            })

          },
          formatPostcode() {
            // if there are no options from what the user typed then check if it's an acceptable postcode
            if(this.autocompleteOptions === null){
              // calling the postcode action from vuex and assigning the value got from the Getter to the local variable
              this.formatPostcodeAction(this.form.postcode)
              this.form.postcode = this.GET_POSTCODE
            }


          },
          openUpdateModal(practitionerImagePath, idConcerned, indexx = ''){
              this.UpdateProfileImagesModal = true
              this.practitionerImagePath = practitionerImagePath
              this.idConcerned = idConcerned
              if(indexx === 'clinicLogo'){
                this.modalId = 'updateClinicLogo'
              }else{
                this.modalId = 'updatePractitionerImage'
              }
          },
          closeModal(){
              this.updateCreateClinicModal = false
          },

          // method to handle the postcode input
          async handleInput() {
            if (this.form.postcode.trim() === '') {
              this.showResults = false;
            } else {
              try {
                const response = await axios.get(`https://api.postcodes.io/postcodes/${encodeURIComponent(this.form.postcode)}/autocomplete`);
                this.autocompleteOptions = response.data.result;
                this.showResults = true;
              } catch (error) {
                console.error(error);
              }
            }
          },
          selectResult(result) {
            this.form.postcode = result;

            axios.get(`/postcode-get-info/${encodeURIComponent(this.form.postcode)}`)
            .then(res => {
              if (res.data.status === 200) {
                const adminDistrict = res.data.result.admin_district;

                if (adminDistrict.toLowerCase().includes('city of')) {
                  const splittedDistrict = adminDistrict.split(',')[0].trim();
                  this.form.city = splittedDistrict;
                }else if (adminDistrict.toLowerCase().includes('city')) {
                  const splittedDistrict = adminDistrict.split('City')[0].trim();
                  this.form.city = splittedDistrict;
                } else {
                  this.form.city = adminDistrict;
                }
              } else if (res.data.status === 404) {
                this.form.city = '';
              }
            })
            this.showResults = false;
          },

          // method to handle the city input
          async handleCityInput() {
            const city = this.form.city.trim();
            if (city === '' || city.includes('/')) {
              this.showCityResults = false;
            } else {
              try {
                const response = await axios.get('/setup/get-locations/' + city);
                this.autocompleteCityOptions = response.data;
                this.showCityResults = true;
              } catch (error) {
                console.error(error);
              }
            }
          },
          selectCityResult(result) {
            this.form.city = result;
            this.showCityResults = false;
          },


        },
        watch: {
          // watching if the timer has launched
          timerEnabled(value) {
                if (value) {
                    setTimeout(() => {
                        this.timeWaited--;
                    }, 1000);
                }
            },
            // reducing 1 number each second
            timeWaited: {
                handler(value) {

                    if (value > 0 && this.timerEnabled) {
                        setTimeout(() => {
                            this.timeWaited--;
                        }, 1000);
                    }else {
                      this.pausedFor30Seconds = false
                    }

                },
                immediate: true // This ensures the watcher is triggered upon creation
            }
        },
        computed: {
          ...mapGetters('commonFunctions', ['GET_POSTCODE']),
          ...mapGetters('setup', ['GET_USER_DATA', 'GET_USER_CLINIC_HEADOFFICE_DATA'])
        },
        mounted() {
          document.addEventListener('click', (event) => {
            if (!this.$el.contains(event.target)) {
              this.showResults = false;
            }
          });
          document.addEventListener('click', (event) => {
            if (!this.$el.contains(event.target)) {
              this.showCityResults = false;
            }
          });
        },
    }
</script>

<style lang="scss" scoped>
.action-button{
  width: fit-content;
}
.back{
  float: left;
  margin: -70px auto 20px 20px;
}
</style>

<style lang="scss" scoped>
.roundImage{
    width: 300px !important;
    border-radius: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4), 0px 8px 15px rgba(0, 0, 0, 0.2);
}
</style>
