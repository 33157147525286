import axios from 'axios';
import { defaultsDeep } from 'lodash';
export default {
    namespaced: true,
    state: {
        services: {},
        tabs: {},
        active_tab: ''

    },
    getters: {
        GET_ACTIVE_TAB(state)
        {
            return state.active_tab
        },
    },
    actions:{

        // save services
        saveService({commit}, payload) {
            axios.post('/dashboard/services/add-service', payload)
            .then((res) => {
                window.location="/dashboard/services/view-service/"+res.data.clinic_ref+'/'+res.data.ref;
                // redirect users to service page
            }).catch((e) => {
                console.log(e)
            });
        },
        saveServiceSuggestionAction(context, payload) {
            return new Promise((resolve, reject) => {
                axios.post('/dashboard/services/saveServiceSuggestionAction', payload)
                .then((res) => {
                    resolve(res)
                    // redirect users to service page
                }).catch((e) => {
                    reject(e)
                    console.log(e)
                });
            })
        },
        // update services
        updateService({commit}, payload) {

            axios.post('/dashboard/services/update-service/'+payload.ref, payload)
            .then(() => {
                window.location.reload();
            }).catch((e) => {
                console.log(e)
            });
        },

        // add extra
        addExtra({commit}, payload) {
            axios.post('/dashboard/services/add-service-extra/'+payload.parent_service_ref, payload)
            .then(() => {
                window.location.reload();
            }).catch((e) => {
                console.log(e)
            });
        },

        // edit extra
        editExtra({commit}, payload) {
            axios.post('/dashboard/services/edit-service-extra/'+payload.id, payload)
            .then(() => {
                window.location.reload();
            }).catch((e) => {
                console.log(e)
            });
        },

        // delete service
        deleteService({commit}, payload)
            {
                Swal.fire({
                title: "You're deleting this service",
                text: "Are you sure you want to delete this service?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete('/dashboard/services/delete-service/'+payload)
                    .then(() => {
                        Swal.fire({
                            toast: true,
                            icon: 'success',
                            title: 'Service deleted',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        }).then(() => {
                            window.location.reload()
                        })
                    })
                }
              }).catch((e) => {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: e.response.status+' Error.',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true
                });
            });
        },

        // pulls practitioner
        setUpPractitionerPage({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.post('/dashboard/practitioners/setup-practitioner-page/'+ payload)
                .then((res) => {
                    resolve(res)
                    commit('SET_PRACTITIONER_DETAILS', res.data)
                }).catch((e) => {
                    reject(e)
                    console.log(e)
                });
            })
        },
        setUrlTab({commit}, payload)
        {
            let a_tab = '';
            if (window.location.search.includes('Services')){
                commit('SET_ACTIVE_TAB', 'Services')
            }
        },
        // Change tab
        changeActiveTab({commit, dispatch}, payload)
        {

            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('tab', payload);
            const newUrl = window.location.origin + window.location.pathname + '?' + searchParams;
            window.history.pushState({path:newUrl},'',newUrl);

            commit('SET_ACTIVE_TAB', payload);
        },
    },
    mutations: {
        SET_ACTIVE_TAB(state, tab)
        {
            state.active_tab = tab
        },
    },

}
