// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/cjs.js??clonedRuleSet-12.use[1]!../../../../../../node_modules/vue-select/dist/vue-select.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "input[data-v-39eae32a],svg[data-v-39eae32a],textarea[data-v-39eae32a]{cursor:pointer}.action-button[data-v-39eae32a]{width:-moz-fit-content!important;width:fit-content!important}", "",{"version":3,"sources":["webpack://./resources/js/components/Dashboard/Reviews/NewReviewsFolder/ReviewRequest.vue"],"names":[],"mappings":"AAGA,sEACI,cADJ,CAGA,gCACI,gCAAA,CAAA,2BAAJ","sourcesContent":["\n\n@import \"vue-select/dist/vue-select.css\";\ninput, textarea , svg{\n    cursor: pointer;\n}\n.action-button{\n    width: fit-content !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
