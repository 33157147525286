<style lang="scss">
  @import "../../../../sass/components/modal";
</style>

<template>
    <transition name="fade">
       <div class="modal-overlay fixed inset-0 w-full h-screen flex items-center justify-center bg-semi-75" style="background-color:rgba(0,0,0,0.6);z-index:99;" @click="$emit('closeModal')">
          <div class="card-det modal-card  modal modal-wrapper w-full max-w-2xl bg-white shadow-lg rounded-lg addModal"  style="z-index:99;max-height: 600px; overflow-y: auto; position:relative">
             <div class="modal-container" @click.stop>
                <div class="modal">
                   <!--<Loader v-if="GET_LOADER"/>-->
                   <div class="modal-header block-title">
                      <slot name="header">
                         <div class="header-bit w-full flex">
                            <!-- placing the header we got from the computed property -->
                            <p class="card-title font-bold uppercase my-auto flex-grow mb-0"> {{ GET_WORKFLOW['title'] }}</p>
                            <a href="">
                               <svg xmlns="http://www.w3.org/2000/svg"  @click="$emit('closeModal')" class=" ml-auto h-9 w-9 pt-3 pr-4 close-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                               </svg>
                            </a>
                         </div>
                      </slot>
                   </div>
                   <div v-if="this.imageSrc" class="modal-content">
                      <div class="text-center modal-body">
                        <div :class="!originalImageShow && 'originalImageShow'" class="form-input p-5" style="display: flex; align-items: center; justify-content: center;">
                            <div v-if="this.imageSrc">
                                <vue-cropper
                                    class="mr-2 w-50"
                                    ref="cropper"
                                    :minContainerWidth="350"
                                    :minContainerHeight="350"
                                    :aspectRatio="1/1"
                                    :zoomable="false"
                                    :guides="true"
                                    :src="imageSrc"
                                    ></vue-cropper>
                                <div class="w-100"></div>
                                <!-- Cropped image previewer -->
                            </div>
                        </div>
                            <button class="btn btn-secondary" v-if="this.imageSrc && originalImageShow" @click="cropImage">Set image</button>
                        <div class="text-center">
                            <img class="roundImage ml-2 w-50 bg-light" v-if="!originalImageShow && croppedImageSrc.length > 0" width="400" height="400" :src="croppedImageSrc" />
                            <button class="mt-2 btn btn-secondary" v-if="!originalImageShow && croppedImageSrc.length > 0" @click="showOriginalImage">Crop</button>
                        </div>
                      </div>
                   </div>
                   <div v-else class="center" style="text-align: center;">
                      <div class="card">
                         <img class="py-2 px-4  card-img-top avatar" :src="pictureConcerned" alt="Card image cap">

                            <div v-if="!pictureConcerned.toLowerCase().includes('default')"  class="d-flex justify-content-evenly">
                                <button @click="cropExistingImage" class="btn btn-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M5 17c0 1.1.9 2 2 2h10v4h2v-4h4v-2H7V1H5v4H1v2h4v10zm14-2V7c0-1.1-.9-2-2-2H9v2h8v8h2z"/></svg>
                                </button>
                                <button @click="deleteExistingImage" class="btn btn-danger">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/></svg>
                                </button>

                            </div>
                         <div v-if="!loadingWorkflow" class="card-body">
                            <label for="file-upload" class="custom-file-upload">
                            Upload Picture
                            </label>
                            <input  id="file-upload" class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500"  accept="image/*" @change="setImage" :type="GET_WORKFLOW.components['profilePicture']['inputType']" :name="GET_WORKFLOW.components['profilePicture']['inputName']" :required="GET_WORKFLOW.components['profilePicture'].required">
                         </div>
                      </div>
                   </div>
                   <div class="modal-footer text-justify">
                      <slot name="footer">
                         <div class="action-buttons w-full flex">
                            <div class="my-auto">
                               <a href="" class="modal-default-button cancel-button text-gray-600" @click="$emit('closeModal')">
                               Cancel
                               </a>
                            </div>
                            <div class="actions ml-auto">
                               <div class="actions ml-auto">
                                  <button @click="uploadImage" v-if="croppedImageSrc"  :disabled="loadingSubmitButton" type="submit"                               class="rounded capitalize px-4 py-2 text-white font-semibold text-[20px] bg-[#0EE2B1]">
                                  <span v-if="loadingSubmitButton" class="spinner-border" role="status" aria-hidden="true"></span>
                                  <span v-else>Save</span>
                                  </button>
                               </div>
                            </div>
                         </div>
                      </slot>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </transition>
 </template>
<script>
// importing the helpers for calling  getters from vuex
import { mapActions, mapGetters } from 'vuex';
import Loader from '../../Loader.vue';

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import PrimaryButton from "../../PrimaryButton.vue";
export default {
    props: {
        satellite: {
            default (rawProps) {
                return {
                    name: ''
                }
            }
        },
        idConcerned: {
            default: '1'
        },
        chosenModal: {
            default: ''
        },
        pictureConcerned: {
            default: ''
        }
    },
    components: {
        PrimaryButton,
        Loader,
        VueCropper
    },
    data() {
        return {
            loadingWorkflow: true,
            loadingSubmitButton: false,
            originalImageShow: true,
            imageSrc: "",
            croppedImageSrc: "",

            formData: {},
            // list of modal IDs and header title
            modalDetails: [
                {
                    modalId: 'updatePractitionerImage',
                    modalHeaderTitle: 'Edit ' + this.satellite.name + ' Clinic'
                },
                {
                    modalId: 'updateClinicLogo',
                    modalHeaderTitle: 'Edit ' + this.satellite.name + ' Clinic'
                },
                {
                    modalId: 'updateSatelliteClinicLogo',
                    modalHeaderTitle: 'Edit ' + this.satellite.name + ' Clinic'
                }
            ],
        }
    },
    methods: {
        ...mapActions('mainClinic', ['loadWorkflow', 'toggleLoader', 'getMainClinic']),
        // trigger the action when form is submitted


        setImage: function(e) {
            const file = e.target.files[0]
            if (!file.type.includes("image/")) {
                alert("Please select an image file")
                return
            }
            if (typeof FileReader === "function") {
                const reader = new FileReader()
                reader.onload = event => {
                    this.imageSrc = event.target.result

                    // Rebuild cropperjs with the updated source
                    this.$refs.cropper.replace(event.target.result)
                }
                reader.readAsDataURL(file)
            } else {
                alert("Sorry, FileReader API not supported")
            }
        },
        cropImage() {
            this.originalImageShow = false
            // Get image data for post processing, e.g. upload or setting image src
            this.croppedImageSrc = this.$refs.cropper.getCroppedCanvas().toDataURL()
        },
        showOriginalImage() {
            this.originalImageShow = true
        },
        uploadImage() {
            this.loadingSubmitButton = true
            this.$refs.cropper.getCroppedCanvas().toBlob(function(blob) {
                let formData = new FormData()
                // Add name for our image
                formData.append("name", "image-name-" + new Date().getTime())

                // Append image file
                formData.append("file", blob)

                axios
                    .post("/dashboard/clinic/" + this.GET_WORKFLOW['apiEndpoint'] + "/" + this.idConcerned, formData)
                    .then((response) => {
                        window.location.reload();
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }.bind(this))
        },
        cropExistingImage() {
            this.imageSrc = this.pictureConcerned
        },
        deleteExistingImage() {

            Swal.fire({
                title: "You're deleting this image",
                text: "Are you sure you want to delete this image?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                    axios.put("/dashboard/clinic/" + this.GET_WORKFLOW['apiEndpoint'] + "/" + this.idConcerned, {
                        imagePath: this.pictureConcerned
                    })
                    .then(() => {
                        Swal.fire(
                            'Deleted!',
                            'Deleted Successfully.',
                            'success'
                          ).then(() => {
                            window.location.reload()
                          })
                    })

                }
              }).catch((e) => {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: e.response.status+' Error.',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true
                });
            });

        }
    },
    computed: {
        // getting data options stored in the vuex state
        ...mapGetters('mainClinic', ['GET_GRAB_SATELITE', 'GET_WORKFLOW', 'GET_ACTIVE_TAB', 'GET_LOADER', 'MAIN_CLINIC']),

        // computed property that returns the accurate header name of the actual modal
        accurateModalHeaderTitle() {
            return this.modalDetails.filter(modalId => modalId.modalId === this.chosenModal)
        },

        // retrieves the save function in the workflow
        saveFunction() {
            return this.GET_WORKFLOW['saveFunction'];
        },

        // retrieves the success message
        successMessage() {
            return this.GET_WORKFLOW['successAddMessage']
        }

    },
    mounted() {
        this.getMainClinic();
        this.loadWorkflow(this.chosenModal)
        .then(() => {
            this.loadingWorkflow = false
        });
    },
}
</script>




<style lang="scss" scoped>
.avatar {
  vertical-align: middle;
  border-radius: 50%;
}
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
</style>

<style lang="scss" scoped>
.roundImage{
    width: 300px !important;
    border-radius: 100%;
    margin: auto;
}

.originalImageShow {
    display: none !important;
}
</style>
