import axios from 'axios';
export default {
    namespaced: true,
    state: {
        guestWorkflow: [],
    },
    getters: {
        GET_GUEST_WORKFLOW(state)
        {
          return state.guestWorkflow
        },
    },
    actions:{
        saveNewTicket({commit}, payload) {
          return new Promise((resolve, reject) => {
            axios.post('/save-new-ticket', payload)
            .then((res) => {
              resolve(res)
            }).catch((e) => {
              console.log(e)
              reject(e)
            })
          })
        },
        loadGuestWorkflow({commit}, payload) {
          return new Promise((resolve, reject) => {
            axios.post('/get-guest-workflow', {type: payload})
            .then((res) => {
              commit('SET_GUEST_WORKFLOW', res.data)
              if(typeof res.data.steps !== 'undefined')
              {
                let steps = Object.keys(res.data.steps);
                const workflowSteps = [];
                steps.forEach((step) => workflowSteps.push(res.data.steps[step].title));
                commit('SET_WORKFLOW_STEPS', workflowSteps);
              }
              resolve(res)
            }).catch((e) => {
              console.log(e)
              reject(e)
            })
          })
        },
        loadHelpGuestWorkflow({commit}, payload) {
          return new Promise((resolve, reject) => {
            axios.post('/get-guest-workflow', {type: payload})
            .then((res) => {
              if(typeof res.data.steps !== 'undefined')
              {
                let steps = Object.keys(res.data.steps);
                const workflowSteps = [];
                steps.forEach((step) => workflowSteps.push(res.data.steps[step].title));
              }
              resolve(res)
            }).catch((e) => {
              console.log(e)
              reject(e)
            })
          })
        },
    },
    mutations: {
        SET_GUEST_WORKFLOW(state, guestWorkflow)
        {
          state.guestWorkflow = guestWorkflow
        },
    },
    
}