import axios from 'axios';
export default {
    namespaced: true,
    state: {
        sateliteClinics: {},
        mainClinic: {},
        lastUpdated: '',
        paymentOptions: {},
        satellite: {},
        workflow: [],
        workflowSteps: [],
        activeTabIndex: 0,
        showLoader: false,
        next_state: false,
        indicateRequiredFields: false,
    },
    getters: {
        GET_SATELITE_CLINICS(state)
        {
          return state.sateliteClinics
        },
        MAIN_CLINIC(state)
        {
            return state.mainClinic
        },
        GET_PAYMENT_OPTIONS(state)
        {
            return state.paymentOptions
        },
        GET_GRAB_SATELITE(state)
        {
            return state.satellite
        },
        GET_WORKFLOW(state)
        {
          return state.workflow
        },
        GET_WORKFLOW_STEPS(state)
        {
          return state.workflowSteps
        },
        GET_ACTIVE_TAB(state)
        {
          return state.activeTabIndex
        },
        GET_LOADER(state)
        {
          return state.showLoader
        },
        GET_ERROR_NEXT(state) {
          return state.next_state
        }

    },
    actions:{
        setBookingUrlDetails({commit}, ref) {
          return new Promise((resolve, reject) => {
            axios.get('/dashboard/clinic/booking-url/' + ref)
            .then(response => {
                resolve(response)
              }).catch(error => {
                reject(error)
              })
            })
        },
        saveOnlineBooking({commit}, payload) {
          return new Promise((resolve, reject) => {
            axios.put('/dashboard/clinic/save-booking-url', payload)
            .then(response => {
              Swal.fire({
                toast: true,
                icon: 'success',
                title: 'Clinic booking url updated',
                animation: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true
              })
              resolve(response)
            }).catch(error => {
              reject(error)
            })
          })
        },
        getUserSateliteClinics(context) {
          return new Promise((resolve, reject) => {
            axios.get('/dashboard/clinic/get-user-satelite-cinics')
            .then(response => {
                context.commit('storingUserSateliteClinics', response.data)
                resolve(response)
              }).catch(error => {
                reject(error)
              })
            })
        },
        loadWorkflow({commit}, payload) {
          return new Promise((resolve, reject) => {
            axios.post('/get-workflow', {type: payload})
            .then((res) => {
              commit('SET_WORKFLOW', res.data)
              resolve(res)
              if(typeof(res.data.steps) !== 'undefined')
              {
                let steps = Object.keys(res.data.steps);
                const workflowSteps = [];
                steps.forEach((step) => workflowSteps.push(res.data.steps[step].title));
                commit('SET_WORKFLOW_STEPS', workflowSteps);
              }
            }).catch((e) => {
              console.log(e)
              reject(e)
            })
          })
        },

        nextStep({commit, getters}, formData)
        {
          commit('SET_ERROR_NEXT', false)
          let goodToGoNext = true
          if(typeof getters.GET_WORKFLOW['steps'] !== 'undefined') {
            let activeTab = getters.GET_ACTIVE_TAB + 1;
            let workflow = getters.GET_WORKFLOW['steps'][activeTab]['components'];

            for (let componentKey of Object.keys(workflow)) {
              let component = workflow[componentKey]['inputName'];
              if(typeof formData[component] === 'undefined' || formData[component] === ''  || formData[component] === 0 ) {
                if(workflow[componentKey]['required']) {
                  goodToGoNext = false
                }
              }
            }
          }
          if(goodToGoNext) {
            commit('SET_ACTIVE_TAB_PLUS')
            commit('SET_ERROR_NEXT', false)
          }else {
            commit('SET_ERROR_NEXT', true)
          }
        },
        previousStep({commit})
        {
          commit('SET_ACTIVE_TAB_MINUS')
        },

        toggleLoader({commit})
        {
          commit('TOGGLE_LOAD')
        },

        // updated ClinicName
        saveClinicName(context, payload)
        {
          return new Promise((resolve, reject) => {
            axios.put('/dashboard/profile/save-clinic-name',{clinicName:payload})
            .then(response => {
              Swal.fire({
                toast: true,
                icon: 'success',
                title: 'Clinic Name Updated',
                animation: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true
              })
              context.dispatch('getMainClinic')
              resolve(response)
            }).catch(error => {
              reject(error)
            })
          })
        },

        // adding new clinic action
        saveClinic(context, clinicData) {
            // using the promise and resolve , reject in order to add a promise inside the component calling this action
            return new Promise((resolve, reject) => {
            axios.post('/dashboard/clinic/save-new-clinic', clinicData)
              .then(response => {
                context.dispatch('getMainClinic')
                resolve(response)
                window.location = '/dashboard/clinic/view/'+response.data
              }).catch(error => {
                reject(error)
              })
            })
        },
        updateClinicAction(context, clinicData) {
            // using the promise and resolve , reject in order to add a promise inside the component calling this action
            return new Promise((resolve, reject) => {
            axios.put('/dashboard/clinic/update-clinic/'+clinicData.id, clinicData.clinic)
            .then(response => {
                context.dispatch('getMainClinic')
                resolve(response)
              }).catch(error => {
                reject(error)
              })
            })
        },
        getPaymentModality(context) {

          return new Promise((resolve, reject) => {
            axios.get('/dashboard/clinic/get-payment-methods')
            .then(response => {
                context.commit('storingPaymentOptionsMutation', response.data)
                resolve(response)
              }).catch(error => {
                reject(error)
              })
            })
        },
        savePaymentModality(context, paymentData) {
            // using the promise and resolve , reject in order to add a promise inside the component calling this action
            return new Promise((resolve, reject) => {
            axios.post('/dashboard/clinic/update-payment-methods', {
                online_payment: paymentData.online_payment,
                card_payment: paymentData.card_payment,
                bank_transfers: paymentData.bank_transfers,
                cash: paymentData.cash,
                cheques: paymentData.cheques,
                cryptocurrency: paymentData.cryptocurrency,
                pay_pal: paymentData.pay_pal,
                klarna: paymentData.klarna,
                clearpay: paymentData.clearpay,
                pay_in_full_before: paymentData.pay_in_full_before,
                pay_deposit: paymentData.pay_deposit,
                pay_on_completion: paymentData.pay_on_completion,
            }).then(response => {
                context.dispatch('getMainClinic')
                resolve(response)
              }).catch(error => {
                reject(error)
              })
            })
        },
        // an action for updating the clinic address
        saveMainClinicAddress(context, updatingClinicAddress) {
            // this in order to give response to the component and there we can use another promise for when this finishes executing
            return new Promise((resolve, reject) => {
                axios.post('/dashboard/clinic/save-main-clinic-address', {
                    address_line1: updatingClinicAddress.address_line1,
                    address_line2: updatingClinicAddress.address_line2,
                    address_line3: updatingClinicAddress.address_line3,
                    city: updatingClinicAddress.city,
                    county: updatingClinicAddress.county,
                    postcode: updatingClinicAddress.postcode,
                  }).then(response => {
                    context.dispatch('getMainClinic')
                    resolve(response)
                  }).catch(error => {
                    reject(error)
                  })
            })
        },
        saveLegalEntity(context, payload) {
            // this in order to give response to the component and there we can use another promise for when this finishes executing
            return new Promise((resolve, reject) => {
                axios.post('/dashboard/clinic/save-legal-entity', {
                    legal_entity: payload.legal_entity,
                  }).then(response => {
                    resolve(response)
                  }).catch(error => {
                    reject(error)
                  })
            })
        },
        getMainClinic({commit}, payload) {
          let url = '/dashboard/clinic/get-main-clinic';
            return new Promise((resolve, reject) => {
            axios.post(url, payload).
              then(res => {
                resolve(res)
                commit('setMainClinicData', res.data);
              }).catch(error => {
                reject(error)
              })
           })
        }
    },

    mutations: {
        storingUserSateliteClinics(state, sateliteClinics)
        {
          state.sateliteClinics = sateliteClinics
        },
        // mutations are for updating the state
        setMainClinicData(state, mainClinic)
        {
            state.mainClinic = mainClinic;
            state.lastUpdated = mainClinic.updated_at;
        },
        storingPaymentOptionsMutation(state, paymentOptions)
        {
            state.paymentOptions = paymentOptions
        },
        storingGrabSateliteMutation(state, satellite)
        {
            state.satellite = satellite
        },
        SET_WORKFLOW(state, workflow)
        {
          state.workflow = workflow
        },
        SET_WORKFLOW_STEPS(state, workflowSteps)
        {
          state.workflowSteps = workflowSteps
        },
        SET_ACTIVE_TAB_PLUS(state)
        {
          state.activeTabIndex = state.activeTabIndex+1
        },
        SET_ACTIVE_TAB_MINUS(state)
        {
          state.activeTabIndex = state.activeTabIndex-1
        },
        TOGGLE_LOAD(state)
        {
          if(state.showLoader == false)
          {
            state.showLoader = true
          } else {
            state.showLoader = false

          }
        },
        SET_ERROR_NEXT(state, next_state) {
          state.next_state = next_state
        }
    },

}
