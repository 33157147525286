<template>
    <div class="container">

        <div class="main-page mt-5">
            <div class="display-all-container mt-5">
                <all-reviews :reviews="reviews"></all-reviews>
            </div>
        </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import AllReviews from './AllReviews.vue';

export default {
    props: ['reviews'],
    components: {
        AllReviews
    },
    data() {
        return {
            crumbs: [
                {name: 'Reviews', url: '/dashboard/reviews'},
            ],
            
        }
    },
    methods: {
        ...mapActions('commonFunctions', ['loadBreadcrumbs'])
    },
    mounted()
    {
        this.loadBreadcrumbs(this.crumbs);
    }

}
</script>