<style lang="scss">
    .profile-small-header-section {
        border-bottom: 2px solid #0BD2A4;

        h1 {
            font-size: 25px;
            line-height: 12px;
            color: #717170;
            font-weight: 700;
            padding-bottom: 5px;
        }
    }

    .profile-main-content-section {
        p {
            color: #717170;
            font-size: 17px;
            line-height: 28px;
            white-space: pre-wrap;
        }
    }

    .profile-main-content-section {

        .service-list-box {

            .service-list-item {
                margin-right: 30px;
                background-color: #ffff;
                border-radius: 10px;
                padding: 5px 15px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 4px 0px;




                h5 {
                    color: #717170;
                    font-size: 15px;
                    font-weight: 600;
                }
            }

        }
    }


</style>

<template>
    <div class="section-wrapper w-full">
        <div class="profile-small-header-section flex">
            <h1>Services</h1>
            <small class="ml-5 italic text-slate-500"></small>
        </div>
        <div class="profile-main-content-section mt-3">
            <div class="service-list-box flex-wrap flex">
                <li class="flex service-list-item cursor-pointer my-3" v-for="service in services">

                    <h5 class="my-auto m-0 mx-auto">{{ service.main.main_service_name }}</h5>
                </li>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['services'],

    }
</script>
