const AddServiceModalForm =
    {
        "title": "Request a review from the patient. ",
        "description": "The patient will receive an email with a secure link to write their review. You cannot edit the reviews that your patients submit.",
        "loadFunction": "",
        "saveAddFunction": "",
        "saveEditFunction": "saveService",
        "buttonCreateText": "Request Review",
        "buttonUpdateText": "Update",
        "components":
        {
            "componentGroup1":
            {
                "sectionTitle": "Patient Email",
                "sectionExplenation": "Please provide the patient email.",
                "inputTitle": "Patient Email",
                "inputType": "email",
                "inputName": "patient_email",
                "required": true,
                "dataRef": 'patient email',
                "vModel": "patient_email",
                "placeholder": "i.e. patientexample@example.com"
            },



        }
    }


export default AddServiceModalForm;
