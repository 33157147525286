<style scoped lang="scss">
    .edit-icon {
        width: 18px;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .personalBioTextSection::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .personalBioTextSection {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
</style>


<template>
    <div class="card  bio-wrap border-none shadow-lg p-4 h-full">
        <div class="card-h flex">
            <h5 class="text-md font-semibold card-title my-auto">Profile Information</h5>
            <div class="ml-auto cursor-pointer edit-icon flex" @click="toggleEdit()">
                <Icon :icon="editIcon" class="my-auto"></Icon>
            </div>
        </div>
        <p v-if="!editMode" v-text="fullBio" class="mt-4 h-full overflow-y-scroll card-inner-text text-slate-500 personalBioTextSection"></p>
        <div class="flex" v-if="!editMode && !full_bio.full_bio_text">
            <img class="mx-auto my-auto" src="../../../../../public/images/illustrations/bg/bg-19.png" alt="" style="height: 350px;">
        </div>
        <form action="" v-if="editMode" class="mt-4">
            <div class="field-secription flex">
                <p class="text-sm italic font-light">(This description will be displayed on your clinic page)</p>
                <p class="text-sm italic font-light flex ml-auto">(Characters: {{full_bio.characters_count}}/{{full_bio.max_char}})</p>
            </div>
            <textarea class="shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
            rows="4" 
            cols="50"
            id="full_bio" 
            name="full_bio"
            v-model="full_bio.full_bio_text"
            placeholder="This is your chance to get your patients' attention when your clinic is displayed in the search result page. Make it short and catchy."
            @change="saveFullBio(full_bio)"
            @keyup="modificationState(); alterFullBioSavedStatus()"
            :class="{'saved-input' : this.savedFullBio}"
            :maxlength="full_bio.max_char"
            ></textarea>
        </form>
        
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Icon from '../../Icon';

export default {
    props: ['head_office'],
    components: {
        Icon
    },

    data() {
        return {
            full_bio: {
                clinic_id: this.head_office.id,
                full_bio_text: this.head_office.full_bio,
                max_char: 1000,
                characters_count: 0,
            },
            fullBio: 'Write about what your clinic is all about.',
            editIcon: 'edit-square',
            editMode: false

        }
        
    },
    methods: {
        ...mapActions('profile', ['saveFullBio', 'alterFullBioSavedStatus']),
        modificationState()
        {
            this.full_bio.characters_count = this.full_bio.full_bio_text.length;
        },
        toggleEdit()
        {
            if(!this.editMode)
            {
                this.editMode = true
                this.editIcon = 'check_circle'
            } else {
                this.editMode = false
                this.editIcon = 'edit-square'
            }
        }
    },
    computed: {
        ...mapState('profile', ['headOffice', 'savedFullBio', 'lastUpdated']),
        setMainClinicData()
        {
            return this.headOffice;
        },

    },
    mounted()
    {
        if(this.full_bio.full_bio_text !== null) {
            this.full_bio.characters_count = this.full_bio.full_bio_text.length;
        }
        if(!this.full_bio.full_bio_text) {
            this.fullBio = 'Add a bio because it is empty'
        } else {
            this.fullBio = this.full_bio.full_bio_text
        }
    }   

}
</script>