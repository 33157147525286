<template>
    <div class="">
        <div class="main-page mt-5">
            <div class="display-all-clinics-container mt-5">
                <all-clinics :satellites="satellites"></all-clinics>
            </div>
        </div>

    </div>
</template>

<script>
import AllClinics from './AllClinics.vue';
    // importing the helpers for calling mutations from vuex
import { mapMutations, mapActions } from 'vuex';
export default {
    props: ['main_clinic', 'payment_options', 'satellites'],
    components: { AllClinics },
    data() {
        return {
            crumbs: [
                {name: 'Clinics', url: '/dashboard/clinic'},
            ]
        }
    },
    methods: {
        // importing the mutation from the module
        ...mapActions('commonFunctions', ['loadBreadcrumbs']),
        ...mapMutations('mainClinic', ['storingPaymentOptionsMutation'])
    },
    created() {
        // using the mutation  to store the actual payment options and no need to pass through the props 
        this.storingPaymentOptionsMutation(this.payment_options)
    },
    mounted()
    {
        this.loadBreadcrumbs(this.crumbs);
    }

}

</script>