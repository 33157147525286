<style lang="scss">
    ul {
        padding: 0px;
    }
    .profile-small-header-section {
        border-bottom: 2px solid #0BD2A4;

        h1 {
            font-size: 25px;
            line-height: 12px;
            color: #717170;
            font-weight: 700;
            padding-bottom: 5px;
        }
    }

    .profile-main-content-section {
        p {
            color: #717170;
            font-size: 17px;
            line-height: 28px;
            white-space: pre-wrap;
        }
    }
    .profile-main-content-section {
        .practitioner-list-box {
            .practitioner-list-item {
                list-style: none!important;
                margin-right: 80px;

                img {
                    border-radius: 50%;
                    height: 180px;
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 7px 0px;
                }

            }

        }
    }

    h5 {
        color: #838382;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
    }
    .capitalize-first {
        text-transform: capitalize;
    }
    .capitalize-first:first-letter {
        text-transform: uppercase;
    }
    .overflow-auto {
  overflow: auto;
}


.top-div {
  background-color: #ffffff;
  padding: 2px;
  position: sticky;
  top: 0;
}

.bottom-div {
  padding: 10px;
}

.overflow-shadow {
  /* Add your custom styles for the overflow shadow here */
  /* For example: */
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
}


</style>
<template>
    <div class="section-wrapper w-full">
       <div class="flex justify-between items-center profile-small-header-section">
          <!-- Left-side content (h1) -->
          <div>
             <h1 class="text-lg font-bold">Reviews</h1>
          </div>
          <!-- Right-side content (button dropdown) -->
          <div class="relative">
             <button
                class="border border-gray-300 text-gray-600 px-4 mb-2 py-2 rounded-lg"
                @click="toggleDropdown"
                >
             {{ prefix_word }} {{ actual_label }}
             </button>
             <div v-show="showDropdown" @click="toggleDropdown" class="absolute top-full z-1 right-0 mt-1 bg-white border border-gray-300 rounded shadow-md">
                <ul>
                    <li v-for="option in filterOptions" :key="option.id" @click="sortReviews(option.id, option.label, option.prefix_word)" class="px-4 py-2 hover:bg-gray-100 cursor-pointer whitespace-nowrap">
                    {{ option.label }}
                    </li>
                </ul>
            </div>

          </div>
       </div>
       <div class="profile-main-content-section mt-3">
          <div class="practitioner-list-box flex-wrap flex">
             <h5 class="my-auto m-0 mx-aut text-center">{{ sortedReviews.length }} Review<span v-if="sortedReviews.length > 1">s</span></h5>
          </div>
       </div>
       <div class="mt-3">
        <div class="overflow-auto max-h-96">
            <div class="top-div overflow-shadow"></div>
                <div class="bottom-div">
                    <!-- Content of the bottom div goes here -->
                    <one-profile-review-tile
                    :review="review"
                    v-for="review in sortedReviews"
                    :key="review.id"
                    />
                </div>
            </div>

       </div>
    </div>
 </template>
<script>

import filterOptions from '../../../../constants/search/profile/filterOptions'
import OneProfileReviewTile from './OneProfileReviewTile'
    export default {
        data() {
            return {
                filterOptions: filterOptions,
                showDropdown: false,
                sortedReviews: [],
                actual_label: '',
                prefix_word: 'Sort by'
            }
        },
        props: ['reviews'],
        components: {OneProfileReviewTile},

        methods: {
             addShadowOnScroll() {
  const container = document.querySelector('.overflow-auto');
  const scrollPosition = container.scrollTop;

  const shadowElement = document.querySelector('.overflow-shadow');
  if (scrollPosition > 0) {
    shadowElement.style.display = 'block';
  } else {
    shadowElement.style.display = 'none';
  }
},
            toggleDropdown() {
                this.showDropdown = !this.showDropdown;
            },
            sortReviews(id, label, prefix_word){
                this.actual_label = label
                this.prefix_word = prefix_word
                switch(id){
                    case 'most_recent': this.sortByDateDescending(); break
                    case 'older':  this.sortByDateAscending(); break
                    case 'highly_rated':  this.sortByRatingDescending(); break
                    case 'poorly_rated':  this.sortByRatingAscending(); break
                    case 'one_star':  this.filterReviewsByRating(1); break
                    case 'two_stars':  this.filterReviewsByRating(2); break
                    case 'three_stars':  this.filterReviewsByRating(3); break
                    case 'four_stars':  this.filterReviewsByRating(4); break
                    case 'five_stars':  this.filterReviewsByRating(5); break
                    default: this.showAll(); break
                }
                // console.log(id)
            },
            showAll() {
                this.sortedReviews = this.reviews
            },
            sortByRatingAscending() {
                this.sortedReviews = this.reviews
                this.sortedReviews = this.sortedReviews.slice().sort((a, b) => a.rating - b.rating);
            },
            sortByRatingDescending() {
                this.sortedReviews = this.reviews
                this.sortedReviews =  this.sortedReviews.slice().sort((a, b) => b.rating - a.rating);
            },
            sortByDateAscending() {
                this.sortedReviews = this.reviews
                this.sortedReviews =  this.sortedReviews.slice().sort((a, b) => new Date(a.review_date) - new Date(b.review_date));
            },
            sortByDateDescending() {
                this.sortedReviews = this.reviews
                this.sortedReviews =  this.sortedReviews.slice().sort((a, b) => new Date(b.review_date) - new Date(a.review_date));
            },
            filterReviewsByRating(rating) {
                if (typeof rating !== 'number' || rating < 1 || rating > 5) {
                    throw new Error('Invalid rating. Please provide a number between 1 and 5.');
                }
                this.sortedReviews = this.reviews
                this.sortedReviews =  this.sortedReviews.filter(review => review.rating === rating);
            }
        },
        created() {
            this.sortedReviews = this.reviews

                // Step 1: Create a copy of the filterOptions object to avoid modifying the original one
                const filteredFilterOptions = { ...this.filterOptions };

                // Step 2: Get all unique ratings from the sortedreviews array
                const ratingsSet = new Set(this.sortedReviews.map((review) => review.rating));

                // Step 3: Remove the "star" filter options whose rating is not present in the sortedreviews array
                for (const option in filteredFilterOptions) {
                if (option.includes("_stars") && !ratingsSet.has(filteredFilterOptions[option].value)) {
                    delete filteredFilterOptions[option];
                }
                }

                this.filterOptions = filteredFilterOptions
        },
        computed: {
            // filteredFilterOptions() {
            //     // Step 1: Create a copy of the filterOptions object to avoid modifying the original one
            //     const filteredFilterOptions = { ...this.filterOptions };

            //     // Step 2: Get all unique ratings from the sortedreviews array
            //     const ratingsSet = new Set(this.sortedReviews.map((review) => review.rating));

            //     // Step 3: Remove the "star" filter options whose rating is not present in the sortedreviews array
            //     for (const option in filteredFilterOptions) {
            //     if (option.includes("_stars") && !ratingsSet.has(filteredFilterOptions[option].value)) {
            //         delete filteredFilterOptions[option];
            //     }
            //     }

            //     console.log(filteredFilterOptions);

            //     return this.filterOptions
            // }
        }
    }
</script>
