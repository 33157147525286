<template>
  <div v-if="this.imageSrc" class="modal-content">
    <div class="modal-body">
      <div class="form-input flex p-5">
        
          <div
            v-if="this.imageSrc"
            class="my-3 d-flex align-items-center justify-content-center mx-auto"
            >
              <vue-cropper
                class="mr-2 w-50"
                ref="cropper"
                :aspectRatio="1/1"
                :zoomable="false"
                :guides="true"
                :src="imageSrc"
                ></vue-cropper>
                <div class="w-100"></div>
              <img class="tftf ml-2 w-50 bg-light" :src="croppedImageSrc" />

            <!-- Cropped image previewer -->
          </div>
          <!-- <button>Upload</button> -->
      </div>
      <button class="btn btn-secondary" v-if="this.imageSrc" @click="cropImage">Crop</button>
    </div>
    <div class="modal-footer">
      <!-- <button type="button"  v-if="this.croppedImageSrc" @click="uploadImage" class="btn btn-primary">Save</button> -->
    </div>
  </div>
  <div v-else class="center" style="text-align: center;">
     <div class="card">
        <img class="py-2 px-4  card-img-top avatar" :src="MAIN_CLINIC.image_path" alt="Card image cap">
        <div class="card-body">
          <label for="file-upload" class="custom-file-upload">
            Upload Picture
          </label>
           <input  id="file-upload" class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500"  accept="image/*" @change="setImage" :type="GET_WORKFLOW.components['profilePicture']['inputType']" :name="GET_WORKFLOW.components['profilePicture']['inputName']" :required="GET_WORKFLOW.components['profilePicture'].required">
        </div>
     </div>
  </div>
</template>
  
  <script>
  import VueCropper from 'vue-cropperjs';
  import 'cropperjs/dist/cropper.css';
  // importing the helpers for calling actions and getters from vuex
  import { mapActions, mapGetters } from 'vuex';
      export default {
            components: {
                VueCropper
            },
          props: {
            formData: {
                type: Object
              },
            mainClinicInfo: {
                type: Object,
            } 
          },
          data() {
              return {
                  imageSrc: "",
        croppedImageSrc: "",
              }
          },
          computed: {
            ...mapGetters('mainClinic', ['MAIN_CLINIC', 'GET_WORKFLOW'])
          },
          methods: {
            setImage: function (e) {
                const file = e.target.files[0]
                if (!file.type.includes("image/")) {
                alert("Please select an image file")
                return
                }
                if (typeof FileReader === "function") {
                const reader = new FileReader()
                reader.onload = event => {
                    this.imageSrc = event.target.result

                    // Rebuild cropperjs with the updated source
                    this.$refs.cropper.replace(event.target.result)
                }
                reader.readAsDataURL(file)
                } else {
                alert("Sorry, FileReader API not supported")
                }
            },
          cropImage() {
        // Get image data for post processing, e.g. upload or setting image src
        this.croppedImageSrc = this.$refs.cropper.getCroppedCanvas().toDataURL()
      },
      uploadImage() {
        this.$refs.cropper.getCroppedCanvas().toBlob(function (blob) {
          let formData = new FormData()

          // Add name for our image
          formData.append("name", "image-name-" + new Date().getTime())

          // Append image file
          formData.append("file", blob)

          axios
            .post("/dashboard/clinic/upload-main-clinic-picture", formData)
            .then(response => {
              window.location.reload();
            })
            .catch(function (error) {
              console.log(error)
            })
        })
      },
          },
      }
  </script>
  
  <style lang="scss" scoped>
  .avatar {
    vertical-align: middle;
    border-radius: 50%;
  }
  .center {
    margin: auto;
    width: 50%;
    padding: 10px;
  }
  input[type="file"] {
    display: none;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
.tftf{
  border-radius: 100%;
}
  </style>
  