<template>
    <div class="">
        <div class="section-title">
            <h5>Personal Information</h5>
        </div>
        <form @submit.prevent="savePersonalInfo()">
        <div class="bg-white rounded shadow w-full py-5 px-3">
            <div class="profile-img-section flex">
            </div>
            <div class="info-section">
                <div class="form-group my-2">
                  <label for="title">Title</label>
                  <dropdown
                    :options="GET_TITLES"
                    :mappings="titleMappings"
                    v-model="clinician.title"
                    >
                  </dropdown>
                </div>
                <div class="form-group my-2">
                  <label for="">First Name</label>
                  <input type="text" name="first_name" v-model="clinician.first_name" id="" class="form-control" placeholder="" aria-describedby="helpId">
                  <small id="helpId" class="text-muted">Help text</small>
                </div>
                <div class="form-group my-2">
                  <label for="">Middle Name</label>
                  <input type="text" name="first_name" v-model="clinician.middle_name" id="" class="form-control" placeholder="" aria-describedby="helpId">
                  <small id="helpId" class="text-muted">Help text</small>
                </div>
                <div class="form-group my-2">
                  <label for="">Last Name</label>
                  <input type="text" name="first_name" v-model="clinician.last_name" id="" class="form-control" placeholder="" aria-describedby="helpId">
                  <small id="helpId" class="text-muted">Help text</small>
                </div>
                <div class="form-group my-2">
                  <label for="">Known As</label>
                  <input type="text" name="first_name" v-model="clinician.known_as" id="" class="form-control" placeholder="" aria-describedby="helpId">
                  <small id="helpId" class="text-muted">Help text</small>
                </div>
                <div class="form-group my-2">
                  <label for="">Gender</label>
                  <dropdown
                    :options="GET_GENDERS"
                    :mappings="genderMappings"
                    v-model="clinician.gender"
                    >
                  </dropdown>
                  <small id="helpId" class="text-muted">Feel free to specify your gender. This will be used as a filter. Some patients prefer to see practitioners from a specific gender.</small>
                </div>
                <div class="form-group my-2">
                  <label for="">Is this the owner of the clinic?</label>
                  <input type="checkbox" name="first_name" v-model="clinician.is_owner" id="" class="" placeholder="" aria-describedby="helpId">
                </div>
            </div>
            <div class="flex mt-4">
                <PrimaryButton :type="'submit'">Update</PrimaryButton>
            </div>
        </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters, mapState } from 'vuex';
import Dropdown from '../../Dropdown.vue';
import PrimaryButton from "../../PrimaryButton.vue";

    export default {
        components: {
            PrimaryButton,
            Dropdown
        },
        data() {
            return {
                clinician: {}
            }
        },
        methods: {
            ...mapActions('commonFunctions', ['getValues', 'redirectAction']),
            savePersonalInfo()
            {
                axios.put('/dashboard/practitioners/update-personal-info/'+this.clinician.ref, this.clinician).then(() => {
                    Swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Personal Information Saved',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    });
                    this.redirectAction('Personal')
                }).catch(() => {
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Something went wrong. Contact support',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    });
                });
            },
            // Set the fallback value when not mounted anymore
            setClinician()
            {
                this.clinician = this.GET_PRACTITIONER;
            },
            // capitaliwying first letter
            capitalizeFirstLetter(str) {
                return str.charAt(0).toUpperCase() + str.slice(1);
            },
        },
        computed:
        {
            ...mapGetters('commonFunctions', ['GET_TITLES', 'GET_GENDERS']),
            ...mapGetters('practitioner', ['GET_PRACTITIONER']),
            titlesArr()
            {
                const titlesConst = this.GET_TITLES;
                const resultArray = Object.keys(titlesConst).map(function(key) {
                  return titlesConst[key];
                });

                return resultArray;

            },
            // getting the mapping for titles
            titleMappings() {
                return this.GET_TITLES.reduce((mappings, title, index) => {
                    mappings[title] = this.capitalizeFirstLetter(title);
                    return mappings;
                }, {});
            },
            // getting the mapping for genders
            genderMappings() {
                return this.GET_GENDERS.reduce((mappings, gender, index) => {
                    mappings[gender] = this.capitalizeFirstLetter(gender);
                    return mappings;
                }, {});
            },
        },
        mounted()
        {
            this.getValues(['titles', 'genders']);

            // when component is mounted set the clinician details to use as vue-models too
            this.$store.watch(() => this.$store.state.practitioner.practitioner, async () => {
                 this.clinician = this.$store.state.practitioner.practitioner;
             });
             this.setClinician();

        }
    }
</script>
