import axios from 'axios';
import { defaultsDeep } from 'lodash';

export default {
    namespaced: true,
    state: {
        clinics: {},
        clinic: {},
        tabs: {},
        active_tab: '',
        errors: '',
        satelite_services: '',
        logs: []

    },
    getters: {
        GET_ACTIVE_TAB(state)
        {
            return state.active_tab;
        },
        GET_ERRORS(state)
        {
            return state.errors;
        },
        GET_SATELITE_PRACTITIONERS(state, data)
        {
            return state.satelite_practitioners;
        },
        GET_LOGS(state)
        {
            return state.logs
        }
    },
    actions: {
        saveNewCondition({commit}, payload)
        {
            axios.post('/dashboard/clinic/store-condition-treated', {payload: payload})
            .then((res) => {
                //console.log(res.data)
            }).catch((e) => {
                console.log(e)
            });
        },
        getCondition({commit})
        {
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/clinic/get-condition')
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        setLogs({commit}, payload)
        {
            axios.post('/api/dashboard/logs', {payload})
            .then((res) => {
                commit('FILL_LOGS', res.data)
            }).catch((e) => {
                console.log(e)
            });
        },
        setMyServices({}, payload)
        {
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/clinic/get-my-services')
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        setAssociatedServices({commit}, payload)
        {
            return new Promise((resolve, reject) => {
                axios.put('/dashboard/services/set-associated-satelite-clinics/' + payload.ref, {
                    servicesList: payload.servicesList
                })
                .then(response => {
                    resolve(response)
                    context.commit('SET_SATELITE_SERVICES', response.data)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getAssociatedServices(context, ref)
        {
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/services/get-associated-satelite-clinics/' + ref)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        setClinicPage({commit})
        {
            commit('SET_CLINIC_PAGE');
        },
        // Change tab
        changeActiveTab({commit}, payload)
        {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('tab', payload);
            const newUrl = window.location.origin + window.location.pathname + '?' + searchParams;
            window.history.pushState({path:newUrl},'',newUrl);
            commit('SET_ACTIVE_TAB', payload);
        },
        singleClinicModalSaveAction({commit}, payload)
        {
            switch(payload.action)
            {
                case 'saveClinicEmailAddress':
                    if(!payload.data.email){
                        commit('SET_VALIDATION_ERROR', 'The email field is required.')
                        return

                    }
                    break
                case 'saveClinicName':
                    if(!payload.data.name){
                        commit('SET_VALIDATION_ERROR', 'The name field is required.')
                        return
                    }
                    break
                case 'saveClinicTelephone':
                    if(!payload.data.telephone){
                        commit('SET_VALIDATION_ERROR', 'No changes made.')
                        return
                    }
                    break
                case 'saveClinicMobile':
                    if(!payload.data.mobile_phone){
                        commit('SET_VALIDATION_ERROR', 'No changes made.')
                        return
                    }
                    break
            }

            axios.put('/dashboard/clinic/update/'+payload.clinic+'/'+payload.action, {payload})
            .then(() => {
                Swal.fire({
                    toast: true,
                    icon: 'success',
                    title: payload.type.toUpperCase()+' SAVED',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 1000,
                    timerProgressBar: true
                }).then(() => {
                    window.location.reload()
                })

            }).catch((e) => {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: e.response.status+' Error.',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true
                });
            })
        },
        save({commit}, payload)
        {
            switch(payload[0])
            {
                case 'saveClinicCity':
                    if(!payload[2]){
                        commit('SET_VALIDATION_ERROR', 'City field cannot be blank.')
                        return
                    }
                    axios.put('/dashboard/clinic/update/'+payload[1]+'/saveClinicCity', {city: payload[2]})
                    .then(() => {
                        commit('SET_VALIDATION_ERROR', null)
                        Swal.fire({
                            toast: true,
                            icon: 'success',
                            title: 'Updates successfully saved',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true
                        })

                    }).catch((e) => {
                        Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: e.response.status+' Error.',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true
                        });
                    })
                    break;
                case 'saveClinicPostcode':
                    if(!payload[2]){
                        commit('SET_VALIDATION_ERROR', 'Postcode field cannot be blank.')
                        return
                    }
                    axios.put('/dashboard/clinic/update/'+payload[1]+'/saveClinicPostcode', {postcode: payload[2]})
                    .then(() => {
                        commit('SET_VALIDATION_ERROR', null)
                        Swal.fire({
                            toast: true,
                            icon: 'success',
                            title: 'Updates successfully saved',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true
                        }).then(() => {
                            window.location.reload()
                        })

                    }).catch((e) => {
                        Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: e.response.status+' Error.',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true
                        });
                    })
                    break;
                case 'saveClinicMaxMilesRadius':
                    axios.put('/dashboard/clinic/update/'+payload[1]+'/saveClinicMaxMilesRadius', {max_radius: payload[2]})
                    .then(() => {
                        commit('SET_VALIDATION_ERROR', null)
                        Swal.fire({
                            toast: true,
                            icon: 'success',
                            title: 'Updates successfully saved',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 1000,
                            timerProgressBar: true
                        })

                    }).catch((e) => {
                        Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: e.response.status+' Error.',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true
                        });
                    })
                    break;
            }

        },
        setAllSateliteClinics(context) {
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/clinic/get-all-satelite-clinics')
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        setAssociatedPractitioners({commit}, payload)
        {
            return new Promise((resolve, reject) => {
                axios.put('/dashboard/clinic/set-associated-practitioners/' + payload.ref, {
                    practitionersList: payload.practitionersList
                })
                .then(response => {
                    resolve(response)
                    context.commit('SET_SATELITE_PRACTITIONERS', response.data)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getAssociatedPractitioners(context, ref)
        {
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/clinic/get-associated-practitioners/' + ref)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
            })
        }
    },
    mutations: {
        SET_ACTIVE_TAB(state, tab)
        {
            state.active_tab = tab;
        },
        SET_CLINIC_PAGE(state)
        {
            state.active_tab = 'Profile';
        },
        SET_VALIDATION_ERROR(state, message)
        {
            state.errors = message;
        },
        SET_SATELITE_PRACTITIONERS(state, data)
        {
            state.satelite_practitioners = data
        },
        SET_SATELITE_SERVICES(state, data)
        {
            state.satelite_services = data
        },
        FILL_LOGS(state, data)
        {
            state.logs = data
        }
    }
}
