<style lang="scss" scoped>
    .newsletter-signup {
        @media (max-width: 768px)
        {
            > div {
                flex-direction: column;
            }

        }

        @media (max-width: 576px)
        {
            p {
                max-width: 100%;
            }

        }
    }

    .sign-up-text-wrapper {
        p {
            color: #212429;
            font-size: 40px;
            font-style: normal;
            font-weight: 900;
            line-height: 60px;
        }
    }
</style>
<template>
    <section class="w-full flex">
        <div class="newsletter-signup mx-auto text-center left-pom my-auto flex items-center container need-health-care-banner">

            <div class="container md:flex w-full" style="max-width:80%">
                <div class="container sign-up-text-wrapper mb-4 md:my-auto mr-4 w-full">
                    <p class="font-extra-bold text-white mx-auto md:ml-0 my-auto xs:text-center md:text-left"><span class="" style="color:#212429">Sign up</span> to our newsletter & get the latest updates</p>
                </div>
                <div class="container sign-up-wrapper search-box p-2 my-auto w-full">
                    <newsletter-signup-input></newsletter-signup-input>

                </div>
            </div>
        </div>
    </section>
</template>
<script>
import NewsletterSignupInput from './InputComponents/NewsletterSignupInput.vue'
export default {
    components: { NewsletterSignupInput }
}
</script>
