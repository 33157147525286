<template>
    <div class="container">
      <div class="flex">
        <button @click.prevent="openAddClinicModal = true" class="ml-auto p-2 rounded text-white font-bold bg-[#0BD2A4] mb-3">Add New Clinic</button>
      </div>
      <table-framework
            :theads="theads"
            :tableTitle="'Your Clinics'"
            :showTableSearch="true"
            :showTableToggle="true"
            :tableSearchText="searchInput"
            @textSearch="textSearch"
            :showTableActionButton="false"
            :allSelected="allSelected"
            :toggle="toggle"
            :filteredOptions="filteredSateliteClinics"
            :selectedIds="selectedIds"
            @onToggleChange="onToggleChange"
        >
            <tbody slot="table-body"  v-if="filteredSateliteClinics.length > 0">
              <tr v-for="(clinic, index) in filteredSateliteClinics" :key="clinic.id"
                :class="{
                  '': selectedIds.includes(clinic.ref),
                  'bg-white': !selectedIds.includes(clinic.ref),
                  'border-b': true,
                  'dark:border-gray-700': index !== filteredSateliteClinics.length - 1,
                  'hover:bg-gray-30': true,
                  'dark:hover:bg-gray-600': true,
                }"
                >

                <th class="ml-2 py-4 px-6  whitespace-nowrap" :class="selectedIds.includes(clinic.ref) && '' ">
                  <InputToggle :selectedIds="selectedIds" :element="clinic.ref" @toggleSelected="toggleSelected" />
                </th>
                <td class="py-2 px-6  image-td" :class="selectedIds.includes(clinic.ref) && '' ">
                  <img class="rounded-full w-12 h-12" :src="clinic.image_path" alt="" >
                </td>
                <td class="py-4 px-6 font-medium text-gray-900" :class="selectedIds.includes(clinic.ref) && '' ">
                  {{clinic.name}}
                </td>
                <td class="py-4 px-6 font-medium text-gray-900" :class="selectedIds.includes(clinic.ref) && '' ">
                  {{clinic.ref}}
                </td>
              </tr>
            </tbody>
            <div class="mt-5" v-if="filteredSateliteClinics.length === 0">
              <p>You don't have any satellite clinics yet.</p>
            </div>
      </table-framework>
      <UpdateCreateClinicModal @closeModal="closeModal" v-if="openAddClinicModal" :chosenModal="'addClinic'"/>
    </div>
  </template>



<script>
import TableFramework from '../../../Table.vue';
import InputToggle from '../../Clinics/TabComponents/InputToggle.vue';
import clinicToPractitionersTableHead from '../../../../constants/services/clinicToPractitionersTableHead';
import { mapActions } from 'vuex';
import UpdateCreateClinicModal from "../../Clinics/UpdateCreateClinicModal.vue";
export default {
    components: {UpdateCreateClinicModal, TableFramework, InputToggle},
    props: ['practitionerRef'],
    data()
    {
        return {
            theads: clinicToPractitionersTableHead,
            selectedIds: [],
            sateliteClinics: [],
            filteredSateliteClinics: [],
            addSateliteClinicModal: false,
            toggle: false,
            searchInput: '',
            openAddClinicModal: false
        }
    },
    methods:
    {
        ...mapActions('satelite', ['setAllSateliteClinics']),
        ...mapActions('practitioner', ['setAssociatedSateliteClinics', 'getAssociatedSateliteClinics']),
        ...mapActions('mainClinic', ['getUserSateliteClinics']),
        textSearch(Searchtext)
        {
          this.searchInput = Searchtext
        },
        loadSateliteClinics()
        {
            this.setAllSateliteClinics()
            .then(res => {
                this.sateliteClinics = res.data;
                this.filteredSateliteClinics = res.data;
            }).catch(error => {
                console.log(error)
            })

        },
        toggleSelected(id) {
            if (this.selectedIds.includes(id)) {
                // Remove id from selected ids array if it's already selected
                this.selectedIds = this.selectedIds.filter((selectedId) => selectedId !== id);
            } else {
                // Add id to selected ids array if it's not already selected
                if (!Array.isArray(this.selectedIds)) {
                this.selectedIds = [];
                }
                // Add id to selected ids array if it's not already selected
                this.selectedIds.push(id);
            }
            this.setAssociatedSateliteClinics({
                ref: this.practitionerRef,
                sateliteClinicsList: this.selectedIds
            })
        },
        onToggleChange(toggle) {
            if(toggle) {
                this.selectedIds = this.filteredSateliteClinics.map(sateliteClinic => sateliteClinic.ref);
            }else {
                this.selectedIds = [];
            }
            this.setAssociatedSateliteClinics({
                ref: this.practitionerRef,
                sateliteClinicsList: this.selectedIds
            })
        },
      closeModal()
      {
        this.openAddClinicModal = false;
      }
    },
    watch: {
        searchInput: {
            handler(newValue) {
                this.filteredSateliteClinics = this.sateliteClinics
                this.filteredSateliteClinics = this.sateliteClinics.filter(sateliteClinic => {
                const searchLower = this.searchInput.toLowerCase();
                const hasName = sateliteClinic.name && sateliteClinic.name.toLowerCase().includes(searchLower);
                const hasRef = sateliteClinic.ref && sateliteClinic.ref.toLowerCase().includes(searchLower);
                return hasName || hasRef;
                });
            }
        }
    },
    mounted()
    {
        this.getUserSateliteClinics();
        this.loadSateliteClinics();
    },
    created()
    {
        this.getAssociatedSateliteClinics(this.practitionerRef)
        .then(response => {
            this.selectedIds = response.data
        }).catch(err => {
            console.log(err)
        })
    },
    computed: {
        allSelected() {
            if(this.selectedIds.length === this.filteredSateliteClinics.length){
                this.toggle = true
            }else {
                this.toggle = false
            }
            return this.selectedIds.length === this.filteredSateliteClinics.length;
        },
    }
}
</script>

<style lang="scss" scoped>
.roundImage{
    width: 50px  !important;
}
.form-check-label {
    font-weight: bold;
    text-transform: uppercase;
  }
  .form-check-input:checked {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
  }
  .form-check-input:checked + .form-check-label {
    color: #0d6efd !important;
  }
</style>
