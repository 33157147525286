const AddServiceModalForm = 
    {
        "title": "",
        "loadFunction": "",
        "saveAddFunction": "saveServiceExtra",
        "saveEditFunction": "",
        "buttonCreateText": "",
        "buttonUpdateText": "Save",
        "components":
        {
            "componentGroup1":
            {
                "sectionTitle": "Add Service Extras",
                "sectionExplenation": "Please provide a name for this service.",
                "inputTitle": "Service Name",
                "inputType": "text",
                "inputName": "service_name",
                "required": true,
                "dataRef": 'service name',
                "vModel": "service_name",
                "placeholder": ""
            },
            "componentGroup2":
            {
                "sectionTitle": "Pricing Modality",
                "sectionExplenation": "How will you price this service?",
                "inputTitle": "Service Pricing Modality",
                "inputType": "select",
                "inputName": "service_pricing_modality",
                "required": true,
                "dataRef": 'pricing_modality',
                "vModel": "pricing_modality",
                "placeholder": "",
                "options": {
                
                    "": "Please select an option",
                    "included": "Included in main service price",
                    "surcharge": "As a surcharge"
                    
                }
            },
            "componentGroup3":
            {
                "sectionTitle": "Service Surcharge",
                "sectionExplenation": "How much extra do you charge for this service?",
                "inputTitle": "Service Surcharge",
                "inputType": "number",
                "inputName": "service_surcharge",
                "required": false,
                "dataRef": 'service_surcharge',
                "vModel": "service_surcharge",
                "placeholder": "",
                "options": [
                    {
                        "": "Please select an option",
                        "included": "Included in main service price",
                        "surcharge": "As a surcharge" 
                    }
                ]
            },

        }
    }


export default AddServiceModalForm;