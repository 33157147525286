<template>
    <div class="container">

      <div class="mt-5">
        <ul class="flex">
          <li v-for="tab in compliance_page_tabs" :key="tab" class="mr-4 px-4 py-2 cursor-pointer rounded-lg" :class="{'active-tab': active_tab == tab}" @click="active_tab = tab">{{tab}}</li>
        </ul>
      </div>
      <div class="" v-if="active_tab === 'Clinics'">
        <div class="mt-5">
          <div class="p-3 bg-white shadow rounded-lg">
            <table class="w-full table-auto">
              <thead class="border-b">
                <th class="p-2 font-bold">Clinic Name</th>
                <th class="p-2 font-bold">Ref</th>
                <th class="p-2 font-bold">Critical actions</th>
                <th class="p-2 font-bold">Improvements</th>
                <th class="p-2 font-bold"></th>
              </thead>
              <tbody>
                <tr v-for="clinic in compliancearr.clinics">
                  <td class="p-2 border-b">{{clinic.info.name}}</td>
                  <td class="p-2 border-b">{{clinic.info.ref}}</td>
                  <td class="p-2 border-b" v-if="!clinic.isCompliant">Critical actions needed</td>
                  <td class="p-2 border-b" v-else>No actions required</td>
                  <td class="p-2 border-b" v-if="clinic.isImprovable">Improvements needed</td>
                  <td class="p-2 border-b" v-else>None</td>
                  <td class="p-2 border-b"><button class="btn btn-blue-grad text-white" @click.prevent="showModal(clinic)">View</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <create-update-modal v-if="modal" @close="modal = false; activeClinic = {}" :showActionButton="false" :title="activeClinic.info.name + ' Compliance'">
          <div class="" slot="content">
            <div class="">
              <div class="border-b" v-for="(action, index) in activeClinic.complianceActions">
                <h6 class="font-bold bg-teal-500 border text-white rounded text-[18px] p-2">{{ action.name }}</h6>
                <div class="description p-3">
                  <p><strong>Impact:</strong><span> {{action.impact}}</span></p>
                  <p>{{action.implications}}</p>
                  <div class="">
                    <strong>How to fix it:</strong>
                    <div class="mt-3">
                      <a class="btn btn-blue-grad text-white font-bold py-2 px-3 rounded" v-for="suggested in action.suggested_actions" :href="suggested.action_url" target="blank">{{suggested.action_name}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </create-update-modal>
      </div>
      <div class="" v-if="active_tab === 'Practitioners'">
        <div class="mt-5" v-if="compliancearr.practitioners.length == 0">
          <p>You don't have any practitioner yet. </p>
        </div>
        <div v-else class="main-page my-10">
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            <div v-for="practitioner in compliancearr.practitioners"  :key="practitioner.ref"  class="flex items-center justify-center">
              <OnePractitionerCompliance :practitioner="practitioner" />
            </div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
import OnePractitionerCompliance from './OnePractitionerCompliance'
import complianceDetailsModal from "./complianceDetailsModal.vue";
import {mapActions} from "vuex";
export default {
    props: ['compliancearr'],
    components: {complianceDetailsModal, OnePractitionerCompliance},
    data()
    {
      return {
        compliance_page_tabs: [
            'Clinics',
            'Practitioners'
        ],
        active_tab: "Clinics",
        modal: false,
        activeClinic: {},
        crumbs: [
          {name: 'Compliance', url: '/dashboard/compliance'},
        ]
      }
    },
  methods: {
    ...mapActions('commonFunctions', ['loadBreadcrumbs']),
    showModal(clinic)
      {
        this.modal = true;
        this.activeClinic = clinic;
      },
      closeModal()
      {
        this.modal = false;
        this.activeClinic = {};
      },
      mounted()
      {
        this.loadBreadcrumbs(this.crumbs);
      }
  }
}
</script>
<style scoped lang="scss">
.active-tab {
  background-color: #d2f7efb3;
  color:  #0EE2B1;
}
</style>
