<template>
    <div class="container">

        <table-framework
            :theads="theads"
            :tableTitle="'Your Practitioners'"
            :showTableActionButton="true"
            :tableButtonText="'Add Practitioner'"
            @tableAction="openUpdateClinicModal('addPractitioner')"
        >
            <tbody slot="table-body">
                <tr class="" v-if="practitioners.length == 0">
                    <p class="p-3 mx-2">You don't have any practitioner yet. </p>
                </tr>
                
                <tr v-else  v-for="practitioner in practitioners" :key="practitioner.id">
                        <td scope="row" class="py-2 px-6  whitespace-nowrap image-td">
                            <img @click="openUpdateModal(practitioner.image_path, practitioner.id)" class="roundImage ml-2 w-50 bg-light" :src="practitioner.image_path" />
                        </td>
                        <td scope="row" class="py-4 px-6 whitespace-nowrap ">
                            {{practitioner.first_name}} {{practitioner.middle_name}} {{practitioner.last_name}}
                        </td>
                        <td class="py-4 px-6">
                            {{practitioner.known_as}}
                        </td>
                        <td class="py-4 px-6">
                            {{practitioner.profession}}
                        </td>
                        <td class="py-4 px-6 my-auto">
                                <a class="mx-2 btn btn-sm" role="button" @click.prevent="grabPractitioner(practitioner.ref);">View/Edit</a>
                                <button @click.prevent="openDeleteModal(practitioner.id)" v-if="practitioners.length > 1" class="mx-2" name="Delete Service"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" style="color:red">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                </button>
                            <update-create-clinic-modal class="text-left" :practitioner="practitioner" :chosenModal="chosenModal" v-if="updateClinicModal"></update-create-clinic-modal>
                            <DeleteClinicOrPractitionerModal v-if="deleteModal"  :title="'Delete a practitioner'" :deleteType="'removePractitioner'" :idToDelete="idToDelete" @close="deleteModal = false" />
                        </td>
                    </tr>
            </tbody>
         </table-framework>  
        <UpdateProfileImagesModal @closeModal="closeModal" v-if="UpdateProfileImagesModal" :idConcerned="idConcerned" :pictureConcerned="practitionerImagePath" :chosenModal="modalId"/>
    </div>
</template>


<script>
import { mapActions } from 'vuex';
import allPractitionersTableHeads from '../../../constants/satelites/allPractitionersTableHeads';
import UpdateCreateClinicModal from '../Clinics/UpdateCreateClinicModal';
import UpdateProfileImagesModal from '../Clinics/UpdateProfileImagesModal.vue';
import DeleteClinicOrPractitionerModal from '../Clinics/DeleteClinicOrPractitionerModal.vue';
export default {
    props: [],
    components: {
        UpdateCreateClinicModal,
        UpdateProfileImagesModal,
        DeleteClinicOrPractitionerModal
    },
    data()
    {
        return {
            theads: allPractitionersTableHeads,
            deleteModal: false,
            updateClinicModal: false,
            chosenModal: '',
            practitioners: [],
            practitionerImagePath: '',
            modalId: '',
            idConcerned: '',
            idToDelete: '',
            addPractitionerModal: false,
            UpdateProfileImagesModal: false,
        }
    },
    methods: 
    {   
        ...mapActions('practitioner', ['setUserPractitioners']),
        ...mapActions('mainClinic', ['getUserSateliteClinics']),
        openUpdateClinicModal(modalName)
        {
            this.chosenModal = modalName
            this.updateClinicModal = true
        },
        loadPractitioners()
        {
            this.setUserPractitioners()
            .then(res => {
                this.practitioners = res.data;
            }).catch(error => {
                console.log(error)
            })
            
        },
        grabPractitioner(id)
        {
            window.location.href="/dashboard/practitioners/get-practitioner/"+id;
        },
        openUpdateModal(practitionerImagePath, idConcerned){
            this.UpdateProfileImagesModal = true
            this.practitionerImagePath = practitionerImagePath
            this.idConcerned = idConcerned
            this.modalId = 'updatePractitionerImage'
        },
        closeModal(){
            this.updateCreateClinicModal = false
        },
        openDeleteModal(idConcerned) {
            this.idToDelete = idConcerned
            this.deleteModal = true
        }
    },
    mounted()
    {
        this.getUserSateliteClinics();
        this.loadPractitioners();
    }
}
</script>

<style lang="scss" scoped>
.roundImage{
    width: 50px  !important;
}
</style>