<template>
    <div  v-if="!loadFormdata" class="container">
        <div class="form-section flex py-4 pr-4">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup1.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup1.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup1.inputTitle }}
                        <span v-if="formData.components.componentGroup1.required" class="text-red-400">*</span>
                    </label>
                    <input 
                    :type="formData.components.componentGroup1.inputType" 
                    class="w-full" v-model="modalData.patient_email" 
                    :required="formData.components.componentGroup1.required"
                    :placeholder="formData.components.componentGroup1.placeholder"
                    :name="formData.components.componentGroup1.inputName"
                    @input="pushData"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddReviewRequestModalForm from '../../../constants/services/modalForms/CreateReviewRequestModalForm'

export default {
    props: ['target', 'type'],
    data()
    {
        return {
            modalData: {
                patient_email: ''
            },
            formData: {},
            loadFormdata: true,
            radius_tier_options: 1
        }
    },
    computed: {
        ...mapGetters('mainClinic', ['MAIN_CLINIC']),
        ...mapGetters('practitioner', ['GET_ERRORS']),
    },
    methods: {
        pushData(){
            this.modalData.clinic_ref = this.MAIN_CLINIC.ref
            this.$emit('eventListener', this.modalData)
            this.$forceUpdate();
        }
    },
    mounted()
    {
        this.formData = AddReviewRequestModalForm;
        // If editing, pass in target services values
        if(this.target)
        {
            this.modalData.mainClinic = this.target.mainClinic;

            if(this.type == 'edit')
            {
               this.modalData.services = this.target.main_service_name;
               this.modalData.ref = this.target.ref;
               this.modalData.type = "service"
            }
        }
    },
    watch: {
        formData: {
            handler(newValue) {
            this.loadFormdata = false
            }
        }
    }
}
</script>


<style lang="scss" scoped>

@import "vue-select/dist/vue-select.css";

</style>