<template>
    <aside
      class="my-3 overflow-visible border-0 sidenav border-radius-xl"
      id="sidenav-main"
    >

    <UpdateProfileImagesModal @closeModal="closeModal" v-if="UpdateProfileImagesModal" :idConcerned="idConcerned" :pictureConcerned="practitionerImagePath" :chosenModal="modalId"/>
      <div class="sidenav-header flex-col mb-4">
        <img class="clinicLogo" :src="MAIN_CLINIC.image_path" @click="openUpdateModal(MAIN_CLINIC.image_path, MAIN_CLINIC.id)" alt="">
      </div>
      <hr class="mt-0 horizontal dark" />

      <sidebar-list  :dashboardItems="dashboarditems"/>
    </aside>
  </template>
  <script>
  import { mapActions, mapGetters } from 'vuex'
  import CircularImg from "../../../CircularImg.vue";
  import SidebarList from "./SidebarList.vue";
  import Icon from "../../../Icon";
  import UpdateProfileImagesModal from '../../Clinics/UpdateProfileImagesModal'

  export default {
    name: "Sidebar",
    components: {
    SidebarList,
    CircularImg,
    Icon,
    UpdateProfileImagesModal
},
    data() {
      return {
        sidebarToggleIcon: 'arrow-bar-left',
        UpdateProfileImagesModal: false,
        idConcerned: '',
        modalId: '',
        practitionerImagePath: false

      };
    },
    props: [
      'dashboarditems'
    ],
    computed: {
      ...mapGetters('mainClinic', ['MAIN_CLINIC'])
    },
    methods: {
      ...mapActions('mainClinic', ['getMainClinic']),
      openUpdateModal(practitionerImagePath, idConcerned){
          this.UpdateProfileImagesModal = true
          this.practitionerImagePath = practitionerImagePath
          this.idConcerned = idConcerned
          this.modalId = 'updateClinicLogo'
      },
      closeModal(){
          this.updateCreateClinicModal = false
      },
    },
    created(){
      this.getMainClinic()
    }

  };
  </script>

  <style lang="scss">
  .swal2-container {
    z-index: 9999;
  }
  .clinicLogo {
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4), 0px 8px 15px rgba(0, 0, 0, 0.2);
    border-radius: 100%;
  }
  </style>
