<template>
    <div class="w-full">
        <setup-steps-component
            :user_clinic_data="user_clinic_data"
            :user_practitioner_data="user_practitioner_data"
            :actualStepContentProp="actualStepContent"
            :allSteps="allSteps">
        </setup-steps-component>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import SetupStepsComponent from './Steps/SetupStepsComponent.vue'




export default {
    components: {
        SetupStepsComponent,

    },
    props: ['user_data', 'steps', 'user_practitioner_data', 'user_clinic_data'],
    data() {
        return {
            actualStepContent: null,
            actualStep: this.user_data.actual_setup_step,
            allSteps: this.steps.steps
        }
    },
    methods: {
        ...mapMutations('setup', ['setUserData'])
    },
    created(){
        this.actualStepContent = this.allSteps[this.actualStep]
        this.setUserData(this.user_data)
    }
}
</script>
