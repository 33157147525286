<style scoped lang="scss">

    .single-clinic-profile-main-card {
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
        box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
    }

    .card-title-section {
        h1 {
            font-size: 28px;
            color: #1E1F1D;
        }
        .under-title-paragraph {
            font-size: 18px;
            color: #838382;
        }

        .number-of-reviews {
            p {
                color: #838382!important;
                font-size: 18px!important;
            }
        }

    }

    ul {
        padding-left: 0!important;

        li {
            color: #838382;
            font-size: 16px;
        }
    }
    .contact-section {
        button {
            width: 130px!important;
        }
    }

    .main-card-section-title {
        color:#838382;
        font-weight: 600;
        letter-spacing: 0.4px;
        font-size: 18px;
    }

    .satelite-badge {
        position: relative;
        display: inline-block;
    }

    .satelite-badge .tooltiptext {
        visibility: hidden;
        background-color: #f4c11b;
        width: 150px;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 5px;
        left: 100%;
        margin-left: 5px;
        opacity: 0;
        transition: opacity 0.3s;
        font-size: 15px;
    }

    .satelite-badge:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }

    .review-icon {
      color: #0EE2B1;
      font-size: 17px;
    }

    .number-of-reviews {
      p {
        font-size: 12px;
        color: #838382;
      }
    }


</style>

<template>
    <div class="single-clinic-profile-main-card bg-white rounded-lg w-full p-8 mt-5">
        <div class="w-full md:flex">
            <div class=" lg:w-1/4 my-auto">
                <RoundProfilePicture :profileImage="dbvars.imgUrl"></RoundProfilePicture>
            </div>
            <div class="w-2/4 ml-5 mt-2 ">
                <div class="card-title-section">
                    <div class="flex">
                        <h1 class="flex">
                          {{ dbvars.name_displayed }}
                        </h1>
                        <span class="flex">
                            <b-badge pill variant="light" class="my-auto capitalize ml-3" style="background-color: #17a2b8!important;">{{dbvars.profileType}}</b-badge>
                        </span>
                    </div>

                    <p class="under-title-paragraph mt-0">Based in {{ dbvars.satelite.city }}</p>
                    <div></div>
                </div>
              <div class="status-badge my-3">
                <ProfileVerificationStatusBadge :verified="dbvars.verified"></ProfileVerificationStatusBadge>
              </div>
                <div class="reviews-sections mt-3">
                  <div v-if="dbvars.reviews.length >= 1">
                    <div class="rating flex">
                      <ul class="flex p-0 my-0">
                        <li v-for="n in dbvars.number_of_reviews" :key="n" class="mx-1 star-list"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill review-icon" viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                        </svg>
                        </li>
                      </ul>
                    </div>
                    <div class="number-of-reviews flex mx-2 my-2">
                      <p class="">{{dbvars.reviews.length}} Review<span v-if="dbvars.reviews.length > 1">s</span></p>
                    </div>
                  </div>
                  <div v-else>
                    <p>No reviews</p>
                  </div>

                    <div class="contact-section flex mt-2">
                        <button v-if="dbvars.satelite.email && dbvars.mainServices.length > 0 " class="btn contact-action-btn mr-1 border rounded" @click.prevent="openEmailModal()">Email</button>
                        <button v-if="dbvars.satelite.telephone" class="btn contact-action-btn mr-1 border rounded" @click.prevent="toggleTelephone()">{{ showTelephone ? dbvars.satelite.telephone : 'Telephone' }}</button>
                        <button v-if="dbvars.satelite.mobile_phone" class="btn contact-action-btn mr-1 border rounded" @click.prevent="toggleMobile()">{{ showMobile ? dbvars.satelite.mobile_phone : 'Mobile' }}</button>
                        <a role="button" v-if="dbvars.mainClinic.website" target="blank" :href="dbvars.mainClinic.website" class="btn contact-action-btn mr-1 border rounded" @click="recordAction('website_clicked')">Website</a>
                        <button v-if="dbvars.satelite.booking_url" class="btn  mx-1 bg-[#0EE2B1] px-3 py-2 rounded text-white font-semibold" style="background-color:#0BD2A4;color:#ffff;" @click="bookOnline">Book Online</button>
                    </div>
                    <div class="social-section flex mt-3">
                        <div class="social-icons">
                            <a class="mr-2 cursor-pointer" target="blank" v-if="dbvars.mainClinic.instagram" :href="dbvars.mainClinic.instagram" @click="recordAction('instagram_clicked')"><box-icon name='instagram' type='logo' color='#0bd2a4' ></box-icon></a>
                            <a class="mx-2 cursor-pointer" target="blank" v-if="dbvars.mainClinic.facebook" :href="dbvars.mainClinic.facebook" @click="recordAction('facebook_clicked')"><box-icon type='logo' color="#0BD2A4" name='meta'></box-icon></a>
                            <a class="mx-2 cursor-pointer" target="blank" v-if="dbvars.mainClinic.linkedin" :href="dbvars.mainClinic.linkedin" @click="recordAction('linkedin_clicked')"><box-icon type='logo' color="#0BD2A4" name='linkedin'></box-icon></a>
                            <a class="mx-2 cursor-pointer" target="blank" v-if="dbvars.mainClinic.twitter"  :href="dbvars.mainClinic.twitter" @click="recordAction('twitter_clicked')"><box-icon type='logo' color="#0BD2A4" name='twitter'></box-icon></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-1/4 hidden lg:flex">
                <div class="services-section mt-2" v-if="dbvars.services.length > 0">
                    <h5 class="main-card-section-title">Services</h5>
                    <ul class="service-list">
                        <li class="flex" v-for="service in dbvars.services" v-if="service.main.is_service_category === 1"><box-icon class="my-auto mr-1" name='check' color='#0bd2a4'></box-icon>{{ service.main.main_service_name }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <create-update-modal
               v-if="emailModal"
               @close="emailModal = false;
               target = dbvars"
               :title="'Send Email Enquiry To '+dbvars.satelite.name"
               :buttonText="SendEmailModalButtonText"
               :disabled="!this.modalData.patient_email || SendEmailModalButtonText == 'Sending...'"
               @save="update(modalData)"
            >
             <div class="" slot="content">
                    <EmailEnquiryModalContent @eventListener="updateModalData($event)" :target="dbvars" :formData="formData"></EmailEnquiryModalContent>
             </div>
        </create-update-modal>
    </div>
</template>

<script>
import RoundProfilePicture from '../../Utilities/RoundProfilePicture';
import CreateUpdateModal from '../../CreateUpdateModal.vue';
import EmailFormData from '../../../constants/search/profile/EmailEnquiryFormData';
import EmailEnquiryModalContent from './EmailEnquiryModalContent.vue'
import axios from 'axios';
import { mapActions } from 'vuex';
import ProfileVerificationStatusBadge from "../SearchCards/ProfileVerificationStatusBadge.vue";

    export default {
        props: ['dbvars'],
        components: {
          ProfileVerificationStatusBadge,
            RoundProfilePicture,
            CreateUpdateModal,
            EmailEnquiryModalContent,
        },
        data () {
            return {
                showTelephone: false,
                showMobile: false,
                emailModal: false,
                modalData: {},
                formData: EmailFormData,
                toggleModal: false,
                SendEmailModalButtonText: 'Send Email'
            }
        },
        methods: {
            ...mapActions('analytics', ['logAnalytics']),
            bookOnline(){
                this.recordAction('booking_portal_clicked')
                this.$emit('scrollToBookingComponent');
            },
            recordAction(target)
            {
                this.logAnalytics({action: target, data: this.dbvars})
            },
            toggleTelephone()
            {
                if(this.showTelephone == false)
                {
                    this.showTelephone = true;
                    this.recordAction('viewed_telephone');
                } else {
                    this.showTelephone = false;
                }
            },
            toggleMobile()
            {
                if(this.showMobile == false)
                {
                    this.showMobile = true;
                    this.recordAction('viewed_mobile_phone');
                } else {
                    this.showMobile = false;
                }
            },
            updateModalData(data)
            {
                this.modalData = data;
            },
            openEmailModal()
            {
                this.emailModal = true;
            },
            update()
            {
                this.SendEmailModalButtonText = 'Sending...'
                axios.post('/send-enquiry-email/'+this.dbvars.satelite.id, this.modalData)
                .then((res) => {
                this.SendEmailModalButtonText = 'Sent'
                Swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Email successfully sent',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 1000,
                        timerProgressBar: true
                    }).then(() => {
                        this.recordAction('sent_enquiry_email');
                        window.location.reload();
                    })

                }).catch((e) => {
                    this.SendEmailModalButtonText = 'Error'
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: e.response.status+' Error.',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true
                    });
                })
            }

        }
    }
</script>
