export default {
    props: {
        icon: String,
        type: {
            type: String,
            default: 'outline',
        },
        base: Boolean,
        wrapperClass: {
            type: String,
            default: 'icon'
        }
    },

    render(h) {
        return h('div', {
            domProps: { innerHTML: require(`../../../public/icons/${this.base ? '' : `${this.type}/`}${this.icon}.svg`) },
            class: this.wrapperClass,
        });
    },
};
