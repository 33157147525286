<style lang="scss">
    .profile-small-header-section {
        border-bottom: 2px solid #0BD2A4;

        h1 {
            font-size: 25px;
            line-height: 12px;
            color: #717170;
            font-weight: 700;
            padding-bottom: 5px;
        }
    }

    .profile-main-content-section {
        p {
            color: #717170;
            font-size: 17px;
            line-height: 28px;
            white-space: pre-wrap;
        }
    }

    .profile-main-content-section {

        .satelites-list-box {

            .satelites-list-item {
                margin-right: 30px;
                background-color: #ffff;
                border-radius: 10px;
                padding: 5px 15px;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 4px 0px;
            
                
                h5 {
                    color: #656565;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

        }
    }

    .satelite-tag {
        padding: 1px 10px;
        color: #ffff;
        font-weight: 700;
        border-radius: 5px;
        font-size: 12px;
    }

    .main-tag {
        background-image: linear-gradient(310deg, #17ad37, #98ec2d);;
    }

    .sat-tag {
        background-color: #b0b0b0;
    }

    .set-columns {
        padding: 0;
        column-count: 2;
        column-gap: 40px;
        -webkit-column-count: 2;
        -moz-column-count: 2; 
    }

    .img-box {
        background-image: linear-gradient(310deg, #17ad37, #98ec2d);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-right: 30px;
    }

    .satelite-card-subtitle {
        color: rgb(109, 109, 109);
    }


</style>

<template>
    <div class="section-wrapper w-full">
        <div class="profile-small-header-section md:flex">
            <h1>Satelite Clinics ({{ satelites.length }})</h1>
            <small class="md:ml-5 italic text-slate-500">(This clinic belongs to the same group of clinics shown below)</small>
        </div>
        <div class="profile-main-content-section mt-3">
            <div class="satelites-list-box w-full">
                <ul class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <li class="flex satelites-list-item cursor-pointer mb-3 px-2 py-3" v-for="satelite in satelites" @click.prevent="redirectTo(satelite)" v-if="satelite.ref !== targetSatelite">
                        <div class="img-box my-auto flex w-1/4">
                            <box-icon class="my-auto mx-auto cursor-pointer relative" type="solid" name='clinic' color="#ffff"></box-icon>
                        </div>
                        <div class="w-3/4 my-auto">
                            <div class="top-section">
                                <div class="my-auto mb-2 ml-auto"><small class="satelite-tag main-tag" v-if="satelite.head_office">Main clinic</small><small class="satelite-tag sat-tag" v-else>Satelite clinic</small></div>
                                <h5 class="my-auto mb-0 mr-4" >{{ satelite.name }}</h5>
                            </div>
                            <div class="satelite-card-subtitle"> <small class="italic">Based in {{ satelite.city }}</small></div>
                        </div>
                    </li>
                </ul>

            </div>
        </div>

    </div>
</template>


<script>

    export default {
        props: ['satelites', 'targetSatelite'],
        data() 
        {
            return {

            }
        },
        methods: {
            redirectTo(satelite)
            {
                window.open('/view-profile/'+satelite.ref+'/'+satelite.slug, '_blank');
            }
        },
        mounted() {

        }

    }
</script>