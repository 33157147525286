var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"flex"},[_c('button',{staticClass:"ml-auto p-2 rounded text-white font-bold bg-[#0BD2A4] mb-3",on:{"click":function($event){$event.preventDefault();_vm.openAddClinicModal = true}}},[_vm._v("Add New Clinic")])]),_vm._v(" "),_c('table-framework',{attrs:{"theads":_vm.theads,"tableTitle":'Your Clinics',"showTableSearch":true,"showTableToggle":true,"tableSearchText":_vm.searchInput,"showTableActionButton":false,"allSelected":_vm.allSelected,"toggle":_vm.toggle,"filteredOptions":_vm.filteredSateliteClinics,"selectedIds":_vm.selectedIds},on:{"textSearch":_vm.textSearch,"onToggleChange":_vm.onToggleChange}},[(_vm.filteredSateliteClinics.length > 0)?_c('tbody',{attrs:{"slot":"table-body"},slot:"table-body"},_vm._l((_vm.filteredSateliteClinics),function(clinic,index){return _c('tr',{key:clinic.id,class:{
              '': _vm.selectedIds.includes(clinic.ref),
              'bg-white': !_vm.selectedIds.includes(clinic.ref),
              'border-b': true,
              'dark:border-gray-700': index !== _vm.filteredSateliteClinics.length - 1,
              'hover:bg-gray-30': true,
              'dark:hover:bg-gray-600': true,
            }},[_c('th',{staticClass:"ml-2 py-4 px-6 whitespace-nowrap",class:_vm.selectedIds.includes(clinic.ref) && ''},[_c('InputToggle',{attrs:{"selectedIds":_vm.selectedIds,"element":clinic.ref},on:{"toggleSelected":_vm.toggleSelected}})],1),_vm._v(" "),_c('td',{staticClass:"py-2 px-6 image-td",class:_vm.selectedIds.includes(clinic.ref) && ''},[_c('img',{staticClass:"rounded-full w-12 h-12",attrs:{"src":clinic.image_path,"alt":""}})]),_vm._v(" "),_c('td',{staticClass:"py-4 px-6 font-medium text-gray-900",class:_vm.selectedIds.includes(clinic.ref) && ''},[_vm._v("\n              "+_vm._s(clinic.name)+"\n            ")]),_vm._v(" "),_c('td',{staticClass:"py-4 px-6 font-medium text-gray-900",class:_vm.selectedIds.includes(clinic.ref) && ''},[_vm._v("\n              "+_vm._s(clinic.ref)+"\n            ")])])}),0):_vm._e(),_vm._v(" "),(_vm.filteredSateliteClinics.length === 0)?_c('div',{staticClass:"mt-5"},[_c('p',[_vm._v("You don't have any satellite clinics yet.")])]):_vm._e()]),_vm._v(" "),(_vm.openAddClinicModal)?_c('UpdateCreateClinicModal',{attrs:{"chosenModal":'addClinic'},on:{"closeModal":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }