<template>
    <div  v-if="!loadFormdata" class="container">
        <div class="form-section flex py-4 pr-4">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup1.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup1.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup1.inputTitle }}
                        <span v-if="formData.components.componentGroup1.required" class="text-red-400">*</span>
                    </label>
                    <input
                    :type="formData.components.componentGroup1.inputType"
                    class="w-full" v-model="modalData.services"
                    :required="formData.components.componentGroup1.required"
                    :placeholder="formData.components.componentGroup1.placeholder"
                    :name="formData.components.componentGroup1.inputName"
                    @input="pushData"
                    />
                  <p v-if="forbiddenServiceMessage" class="text-red-700">Choose another name for your service. This service name will be used as a search criteria by potential clients. Make it unique to you service. For example, if you are a nutritionist, type 'Nutritionist Initial Consultation'.</p>
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup2.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup2.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup2.inputTitle }}
                        <span v-if="formData.components.componentGroup2.required" class="text-red-400">*</span>
                    </label>
                    <select
                    class="w-full" v-model="modalData.delivery_type"
                    :required="formData.components.componentGroup2.required"
                    :placeholder="formData.components.componentGroup2.placeholder"
                    :name="formData.components.componentGroup2.inputName"
                    @change="pushData"
                    >
                    <option>Please select an option</option>
                    <option value="online">Online</option>
                    <option value="domiciliary">Patient's house</option>
                    <option value="both">Both</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup3.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup3.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup3.inputTitle }}
                        <span v-if="formData.components.componentGroup3.required" class="text-red-400">*</span>
                    </label>
                    <select
                    class="w-full" v-model="modalData.pricing_modality"
                    :required="formData.components.componentGroup3.required"
                    :placeholder="formData.components.componentGroup3.placeholder"
                    :name="formData.components.componentGroup3.inputName"
                    @change="pushData"
                    >
                    <option>Please select an option</option>
                    <option value="fixed">Fixed Fee</option>
                    <option value="radius">Radius</option>
                    <option value="quote">Quote Based</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4" v-if="modalData.pricing_modality == 'fixed' ">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.components.componentGroup4.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.components.componentGroup4.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.components.componentGroup4.inputTitle }}
                        <span v-if="formData.components.componentGroup4.required" class="text-red-400">*</span>
                    </label>
                    <input
                    :type="formData.components.componentGroup4.inputType"
                    class="w-full" v-model="modalData.fixed_service_price"
                    :required="formData.components.componentGroup4.required"
                    :placeholder="formData.components.componentGroup4.placeholder"
                    :name="formData.components.componentGroup4.inputName"
                    @input="pushData"
                    />

                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4" v-if="modalData.pricing_modality == 'radius'">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">Radius Tier 1</div>
                <small class="font-sm text-gray-400">Insert the pricing for the specified miles radius range</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        Radius Tier 1
                        <span class="text-red-400">*</span>
                    </label>
                    <div class="flex">
                        <p class="mr-4 my-auto">Within</p>
                        <select
                            class="w-full" v-model="modalData.radius_miles_tier_1"
                            required
                            name="radius_miles_tier_1"
                            @change="pushData"
                            >
                            <option>Select an option</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </select>
                        <p class="mr-4 ml-2 my-auto">miles</p>
                        <p class="ml-4 mr-2 my-auto">£</p>
                        <input
                        type="number"
                        class="w-full" v-model="modalData.radius_price_tier_1"
                        required
                        name="radius_price_tier_1"
                        placeholder="i.e. 50"
                        @input="pushData"
                        />
                        <button class="ml-4 btn btn-sm" v-show="radius_tier_options == 1" @click.prevent="radius_tier_options++ ; pushData()">+</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4" v-if="modalData.pricing_modality == 'radius' && (radius_tier_options >= 2 || modalData.radius_miles_tier_2)">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">Radius Tier 2</div>
                <small class="font-sm text-gray-400">Insert the pricing for the specified miles radius range</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        Radius Tier 2
                        <span class="text-red-400">*</span>
                    </label>
                    <div class="flex">
                        <p class="mr-4 my-auto">Within</p>
                        <select
                            class="w-full" v-model="modalData.radius_miles_tier_2"
                            required
                            name="radius_miles_tier_1"
                            @change="pushData"
                            >
                            <option>Select an option</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </select>
                        <p class="mr-4 ml-2 my-auto">miles</p>
                        <p class="ml-4 mr-2 my-auto">£</p>
                        <input
                        type="number"
                        class="w-full" v-model="modalData.radius_price_tier_2"
                        required
                        name="radius_price_tier_2"
                        placeholder="i.e. 129.99"
                        @change="pushData"

                        />
                        <button class="ml-4 btn btn-sm" v-if="radius_tier_options == 2 || modalData.radius_miles_tier_2 && modalData.radius_price_tier_2 && radius_tier_options == 2 " @click.prevent="radius_tier_options += 1 ; pushData()">+</button>
                        <button class="ml-4 btn btn-sm" v-if="radius_tier_options == 2 || modalData.radius_miles_tier_2 && modalData.radius_price_tier_2 && radius_tier_options == 2 " @click.prevent="radius_tier_options-- ; removeOption(2) ; pushData()">-</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4" v-if="modalData.pricing_modality == 'radius' && (radius_tier_options >= 3 || modalData.radius_miles_tier_3)">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">Radius Tier 3</div>
                <small class="font-sm text-gray-400">Insert the pricing for the specified miles radius range</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        Radius Tier 3
                        <span class="text-red-400">*</span>
                    </label>
                    <div class="flex">
                        <p class="mr-4 my-auto">Within</p>
                        <select
                            class="w-full" v-model="modalData.radius_miles_tier_3"
                            required
                            name="radius_miles_tier_1"
                            @change="pushData"
                            >
                            <option>Select an option</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </select>
                        <p class="mr-4 ml-2 my-auto">miles</p>
                        <p class="ml-4 mr-2 my-auto">£</p>
                        <input
                        type="number"
                        class="w-full" v-model="modalData.radius_price_tier_3"
                        required
                        name="radius_price_tier_3"
                        placeholder="i.e. 200.99"
                        @input="pushData"
                        @change="pushData"

                        />
                        <button class="ml-4 btn btn-sm" v-if="radius_tier_options == 3 || radius_tier_options == 3 && modalData.radius_miles_tier_3 && modalData.radius_price_tier_3" @click.prevent="radius_tier_options += 1 ; pushData()">+</button>
                        <button class="ml-4 btn btn-sm" v-if="radius_tier_options == 3 || radius_tier_options == 3 && modalData.radius_miles_tier_3 && modalData.radius_price_tier_3" @click.prevent="radius_tier_options-- ; removeOption(3) ; pushData()">-</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4" v-if="modalData.pricing_modality == 'radius' && (radius_tier_options >= 4 || modalData.radius_miles_tier_4)">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">Radius Tier 4</div>
                <small class="font-sm text-gray-400">Insert the pricing for the specified miles radius range</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        Radius Tier 4
                        <span class="text-red-400">*</span>
                    </label>
                    <div class="flex">
                        <p class="mr-4 my-auto">Within</p>
                        <select
                            class="w-full" v-model="modalData.radius_miles_tier_4"
                            required
                            name="radius_miles_tier_1"
                            @change="pushData"
                            >
                            <option>Select an option</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </select>
                        <p class="mr-4 ml-2 my-auto">miles</p>
                        <p class="ml-4 mr-2 my-auto">£</p>
                        <input
                        type="number"
                        class="w-full" v-model="modalData.radius_price_tier_4"
                        required
                        name="radius_price_tier_4"
                        placeholder="i.e. 250.99"
                        @input="pushData"
                        @change="pushData"
                        />
                        <button class="ml-4 btn btn-sm" v-if="radius_tier_options == 4 || radius_tier_options == 4 && modalData.radius_miles_tier_4 && modalData.radius_price_tier_4" @click.prevent="radius_tier_options-- ; removeOption(4) ; pushData()">-</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddServiceModalForm from '../../../constants/services/modalForms/addServiceModalForm'

export default {
    props: ['target', 'type'],
    data()
    {
        return {
            modalData: {},
            formData: {},
            loadFormdata: true,
            radius_tier_options: 1,
            forbiddenServices:
                [
                    'initial consultation',
                    'consultation',
                    'consult',
                    'follow-up consultation',
                    'follow up consultation',
                    'follow-up',
                    'follow up'
                ],
                forbiddenServiceMessage: false
        }
    },
    computed: {
        ...mapGetters('practitioner', ['GET_ERRORS']),
    },
    methods: {
        pushData(){
            if(this.modalData.services && this.forbiddenServices.includes(this.modalData.services.toLowerCase()))
            {
              this.forbiddenServiceMessage = true;
            } else {
              this.forbiddenServiceMessage = false;
            }
            this.$emit('eventListener', this.modalData)
            this.$forceUpdate();
        },
        removeOption(n)
        {
            if(n == 1 ){
                this.modalData.radius_miles_tier_1 = null;
                this.modalData.radius_price_tier_1 = null;
            } else if(n == 2) {
                this.modalData.radius_miles_tier_2 = null;
                this.modalData.radius_price_tier_2 = null;
            } else if(n == 3) {
                this.modalData.radius_miles_tier_3 = null;
                this.modalData.radius_price_tier_3 = null;
            } else if(n == 4) {
                this.modalData.radius_miles_tier_4 = null;
                this.modalData.radius_price_tier_4 = null;
            }
        }
    },
    mounted()
    {
        this.formData = AddServiceModalForm;
        // If editing, pass in target services values
        if(this.target)
        {
            this.modalData.mainClinic = this.target.mainClinic;

            if(this.type == 'edit')
            {
               this.modalData.services = this.target.main_service_name;
               this.modalData.ref = this.target.ref;
               this.modalData.type = "service"

               // delivery type
               if(this.target.domiciliary == 1)
               {
                    this.modalData.delivery_type = 'domiciliary'

               } else if(this.target.virtual == 1) {
                    this.modalData.delivery_type = 'online'

               } else if(this.target.both_virtual_and_domiciliary == 1) {
                    this.modalData.delivery_type = 'both'

               }

               // pricings
               if(JSON.parse(this.target.pricings).pricing_modality == 'fixed')
               {
                    this.modalData.pricing_modality  = 'fixed'

               } else if(JSON.parse(this.target.pricings).pricing_modality == 'radius') {
                    this.modalData.pricing_modality  = 'radius'
               }

               // fixed price
               this.modalData.fixed_service_price = JSON.parse(this.target.pricings).fixed_service_price

               // tier 1
               this.modalData.radius_miles_tier_1 = JSON.parse(this.target.pricings).radius_tier.tier1.radius_miles_tier_1
               this.modalData.radius_price_tier_1 = JSON.parse(this.target.pricings).radius_tier.tier1.radius_miles_price_1

               // tier 2
               this.modalData.radius_miles_tier_2 = JSON.parse(this.target.pricings).radius_tier.tier2.radius_miles_tier_2
               this.modalData.radius_price_tier_2 = JSON.parse(this.target.pricings).radius_tier.tier2.radius_miles_price_2
               if(this.modalData.radius_miles_tier_2)
               {
                    this.radius_tier_options = 2
               }

               // tier 3
               this.modalData.radius_miles_tier_3 = JSON.parse(this.target.pricings).radius_tier.tier3.radius_miles_tier_3
               this.modalData.radius_price_tier_3 = JSON.parse(this.target.pricings).radius_tier.tier3.radius_miles_price_3
               if(this.modalData.radius_miles_tier_3)
               {
                    this.radius_tier_options = 3
               }

               // tier 4
               this.modalData.radius_miles_tier_4 = JSON.parse(this.target.pricings).radius_tier.tier4.radius_miles_tier_4
               this.modalData.radius_price_tier_4 = JSON.parse(this.target.pricings).radius_tier.tier4.radius_miles_price_4
               if(this.modalData.radius_miles_tier_4)
               {
                    this.radius_tier_options = 4
               }
            }
        }
    },
    watch: {
        formData: {
            handler(newValue) {
            this.loadFormdata = false
            }
        }
    }
}
</script>


<style lang="scss" scoped>

@import "vue-select/dist/vue-select.css";

</style>
