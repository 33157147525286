const complianceParameters = {
    "MAL": {
        "name": "Medical Malpractice Insurance",
        "short": "COMP-MAL",
        "file": "yes",
        "type": "MAL_PRAC_INS",
        "mandatory": "yes"
    },
    "PUBLIB": {
        "name": "Public Liability",
        "short": "COMP-PUB-LIB",
        "file": "yes",
        "type": "PUB_LIB",
        "mandatory": "yes"
    }
}

export default complianceParameters;
