<style lang="scss">
  @import "../../../../sass/components/modal";
</style>

<template>
  <transition name="fade">
    <div class="modal-overlay fixed inset-0 w-full h-screen flex items-center justify-center bg-semi-75" style="background-color:rgba(0,0,0,0.6);z-index:99;" @click="$emit('closeModal')">
      <div class="card-det modal-card  modal modal-wrapper w-full max-w-2xl bg-white shadow-lg rounded-lg addModal"  style="z-index:99;max-height: 600px; overflow-y: auto; position:relative">
        <div class="modal-container" @click.stop>
          <div class="modal">
            <!--<Loader v-if="GET_LOADER"/>-->
            <div class="modal-header block-title">
              <slot name="header">
                <div class="header-bit w-full flex">
                  <!-- placing the header we got from the computed property -->
                  <p class="card-title font-bold uppercase my-auto flex-grow mb-0"> {{ GET_WORKFLOW['title'] }}</p>
                  <a href=""><svg xmlns="http://www.w3.org/2000/svg"  @click="$emit('closeModal')" class=" ml-auto h-9 w-9 pt-3 pr-4 close-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg></a>
                </div>
              </slot>
            </div>
            <!-- progress stepper section -->
            <div class="stepper-section w-full flex my-3" v-if="GET_WORKFLOW_STEPS.length !== 0">
              <progress-stepper v-if="GET_WORKFLOW_STEPS.length !== 0" :steps="GET_WORKFLOW_STEPS"></progress-stepper>
            </div>
            <form @submit.prevent="submitForm(saveFunction)">
              <div  class="modal-body">
                <!-- here starts dynamic content -->
                <!-- <update-clinic-logo v-if="chosenModal === 'updateClinicLogo'" @close="updateClinicLogo = false" :formData="formData" :mainClinicInfo="MAIN_CLINIC"></update-clinic-logo> -->
                <update-clinic-legal-entity v-if="chosenModal === 'updateLegalEntity'" @close="updateLegalEntity = false" :formData="formData" :mainClinicInfo="MAIN_CLINIC"></update-clinic-legal-entity>
                <update-clinic-main-address v-if="chosenModal === 'updateMainAddress'" @close="updateMainAddress = false" :formData="formData" :mainClinicInfo="MAIN_CLINIC"></update-clinic-main-address>
                <update-clinic-payment-method :current_step="GET_ACTIVE_TAB" :payment_options="payment_options" v-if="chosenModal === 'updatePaymentMethodModal'" @close="updatePaymentMethodModal = false" :formData="formData" :mainClinicInfo="MAIN_CLINIC"></update-clinic-payment-method>
                <add-new-practitioner
                v-if="chosenModal === 'addPractitioner'"
                @close="addPractitionerModal = false"
                :formData="formData"
                >
                </add-new-practitioner>
                <add-satelite-clinic-modal-form
                v-if="chosenModal === 'addClinic'"
                @close="addClinicModal = false"
                :formData="formData"
                @setCity="setCity"
                >
                </add-satelite-clinic-modal-form >
                <edit-satelite-clinic-modal-form :satelite="GET_GRAB_SATELITE" v-if="chosenModal === 'editClinic'"  @close="editClinicModal = false"></edit-satelite-clinic-modal-form>
                <!-- here ends dynamic content -->
              </div>
              <div class="modal-footer text-justify">
                <slot name="footer">
                  <div class="action-buttons w-full flex">
                    <div class="my-auto">
                      <a href="" class="modal-default-button cancel-button text-gray-600" @click="$emit('closeModal')">
                        Cancel
                      </a>
                    </div>
                    <div class="actions ml-auto">
                      <div class="actions ml-auto">
                           <button class="back-button ml-auto py-2 px-4 border rounded-md cursor-pointer  back" @click.prevent="previousStep()" v-if="GET_WORKFLOW_STEPS.length !== 0" v-show="GET_ACTIVE_TAB>0">Back</button>
                          <button @click.prevent="nextStep(formData)" v-if="GET_WORKFLOW_STEPS.length !== 0" v-show="GET_ACTIVE_TAB<GET_WORKFLOW_STEPS.length-1" class="action-button ml-auto py-2 px-4 border rounded-md  cursor-pointer  next">Next</button>
                          <PrimaryButton :type="'submit'" v-if="GET_WORKFLOW_STEPS.length !== 0" v-show="GET_ACTIVE_TAB == GET_WORKFLOW_STEPS.length -1">Create</PrimaryButton>
                          <PrimaryButton  :is-disabled="loadingSubmitButton" :type="'submit'" v-if="GET_WORKFLOW_STEPS.length === 0">
                            <span v-if="loadingSubmitButton" class="spinner-border" role="status" aria-hidden="true"></span>
                            <span v-else>Save</span>
                          </PrimaryButton>
                      </div>
                      </div>
                  </div>
                </slot>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
  // importing the helpers for calling  getters from vuex
  import { mapActions, mapGetters } from 'vuex';
  import UpdateClinicLogo from './UpdateClinicLogo';
  import UpdateClinicLegalEntity from './UpdateClinicLegalEntity';
  import UpdateClinicMainAddress from './UpdateClinicMainAddress';
  import UpdateClinicPaymentMethod from './UpdateClinicPaymentMethod';
  import AddSateliteClinicModalForm from './AddSateliteClinicModalForm';
  import EditSateliteClinicModalForm from './EditSateliteClinicModalForm';
  import ProgressStepper from '../../Utilities/ProgressStepper';
  import Loader from '../../Loader.vue';
  import AddNewPractitioner from './AddNewPractitioner';
  import PrimaryButton from "../../PrimaryButton.vue";

  export default {
      props: {
        satellite: {
          default (rawProps) {
            return {name: ''}
          }
        },
        practitioner: {
          default (rawProps) {
            return {first_name: ''}
          }
        },
        chosenModal: {
          default: ''
        }
      },
      components: {
          PrimaryButton,
          Loader,
          AddNewPractitioner,
          ProgressStepper,
          UpdateClinicLogo,
          UpdateClinicLegalEntity,
          UpdateClinicMainAddress,
          UpdateClinicPaymentMethod,
          AddSateliteClinicModalForm,
          EditSateliteClinicModalForm
      },
      data() {
          return {
            formData: {},
            // list of modal IDs and header title
            modalDetails: [
              {
                modalId: 'updateLegalEntity',
                modalHeaderTitle: 'Update Legal Entity'
              },
              {
                modalId: 'updateMainAddress',
                modalHeaderTitle: 'Update Clinic Main Address'
              },
              {
                modalId: 'updatePaymentMethodModal',
                modalHeaderTitle: 'Update Payment Methods'
              },
              {
                modalId: 'addClinic',
                modalHeaderTitle: 'Add A New Clinic'
              },
              {
                modalId: 'addPractitioner',
                modalHeaderTitle: 'Add A New Practitioner'
              },
              {
                modalId: 'editClinic',
                modalHeaderTitle: 'Edit ' + this.satellite.name + ' Clinic'
              },
              {
                modalId: 'updateClinicLogo',
                modalHeaderTitle: 'Edit ' + this.satellite.name + ' Clinic'
              }
            ],
            loadingSubmitButton: false
          }
      },
      methods: {
        ...mapActions('mainClinic', ['loadWorkflow', 'nextStep', 'previousStep', 'saveClinic', 'toggleLoader', 'saveLegalEntity', 'getMainClinic', 'saveMainClinicAddress', 'savePaymentModality', 'getPaymentModality']),
        ...mapActions('practitioner', ['saveNewPractitioner']),
        // trigger the action when form is submitted
        submitForm(saveFnc)
        {
          this.loadingSubmitButton = true
          this.toggleLoader();
          eval('this.'+saveFnc+'(this.formData)')
          .then(() => {
            this.loadingSubmitButton = false
            this.toggleLoader();
            this.$emit('closeModal')
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: this.successMessage,
                showConfirmButton: false,
                timer: 1500
              }).then(() => {
                this.$emit('close');
                window.location.reload();
              })
          }).catch((e) => {
            this.toggleLoader();
            // toast.fire({
            //   icon: 'error',
            //   title: e.response.status+' Error'
            // });
          });
        },
        setCity(city) {
          this.formData.city = city
        }
      },
      computed: {
        // getting data options stored in the vuex state
        ...mapGetters('mainClinic', ['GET_PAYMENT_OPTIONS', 'GET_GRAB_SATELITE', 'GET_WORKFLOW', 'GET_WORKFLOW_STEPS', 'GET_ACTIVE_TAB', 'GET_LOADER', 'MAIN_CLINIC']),

        // computed property that returns the accurate header name of the actual modal
        accurateModalHeaderTitle() {
          return this.modalDetails.filter(modalId => modalId.modalId === this.chosenModal)
        },
        // getting the payment options from vuex
        payment_options() {
            return this.GET_PAYMENT_OPTIONS
        },

        // retrieves the save function in the workflow
        saveFunction()
        {
          return this.GET_WORKFLOW['saveFunction'];
        },

        // retrieves the success message
        successMessage()
        {
          return this.GET_WORKFLOW['successAddMessage']
        }

      },
      mounted() {
        this.getMainClinic();
        this.getPaymentModality()
        this.loadWorkflow(this.chosenModal);
      },
  }
</script>


