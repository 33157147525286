const complianceStatus = {
    0 : {
        'id' : 'todo',
        'name' : 'To do',
        'color': 'green',
        'secondChance': 'yes'
    },
    1 : {
        'id' : 'pendingreview',
        'name' : 'Pending review ',
        'color': 'orange',
        'secondChance': 'no'
    },
    2 : {
        'id' : 'revoked',
        'name' : 'Revoked',
        'color': 'red',
        'secondChance': 'yes'
    },
    3 : {
        'id' : 'expired',
        'name' : 'Expired',
        'color': 'red',
        'secondChance': 'yes'
    },
    4 : {
        'id' : 'furtherquestions',
        'name' : 'Further questions',
        'color': 'yellow',
        'secondChance': 'no'
    },
    5 : {
        'id' : 'approved',
        'name' : 'Approved',
        'color': 'green',
        'secondChance': 'no'
    }
}

export default complianceStatus;
