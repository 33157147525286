<template>
  <div class="container w-full flex">
    <div class=" bg-white shadow rounded mx-auto my-auto w-2/3 p-4">
      <div class="form-inner">
          <div class="title-section">
            <h2 class="text-center font-bold">Write a peer endorsement</h2>
            <p class="text-center">{{JSON.parse(data).clinic_name}} has invited you to write a peer endorsement for them</p>
          </div>
        <div class="form-field-section">
          <div class="w-full">
            <form class="rounded px-8 pt-6 pb-8 mb-4" @submit.prevent="submitForm">
              <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="full_name">
                  Your full name
                </label>
                <input class="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="full_name" type="text" placeholder="Insert your full name here" v-model="form.full_name">
              </div>
              <div class="mb-6">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="profession">
                  Your profession
                </label>
                <input class="shadow appearance-none border border-red-500 rounded w-full p-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="i.e. Nurse" v-model="form.profession">
              </div>
                <div class="mb-6">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="relationship">
                        How are you connected?
                    </label>
                    <select class="shadow appearance-none border border-red-500 rounded w-full p-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" v-model="form.relationship">
                        <option disabled selected>Pick an option</option>
                        <option v-for="(option, index) in JSON.parse(data).relationshipArr" :value="index">{{option}}</option>
                    </select>
                </div>
                <div class="mb-6">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="recommendation">
                        Your recommendation <small class="text-muted text-secondary">(Max {{maxCharacters}} characters)</small>
                    </label>
                    <textarea class="shadow appearance-none border border-red-500 rounded w-full p-3 text-gray-700 mb-1 leading-tight focus:outline-none focus:shadow-outline" placeholder="i.e. Andrea is an amazing practitioner" v-model="form.recommendation" @keyup="countCharacters" :maxlength="maxCharacters"></textarea>
                    <small class="text-muted text-secondary">Characters: {{charactersCount}}/{{maxCharacters}}</small>
                    <p class="text-red-700" v-if="charactersCount < minimumCharacters">Minimum 80 characters before you can submit.</p>
                </div>
              <div class="my-5">
                <p>By submitting this form, you are deemed to accept our terms and conditions.</p>
              </div>
              <div class="flex items-center justify-between">
                <button :disabled="charactersCount < minimumCharacters" :class="{'bg-gray-300 hover:bg-gray-300 text-gray-900': charactersCount < minimumCharacters}" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                  Submit
                </button>
              </div>
                <p class="error_message text-red-600 mt-3 p-3 text-center rounded bg-red-300" v-if="isIncomplete">All fields must be completed</p>
            </form>
        </div>
      </div>
    </div>
  </div>
  </div>

</template>

<script>
    import InputErrorMessage from '../../Utilities/InputErrorMessage.vue'
    import { mapGetters, mapActions } from 'vuex';
    import {isDisabled} from "bootstrap/js/src/util";
        export default {
            props: ['data'],
            components: {InputErrorMessage},
            data() {
                return {
                  form: {
                      id: JSON.parse(this.data).id,
                      clinic_ref: JSON.parse(this.data).clinic_ref,
                  },
                  isIncomplete: false,
                    maxCharacters: 300,
                    minimumCharacters: 80,
                    charCount: 0,
                    maxReached: false
                }
            },
            methods: {
              isDisabled,
                submitForm()
                {
                    if(!this.form.full_name)
                    {
                        this.isIncomplete = true;
                        return
                    } else if(!this.form.profession)
                    {
                        this.isIncomplete = true;
                        return
                    } else if(!this.form.relationship)
                    {
                        this.isIncomplete = true;
                        return
                    } else if(!this.form.recommendation)
                    {
                        this.isIncomplete = true;
                        return
                    }

                    this.isIncomplete = false;

                    axios.post('/peer-endorsement/send', this.form).then((res) => {
                       window.location.href = '/peer-endorsement/thank-you';
                    });
                },
                countCharacters()
                {
                  this.charCount = 0;
                    if(this.form.recommendation)
                    {
                      this.charCount = this.form.recommendation.length;
                    }

                    if(this.charCount >= this.maxCharacters)
                    {
                        this.maxReached = true;
                        return
                    } else {
                        this.maxReached = false;

                    }
                }
            },
            computed: {
                charactersCount()
                {
                   return this.charCount;
                }
            }
        }
</script>

<style lang="scss" scoped>
    .give-review-card{
        margin-top: 70px;
    }
    input, textarea , svg, select{
      cursor: pointer;
    }

</style>
