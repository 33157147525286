<template>
  <div>
     <div class="fixed bottom-4 right-4 flex justify-end" style="z-index:50;">
      <button @click="isOpen = !isOpen" class="p-0 w-12 h-12 bg-teal-500 hover:bg-teal-600 active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none rounded-full">
  <svg viewBox="0 0 20 20" class="w-6 h-6 mx-auto">
    <path fill="#FFFFFF" d="M17.857,3.143H2.143c-0.626,0-1.143,0.508-1.143,1.134v11.943c0,0.626,0.508,1.134,1.143,1.134h15.714c0.626,0,1.134-0.508,1.134-1.134V4.277C19.991,3.651,18.483,3.143,17.857,3.143z M17.857,4.277v1.062l-7.857,5.762L2.143,5.339V4.277H17.857z M17.857,14.019H2.143V6.443l7.857,5.762l7.857-5.762V14.019z"/>
  </svg>
</button>

     </div>
     <transition name="slide">
        <div v-if="isOpen" class="feedbackdrawer fixed top-0 right-0 h-full fit-content bg-white shadow-lg">
           <!-- Sidebar content -->

           <!-- Insert form and other components here -->
           <div class="flex justify-start p-4">
              <button
                 class="text-gray-500 hover:text-gray-800"
                 @click="isOpen = false"
                 >
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
                </svg>
              </button>
           </div>
          <div class="section-title">
            {{ workflow.title }}
          </div>
          <p class="font-sm text-gray-400">{{ workflow.description }}</p>

          <form method="POST"  @submit.prevent="submitForm(workflow.saveFunction)" class="modal-form">
            <div class="form-section my-4 flex px-4">
                <div class="form-section-info w-1/3">
                  <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ workflow.components.person.title }}
                  <span v-if="workflow.components.person.required" class="text-red-400">*</span>
                  </label>
                  <p class="font-sm text-gray-400 text-right">{{workflow.components.person.description }}</p>
                </div>
                <div class="form-section-content w-2/3 ml-5">
                  <div class="mb-3">
                      <select v-model="form.person" >
                        <option disabled>Select a person type</option>
                        <option :value="index" v-for="(personType, index) in workflow.components.person.content" :key="index">{{personType}}</option>
                      </select>
                      <InputErrorMessage v-if="ShowErrors && (!form.person || form.person === '')"/>
                  </div>
                </div>
            </div>
            <div class="form-section my-4 flex px-4">
                <div class="form-section-info w-1/3">
                  <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ workflow.components.type.title }}
                  <span v-if="workflow.components.type.required" class="text-red-400">*</span>
                  </label>
                  <p class="font-sm text-gray-400 text-right">{{workflow.components.type.description }}</p>
                </div>
                <div class="form-section-content w-2/3 ml-5">
                  <div class="mb-3">
                      <select v-model="form.type" >
                        <option disabled>Select a type</option>
                        <option :value="index" v-for="(Type, index) in workflow.components.type.content" :key="index">{{Type}}</option>
                      </select>
                      <InputErrorMessage v-if="ShowErrors && (!form.type || form.type === '')"/>
                  </div>
                </div>
            </div>
            <div class="form-section my-4 flex px-4">
                <div class="form-section-info w-1/3">
                  <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ workflow.components.message.title }}
                  <span v-if="workflow.components.message.required" class="text-red-400">*</span>
                  </label>
                  <p class="font-sm text-gray-400 text-right">{{workflow.components.message.description }}</p>
                </div>
                <div class="form-section-content w-2/3 ml-5">
                  <div class="mb-3">
                      <textarea
                        :type="workflow.components.message.inputType"
                        class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="form.message"
                        :required="workflow.components.message.required"
                        :placeholder="workflow.components.message.placeholder"
                        :name="workflow.components.message.inputName"
                        >
              </textarea>
                      <InputErrorMessage v-if="ShowErrors && (!form.message || form.message === '')"/>
                  </div>
                </div>
            </div>
            <div v-if="!loggedIn" class="form-section my-4 flex px-4">
                <div class="form-section-info w-1/3">
                  <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ workflow.components.follow_up.title }}
                  <span v-if="workflow.components.follow_up.required" class="text-red-400">*</span>
                  </label>
                  <p class="font-sm text-gray-400 text-right">{{workflow.components.follow_up.description }}</p>
                </div>
                <div class="form-section-content w-2/3 ml-5">
                  <div class="mb-3">

                        <label class="relative inline-flex items-center cursor-pointer">

                        <input
                        v-model="form.follow_up"
                        :type="workflow.components.follow_up.inputType"
                        class="sr-only peer"
                        @change="resetUserDetails"
                        :name="workflow.components.follow_up.inputName"
                        >
                          <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                          <span v-if="form.follow_up" class="ml-3 text-sm font-medium ">Yes</span>
                          <span v-else class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">No</span>
                        </label>
                      <InputErrorMessage v-if="ShowErrors && (!form.follow_up || form.follow_up === '')"/>
                  </div>
                </div>
            </div>
            <div v-if="!loggedIn && form.follow_up" class="form-section my-4 flex px-4">
                <div class="form-section-info w-1/3">
                  <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ workflow.components.name.title }}
                  <span v-if="workflow.components.name.required" class="text-red-400">*</span>
                  </label>
                  <p class="font-sm text-gray-400 text-right">{{workflow.components.name.description }}</p>
                </div>
                <div class="form-section-content w-2/3 ml-5">
                  <div class="mb-3">
                      <input
                        :type="workflow.components.name.inputType"
                        class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="form.name"
                        :required="workflow.components.name.required"
                        :placeholder="workflow.components.name.placeholder"
                        :name="workflow.components.name.inputName"
                        />
                      <InputErrorMessage v-if="ShowErrors && (!form.name || form.name === '')"/>
                  </div>
                </div>
            </div>
            <div v-if="!loggedIn && form.follow_up" class="form-section my-4 flex px-4">
                <div class="form-section-info w-1/3">
                  <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ workflow.components.email.title }}
                  <span v-if="workflow.components.email.required" class="text-red-400">*</span>
                  </label>
                  <p class="font-sm text-gray-400 text-right">{{workflow.components.email.description }}</p>
                </div>
                <div class="form-section-content w-2/3 ml-5">
                  <div class="mb-3">
                      <input
                        :type="workflow.components.email.inputType"
                        class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="form.email"
                        :required="workflow.components.email.required"
                        :placeholder="workflow.components.email.placeholder"
                        :name="workflow.components.email.inputName"
                        />
                      <InputErrorMessage v-if="ShowErrors && (!form.email || form.email === '')"/>
                  </div>
                </div>
            </div>
            <div class="cf-turnstile my-4 w-full"
                 :data-sitekey="cfkey"
                 data-callback="onTurnstileSuccess"
            ></div>
            <div class="flex mt-4">
              <button :disabled="loadingSubmitButton" type="submit" class="px-4 action-button ml-auto border cursor-pointer rounded-md submit-button ">
                <span v-if="loadingSubmitButton" class="spinner-border" role="status" aria-hidden="true"></span>
                <span v-else>{{ workflow.saveButton }}</span>
              </button>
            </div>
          </form>
        </div>
     </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
export default {
  props: ['cfkey'],
  components: {
      vSelect
  },
  data() {
    return {
      loadingSubmitButton: false,
      workflow: null,
      isOpen: false,
      ShowErrors: false,
      loggedIn:false,
      userData: null,
      form: {
        person: '',
        type: '',
        name: '',
        email: '',
        message: '',
        follow_up: false
      }
    };
  },

  methods: {
    ...mapActions('guestFunctions', ['loadHelpGuestWorkflow', 'saveNewTicket']),
    ...mapActions('setup', ['getUserData']),
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    submitForm(saveFnc)
    {
        this.loadingSubmitButton = true
        if(this.form.person === ''
          || this.form.type === ''
          || this.form.message ===  ''
          || this.form.follow_up === null) {
            Swal.fire({
                toast: true,
                icon: 'error',
                title: 'Please fill the form correctly',
                animation: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true
            });

        this.loadingSubmitButton = false
        } else {
            eval('this.' + saveFnc + '(this.form)')
                .then(res => {
                    Swal.fire({
                    icon: res.status === 201 ? 'success' : 'error',
                    title: res.status === 201 ? "Report submitted" : 'Something went wrong. Try Again',
                    text: res.status === 201 ?
                    (this.form.follow_up || this.loggedIn ? 'Thank you for your feedback. Your opinion is important to us and we\'ll do everything we can to help you. We will keep you updated.' :
                    'Thank you for your feedback. Your opinion is important to us.')
                    : 'Please try again.',
                    animation: true,
                    showConfirmButton: false,
                    timer: res.status === 201 ? 4500 : 4500,
                    }).then(() => {

                        this.form.person = ''
                        this.form.type = ''
                        this.form.name = ''
                        this.form.email = ''
                        this.form.message = ''
                        this.form.follow_up = false

                        this.isOpen = false

                        this.loadingSubmitButton = false
                      });

                    if (res.status === 201) {
                      // do something

                    }
                })
                .catch(() => {
                    Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Something went wrong. Contact support',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true
                    });

                  this.loadingSubmitButton = false
                });

        }
    },
    resetUserDetails() {
      this.form.name = ''
      this.form.email = ''
    }
  },
  mounted() {
    this.loadHelpGuestWorkflow('helpCenter')
    .then(res => {
      this.workflow = res.data
    });
    this.getUserData()
    .then(res => {
      this.loggedIn = true
      this.userData = res.data
      this.form.name = this.userData.first_name + ' ' + this.userData.last_name
      this.form.email = this.userData.email
    }).catch(e => {
    })
  }
};
</script>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
.feedbackdrawer{
  z-index: 999;
  padding: 20px;
  overflow-y: auto;
}
.action-button{
  width: fit-content;
}
</style>
