<style scoped>
.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 660px;
  position: relative;
  z-index: 0;
  margin-bottom: 24px;
}
.stepper-progress {
  position: absolute;
  background-color: #c5c5c5;
  height: 2px;
  z-index: -1;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.stepper-progress-bar {
  position: absolute;
  left: 0;
  height: 100%;
  width: 0%;
  background-color: #0EE2B1;
  transition: all 500ms ease;
}

.stepper-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #c5c5c5;
  transition: all 500ms ease;
}
.stepper-item-counter {
  height: 30px;
  width: 30px;
  display: grid;
  place-items: center;
  background-color: #fff;
  border-radius: 100%;
  border: 2px solid #c5c5c5;
  position: relative;
}
.stepper-item-counter .icon-success {
  position: absolute;
  opacity: 0;
  transform: scale(0);
  width: 10px;
  transition: all 500ms ease;
}
.stepper-item-counter .number {
  font-size: 10px;
  transition: all 500ms ease;
}
.stepper-item-title {
  position: absolute;
  font-size: 14px;
  bottom: -24px;
}

.stepper-item.success .stepper-item-counter {
  border-color: #0EE2B1;
  background-color: #D2F7EF;
  color: #fff;
  font-weight: 600;
}
.stepper-item.success .stepper-item-counter .icon-success {
  opacity: 1;
  transform: scale(1);
}
.stepper-item.success .stepper-item-counter .number {
  opacity: 0;
  transform: scale(0);
}
.stepper-item.success .stepper-item-title {
  color: #0EE2B1;
}

.stepper-item.current .stepper-item-counter {
  border-color: #0EE2B1;
  background-color: #0EE2B1;
  color: #fff;
  font-weight: 600;
}
.stepper-item.current .stepper-item-title {
  color: #818181;
}

.stepper-pane {
  color: #333;
  text-align: center;
  padding: 120px 60px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.09);
  margin: 40px 0;
}

</style>

<template>
    <div class="stepper mx-auto">
        <div class="stepper-progress">
            <div class="stepper-progress-bar" :style="'width:' + stepperProgress"></div>
        </div>
        <div class="stepper-item" :class="{ 'current': GET_ACTIVE_TAB == index, 'success': GET_ACTIVE_TAB > index}" v-for="(step, index) in steps" :key="index">
            <div class="stepper-item-counter">
                <img class="icon-success" src="https://www.seekpng.com/png/full/1-10353_check-mark-green-png-green-check-mark-svg.png" alt="">
                <span class="number">
                    {{ index+1 }}
                </span>
            </div>
            <span class="stepper-item-title">
                {{ step }}
            </span>
        </div>
    </div>

</template>

<script>
import { mapGetters } from 'vuex';

    export default
    {
        props: {
            steps: Array
        },
        data() {
            return {
                
            }
        },
        computed: {
            ...mapGetters('mainClinic', ['GET_ACTIVE_TAB']),
            stepperProgress() {
                return ( 100 / this.steps.length  ) * ( this.GET_ACTIVE_TAB +1 ) + '%'
            }
        },

        
    }
</script>