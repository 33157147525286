import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth'
import setup from './modules/setup'
import search from './modules/search'
import profile from './modules/profile';
import mainClinic from './modules/mainClinic';
import commonFunctions from './modules/commonFunctions';
import practitioner from './modules/practitioner';
import guestFunctions from './modules/guestFunctions';
import satelite from './modules/satelite';
import service from './modules/service';
import reviews from './modules/reviews';
import account from './modules/account';
import practitionerCompliances from './modules/practitionerCompliances';
import log from './modules/log';
import analytics from './modules/analytics';


Vue.use(Vuex);

export const store = new Vuex.Store({

    modules: {
        auth,
        setup,
        profile,
        mainClinic,
        commonFunctions,
        practitioner,
        guestFunctions,
        search,
        satelite,
        service,
        reviews,
        practitionerCompliances,
        account,
        log,
        analytics
    },
});