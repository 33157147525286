<template>
    <div  v-if="!loadFormdata" class="container">
        <div class="form-section flex py-4 pr-4">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.publications.components.componentGroup1.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.publications.components.componentGroup1.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.publications.components.componentGroup1.inputTitle }}
                        <span v-if="formData.publications.components.componentGroup1.required" class="text-red-400">*</span>
                    </label>
                    <input
                    :type="formData.publications.components.componentGroup1.inputType"
                    class="w-full" v-model="modalData.name"
                    :required="formData.publications.components.componentGroup1.required"
                    @input="pushData"
                    />
                </div>
            </div>
        </div>
        <div class="form-section flex py-4 pr-4 border-top ">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formData.publications.components.componentGroup2.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formData.publications.components.componentGroup2.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formData.publications.components.componentGroup2.inputTitle }}
                        <span v-if="formData.publications.components.componentGroup2.required" class="text-red-400">*</span>
                    </label>
                    <input
                    :type="formData.publications.components.componentGroup2.inputType"
                    class="w-full" v-model="modalData.url"
                    :required="formData.publications.components.componentGroup2.required"
                    @input="pushData"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import publicationFormData from '../../../../../public/json/workflows.json'

export default {
    props: ['target'],
    data()
    {
        return {
            modalData: {},
            formData: {},
            loadFormdata: true
        }
    },
    computed: {
        ...mapGetters('practitioner', ['GET_ERRORS'])
    },
    methods: {
        pushData(){
            this.$emit('eventListener', this.modalData)
        }
    },
    mounted()
    {
        this.formData = publicationFormData;
        // If editing, pass in target publication values
        if(this.target)
        {
            this.modalData.id = this.target.id;
            this.modalData.ref = this.target.ref;
            this.modalData.name = this.target.publication_name;
            this.modalData.url = this.target.publication_url;
        }
    },
    watch: {
        formData: {
            handler(newValue) {
            this.loadFormdata = false
            }
        }
    }
}
</script>


<style lang="scss" scoped>

@import "vue-select/dist/vue-select.css";

</style>
