<template>
    <div>
        <div class="form-section flex py-4 pr-4" v-for="formComponent in formData.components">
            <div class="form-section-info w-1/3">
                <div class="form-section-info-title">{{ formComponent.sectionTitle }}</div>
                <small class="font-sm text-gray-400">{{ formComponent.sectionExplenation }}</small>
            </div>
            <div class="form-section-content w-2/3 ml-5">
                <div class="mb-3">
                    <label>
                        {{ formComponent.inputTitle }}
                        <span v-if="formComponent.required" class="text-red-400">*</span>
                    </label>
                    <input 
                    :type="formComponent.inputType" 
                    class="w-full" v-model="modalData[formComponent.vModel]" 
                    :required="formComponent.required"
                    @input="pushData"
                    :name="formComponent.inputName"
                    :class="{'border-red-500': GET_ERRORS !== ''}"
                    />
                    <p v-if="GET_ERRORS" class="text-red-400 font-sm pt-1">* {{ GET_ERRORS }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';


    
export default {
    props: ['clinic', 'formData'],
    data()
    {
        return {
            modalData: {}
        }
    },
    methods:
    {
        pushData() {
            this.$emit('eventListener', this.modalData)
        },

    },
    computed: {
        ...mapGetters('satelite', ['GET_ERRORS']),
    },
    mounted()
    {
        if(this.clinic)
        {
            this.modalData = this.clinic
        }
    }
}
</script>