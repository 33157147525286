<template>
    <div class="">
        <ul class="px-2 py-3">
            <sidebar-item v-for="sideItem in dashboardItems" :listItem="sideItem" :key="sideItem.title"></sidebar-item>
        </ul>
    </div>
</template>


<script>
import SidebarItem from './SidebarItem.vue';
    export default {
        props: [
            'dashboardItems'
        ],
        components: {
            SidebarItem
        }
    }
</script>