import { render, staticRenderFns } from "./SinglePractitionerTopbar.vue?vue&type=template&id=9efa33b6&scoped=true"
import script from "./SinglePractitionerTopbar.vue?vue&type=script&lang=js"
export * from "./SinglePractitionerTopbar.vue?vue&type=script&lang=js"
import style0 from "./SinglePractitionerTopbar.vue?vue&type=style&index=0&id=9efa33b6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9efa33b6",
  null
  
)

export default component.exports