import axios from 'axios';
export default {
    namespaced: true,
    state: {
        logs: [],
    },
    getters: {
      GET_LOGS(state)
      {
        return state.logs
      },
    },
    actions:{
      setLogs({commit}, payload)
      {
          axios.post('/api/dashboard/logs', {payload})
          .then((res) => {
              commit('FILL_LOGS', res.data)
          }).catch((e) => {
              console.log(e)
          });
      },
    },
    mutations: {
      FILL_LOGS(state, data)
      {
          state.logs = data
      }
    },

}
