<template>
    <div class="container">

        <table-framework
            :theads="theads"
            :tableTitle="'Your clinics'"
            :showTableActionButton="true"
            :tableButtonText="'Add Clinic'"
            @tableAction="openUpdateClinicModal('addClinic')"
        >
            <tbody slot="table-body" class="table-body-text">
                <tr class="" v-if="satellites.length == 0">
                    <p class="p-3 mx-2">You don't have any clinics yet. </p>
                </tr>

                <tr v-else  :class="satellite.head_office === 1 ? 'bg-info text-white' : 'bg-white'" v-for="satellite in satellites" :key="satellite.id">
                        <td scope="row" class="px-6  whitespace-nowrap  image-td">
                            <img @click="openUpdateModal(satellite.image_path, satellite.id)" class="roundImage ml-2 w-50 bg-light" :src="satellite.image_path" />
                        </td>
                        <td scope="row" class="py-4 px-6  whitespace-nowrap">
                            {{satellite.name}}
                        </td>
                        <td class="py-4 px-6">
                            {{satellite.city}}
                        </td>
                        <td class="py-4 px-6">
                            {{satellite.postcode}}
                        </td>
                        <td class="py-4 px-6 my-auto">
                          <a class="mx-2 btn btn-sm" role="button" :href="'/view-profile/'+satellite.ref+'/'+satellite.name" target="_blank">View</a>

                          <a class="mx-2 btn btn-sm" role="button" :href="'/dashboard/clinic/view/'+satellite.ref">Edit</a>
                                <button v-if="satellite.head_office !== 1" @click.prevent="deleteModal = true" class="mx-2" name="Delete Service"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" style="color:red">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                    </svg>
                                </button>

                            <update-create-clinic-modal class="text-left" :satellite="satellite" :chosenModal="chosenModal" v-if="updateClinicModal"></update-create-clinic-modal>
                            <DeleteClinicOrPractitionerModal v-if="deleteModal"  :title="'Delete a clinic'" :deleteType="'removeClinic'" :idToDelete="satellite.id" @close="deleteModal = false" />
                        </td>
                    </tr>
            </tbody>
         </table-framework>

        <UpdateProfileImagesModal @closeModal="closeModal" v-if="UpdateProfileImagesModal" :idConcerned="idConcerned" :pictureConcerned="SatelliteImagePath" :chosenModal="modalId"/>


    </div>

</template>

<script>
import axios from 'axios';
import TableFramework from '../../Table.vue';
import UpdateCreateClinicModal from './UpdateCreateClinicModal';
import DeleteClinicOrPractitionerModal from './DeleteClinicOrPractitionerModal';
import UpdateProfileImagesModal from '../Clinics/UpdateProfileImagesModal.vue';
import allClinicsTableHeads from '../../../constants/satelites/allClinicsTableHeads';
import { mapMutations, mapActions } from 'vuex';
export default {
    props: ['satellites'],
    components: {
        TableFramework,
        UpdateCreateClinicModal,
        UpdateProfileImagesModal,
        DeleteClinicOrPractitionerModal
    },
    data() {
        return {
            theads: allClinicsTableHeads,
            deleteModal: false,
            updateClinicModal: false,
            chosenModal: '',

            addClinicModal: false,
            editClinicModal: false,
            sateliteClinic: {},

            SatelliteImagePath: '',
            modalId: '',
            idConcerned: '',
            UpdateProfileImagesModal: false,

        }
    },
    methods: {
        ...mapMutations('mainClinic', ['storingGrabSateliteMutation']),
        ...mapActions('mainClinic', ['getUserSateliteClinics']),
        openUpdateClinicModal(modalName)
        {
            this.chosenModal = modalName
            this.updateClinicModal = true
        },
        grabSatelite(satellite)
        {
            this.storingGrabSateliteMutation(satellite)
        },
        openUpdateModal(SatelliteImagePath, idConcerned){
            this.UpdateProfileImagesModal = true
            this.SatelliteImagePath = SatelliteImagePath
            this.idConcerned = idConcerned
            this.modalId = 'updateSatelliteClinicLogo'
        },
        closeModal(){
            this.updateCreateClinicModal = false
        }
    },
    mounted()
    {
        this.getUserSateliteClinics();
    }
}

</script>

<style lang="scss" scoped>
.roundImage{
    width: 50px  !important;
    border-radius: 50px;
}
</style>
