import axios from 'axios';
export default {
    namespaced: true,
    state: {
        searchResults: {},
        searchFilteredResults: {},
        filteredResults: {},
        fiters: [],
        activeCoords: [
            {
                lat: '',
                lon: ''
            },
        ]
    },
    getters: {
        GET_SEARCH_RESULTS(state)
        {
            return state.searchResults;
        },
        GET_FILTERED_SEARCH_RESULTS(state)
        {
            return state.searchFilteredResults;
        },
        GET_ACTIVE_COORDS(state)
        {
            return state.activeCoords
        }
    },
    actions: {
        searchProviders({commit}, payload) {
            if(payload.method == 'service' ||payload.method == 'postcode' || payload.method == 'town')
            {
                var url = '/search/get-results';
            } else if(payload.method == 'clinic') {
                var url = '/search/get-results/clinic-name';
            } else  if(payload.method == 'practitioner') {
                var url = '/search/get-results/practitioner';
            } else  if(payload.method == 'condition') {
                var url = '/search/get-results/condition';
            } else  if(payload.method == 'profession') {
                var url = '/search/get-results/profession';
            }

            axios.post(url, payload).then(res => {
                commit('SET_SEARCH_RESULTS', res.data);
                commit('SET_FILTERED_SEARCH_RESULTS', res.data);
            });

        },
        CHANGE_ACTIVE_COORDINATES({commit}, payload)
        {
            commit('SET_ACTIVE_COORDINATES', payload)
        },
        getServicesForSearch({commit})
        {
            return new Promise((resolve, reject) => {
                axios.get('/search/getServicesForSearch')
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getProfessionsForSearch({commit})
        {
            return new Promise((resolve, reject) => {
                axios.get('/search/getProfessionsForSearch')
                    .then(response => {
                        resolve(response)
                    }).catch(error => {
                    reject(error)
                })
            })
        },
        getConditionsForSearch({commit})
        {
            return new Promise((resolve, reject) => {
                axios.get('/search/getConditionsForSearch')
                .then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    mutations: {
        SET_SEARCH_RESULTS(state, results)
        {
            state.searchResults = results;
        },
        SET_FILTERED_SEARCH_RESULTS(state, results)
        {
            state.searchFilteredResults = results;
        },
        SET_ACTIVE_COORDINATES(state, coords)
        {
            state.activeCoords[0].lat = coords[0],
            state.activeCoords[0].lon = coords[1]
        }
    },
}
