<template>
    <div class="">
        <div class="w-full mb-2">
            <associate-clinic-to-practitioner :practitionerRef="practitionerRef"></associate-clinic-to-practitioner>
        </div>
    </div>

</template>

<script>
import AssociateClinicToPractitioner from './TabComponents/AssociateClinicToPractitioner.vue';
import { mapGetters } from 'vuex';
export default {
    props:['practitionerRef'],
    components: {
        AssociateClinicToPractitioner
    },
    computed: {
        // ...mapGetters('practitioner', ['GET_PRACTITIONER']),
    }
}
</script>