<style lang="scss">
    .profile-small-header-section {
        border-bottom: 2px solid #0BD2A4;

        h1 {
            font-size: 25px;
            line-height: 12px;
            color: #717170;
            font-weight: 700;
            padding-bottom: 5px;
        }
    }

    .profile-main-content-section {
        p {
            color: #717170;
            font-size: 17px;
            line-height: 28px;
            white-space: pre-wrap;
        }
    }
    .profile-main-content-section {
        .practitioner-list-box {
            .practitioner-list-item {
                list-style: none!important;
                margin-right: 80px;

                img {
                    border-radius: 50%;
                    height: 180px;
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 7px 0px;
                }

                h5 {
                    color: #717170;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

        }
    }
</style>
<template>
    <div class="section-wrapper w-full">
        <div class="profile-small-header-section flex">
            <h1>Online Booking</h1>
        </div>
        <div class="profile-main-content-section mt-3">
            <div class="practitioner-list-box flex-wrap flex">
                <iframe :src="booking_url" width="100%" height="400px" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['booking_url'],

    }
</script>