<template>
    <div class="mx-5">

        <div class="top-bar-search my-auto flex">
            <form @submit.prevent="initSearch()" class="w-full flex">
                <select name="search_type" v-model="search_type" id=""
                    class="cursor-pointer appearance-none w-full text-gray-700 mx-2 py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white"
                    @change="getSearchType()" style="border-radius: 20px;">
                    <option disabled selected>Search By</option>
                    <option value="profession">Profession</option>
                    <option value="service">Service</option>
                    <option value="clinic">Clinic</option>
                    <option value="practitioner">Professional</option>
                    <option value="condition">Condition</option>
                </select>
                <select v-if="profession_group" @click="toggleChooseProfession"
                        class="cursor-pointer appearance-none w-full text-gray-700 mx-2 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white"
                        name="service" v-model="profession" id="">
                        <option :value="profession"> {{ profession !== '' ? profession : 'Choose a profession' }} </option>
                </select>
                <select v-if="service_group" @click="toggleChooseService"
                    class="cursor-pointer appearance-none w-full text-gray-700 mx-2 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white"
                    name="service" v-model="service" id="">
                    <option :value="service"> {{ service !== '' ? service : 'Choose a service' }} </option>
                </select>
                <select v-if="condition_group" @click="toggleChooseCondition"
                    class="cursor-pointer appearance-none w-full text-gray-700 mx-2 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white"
                    name="service" v-model="condition" id="" style="border:none; border-radius:0">
                    <option :value="condition"> {{ condition !== '' ? condition : 'Choose a condition' }} </option>
                </select>
                <input v-if="location_group" type="text" v-model="location" @input="handleCityInput" name="location"
                    class="mx-2" placeholder="Enter postcode or city">
                <div v-if="showCityResults" class="z-10 bg-white border border-gray-300 rounded-lg mt-1 w-full">
                    <div v-for="(result, index) in autocompleteCityOptions" :key="index"
                        class="py-2 px-4 cursor-pointer hover:bg-blue-100" @click="selectCityResult(result)">
                        {{ result }}
                    </div>
                </div>
                <input v-if="practitioner_group" type="text" v-model="practitioner" name="practitioner" class="mx-2"
                    placeholder="Enter a practitioner">
                <input v-if="clinic_group" type="text" v-model="clinic" name="clinic" class="mx-2"
                    placeholder="Enter a clinic">
                <button v-if="!loadingData" type="submit"
                    class="flex items-center btn bg-blue-500 text-white rounded-lg shadow px-4 py-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                        <path fill-rule="evenodd"
                            d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                            clip-rule="evenodd" />
                    </svg>
                    <span>Search</span>
                </button>
            </form>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import CreateUpdateModal from '../CreateUpdateModal.vue'
import SearchServicesChoosingModalContent from '../Search/SearchServicesChoosingModalContent.vue'
import SearchConditionChoosingModalContent from '../Search/SearchConditionChoosingModalContent.vue'

import VueTypedJs from 'vue-typed-js'

export default {
    components: {
        CreateUpdateModal,
        SearchServicesChoosingModalContent,
        SearchConditionChoosingModalContent
    },
    props: ['last_query', 'servicesession', 'last_query_method', 'professionsession'],
    data() {
        return {
            SearchModal: false,
            // attributes necessary for the city autocomplete
            showCityResults: false,
            autocompleteCityOptions: [],

            service: this.servicesession,
            profession: this.professionsession,
            search_type: this.last_query_method,
            location: this.last_query,
            practitioner: this.last_query,
            clinic: this.last_query,
            condition: this.last_query,
            location_group: true,
            practitioner_group: false,
            profession_group: true,
            clinic_group: false,
            service_group: false,
            condition_group: false,
            // variable for storing all services
            allservices: [],
            ChooseService: false,
            // variable for storing all conditions
            allconditions: [],
            ChooseCondition: false,
            // storing user coordiantes
            userLat: null,
            userLng: null,
            loadingData: false
        }
    },
    methods: {
        ...mapActions('search', ['getServicesForSearch', 'getConditionsForSearch']),
        closeSearchModal() {
            this.SearchModal = false
        },
        getSearchType() {
          if (this.search_type == 'profession') {
            this.location_group = true;
            this.profession_group = true,
            this.service_group = false,
                this.practitioner_group = false;
            this.clinic_group = false;
            this.condition_group = false;
          } if (this.search_type == 'service') {
                this.location_group = true;
                this.service_group = true,
                    this.profession_group = false,
                    this.practitioner_group = false;
                this.clinic_group = false;
                this.condition_group = false;
            } else if (this.search_type == 'clinic') {
                this.location_group = false;
                this.service_group = false,
                    this.profession_group = false,
                    this.practitioner_group = false;
                this.clinic_group = true;
                this.condition_group = false;
            } else if (this.search_type == 'practitioner') {
                this.location_group = false;
                this.service_group = false,
                    this.profession_group = false,
                    this.practitioner_group = true;
                this.clinic_group = false;
                this.condition_group = false;
            } else if (this.search_type == 'condition') {
                this.location_group = false;
                this.service_group = false,
                    this.profession_group = false,
                    this.practitioner_group = false;
                this.clinic_group = false;
                this.condition_group = true;
            }
        },
        toggleChooseService() {
            this.ChooseService = true
        },
        toggleChooseProfession() {
          this.ChooseProfession = true
        },
        toggleChooseCondition() {
            this.ChooseCondition = true
        },
        closeChooseService() {
            this.ChooseService = false
        },
        closeChooseCondition() {
            this.ChooseCondition = false
        },
        setServiceChosen(value) {
            this.service = value.main_service_name
        },
        setConditionChosen(value) {
            this.condition = value.name
        },
        getCurrentPosition() {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    position => resolve(position),
                    error => reject(error)
                );
            })
        },
        initSearch() {
            if (this.location) {
                var location = this.location
            } else {
                var location = "&location=null"
            }
            if (this.service) {
                var service = this.service
            } else {
                var service = "&service=null"
            }
            if (this.profession) {
              var profession = this.profession
            } else {
              var profession = "&profession=null"
            }
            if (this.practitioner) {
                var practitioner = this.practitioner
            } else {
                var practitioner = "&practitioner=null"
            }
            if (this.clinic) {
                var clinic = this.clinic
            } else {
                var clinic = "&clinic=null"
            }
            if (this.condition) {
                var condition = this.condition
            } else {
                var condition = "&condition=null"
            }
            // checking if it's s service and the form is invalid
            if (this.search_type === 'service' && this.service === '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please choose a service',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            } else if (this.search_type === 'service' && this.location === '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please enter a valid postcode or a city',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            } else if (this.search_type === 'clinic' && this.clinic === '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please enter a valid clinic name',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            } else if (this.search_type === 'practitioner' && this.practitioner === '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please enter a valid practitioner name',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            } else if (this.search_type === 'condition' && this.condition === '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please choose a valid condition name',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
            }
            else {
                if (this.userLat !== null && this.userLng !== null) {
                    window.location.href = "/init-search/" + this.search_type + "/" + location + "/" + practitioner + "/" + clinic + "/" + condition + "/" + service + "/" + this.userLat + "/" + this.userLng;
                } else {
                    window.location.href = "/init-search/" + this.search_type + "/" + location + "/" + practitioner + "/" + clinic + "/" + condition + "/" + service;
                }
            }
        },

        // method to handle the city input
        async handleCityInput() {
            const city = this.location.trim();
            if (city === '' || city.includes('/')) {
                this.showCityResults = false;
            } else {
                if (this.location.length >= 2) {
                    try {
                        if (/\d/.test(this.location)) {
                            // Calling the postcode API for autocomplete postcodes
                            const responsePostcodes = await axios.get(`https://api.postcodes.io/postcodes/${encodeURIComponent(city)}/autocomplete`);
                            const result = responsePostcodes.data.result;
                            const autocompleteOptions = result && Array.isArray(result) ? result.slice(0, 5) : [];
                            this.autocompleteCityOptions = autocompleteOptions;
                        } else {
                            // Calling the API for getting the cities
                            const responseCities = await axios.get('/search/get-cities/' + city);
                            let displayNames = null;
                            // Assigning only the display_name column
                            if (Array.isArray(responseCities.data)) {
                                displayNames = responseCities.data.map(function (item) {
                                    return item.display_name;
                                });
                            }
                            // Limit to 5 options
                            this.autocompleteCityOptions = displayNames ? displayNames.slice(0, 5) : [];
                        }






                        this.showCityResults = true;
                    } catch (error) {
                        console.error(error);
                    }

                }
            }

        },
        selectCityResult(result) {
            this.location = result;
            this.showCityResults = false;
        },
    },
    watch: {
        search_type: {
            handler(newValue) {
                if (newValue === 'practitioner' || newValue === 'clinic' || newValue === 'condition') {
                    this.loadingData = true
                    this.getCurrentPosition()
                        .then(position => {
                            this.userLat = position.coords.latitude;
                            this.userLng = position.coords.longitude;
                            this.loadingData = false
                        })
                        .catch(error => {
                            console.error(error);
                            this.loadingData = false
                        });
                }
            },
        },
    },
    created() {
        this.getSearchType()
        this.getServicesForSearch()
            .then(res => {
                this.allservices = res.data
                const updatedResponse = this.allservices.map(item => {
                    const capitalizedServiceName = item.main_service_name.charAt(0).toUpperCase() + item.main_service_name.slice(1);
                    return {
                        ...item,
                        main_service_name: capitalizedServiceName
                    };
                });
                this.allservices = updatedResponse
            })
        this.getConditionsForSearch()
            .then(res => {
                this.allconditions = res.data
                const updatedResponse = this.allconditions.map(item => {
                    const capitalizedServiceName = item.name.charAt(0).toUpperCase() + item.name.slice(1);
                    return {
                        ...item,
                        name: capitalizedServiceName
                    };
                });
                this.allconditions = updatedResponse
            })
        if (this.search_type === 'practitioner' || this.search_type === 'clinic' || this.search_type === 'condition') {
            this.loadingData = true
            this.getCurrentPosition()
                .then(position => {
                    this.userLat = position.coords.latitude;
                    this.userLng = position.coords.longitude;
                    this.loadingData = false
                })
                .catch(error => {
                    console.error(error);
                    this.loadingData = false
                });
        }
    },
    mounted() {
        document.addEventListener('click', (event) => {
            if (!this.$el.contains(event.target)) {
                this.showCityResults = false;
            }
        });
    },
}
</script>

<style lang="scss" scoped>
.cityresult {
    position: relative;
    z-index: 99999 !important;
}
</style>
