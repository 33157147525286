<template>
  <div class="user-profile">
    <img @click="modalOpen = true" :src="practitioner.image_path" alt="Profile image" class="w-40 h-40 mb-3 rounded-full shadow-lg">
    <span v-if="checkingCompliance === 'COMPLIANT'" class="inline-block w-4 h-4 mr-2 bg-green-600 rounded-full"></span>
    <span v-else-if="checkingCompliance === 'NON-COMPLIANT'" class="inline-block w-4 h-4 mr-2 bg-red-600 rounded-full"></span>
    <span v-else class="inline-block w-4 h-4 mr-2 bg-orange-600 rounded-full"></span>
    <a v-if="checkingCompliance === 'NON-COMPLIANT'" class="linkdecoration cursor-pointer mx-2" @click="modalOpen = true">
      <h2 class="user-name">{{ capitalizedTitle }} {{ practitioner.first_name }} {{ practitioner.last_name }}</h2>
    </a>
    <h2 v-else class="user-name cursor-pointer " @click="modalOpen = true">{{ capitalizedTitle }} {{ practitioner.first_name }} {{ practitioner.last_name }}</h2>
    <span class="italic text-sm text-gray-500 dark:text-gray-400">{{ capitalizedProfession }}</span>
    <create-update-modal v-if="modalOpen" @close="modalOpen = false;" :show-action-button="false" :title="'Practitioner Compliance'">
      <div class="" slot="content">
        <complianceDetailsModal :checkingCompliance="checkingCompliance" :practitioner="practitioner" />
      </div>
    </create-update-modal>
  </div>
</template>

<script>
import CreateUpdateModal from '../../CreateUpdateModal.vue';
import complianceDetailsModal from './complianceDetailsModal.vue';

export default {
  props: ['practitioner'],
  components: {
    complianceDetailsModal,
    CreateUpdateModal,
  },
  data() {
    return {
      modalOpen: false,
      checkingCompliance: 'COMPLIANT',
    };
  },
  computed: {
    capitalizedTitle() {

      if (!this.practitioner.title) {
        return '';
      }
      const title = this.practitioner.title.charAt(0).toUpperCase() + this.practitioner.title.slice(1);
      return title;
    },
    capitalizedProfession() {
      if (!this.practitioner.profession) {
        return 'Not yet assigned';
      }
      const profession = this.practitioner.profession.charAt(0).toUpperCase() + this.practitioner.profession.slice(1);
      return profession;
    },
  },
  created() {
    if (this.practitioner.compliances.length === 0) {
      this.checkingCompliance = 'NON-COMPLIANT';
    } else {
      this.practitioner.compliances.forEach((element) => {
        if (element.status == 1) {
          this.checkingCompliance = 'PENDING';
          return;
        }
        if (element.status != 5) {
          this.checkingCompliance = 'NON-COMPLIANT';
          return;
        }
          if (element.status == 5) {
              this.checkingCompliance = 'COMPLIANT';
              return;
          }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.user-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.user-name {
  margin-top: 10px;
  font-size: 17px;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .user-profile {
    flex-direction: column;
  }

  .profile-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }

  .user-name {
    margin-top: 0;
    font-size: 17px;
  }
}

.clinicLogo {
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4), 0px 8px 15px rgba(0, 0, 0, 0.2);
  border-radius: 100%;
}

.linkdecoration {
  text-decoration: none;
}
</style>
