<template>
    <div class="rounded my-2">
      <b-badge pill variant="success" :style="{'background-color': verified ? '#ccedb7!important' : '#e5e8f0!important'}"><p class="capitalize mb-0"><span v-if="!verified">un</span>verified profile</p></b-badge>
    </div>
</template>

<script>
  export default {
    name: "ProfileVerificationStatusBadge",
    props: {
      verified: {
        type: Boolean,
        default: false,
        required: true
      }
    }
  }
</script>


<style scoped lang="scss">

</style>
