<style scoped>
    .contact-label {
        color: #344767!important;
        font-size: 15px;
        font-weight: 700;
    }

    .edit-icon {
        width: 18px;
    }

    .not-added-field {
        opacity: .2;
    }
</style>


<template>
    <div class="card  bio-wrap border-none shadow-lg p-4 h-full">
        <div class="card-h flex">
            <h5 class="text-md font-semibold card-title my-auto">Clinic Contacts</h5>
            <div class="ml-auto cursor-pointer edit-icon flex" @click="toggleEdit()">
                <Icon :icon="editIcon" class="my-auto"></Icon>
            </div>
        </div>
        <div class="pt-4 mt-4 w-full h-full">
            <div class="flex my-1">
                <div class="w-1/3">
                    <p class="contact-label">Email</p>
                </div>
                <div class="w-2/3">
                    <p class="card-inner-text text-slate-500">{{email.email}}</p>
                </div>
            </div>
            <div class="flex my-1">
                <div class="w-1/3">
                    <p class="contact-label">Website</p>
                </div>
                <div class="w-2/3">
                    <p class="text-sm not-added-field" v-if="!editMode && !website.web_link">Not added</p>
                    <p class="card-inner-text text-slate-500" v-if="!editMode && website.web_link">{{website.web_link}}</p>
                    <input v-if="editMode" type="url" class="change-on-focus shadow appearance-none rounded w-full focus:border-yellow-500 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    v-model="website.web_link"
                    placeholder="i.e. https://www.medimob.co.uk"
                    @change="saveWebsite(website)"
                    @keyup="alterWebsiteSavedStatus()"
                    :class="{'saved-input' : this.savedWebsite}"
                    >
                </div>
            </div>
            <div class="flex my-1">
                <div class="w-1/3">
                    <p class="contact-label">Telephone Number</p>
                </div>
                <div class="w-2/3">
                    <p class="text-sm not-added-field" v-if="!editMode && !telephone.tel_num">Not added</p>
                    <p class="card-inner-text text-slate-500" v-if="!editMode && telephone.tel_num" >{{telephone.tel_num}}</p>
                    <input v-if="editMode" type="tel" class="change-on-focus shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    v-model="telephone.tel_num"
                    placeholder="Your Landline"
                    @change="saveTelephone(telephone)"
                    @keyup="alterTelephoneSavedStatus()"
                    :class="{'saved-input' : this.savedTelephone}"
                    >
                </div>
            </div>
            <div class="flex my-1">
                <div class="w-1/3">
                    <p class="contact-label">Mobile Number</p>
                </div>
                <div class="w-2/3">
                    <p class="text-sm not-added-field" v-if="!editMode && !mobile.mob_num">Not added</p>
                    <p class="card-inner-text text-slate-500" v-if="!editMode && mobile.mob_num" >{{mobile.mob_num}}</p>
                    <input v-if="editMode" type="tel" class="change-on-focus shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    v-model="mobile.mob_num"
                    placeholder="Your Mobile Number"
                    @change="saveMobile(mobile)"
                    @keyup="alterMobileSavedStatus()"
                    :class="{'saved-input' : this.savedMobile}"
                    >
                </div>
            </div>
            <div class="flex my-1">
                <div class="w-1/3">
                    <p class="contact-label">Instagram</p>
                </div>
                <div class="w-2/3">
                    <p class="text-sm not-added-field" v-if="!editMode && !instagram.ig_link">Not added</p>
                    <p class="card-inner-text text-slate-500" v-if="!editMode && instagram.ig_link" >{{instagram.ig_link}}</p>
                    <input v-if="editMode" type="url" class="change-on-focus shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    v-model="instagram.ig_link"
                    placeholder="i.e. https://www.medimob.co.uk"
                    @change="saveInstagram(instagram)"
                    @keyup="alterInstagramSavedStatus()"
                    :class="{'saved-input' : this.savedInstagram}"
                    >
                </div>
            </div>
            <div class="flex my-1">
                <div class="w-1/3">
                    <p class="contact-label">Facebook</p>
                </div>
                <div class="w-2/3">
                    <p class="text-sm not-added-field" v-if="!editMode && !facebook.fb_link">Not added</p>
                    <p class="card-inner-text text-slate-500" v-if="!editMode && facebook.fb_link" >{{facebook.fb_link}}</p>
                    <input v-if="editMode" type="url" class="change-on-focus shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    v-model="facebook.fb_link"
                    placeholder="i.e. https://www.medimob.co.uk"
                    @change="saveFacebook(facebook)"
                    @keyup="alterFacebookSavedStatus()"
                    :class="{'saved-input' : this.savedFacebook}"
                    >
                </div>
            </div>
            <div class="flex my-1">
                <div class="w-1/3">
                    <p class="contact-label">Twitter</p>
                </div>
                <div class="w-2/3">
                    <p class="text-sm not-added-field" v-if="!editMode && !twitter.tw_link">Not added</p>
                    <p class="card-inner-text text-slate-500" v-if="!editMode && twitter.tw_link" >{{twitter.tw_link}}</p>
                    <input v-if="editMode" type="url" class="change-on-focus shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    v-model="twitter.tw_link"
                    placeholder="i.e. https://www.medimob.co.uk"
                    @change="saveTwitter(twitter)"
                    @keyup="alterTwitterSavedStatus()"
                    :class="{'saved-input' : this.savedTwitter}"
                    >
                </div>
            </div>
            <div class="flex my-1">
                <div class="w-1/3">
                    <p class="contact-label">LinkedIn</p>
                </div>
                <div class="w-2/3">
                    <p class="text-sm not-added-field" v-if="!editMode && !linkedin.ld_link">Not added</p>
                    <p class="card-inner-text text-slate-500" v-if="!editMode && linkedin.ld_link" >{{linkedin.ld_link}}</p>
                    <input v-if="editMode" type="url" class="change-on-focus shadow appearance-none rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    v-model="linkedin.ld_link"
                    placeholder="i.e. https://www.medimob.co.uk"
                    @change="saveLinkedIn(linkedin)"
                    @keyup="alterLinkedInSavedStatus()"
                    :class="{'saved-input' : this.savedLinkedIn}"
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Icon from '../../Icon';

export default {
    props: ['head_office'],
    components: {
        Icon
    },

    data() {
        return {
            editMode: false,
            editIcon: 'edit-square',
            email: {
                clinic_id: this.head_office.id,
                email: this.head_office.email,
            },
            website: {
                clinic_id: this.head_office.id,
                web_link: this.head_office.website,
            },
            telephone: {
                clinic_id: this.head_office.id,
                tel_num: this.head_office.telephone,
            },
            mobile: {
                clinic_id: this.head_office.id,
                mob_num: this.head_office.mobile_phone,
            },
            instagram: {
                clinic_id: this.head_office.id,
                ig_link: this.head_office.instagram,
            },
            facebook: {
                clinic_id: this.head_office.id,
                fb_link: this.head_office.facebook,
            },
            twitter: {
                clinic_id: this.head_office.id,
                tw_link: this.head_office.twitter,
            },
            linkedin: {
                clinic_id: this.head_office.id,
                ld_link: this.head_office.linkedin,
            },
            errors: ''

        }
    },
    computed: {
        ...mapState('profile', ['headOffice', 'savedWebsite', 'errorModal', 'formErrors', 'savedInstagram', 'savedFacebook', 'savedTwitter', 'savedLinkedIn', 'savedTelephone', 'savedMobile', 'savedEmail']),
        checkErrors()
            {
                return this.errorModal;
            }
    },
    methods: {
        ...mapActions('profile',
            [
                'saveEmail',
                'saveWebsite',
                'saveTelephone',
                'saveMobile',
                'saveInstagram',
                'saveTwitter',
                'saveFacebook',
                'saveLinkedIn',
                'alterEmailSavedStatus',
                'alterWebsiteSavedStatus',
                'alterInstagramSavedStatus',
                'alterFacebookSavedStatus',
                'alterTwitterSavedStatus',
                'alterLinkedInSavedStatus',
                'alterTelephoneSavedStatus',
                'alterMobileSavedStatus'
            ]
        ),
        toggleEdit()
        {
            if(!this.editMode)
            {
                this.editMode = true
                this.editIcon = 'check_circle'
            } else {
                this.editMode = false
                this.editIcon = 'edit-square'
            }
        }
    },
    watch: {
            checkErrors()
            {
                if(this.errorModal){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: ''+this.formErrors+'',
                    });
                }
            }
        }
}
</script>
