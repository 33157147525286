<template>
    <div class="container">

        <div class="main-page mt-5">
            <div class="display-all-container mt-5">
                <div class="flex flex-col md:flex-row">
                    <div class="w-full md:w-2/6 md:mr-2 mb-2 md:mb-0">
                        <table-framework
                            :theads="theadsBasic"
                            :tableTitle="'Service info'"
                            :showTableActionButton="true"
                            :tableButtonText="'Edit Service Basic Info'"
                            @tableAction="openAddServiceModal()"
                             >
                            <tbody slot="table-body">
                                <tr>
                                    <td style="padding: 12px 25px!important">Service Name</td>
                                    <td style="padding: 12px 25px!important">{{ service.main_service_name }}</td>
                                </tr>
                                <tr>
                                    <td style="padding: 12px 25px!important">Delivery Type</td>
                                    <td style="padding: 12px 25px!important">
                                        <p class="mb-0" v-if="service.virtual === 1">Online</p>
                                        <p class="mb-0" v-if="service.domiciliary === 1">Domiciliary</p>
                                        <p class="mb-0" v-if="service.both_virtual_and_domiciliary === 1">Both Online & Domiciliary</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding: 12px 25px!important">Pricing Modality</td>
                                    <td style="padding: 12px 25px!important">
                                        <p class="mb-0" v-if="JSON.parse(this.service.pricings).pricing_modality == 'fixed'">Fixed Fee</p>
                                        <p class="mb-0" v-if="JSON.parse(this.service.pricings).pricing_modality == 'radius'">Radius Based</p>
                                        <p class="mb-0" v-if="JSON.parse(this.service.pricings).pricing_modality == 'both'">Both Fixed & Radius Based</p>
                                        <p class="mb-0" v-if="JSON.parse(this.service.pricings).pricing_modality == 'quote'">Quote Based</p>
                                    </td>
                                </tr>
                                <tr v-if="JSON.parse(this.service.pricings).pricing_modality == 'fixed'">
                                    <td style="padding: 12px 25px!important">Fixed Price Fee</td>
                                    <td style="padding: 12px 25px!important">
                                        <p class="mb-0">£ {{ JSON.parse(this.service.pricings).fixed_service_price }}</p>
                                    </td>
                                </tr>
                                <tr v-if="JSON.parse(this.service.pricings).pricing_modality == 'radius' && JSON.parse(this.service.pricings).radius_tier.tier1.radius_miles_tier_1 && JSON.parse(this.service.pricings).radius_tier.tier1.radius_miles_price_1">
                                    <td style="padding: 12px 25px!important">Radius Tier 1</td>
                                    <td style="padding: 12px 25px!important">
                                        <p class="mb-0">£{{ JSON.parse(this.service.pricings).radius_tier.tier1.radius_miles_price_1 }} within {{ JSON.parse(this.service.pricings).radius_tier.tier1.radius_miles_tier_1  }} miles</p>
                                    </td>
                                </tr>
                                <tr v-if="JSON.parse(this.service.pricings).pricing_modality == 'radius' && JSON.parse(this.service.pricings).radius_tier.tier2.radius_miles_tier_2 && JSON.parse(this.service.pricings).radius_tier.tier2.radius_miles_price_2">
                                    <td style="padding: 12px 25px!important">Radius Tier 2</td>
                                    <td style="padding: 12px 25px!important">
                                        <p class="mb-0">£{{ JSON.parse(this.service.pricings).radius_tier.tier2.radius_miles_price_2 }} within {{ JSON.parse(this.service.pricings).radius_tier.tier2.radius_miles_tier_2  }} miles</p>
                                    </td>
                                </tr>
                                <tr v-if="JSON.parse(this.service.pricings).pricing_modality == 'radius' && JSON.parse(this.service.pricings).radius_tier.tier3.radius_miles_tier_3 && JSON.parse(this.service.pricings).radius_tier.tier3.radius_miles_price_3">
                                    <td style="padding: 12px 25px!important">Radius Tier 3</td>
                                    <td style="padding: 12px 25px!important">
                                        <p class="mb-0">£{{ JSON.parse(this.service.pricings).radius_tier.tier3.radius_miles_price_3 }} within {{ JSON.parse(this.service.pricings).radius_tier.tier3.radius_miles_tier_3  }} miles</p>
                                    </td>
                                </tr>
                                <tr v-if="JSON.parse(this.service.pricings).pricing_modality == 'radius' && JSON.parse(this.service.pricings).radius_tier.tier4.radius_miles_tier_4 && JSON.parse(this.service.pricings).radius_tier.tier4.radius_miles_price_4">
                                    <td style="padding: 12px 25px!important">Radius Tier 4</td>
                                    <td style="padding: 12px 25px!important">
                                        <p class="mb-0">£{{ JSON.parse(this.service.pricings).radius_tier.tier4.radius_miles_price_4 }} within {{ JSON.parse(this.service.pricings).radius_tier.tier4.radius_miles_tier_4  }} miles</p>
                                    </td>
                                </tr>

                            </tbody>
                        </table-framework>
                    </div>
                    <div class="w-full md:w-4/6 md:ml-2">
                        <table-framework
                            :theads="theads"
                            :tableTitle="'Service Extras'"
                            :showTableActionButton="true"
                            :tableButtonText="'Add Service Extras'"
                            @tableAction="openAddServiceExtrasModal()"
                             >
                            <tbody slot="table-body">
                                <tr class="" v-if="service.childServices == 0">
                                    <p class="p-3 mx-2">No services added yet.</p>
                                </tr>
                                <tr v-else v-for="child in service.childServices">
                                    <td style="padding: 12px 25px!important">{{ child.main_service_name }}</td>
                                    <td style="padding: 12px 25px!important" v-if="JSON.parse(child.pricings).pricing_modality == 'surcharge'">Surcharge of £{{JSON.parse(child.pricings).surcharge_service_price }} </td>
                                    <td style="padding: 12px 25px!important" v-if="JSON.parse(child.pricings).pricing_modality == 'included'">Included in main service price</td>
                                    <td class="flex" style="padding: 12px 25px!important">
                                        <a class="mx-2 btn btn-sm" role="button" @click.prevent="openEditServiceExtrasModal(child)">Edit</a>
                                        <button @click.prevent="deleteService(child.id)" class="mx-2" name="Delete Service"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" style="color:red">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table-framework>
                    </div>
                </div>

                <create-update-modal
                   v-if="toggleServiceModal || toggleServiceExtrasModal"
                   @close="toggleServiceModal = false; toggleServiceExtrasModal = false; target = service"
                   :title="modalTitle"
                   :buttonText="modalButtonText"
                   @save="update(modalData)"
                >
                 <div class="" slot="content">
                        <AddServiceModalContent v-if="toggleServiceModal" @eventListener="updateModalData($event)" :target="target" :type="'edit'"></AddServiceModalContent>
                        <AddChildServiceModalContent v-if="toggleServiceExtrasModal" @eventListener="updateModalData($event)" :target="target" :type="'add'"></AddChildServiceModalContent>
                 </div>

              </create-update-modal>
            </div>
        </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import service from '../../../store/modules/service';
import TableFramework from '../../Table.vue';
import CreateUpdateModal from '../../CreateUpdateModal.vue';
import AddChildServiceModalContent from './AddChildServiceModalContent.vue';
import clinicServicesTableHeads from '../../../constants/services/clinicServicesTableHeads';
import SingleServiceCategoryBasicInfo from './SingleServiceCategoryBasicInfo.vue';
import AddServiceModalContent from './AddServiceModalContent.vue';



export default {
    props: ['service'],
    components: {
        TableFramework,
        CreateUpdateModal,
        AddChildServiceModalContent,
        SingleServiceCategoryBasicInfo,
        AddServiceModalContent
    },
    data() {
        return {
            crumbs: [
                {name: 'Services', url: '/dashboard/services'},
                {name: 'View & Edit', url: '/dashboard/services/view-service/'+this.service.clinic_ref+'/'+this.service.ref},
                {name: this.service.ref, url: '#'},
            ],
            theads: clinicServicesTableHeads,
            theadsBasic: [
                "Input",
                "Value"
            ],
            toggleServiceModal: false,
            toggleServiceExtrasModal: false,
            modalData: {},
            modalTitle: "",
            modalButtonText: "",
            scenario: "",
            target: this.service
        }
    },
    methods: {
        ...mapActions('commonFunctions', ['loadBreadcrumbs']),
        ...mapActions('service', ['saveService', 'updateService', 'addExtra', 'editExtra', 'deleteService']),
            openAddServiceModal()
            {
                this.toggleServiceModal = true;
                this.modalTitle = "Edit Service";
                this.modalButtonText = "Update",
                this.scenario = "editService"
            },
            openAddServiceExtrasModal()
            {
                this.toggleServiceExtrasModal = true;
                this.modalTitle = "Add Service Extras",
                this.modalButtonText = "Add Extra",
                this.scenario = "addExtra"
            },
            updateModalData(data)
            {
                this.modalData = data;
            },
            update(modalData)
            {
                if(this.scenario == 'editService')
                {
                    this.updateService(modalData)

                } else if(this.scenario == 'addExtra')
                {
                    this.addExtra(modalData)
                } else if(this.scenario == 'editExtra') {
                    this.editExtra(modalData)
                }
            },
            openEditServiceExtrasModal(child)
            {
                this.modalButtonText = 'Update';
                this.modalTitle = 'Edit Service Extra';
                this.toggleServiceExtrasModal = true;
                this.target = child;
                this.scenario = 'editExtra';
            }

    },
    mounted()
    {
        this.loadBreadcrumbs(this.crumbs);
    }

}
</script>
