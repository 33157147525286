<style lang="scss">
    .profile-small-header-section {
        border-bottom: 2px solid #0BD2A4;

        h1 {
            font-size: 25px;
            line-height: 12px;
            color: #717170;
            font-weight: 700;
            padding-bottom: 5px;
        }
    }

    .profile-main-content-section {
        p {
            color: #717170;
            font-size: 17px;
            line-height: 28px;
            white-space: pre-wrap;
        }
    }
    .profile-main-content-section {
        .practitioner-list-box {
            .practitioner-list-item {
                list-style: none!important;
                margin-right: 80px;

                img {
                    border-radius: 50%;
                    height: 180px;
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 7px 0px;
                }

                h5 {
                    color: #717170;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

        }
    }
</style>
<template>
    <div class="section-wrapper w-full">
        <div class="profile-small-header-section flex">
            <h1>Practitioners</h1>
        </div>
        <div class="profile-main-content-section mt-3">
            <div class="practitioner-list-box flex-wrap flex">
                <li class="practitioner-list-item cursor-pointer my-3 flex flex-col" v-for="practitioner in practitioners" @click.prevent="goToPractitionerPage(practitioner)">
                    <img :src="practitioner.img" :alt="practitioner.full_name + ' Medimob profile picture'" class="mx-auto">
                    <div class="mt-3">
                        <h5 class="my-auto m-0 mx-aut text-center">{{ practitioner.full_name }}</h5>
                        <p class="italic text-center ">{{ practitioner.profession }}</p>
                    </div>

                </li>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['practitioners'],
        methods: {
          goToPractitionerPage(practitioner)
          {
            var url = practitioner.ref + '/' + practitioner.full_name.replaceAll(' ', '-').toLowerCase();
            window.open('/view-profile/practitioner/' + url, '_blank');
          }
        }

    }
</script>
