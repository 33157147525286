<template>
    <tr>
        <td>{{ compliance.parameter }}</td>
        <td>{{ compliance.mandatory }}</td>
        <td>
            <span :class="colorClass">{{ compStatus[compliance.status].name }}</span>
        </td>
        <td>
            <div  v-popover.top="{name: 'tooltipf'+ compliance.id}" flow="right"   class="relative hoverable">
                <svg width="20px" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"></path>
                </svg>
                <div id="tooltip-default" role="tooltip" class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                
                <div class="tooltip-arrow" data-popper-arrow></div>
                </div>
            </div>
            <popover class="poptooltip" :name="'tooltipf' + compliance.id" :event="'hover'">
            Date Submitted : {{dateSubmitted }} <br>
            <span>Date Reviewed : {{ dateReviewed   }}</span> 
            </popover>
        </td>
        <td>
 
            <button v-if="compStatus[compliance.status].secondChance === 'yes'"  @click.prevent="openUploadFileModal(compliance)" type="button" class="rounded-lg px-3 py-2 text-xs font-medium text-center text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">Upload File</button>
        </td>

        <UploadFileModalContent  v-if="toggleUploadFileModal" @close="toggleUploadFileModal = false;" @done="doneSuccessfully" :compliance="compliance" :practitionerRef="practitionerRef" @eventListener="updateModalData($event)"></UploadFileModalContent>


    </tr>
</template>


<script>
import CreateUpdateModal from '../../../CreateUpdateModal.vue';
import UploadFileModalContent from './UploadFileModalContent.vue' 
import complianceStatus from '../../../../constants/practitioners/complianceStatus.js';
import { mapGetters, mapActions } from 'vuex';
export default {
    components: { 
        CreateUpdateModal,
        UploadFileModalContent
    },
    props: ['compliance', 'practitionerRef'],
    data()
    {
        return {
            compStatus: complianceStatus,
            selectedCompliance: '',
            toggleUploadFileModal: false,
            modalData: {}
        }
    },
    methods: {   
        ...mapActions('practitionerCompliances', ['getPractitionerCompliancesAction', 'saveUploadFile']),
        openUploadFileModal(selectedCompliance)
        {
            this.selectedCompliance = selectedCompliance
            this.toggleUploadFileModal = true;
        },
        updateModalData(data)
        {
            this.modalData = data;
        },
        closeModal(){
            this.toggleUploadFileModal = false
        },
        doneSuccessfully(){
            this.toggleUploadFileModal = false
            this.$emit('callPractitonerCompliances')
        }
    },
    computed:{
        ...mapGetters('practitionerCompliances', ['getLoadingSubmitButton']),
        colorClass() {
            return 'bg-'+ this.compStatus[this.compliance.status].color +'-100 text-'+ this.compStatus[this.compliance.status].color +'-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded  dark:text-gray-700 border border-'+ this.compStatus[this.compliance.status].color +'-400'
        },
        dateSubmitted() {
            if(this.compliance.date_submitted === null) {
                return 'Not yet submitted'
            }else {
                var newDate = new Date(this.compliance.date_submitted);

                const year = newDate.getFullYear();
                const month = newDate.toLocaleString('default', { month: 'long' }); // JavaScript months are zero-indexed, so add 1
                const day = newDate.getDate();

                return day+' '+ month+ ' ' +year
            }
        },
        dateReviewed() {
            if(this.compliance.date_reviewed === null) {
                return 'Not yet reviewed'
            }else {

                var newDate = new Date(this.compliance.date_reviewed);
            
                const year = newDate.getFullYear();
                const month = newDate.toLocaleString('default', { month: 'long' }); // JavaScript months are zero-indexed, so add 1
                const day = newDate.getDate();

                return day+' '+ month+ ' ' +year
            }
        }
    }
}
</script>

<style lang="scss" scoped>

[data-popover='tooltipf'] {

  font-size: 12px;
  line-height: 1.5;
  margin-left: -120px;
}
.poptooltip{
  font-size: 12px;
  line-height: 1.5;
  margin-left: -120px;
}
</style>
