<template>
    <div class="mt-8  h-full container position-relative section-separation">
       <div class="hero-inner flex are-you-a-practitioner-section h-full mx-auto">
          <div class="w-1/2 section">
             <!-- here the left side one i removed -->
             <div class="container w-full">
                <div class="text-box mx-auto">
                   <h3 class="howto-sub-title mb-4">Are you a</h3>
                   <h3 class="howto-sub-title accent-text" style="line-height: 45px">Healthcare Practitioner <span class="howto-sub-title">?</span> </h3>
                   <p class="mod-text mt-5">Medimob welcomes you to join our innovative platform that opens up new possibilities for
                       delivering healthcare services. At Medimob, we understand the importance of empowering
                       practitioners to provide quality care, and we offer a unique set of advantages to support your
                       professional journey.</p>
                </div>
                <div class="rounded-lg pt-4 mr-5">
                   <div class="text-left grid grid-cols-1 gap-4">
                      <div class="py-2 rounded-lg flex">
                         <span class="pr-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73" fill="none">
                               <rect width="73" height="73" rx="20" fill="#F1F4FB"/>
                               <circle cx="36" cy="37" r="12" stroke="#599DE0" stroke-width="2"/>
                            </svg>
                         </span>
                         <div class="flex">
                            <p class="mod-text my-auto">Whether you specialize in telehealth consultations or in-home visits, our
                                platform connects you with individuals seeking your expertise, breaking down geographical barriers.</p>
                         </div>
                      </div>
                      <div class="py-2 rounded-lg flex">
                         <span class="pr-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73" fill="none">
                               <rect width="73" height="73" rx="20" fill="#F1F4FB"/>
                               <circle cx="36" cy="37" r="12" stroke="#599DE0" stroke-width="2"/>
                            </svg>
                         </span>
                         <div class="flex">
                            <p class="mod-text my-auto">Enjoy the flexibility of managing your schedule and services. You have the freedom
                                to choose when and where you provide your services, allowing you to strike the right balance
                                between professional and personal commitments.</p>
                         </div>
                      </div>
                      <div class="py-2 rounded-lg flex">
                         <span class="pr-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="73" height="73" viewBox="0 0 73 73" fill="none">
                               <rect width="73" height="73" rx="20" fill="#F1F4FB"/>
                               <circle cx="36" cy="37" r="12" stroke="#599DE0" stroke-width="2"/>
                            </svg>
                         </span>
                         <div class="flex">
                            <p class="mod-text my-auto">Benefit from increased visibility in the healthcare community and attract new
                                patients who are actively seeking your expertise.</p>
                         </div>
                      </div>
                   </div>
                    <div class="btn-sec mb-5">
                        <a role="button" href="/for-practitioners" class="flex mt-5 blue-home-button btn items-center my-auto w-1/5 bg-blue-500 text-white rounded-lg px-4 py-2">
                            <span>Find out more</span>
                        </a>
                    </div>
                </div>
             </div>
          </div>
          <div class="w-1/2 section">
             <div class="h-full img-wrap">
                <div class="flex w-full h-full">
                    <div class="flex mt-auto w-full md-handling">
                        <img src="/images/therapy_img.png" alt="">
                    </div>
                    <div class="flex flex-col my-auto w-full md-handling">
                        <div class="w-full my-3">
                            <img src="/images/back_therapy.png" alt="">
                        </div>
                        <div class="w-full my-3">
                            <img src="/images/geriatric.png" alt="">
                        </div>
                    </div>

                </div>
             </div>
          </div>
       </div>
    </div>
 </template>
<script>
export default {

}
</script>
