<template>
    <div class="">
        <div class="w-full mb-2">
            <edit-association-single-practitioner :association="GET_PRACTITIONER.association_name" :practitioner="GET_PRACTITIONER"></edit-association-single-practitioner>
        </div>
    </div>

</template>

<script>

import EditAssociationSinglePractitioner from './EditAssociationSinglePractitioner.vue';
import { mapGetters } from 'vuex';

export default {

    components: {
        EditAssociationSinglePractitioner, 
    },
    computed: {
        ...mapGetters('practitioner', ['GET_PRACTITIONER']),
    },

}
</script>