<template>
    <div class="">
        <div class="w-full mr-2">
            <edit-personal-information-single-practitioner :practitioner="practitioner"></edit-personal-information-single-practitioner>
        </div>
    </div>

</template>

<script>

import EditPersonalInformationSinglePractitioner from './EditPersonalInformationSinglePractitioner.vue';

export default {
    components: {
        EditPersonalInformationSinglePractitioner, 
    },
    data()
    {
        return {
            practitioner: {},
        }
    },
    beforeCreate() {
        

       this.$store.watch(() => this.$store.state.practitioner.practitioner, async () => {
            this.practitioner = this.$store.state.practitioner.practitioner;
        });
    },


}
</script>