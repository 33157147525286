<style scoped>
    .map-wrapper {
        min-height: 300px !important;
        height: 100%!important;
        width: 100%!important;
    }

    #mapContainer {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    .mapboxgl-canvas-container {
        height: 100vh!important;
        width: 100vh!important;
    }

    .mapboxgl-popup-close-button {
        width: 15px;
        height: 15px;
    }

</style>

<template>
    <div class="map-wrapper">
        <div id="mapContainer" class="basemap" style="border-radius: 15px;"></div>
    </div>
</template>
<script>

import mapboxgl from 'mapbox-gl';

import { mapGetters } from 'vuex';
mapboxgl.accessToken = 'pk.eyJ1IjoibWVkaW1vYmNsaW5pY3MiLCJhIjoiY2xlYnpxZ3VsMWV4MTN5cXg4eW5ueTVndCJ9.rUWK6NsXGGlAxswGX1gGWg';


export default {
    props: ['coords', 'oldCords', 'targetClinic'],
    data() {
        return {
            clinics: {},
            coordinates: {},
            initialCoords: {},
            previousCoords: {},
            newCoordinates: {},
        }
    },
    computed: {
        ...mapGetters('search', ['GET_ACTIVE_COORDS']),
        cordi()
        {
            return this.oldCords
        }
    },
    methods: {
        move()
        {

        }

    },
    // Watch any changes in coordinates and clinic info when hovering on clinic card
    watch:
    {
        coords(newCoords)
        {
            this.newCoordinates = newCoords
            const map = new mapboxgl.Map({
                container: "mapContainer",
                center: [this.cordi[1], this.cordi[0]],
                style: "mapbox://styles/mapbox/streets-v12",
                zoom: 12,
            });
            map.flyTo({
                center: [this.newCoordinates[1], this.newCoordinates[0]],
                zoom: 12,
                curve: 1,
                easing(t) {
                    return t;
                }
            });

            // Set popup
            const popup = new mapboxgl.Popup({ closeOnClick: false })
            .setLngLat([this.newCoordinates[1], this.newCoordinates[0]])
            .setHTML('<div class="flex"><span class="my-auto"><img class="popup-img mr-5" src="'+this.targetClinic.image_path+'"></span><h5 class="flex-grow font-bold my-auto">'+this.targetClinic.name+'</h5></div>')
            .addTo(map);

            const nav = new mapboxgl.NavigationControl();
            map.addControl(nav, "top-right");
            const geolocate = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
              },
              trackUserLocation: true
            });

            map.addControl(geolocate, "top-right")
            const feats = [];
            for(var i = 0; i < this.clinics.length; i++){
                const featsContent = {
                    'type': 'Feature',
                    'properties': {
                        'description':
                        '<div><img src="'+this.clinics[i].image_path+'"></div><strong>'+this.clinics[i].name+'</strong><p></p>'
                    },
                    'geometry': {
                    'type': 'Point',
                    'coordinates': [this.clinics[i].lon, this.clinics[i].lat]
                    }
                }
                feats.push(featsContent);
                map.on('load', () => {
                    map.addSource('places', {
                        'type': 'geojson',
                        'data': {
                        'type': 'FeatureCollection',
                        'features': feats
                    }
                });
                // Add a layer showing the places.
                map.addLayer({
                    'id': 'places',
                    'type': 'circle',
                    'source': 'places',
                    'paint': {
                        'circle-color': '#0EE2B1',
                        'circle-radius': 10,
                        'circle-stroke-width': 4,
                        'circle-stroke-color': '#ffffff'
                    }
                });
                // Create a popup, but don't add it to the map yet.
                const popup = new mapboxgl.Popup({
                    closeButton: false,
                    closeOnClick: false
                });
                map.on('mouseenter',
                 'places', (e) => {
                    // Change the cursor style as a UI indicator.
                    map.getCanvas().style.cursor = 'pointer';
                    if(typeof e.lngLat !== 'undefined') {
                        // Copy coordinates array.
                        const coordinates = e.features[0].geometry.coordinates.slice();
                        const description = e.features[0].properties.description;
                    
                        // Ensure that if the map is zoomed out such that multiple
                        // copies of the feature are visible, the popup appears
                        // over the copy being pointed to.
                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }
                    
                        // Populate the popup and set its coordinates
                        // based on the feature found.
                        popup.setLngLat(coordinates).setHTML(description).addTo(map);
                    }
                });

                map.on('mouseleave', 'places', () => {
                    map.getCanvas().style.cursor = '';
                    popup.remove();
                });
            });
            }
        }
    },
    setup ()
    {

    },
    mounted() {

        this.$store.watch(() => this.$store.state.search.searchResults, async () => {
            this.clinics = this.$store.state.search.searchResults;
            if(typeof this.coords[0] === 'undefined')
            {
                this.coordinates = [this.clinics[0].lat, this.clinics[0].lon];
                this.initialCoords = [this.clinics[0].lon, this.clinics[0].lat];
            } 
            const map = new mapboxgl.Map({
                container: "mapContainer",
                style: "mapbox://styles/mapbox/streets-v12",
                center: this.initialCoords,
                zoom: 10,
            });
            map.on('load', function () {
                map.resize();
            });
            const nav = new mapboxgl.NavigationControl();
            map.addControl(nav, "top-right");
            const geolocate = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
              },
              trackUserLocation: true
            });

            map.addControl(geolocate, "top-right")
            const feats = [];
            for(var i = 0; i < this.clinics.length; i++){
                const featsContent = {
                    'type': 'Feature',
                    'properties': {
                        'description':
                        '<div><img src="'+this.clinics[i].image_path+'"></div><strong>'+this.clinics[i].name+'</strong><p></p>'
                    },
                    'geometry': {
                    'type': 'Point',
                    'coordinates': [this.clinics[i].lon, this.clinics[i].lat]
                    }
                }
                feats.push(featsContent);
                map.on('load', () => {
                    map.addSource('places', {
                        'type': 'geojson',
                        'data': {
                        'type': 'FeatureCollection',
                        'features': feats
                    }
                });
                // Add a layer showing the places.
                map.addLayer({
                    'id': 'places',
                    'type': 'circle',
                    'source': 'places',
                    'paint': {
                        'circle-color': '#0EE2B1',
                        'circle-radius': 10,
                        'circle-stroke-width': 4,
                        'circle-stroke-color': '#ffffff'
                    }
                });
                // Create a popup, but don't add it to the map yet.
                const popup = new mapboxgl.Popup({
                    closeButton: true,
                    closeOnClick: true
                });
                map.on('mouseenter',
                 'places', (e) => {
                    // Change the cursor style as a UI indicator.
                    map.getCanvas().style.cursor = 'pointer';
                
                    // Copy coordinates array.
                    const coordinates = e.features[0].geometry.coordinates.slice();
                    const description = e.features[0].properties.description;
                
                    // Ensure that if the map is zoomed out such that multiple
                    // copies of the feature are visible, the popup appears
                    // over the copy being pointed to.
                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }
                
                    // Populate the popup and set its coordinates
                    // based on the feature found.
                    popup.setLngLat(coordinates).setHTML(description).addTo(map);
                });

                map.on('mouseleave', 'places', () => {
                    map.getCanvas().style.cursor = '';
                    popup.remove();
                });
            });
            }
        });
    },
}

</script>