<template>
    <ul
        class="
          dropdown-menu
          min-w-max
          absolute
          hidden
          bg-white
          text-base
          z-50
          float-left
          py-2
          list-none
          text-left
          rounded-lg
          shadow-lg
          mt-1
          m-0
          bg-clip-padding
          border-none
          px-0
        "
        aria-labelledby="dropdownMenuButton1"
      >
        <li class="flex pl-3 hover:bg-gray-200" @click.prevent="editListAction">
          <span class="my-auto">          
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 ">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
            </svg></span>
          <a
            class="
              dropdown-item
              text-sm
              py-2
              px-3
              font-normal
              w-full
              whitespace-nowrap
              bg-transparent
              text-gray-700
              hover:bg-gray-100
              cursor-pointer
              my-auto
              
            "
            >
            Edit</a
          >
        </li>
        <li class="flex pl-3 hover:bg-red-200" @click.prevent="deleteListAction">
          <div class="my-auto">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-red-700">
              <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
            </svg>
          </div>


          <a
            class="
              dropdown-item
              text-sm
              py-2
              px-3
              font-normal
              w-full
              whitespace-nowrap
              bg-transparent
              text-red-700
              hover:bg-gray-100
              cursor-pointer
            "
            >Delete</a
          >
        </li>
    </ul>
</template>

<script>
export default {
  methods: {
    editListAction()
    {
      this.$emit('editAction')
    },
    deleteListAction()
    {
      this.$emit('deleteAction')
    }
  }
}
</script>