<style lang="scss" scoped>
    .profile-content-section, .quote-calculator-section-wrapper {
        position: relative;

        .right-profile-content-section {
            position: sticky;
            top: 80px;
            left: 0;
        }
    }
</style>

<template>
    <div class="position-relative h-100" style="margin-top: 100px;">
        <div class="w-full">
            <single-clinic-profile-main-card @scrollToBookingComponent="scrollToBookingComponent" :dbvars="dbvars"></single-clinic-profile-main-card>
        </div>
        <div class="lg:flex w-full mt-2 mr-3 profile-content-section">
            <div class=" lg:w-4/6 left-profile-content-section px-4">
                <div class="about-section-wrapper  my-5" v-if="dbvars.mainClinic.full_bio">
                    <about-section-single-clinic-profile :profileBio="dbvars.mainClinic.full_bio"></about-section-single-clinic-profile>
                </div>
                <div class="about-section-wrapper  my-5 pt-5" v-if="dbvars.professions.length >= 1">
                    <profession-section-single-clinic-profile :professions="dbvars.professions"></profession-section-single-clinic-profile>
                </div>
                <div class="about-section-wrapper  my-5 pt-5" v-if="dbvars.services.length >= 1">
                    <service-section-single-clinic-profile :services="dbvars.services"></service-section-single-clinic-profile>
                </div>
                <div class="conditions_treated_section my-5 pt-5" v-if="dbvars.conditions_treated.length >= 1">
                    <conditions-treated-section-single-clinic-profile :conditions="dbvars.conditions_treated"></conditions-treated-section-single-clinic-profile>
                </div>
                <div class="practitioners-section-wrapper my-5 pt-5" v-if="dbvars.practitioners.length >= 1">
                    <practitioner-section-single-clinic-profile :practitioners="dbvars.practitioners"></practitioner-section-single-clinic-profile>
                </div>
                <div class="practitioners-section-wrapper my-5 pt-5" v-if="dbvars.reviews.length >= 1">
                    <reviews-section-single-clinic-profile :reviews="dbvars.reviews"></reviews-section-single-clinic-profile>
                </div>
                <div class="practitioners-section-wrapper my-5 pt-5" v-if="dbvars.endorsements.length >= 1">
                  <endorsements-section-single-clinic-profile :endorsements="dbvars.endorsements"></endorsements-section-single-clinic-profile>
                </div>
                <div class="service-delivery-type-section-wrapper my-5 pt-5" v-if="dbvars.services.length >= 1">
                    <service-delivery-type-section-single-clinic-profile :services="dbvars.services"></service-delivery-type-section-single-clinic-profile>
                </div>
                <div class="map-section-wrapper my-5 pt-5">
                    <map-section-single-clinic-profile :clinic="dbvars.satelite"></map-section-single-clinic-profile>
                </div>
                <div class="map-section-wrapper my-5 pt-5" v-if="dbvars.satelite.booking_url">
                    <book-online-single-clinic-profile ref="bookingComponent" :booking_url="dbvars.satelite.booking_url"></book-online-single-clinic-profile>
                </div>
                <div class="payment-details-section-wrapper my-5 pt-5" v-if="dbvars.mainClinic.payment_method">
                    <payment-details-section-single-clinic-profile :payment_methods="dbvars.mainClinic.payment_method"></payment-details-section-single-clinic-profile>
                </div>
                <div class="additional-clinics-details-section-wrapper my-5 pt-5" v-if="dbvars.allSatelites.length > 1">
                    <additional-clinics-section-single-clinic-profile :satelites="dbvars.allSatelites" :targetSatelite="dbvars.satelite.ref"></additional-clinics-section-single-clinic-profile>
                </div>
                <div class="legal-entity-section-wrapper my-5 pt-5" v-if="dbvars.mainClinic.legal_entity">
                    <legal-entity-section-single-clinic-profile :legal_entity="dbvars.mainClinic.legal_entity"></legal-entity-section-single-clinic-profile>
                </div>
            </div>
            <div class="lg:w-2/6 mt-5 lg:ml-3 quote-calculator-section-wrapper">
                <div class="right-profile-content-section">
                    <quote-calculator v-if="dbvars.services.length >= 1" :services="dbvars.services" :main="dbvars.mainClinic" :satelite="dbvars.satelite"></quote-calculator>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SingleClinicProfileMainCard from './SingleClinicProfileMainCard.vue';
import AboutSectionSingleClinicProfile from './Sections/AboutSectionSingleClinicProfile.vue';
import ProfessionSectionSingleClinicProfile from './Sections/ProfessionSectionSingleClinicProfile.vue';
import ServiceSectionSingleClinicProfile from './Sections/ServiceSectionSingleClinicProfile.vue';
import PractitionerSectionSingleClinicProfile from './Sections/PractitionerSectionSingleClinicProfile.vue';
import ReviewsSectionSingleClinicProfile from './Sections/ReviewsSectionSingleClinicProfile.vue';
import PaymentDetailsSectionSingleClinicProfile from './Sections/PaymentDetailsSectionSingleClinicProfile.vue';
import LegalEntitySectionSingleClinicProfile from './Sections/LegalEntitySectionSingleClinicProfile.vue';
import ServiceDeliveryTypeSectionSingleClinicProfile from './Sections/ServiceDeliveryTypeSectionSingleClinicProfile.vue';
import BookOnlineSingleClinicProfile from './Sections/BookOnlineSingleClinicProfile.vue';
import MapSectionSingleClinicProfile from './Sections/MapSectionSingleClinicProfile.vue';
import AdditionalClinicsSectionSingleClinicProfile from './Sections/AdditionalClinicsSectionSingleClinicProfile.vue';
import ConditionsTreatedSectionSingleClinicProfile from "./Sections/ConditionsTreatedSectionSingleClinicProfile.vue";
import EndorsementsSectionSingleClinicProfile from "./Sections/EndorsementsSectionSingleClinicProfile.vue";

import QuoteCalculator from '../Profile/QuoteCalculator.vue';

    export default {
        props: ['dbvars'],
        components: {
            AboutSectionSingleClinicProfile,
            ProfessionSectionSingleClinicProfile,
            ServiceSectionSingleClinicProfile,
            PractitionerSectionSingleClinicProfile,
            ReviewsSectionSingleClinicProfile,
            PaymentDetailsSectionSingleClinicProfile,
            LegalEntitySectionSingleClinicProfile,
            ServiceDeliveryTypeSectionSingleClinicProfile,
            BookOnlineSingleClinicProfile,
            MapSectionSingleClinicProfile,
            QuoteCalculator,
            AdditionalClinicsSectionSingleClinicProfile,
            ConditionsTreatedSectionSingleClinicProfile,
            EndorsementsSectionSingleClinicProfile


        },
        methods: {
            scrollToBookingComponent() {

                // Get a reference to the booking component using ref
                const bookingComponent = this.$refs.bookingComponent.$el;

                // Scroll to the booking component
                bookingComponent.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
</script>
