<template>
    <div class="">

        <div class="main-page mt-5">
            <div class="display-all-container mt-5">
                <all-practitioners></all-practitioners>
            </div>
        </div>
    </div>
</template>


<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            crumbs: [
                {name: 'Practitioners', url: '/dashboard/practitioners'},
            ]
        }
    },
    methods: {
        ...mapActions('commonFunctions', ['loadBreadcrumbs'])
    },
    mounted()
    {
        this.loadBreadcrumbs(this.crumbs);
    }
}
</script>