import { render, staticRenderFns } from "./SingleServiceSearchCard.vue?vue&type=template&id=33b753a1&scoped=true"
import script from "./SingleServiceSearchCard.vue?vue&type=script&lang=js"
export * from "./SingleServiceSearchCard.vue?vue&type=script&lang=js"
import style0 from "./SingleServiceSearchCard.vue?vue&type=style&index=0&id=33b753a1&prod&scoped=true&lang=scss"
import style1 from "./SingleServiceSearchCard.vue?vue&type=style&index=1&id=33b753a1&prod&lang=css"
import style2 from "./SingleServiceSearchCard.vue?vue&type=style&index=2&id=33b753a1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b753a1",
  null
  
)

export default component.exports