const filterOptions = 
    {
        "show_all": {
            "id": "show_all",
            "label": "Show All",
            "prefix_word": ""
        },
        "most_recent": {
            "id": "most_recent",
            "label": "Most Recent",
            "prefix_word": "Sort by"
        },
        "older": {
            "id": "older",
            "label": "Older",
            "prefix_word": "Sort by"

        },
        "highly_rated": {
            "id": "highly_rated",
            "label": "Highly Rated",
            "prefix_word": "Sort by"

        },
        "poorly_rated": {
            "id": "poorly_rated",
            "label": "Poorly Rated",
            "prefix_word": "Sort by"

        },
        "one_star": {
            "id": "one_star",
            "label": "Rating (1 Star)",
            "value": 1,
            "prefix_word": "Filter by"

        },
        "two_stars": {
            "id": "two_stars",
            "label": "Rating (2 Stars)",
            "value": 2,
            "prefix_word": "Filter by"

        },
        "three_stars": {
            "id": "three_stars",
            "label": "Rating (3 Stars)",
            "value": 3,
            "prefix_word": "Filter by"

        },
        "four_stars": {
            "id": "four_stars",
            "label": "Rating (4 Stars)",
            "value": 4,
            "prefix_word": "Filter by"

        },
        "five_stars": {
            "id": "five_stars",
            "label": "Rating (5 Stars)",
            "value": 5,
            "prefix_word": "Filter by"

        },
    }


export default filterOptions;