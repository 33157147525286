<style lang="scss" scoped>
    .need-health-care-banner {
        height:420px;

        @media (max-width: 360px)
        {
           height: fit-content;
        }
    }

</style>

<template>
    <section class="w-full container bg-cover bg-center position-relative h-full pb-5">
        <div class="p-8 text-center need-health-care-banner left-pom my-auto flex flex-col items-center">
            <div class="container text-center title title-complex">
                <h1 class="text-white why-choose-title-white">Why Choose <span class="whychoose-title">Medimob</span> ?</h1>
                <div class="flex w-full">
                    <p class="text-white mt-3 white-paragraph-col-bg mx-auto w-3/4">Medimob offers a seamless blend of innovation and personalized care. Here&#39;s why you should
                        choose us:
                    </p>
                </div>
            </div>
        </div>
        <div class="card-wrapper flex ">
            <div class="flex w-full mx-auto max-w-[90%]">
                <div class="single-card mx-2 md:w-1/4" style="border-radius: 30px; background: #FFF;box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);">
                    <div class="card-content p-4 flex flex-col align-items-center">
                        <div class="card-icon-wrapper pb-4">
                            <span class="pb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="74" height="73" viewBox="0 0 74 73" fill="none">
                                  <rect x="0.122925" width="73.076" height="73" rx="36.5" fill="#F1F4FB"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M52.1787 22.478L30.3777 43.4959L24.5925 37.3214C23.5268 36.3176 21.8521 36.2568 20.6342 37.1084C19.4467 37.9905 19.1118 39.5418 19.8425 40.7889L26.6934 51.9214C27.3633 52.9555 28.5203 53.5943 29.8296 53.5943C31.078 53.5943 32.2655 52.9555 32.9353 51.9214C34.0315 50.4918 54.9495 25.5805 54.9495 25.5805C57.6898 22.7822 54.371 20.3184 52.1787 22.4476V22.478Z" fill="#599DE0"/>
                                </svg>
                            </span>
                        </div>
                        <div class="card-title-wrapper">
                            <h6 class="card-title mb-3">Easy To Use</h6>
                        </div>
                        <div class="card-text-wrapper">
                            <p class="card-text text-center">
                              Our user-friendly platform ensures a hassle-free experience.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="single-card mx-2 md:w-1/4" style="border-radius: 30px; background: #FFF;box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);">
                    <div class="card-content p-4 flex flex-col align-items-center">
                        <div class="card-icon-wrapper pb-4">
                            <span class="pb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="74" height="73" viewBox="0 0 74 73" fill="none">
                                  <rect x="0.122925" width="73.076" height="73" rx="36.5" fill="#F1F4FB"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M52.1787 22.478L30.3777 43.4959L24.5925 37.3214C23.5268 36.3176 21.8521 36.2568 20.6342 37.1084C19.4467 37.9905 19.1118 39.5418 19.8425 40.7889L26.6934 51.9214C27.3633 52.9555 28.5203 53.5943 29.8296 53.5943C31.078 53.5943 32.2655 52.9555 32.9353 51.9214C34.0315 50.4918 54.9495 25.5805 54.9495 25.5805C57.6898 22.7822 54.371 20.3184 52.1787 22.4476V22.478Z" fill="#599DE0"/>
                                </svg>
                            </span>
                        </div>
                        <div class="card-title-wrapper">
                            <h6 class="card-title mb-3">Never leave Home</h6>
                        </div>
                        <div class="card-text-wrapper">
                            <p class="card-text text-center">
                                Choose between virtual consultations or have a healthcare practitioner come to you. Your health, your space.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="single-card mx-2 md:w-1/4" style="border-radius: 30px; background: #FFF;box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);">
                    <div class="card-content p-4 flex flex-col align-items-center">
                        <div class="card-icon-wrapper pb-4">
                            <span class="pb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="74" height="73" viewBox="0 0 74 73" fill="none">
                                  <rect x="0.122925" width="73.076" height="73" rx="36.5" fill="#F1F4FB"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M52.1787 22.478L30.3777 43.4959L24.5925 37.3214C23.5268 36.3176 21.8521 36.2568 20.6342 37.1084C19.4467 37.9905 19.1118 39.5418 19.8425 40.7889L26.6934 51.9214C27.3633 52.9555 28.5203 53.5943 29.8296 53.5943C31.078 53.5943 32.2655 52.9555 32.9353 51.9214C34.0315 50.4918 54.9495 25.5805 54.9495 25.5805C57.6898 22.7822 54.371 20.3184 52.1787 22.4476V22.478Z" fill="#599DE0"/>
                                </svg>
                            </span>
                        </div>
                        <div class="card-title-wrapper">
                            <h6 class="card-title mb-3">Safe</h6>
                        </div>
                        <div class="card-text-wrapper">
                            <p class="card-text text-center">
                                Your well-being is our priority. We manually verify the compliance of our members. Look for the 'Verified Profile' badge.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="single-card mx-2 md:w-1/4" style="border-radius: 30px; background: #FFF;box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);">
                    <div class="card-content p-4 flex flex-col align-items-center">
                        <div class="card-icon-wrapper pb-4">
                            <span class="pb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="74" height="73" viewBox="0 0 74 73" fill="none">
                                  <rect x="0.122925" width="73.076" height="73" rx="36.5" fill="#F1F4FB"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M52.1787 22.478L30.3777 43.4959L24.5925 37.3214C23.5268 36.3176 21.8521 36.2568 20.6342 37.1084C19.4467 37.9905 19.1118 39.5418 19.8425 40.7889L26.6934 51.9214C27.3633 52.9555 28.5203 53.5943 29.8296 53.5943C31.078 53.5943 32.2655 52.9555 32.9353 51.9214C34.0315 50.4918 54.9495 25.5805 54.9495 25.5805C57.6898 22.7822 54.371 20.3184 52.1787 22.4476V22.478Z" fill="#599DE0"/>
                                </svg>
                            </span>
                        </div>
                        <div class="card-title-wrapper">
                            <h6 class="card-title mb-3">Save time</h6>
                        </div>
                        <div class="card-text-wrapper">
                            <p class="card-text text-center">
                                Receive prompt and efficient healthcare services without the unnecessary delays.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {

}
</script>
