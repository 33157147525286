<template>
    <div class="">
        <div class="inner-section-wrapper bg-white rounded p-4">
            <CreateUpdateTable 
            :title="practitioner.first_name+`'s Associations`"
            :refID="practitioner.ref"
            :buttonText="'Add Associations'"
            :data="practitionersAssociations"
            :ifempty="practitioner.first_name+' does not have any Associations yet. Please add some.'"
            :theads="['Association Name']"
            @triggerNewEntry="addAssociationModal($event)"
            @editEntry="editAssociationModal($event)"
            >
            <!-- Table body populated dynamically -->
            <div class="table-body" slot="table-body">
                <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-700 w-full flex"  v-if="practitioner.association_name && practitioner.association_name.length > 0 " v-for="association in practitionersAssociations" :key="association.id">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex-grow">{{ association.title }}</td>
                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap flex">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" 
                        class="w-6 h-6 ml-auto dropdown-toggle" 
                        @click.prevent="toggleDropdown = true"
                        type="button"
                        id="dropDownActionButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        >
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                        </svg>
                        <edit-delete-dropdown-options 
                        id="dropdown" 
                        aria-labelledby="dropDownActionButton"
                        @editAction="editAssociationModal(association)"
                        @deleteAction="triggerDelete(association.key)"
                        ></edit-delete-dropdown-options>

                    </td>
                </tr>
                <tr v-if="(practitioner.association_name && practitioner.association_name.length < 5 )|| practitioner.association_name === null" class="bg-white transition duration-300 ease-in-out hover:bg-gray-700 flex pl-4 py-3">{{ practitioner.first_name+' does not have any Associations yet. Please add some.' }}</tr>
            </div>
        </CreateUpdateTable>
        </div>

        <CreateUpdateModal 
            v-if="GET_ASSOCIATION_MODAL_STATUS" 
            :title="modalType+' Association'"
            :data="targetAssociation"
            :buttonText="modalButtonTitle"
            @save="triggerSave($event)"
            @close="wipeAndToggle()"
        >
                <div class="" slot="content">
                    <CreateUpdatePractitionerAssociation @eventListener="updateModalData($event)" :target="targetAssociation"></CreateUpdatePractitionerAssociation>
                </div>
        </CreateUpdateModal>
        
    </div>
</template>
<script>
import CreateUpdateTable from '../../CreateUpdateTable.vue';
import CreateUpdateModal from '../../CreateUpdateModal.vue';
import CreateUpdatePractitionerAssociation from './CreateUpdatePractitionerAssociation.vue';
import EditDeleteDropdownOptions from '../../EditDeleteDropdownOptions.vue';
import { mapActions, mapGetters} from 'vuex';

export default {
    props: ['practitioner'],
    components: {
        CreateUpdateTable,
        CreateUpdateModal,
        CreateUpdatePractitionerAssociation,
        EditDeleteDropdownOptions 
    },
    data() {
        return {
            practitionersAssociations: {},
            modalButtonTitle: '',
            editAssociation: false,
            modalType: '',
            modalData: {},
            targetAssociation: {
                Associations: {}
            },
            
        }
    },
    computed:
    {
        ...mapGetters('practitioner', ['GET_PRACTITIONER', 'GET_ASSOCIATION_MODAL_STATUS']),
    },
    methods: {
        ...mapActions('practitioner', ['saveAssociation', 'deleteAssociation', 'toggleAssociationModal']),
        
        // opens modal to add Association
        addAssociationModal()
        {
            this.modalType="add";
            let existingAssociations = JSON.parse(this.practitioner.association_name)
            this.targetAssociation.Associations = []
            // this.targetAssociation.Associations.push(JSON.parse(this.practitioner.Associations))
            this.targetAssociation.Associations = this.targetAssociation.Associations.concat(existingAssociations)
            this.toggleAssociationModal()
            this.updateCreateAssociation = true;
            this.modalButtonTitle="Create"
        },

        // opens modal to edit Association
        editAssociationModal(target)
        {
            this.modalType="edit";
            let existingAssociations = JSON.parse(this.practitioner.association_name)
            this.targetAssociation.Associations = []
            // this.targetAssociation.Associations.push(JSON.parse(this.practitioner.Associations))
            this.targetAssociation.Associations = this.targetAssociation.Associations.concat(existingAssociations)
            this.toggleAssociationModal()
            this.updateCreateAssociation = true;
            this.modalButtonTitle = "Update"
        },
        updateModalData(data)
        {
            this.modalData = data;
        },
        triggerSave()
        {
            let data = {
                type: this.modalType, 
                data: this.targetAssociation.Associations,
                practitioner: this.GET_PRACTITIONER.id
            }
            this.saveAssociation(data);
        },
        triggerDelete(id)
        {
            let data = { 
                deletedId: id,
                practitioner: this.GET_PRACTITIONER.id
            }
            this.deleteAssociation(data);
        },

        // Wipes out forms when closing modal
        wipeAndToggle()
        {
            this.targetAssociation = {}
            this.toggleAssociationModal()
        }

    },
    created(){
        if(typeof this.practitioner.association_name !== 'undefined'){
            this.practitionersAssociations = JSON.parse(this.practitioner.association_name)
        }
    },
    watch:{
        practitioner: {
            handler(newValue) {
                this.practitionersAssociations = JSON.parse(this.practitioner.association_name)
                
            }
        },
    }
}
</script>