<style scoped lang="scss">
.search-card {
    height: 250px;
    width: 100%;
}

.clinic-name {
    font-size: 21px;
    font-weight: 600;
    color: #1E1F1D;
    line-height: 22px;
}

.verification-badge {
    color: #0BD2A4;
}

.distance-box {
    .distance {
        font-size: 16px;
        color: #838382;
        line-height: 12px;
        font-weight: 300;
    }
}

.service-box {
    bottom: 0;

    .service-list-item {
        font-size: 12px;
        line-height: 12px;
        color: #838382;

        .service-icon {
            color: #0BD2A4;
        }
    }
}

.search-action-btn {
    position: absolute !important;
    bottom: 0;

    .profile-btn {
        background-color: #3490DC;
        color: white;
        height: 100%;
        margin-top: 10px;
        padding: 9px;
        font-weight: 600;
        border-radius: 60px;
        font-size: medium;
    }
}

.review-icon {
    color: #0EE2B1;
    font-size: 17px;
}

.number-of-reviews {
    p {
        font-size: 12px;
        color: #838382;
    }
}
</style>

<template>
    <div v-if="typeof searchItem === 'object'" class="card-wrapper bg-white rounded-2xl shadow-sm my-3 search-card">
        <div class="card-inner p-4 h-full">
            <div class="flex w-full h-full">
                <div class="w-1/4 mr-4">
                    <RoundProfilePicture class="zindexImage" :profileImage="searchItem.image"></RoundProfilePicture>
                </div>
                <div class="flex-grow my-auto position-relative h-full ml-3">
                    <div class="heading">
                        <div class="clinic-name-section flex">
                            <h1 class="clinic-name mr-2">{{ searchItem.name }}</h1>
                            <div class="verification-mark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-check-circle-fill verification-badge" viewBox="0 0 16 16">
                                    <path
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="distance-box mt-1">
                        <p v-if="searchItem.distance < 1" class="distance">{{ distance }} meters away (from the Post Code)
                        </p>
                        <p v-else-if="!isNaN(parseFloat(distance))" class="distance">{{ distance }} miles away (from the
                            Post Code)</p>
                    </div>
                    <div class="service-box flex w-full">
                        <div class="mr-4">
                            <span v-if="showAllServices || searchItem.services.length <= maxVisibleServices">
                                Services :
                            </span>
                            <ul class="p-0">
                                <li v-for="(item, index) in visibleServices" :key="item.ref"
                                    class="flex service-list-item my-2">
                                    <span class="mr-2 my-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            class="bi bi-check2 service-icon" viewBox="0 0 16 16">
                                            <path
                                                d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                        </svg>
                                    </span>
                                    {{ item.main_service_name }}
                                </li>
                                <li v-if="searchItem.services.length > maxVisibleServices">
                                    <a @click="toggleShowAllServices">{{ showAllServices ? 'Show Less' : 'Show More' }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="w-1/4 h-full relative">
                    <div class="reviews-sections">
                        <div class="rating flex">
                            <ul class="flex p-0 ml-auto my-0">
                                <li v-for="n in searchItem.reviews" :key="n" class="mx-1 star-list"><svg
                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-star-fill review-icon" viewBox="0 0 16 16">
                                        <path
                                            d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                    </svg>
                                </li>
                            </ul>
                        </div>
                        <div class="number-of-reviews flex mr-2">
                            <p class="ml-auto">{{ searchItem.number_of_reviews }} Reviews</p>
                        </div>
                    </div>

                    <div class=" search-action-btn flex flex-wrap lg:grid-cols-2 lg:gap-4 w-full">
                        <a role="button"  :href="'/view-profile/'+searchItem.ref+'/'+searchItem.slug" class="no-underline cursor-pointer my-auto ml-auto profile-btn">View profile</a>

                    </div>
                </div>
            </div>

        </div>

        <MapBoxModal v-if="openMapBoxModal" @save="closeMapBoxModal()" @close="closeMapBoxModal()">
            <div class="" slot="content">
                <search-mapbox-modal :coords="coords" :oldCords="oldCoordinates"
                    :targetClinic="targetClinic"></search-mapbox-modal>
            </div>
        </MapBoxModal>
    </div>
</template>

<script>
import MapBoxModal from '../MapBoxModal.vue'
import SearchMapboxModal from '../SearchMapboxModal.vue'
import RoundProfilePicture from '../../Utilities/RoundProfilePicture';

export default {
    data() {
        return {
            openMapBoxModal: false,
            coords: {},
            targetClinic: {},
            oldCoordinates: {},
            maxVisibleServices: 6, // Maximum services to show initially
            showAllServices: false, // Whether to show all services or not
        }
    },
    props: ['searchItem'],
    components: {
        RoundProfilePicture,
        MapBoxModal,
        SearchMapboxModal
    },
    methods: {
        closeMapBoxModal() {
            this.openMapBoxModal = false
        },
        changeCoords(payload) {
            this.coords = payload;
        },
        oldCoords(payload) {
            this.oldCoordinates = payload;
        },
        passClinic(clinic) {
            this.targetClinic = clinic;
        },

        toggleShowAllServices() {
            this.showAllServices = !this.showAllServices;
        }
    },
    created() {
        this.changeCoords([this.searchItem.lat, this.searchItem.lon])
        this.oldCoords([this.searchItem.lat, this.searchItem.lon])
        this.passClinic(this.searchItem)
    },
    computed: {
        distance() {
            if (this.searchItem.distance < 1) {
                return Math.round(this.searchItem.distance * 1609.344)
            } else {
                return Math.round(this.searchItem.distance)
            }
        },
        visibleServices() {
            return this.showAllServices
                ? this.searchItem.services
                : this.searchItem.services.slice(0, this.maxVisibleServices);
        },
    }
}
</script>
<style>
.service-box {
    flex-wrap: wrap;
}
</style>
<style lang="scss" scoped>
.zindexImage {
    z-index: 1;
    position: sticky;
}
</style>
