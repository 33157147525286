<template>
    <section class="w-full section-separation container">
        <div class="text-center left-pom my-auto flex flex-col items-center">
            <div class="flex-col">
            <div class="why-choose-paragraph how-it-works container title title-complex pt-2 pb-6">
                <h1 class="font-bold howto-title text-center">Find your practitioner</h1>
                <h2 class="howto-sub-title font-bold text-center">How Medimob Works</h2>
            </div>
                <p class="text-[18px] mt-4 text-center">We simplify your healthcare journey in three easy steps:</p>
            </div>
            <div class="body mt-6">
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4 text-center">
                  <div class="p-4 rounded-lg flex flex-col items-center">
                    <svg class="mb-8" xmlns="http://www.w3.org/2000/svg" width="175" height="94" viewBox="0 0 175 94" fill="none">
                      <path d="M159.53 83.449V8.52801C159.53 3.81929 155.697 0 150.972 0H24.0275C19.3025 0 15.47 3.81929 15.47 8.52801V83.449H2C2 89.2738 3.885 91 8.68 91H166.32C171.115 91 173 89.2738 173 83.449H159.53ZM19.81 16.2538V5.68534H154.787V16.2538H154.77V78.1822H19.81V16.2538ZM104.405 83.7629C104.405 86.344 103.495 88.4542 102.358 88.4542H72.6425C71.505 88.4542 70.595 86.3614 70.595 83.7629V83.449H104.405V83.7629Z" fill="#212429"/>
                      <path d="M143.657 15.6667H29.602V36.5365H143.657V15.6667Z" fill="#0BD2A4"/>
                      <path d="M61.8159 40.9074H29.602V70.5H61.8159V40.9074Z" fill="#0BD2A4"/>
                      <path d="M143.657 40.1495H70.4949V47.787H143.657V40.1495Z" fill="#0BD2A4"/>
                      <path d="M143.657 63.7328H70.4949V71.3704H143.657V63.7328Z" fill="#0BD2A4"/>
                      <path d="M143.657 51.9335H70.4949V59.571H143.657V51.9335Z" fill="#0BD2A4"/>
                    </svg>
                    <h2 class="text-xl font-extrabold hiw-card-title mb-3">Search & Select</h2>
                    <p class="hiw-card-text">Explore a variety of healthcare service. Find the provider that suits your needs,
                        whether it&#39;s for a virtual consultation or an in-home visit.</p>
                  </div>

                  <div class="p-4 rounded-lg flex flex-col items-center">
                    <svg  class="mb-8" xmlns="http://www.w3.org/2000/svg" width="97" height="97" viewBox="0 0 97 97" fill="none">
                      <g clip-path="url(#clip0_722_1604)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.6389 59.3047C30.9861 59.0083 35.0278 64.6667 35.0278 64.6667H47.2875C48.8772 64.6667 50.0358 64.6667 53.0536 64.6667H61.9722C61.9722 64.6667 66.0139 59.0083 67.3611 59.3047C81.9111 62.6189 97 72.1841 97 82.3691V97H0V82.3691C0 72.1841 15.0889 62.6189 29.6389 59.3047ZM70.0556 75.4444V70.0555H75.4444V75.4444H80.8333V80.8333H75.4444V86.2222H70.0556V80.8333H64.6667V75.4444H70.0556Z" fill="#0BD2A4"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M48.5 48.5C60.4094 48.5 70.0555 38.8539 70.0555 26.9444C70.0555 15.035 60.4094 5.38889 48.5 5.38889C36.5905 5.38889 26.9444 15.035 26.9444 26.9444C26.9444 38.8539 36.5905 48.5 48.5 48.5ZM48.5 53.8889C63.3733 53.8889 75.4444 41.8178 75.4444 26.9444C75.4444 12.0711 63.3733 0 48.5 0C33.6267 0 21.5555 12.0711 21.5555 26.9444C21.5555 41.8178 33.6267 53.8889 48.5 53.8889Z" fill="#212429"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_722_1604">
                          <rect width="97" height="97" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                    <h2 class="hiw-card-title mb-3">Connect</h2>
                    <p class="hiw-card-text">Once you&#39;ve chosen your provider, you'll be able to connect with them in many ways.</p>
                  </div>

                  <div class="p-4 rounded-lg flex flex-col items-center">
                    <svg  class="mb-8"  xmlns="http://www.w3.org/2000/svg" width="97" height="97" viewBox="0 0 97 97" fill="none">
                      <g clip-path="url(#clip0_722_1610)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M96.2185 47.8382L48.6616 0L0.781289 47.8382C-0.269544 48.8917 -0.269544 50.5934 0.781289 51.6469C1.83212 52.7004 3.52962 52.7004 4.58046 51.6469L10.7777 45.4882V94.3258C10.7777 95.8115 11.9902 97.027 13.4721 97.027H83.5277C85.0096 97.027 86.2221 95.8115 86.2221 94.3258V45.4341L92.3924 51.6469C93.4432 52.7004 95.1407 52.7004 96.1916 51.6469C97.2693 50.5934 97.2693 48.8917 96.2185 47.8382ZM48.6346 7.64439L80.8332 40.0047V91.5976H16.1666V40.0858L48.6346 7.64439Z" fill="#212429"/>
                        <path d="M26.9445 52.4843C26.9445 44.7589 31.7675 37.5737 38.3958 37.5737C42.9764 37.5737 46.3983 40.7881 48.5 45.3531C50.6017 40.7881 54.0236 37.5737 58.6042 37.5737C65.2325 37.5737 70.0556 44.7589 70.0556 52.4843C70.0556 69.0426 48.5 80.7928 48.5 80.7928C48.5 80.7928 33.977 73.3375 28.7767 61.1281C28.3995 60.2637 28.0761 59.3453 27.8067 58.4269C27.2678 56.5631 26.9445 54.5642 26.9445 52.4843Z" fill="#0BD2A4"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_722_1610">
                          <rect width="97" height="97" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                    <h2 class="hiw-card-title mb-3">Receive treatment</h2>
                    <p class="hiw-card-text">Enjoy the flexibility of receiving treatment online or at your home. Your
                        healthcare experience is tailored to your preferences and needs.</p>
                  </div>
                </div>

                <a href="/about-medimob" class="flex blue-home-button mx-auto text-decoration-none items-center my-5 w-1/5 bg-blue-500 text-white rounded-lg px-4 py-3 text-[20px]">
                  <span>Get Started</span>
                </a>
            </div>
        </div>

    </section>
</template>
<script>
export default {

}
</script>
