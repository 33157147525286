<template>
    <div  v-if="!loadFormdata" class="px-4 mb-4 container">
        <div class="form-section-info mt-4">
            <h3 class="form-section-info-title font-semibold pb-3">{{ GET_WORKFLOW.title}}</h3>
            <div class="p-4">
                <span>{{GET_WORKFLOW.description}}</span>
            </div>

        </div>
        <div class="form-section mt-4 flex px-4">
            <div class="form-section-info w-1/6">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" >{{ GET_WORKFLOW.components.booking_url.title }}
                    <span v-if="GET_WORKFLOW.components.booking_url.required" class="text-red-400">*</span>
                </label>
                <p class="font-sm text-gray-400 text-right">{{GET_WORKFLOW.components.booking_url.description }}</p>
            </div>
            <div class="form-section-content w-1/3 ml-5">
                <div class="mb-3">

                    <input 
                    :type="GET_WORKFLOW.components.booking_url.inputType" 
                    class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " 
                    v-model="modalData.booking_url" 
                    :required="GET_WORKFLOW.components.booking_url.required"
                    :name="GET_WORKFLOW.components.booking_url.inputName"
                    />
                    <InputErrorMessage v-if="showErrors && (!modalData.booking_url || modalData.booking_url === '') && GET_WORKFLOW.components.booking_url.required"/>
                </div>
            </div>
        </div>

        <div class="ml-5">
            <div class="">
                <button :disabled="loadingSubmitButton" @click="submitForm(GET_WORKFLOW.saveFunction)" type="submit" class="px-4 action-button border cursor-pointer rounded-md submit-button ">
                    <span v-if="loadingSubmitButton" class="spinner-border" role="status" aria-hidden="true"></span>
                    <span v-else>{{ GET_WORKFLOW.saveButton }}</span>  
                </button>
            </div>
        </div> 
    </div>


</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InputErrorMessage from '../../Utilities/InputErrorMessage.vue'

export default {
    props: ['clinicRef'],
    data() {
        return {

            form:{},
            formData: {},
            loadFormdata: true,
            showErrors: false,
            loadingSubmitButton: false,
            modalData: {
                clinic_ref: this.clinicRef,
                booking_url: ''
            },
        }
    },
    components:{InputErrorMessage},
    methods: {
        ...mapActions('mainClinic', ['loadWorkflow', 'saveOnlineBooking', 'setBookingUrlDetails']),
        isURL(urlString) {
            try {
                const url = new URL(urlString);
                return url.protocol === "http:" || url.protocol === "https:";
            } catch (error) {
                return false;
            }
        },
        submitForm(saveFnc)
        {
            this.loadingSubmitButton = true
            this.showErrors = true
            if (this.isURL(this.modalData.modalData) && this.modalData.modalData !== '') {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please fill the form correctly',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true
                });
                this.loadingSubmitButton = false
            }
            else {
                eval('this.'+saveFnc+'(this.modalData)')
                    .then(res => {
                        this.triggerBookingUrl()
                        this.loadingSubmitButton = false
                    }).catch((e) => {
                        this.loadingSubmitButton = false
                    })
            }
        },
        triggerBookingUrl()
        {

            this.loadFormdata = true
            this.showErrors = false

            this.loadWorkflow('booking_url')
                .then(res => {
                    this.loadFormdata = false
                }).catch(e => {
                    console.log(e)
                })
            this.setBookingUrlDetails(this.clinicRef)
            .then(res => {
                this.modalData.booking_url = res.data
            })
        }

    },
    created() {
        this.triggerBookingUrl()
        
    },
    computed: {
        ...mapGetters('mainClinic', ['GET_WORKFLOW'])
    }
}
</script>

<style lang="scss" scoped>
.action-button{
    width: fit-content;
}
.abort-button{
    height: 52px;
}
</style>