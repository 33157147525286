<template>
    <div class="">
        <div class="w-full mb-2">
            <edit-profession-single-practitioner :profession="GET_PRACTITIONER.profession" :idRef="practitionerRef"></edit-profession-single-practitioner>
        </div>
        <div class="w-full mb-2">
            <edit-regulatory-body-single-practitioner :professional_body="GET_PRACTITIONER" :id="practitionerRef" ></edit-regulatory-body-single-practitioner>
        </div>
    </div>

</template>

<script>

import EditProfessionSinglePractitioner from './EditProfessionSinglePractitioner.vue';
import EditRegulatoryBodySinglePractitioner from './EditRegulatoryBodySinglePractitioner.vue';
import EditQualificationsSinglePractitioner from './EditQualificationsSinglePractitioner.vue';
import { mapGetters } from 'vuex';

export default {
    props: ['practitionerRef'],
    components: {
        EditProfessionSinglePractitioner,
        EditRegulatoryBodySinglePractitioner,
        EditQualificationsSinglePractitioner
    },
    computed: {
        ...mapGetters('practitioner', ['GET_PRACTITIONER']),
    },

}
</script>