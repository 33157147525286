<template>
    <div class="container">
      <div class="flex">
        <button @click.prevent="goToServices" class="ml-auto p-2 rounded text-white font-bold bg-[#0BD2A4] mb-3">Add New Service</button>
      </div>
      <table-framework
            :theads="theads"
            :tableTitle="'Your Services'"
            :showTableSearch="true"
            :showTableToggle="true"
            :tableSearchText="searchInput"
            @textSearch="textSearch"
            :showTableActionButton="false"
            :allSelected="allSelected"
            :toggle="toggle"
            :filteredOptions="filteredServices"
            :selectedIds="selectedIds"
            @onToggleChange="onToggleChange"
        >

            <tbody slot="table-body"  v-if="filteredServices.length > 0">
                <tr
                    v-for="(service, index) in filteredServices"
                    :key="service.id"
                    :class="{
                    '': selectedIds.includes(service.ref),
                    'bg-white': !selectedIds.includes(service.ref),
                    'border-b': true,
                    'dark:bg-gray-800': true,
                    'dark:border-gray-700': index !== filteredServices.length - 1,
                    'hover:bg-gray-30': true,
                    'dark:hover:bg-gray-600': true,
                    }"
                >
                    <th class="py-4 px-6  whitespace-nowrap"  :class="selectedIds.includes(service.ref) && '' ">
                        <InputToggle :selectedIds="selectedIds" :element="service.ref" @toggleSelected="toggleSelected" />
                    </th>
                    <th scope="row" class="py-4 px-6  whitespace-nowrap"  :class="selectedIds.includes(service.ref) && '' ">
                        {{service.main_service_name}}
                    </th>
                    <th scope="row" class="py-4 px-6  whitespace-nowrap"  :class="selectedIds.includes(service.ref) && '' ">
                        {{service.ref}}
                    </th>
                </tr>
            </tbody>
            <div class="mt-5" v-if="filteredServices.length === 0">
              <p>You don't have any services yet.</p>
            </div>
      </table-framework>
    </div>
</template>


<script>
import TableFramework from '../../../Table.vue';
import InputToggle from '../../Clinics/TabComponents/InputToggle.vue';
import serviceToPractitionersTableHead from '../../../../constants/services/serviceToPractitionersTableHead';
import { mapActions } from 'vuex';
import UpdateCreateClinicModal from "../../Clinics/UpdateCreateClinicModal.vue";
export default {
    components: {UpdateCreateClinicModal, TableFramework, InputToggle},
    props: ['practitionerRef'],
    data()
    {
        return {
            theads: serviceToPractitionersTableHead,
            selectedIds: [],
            services: [],
            filteredServices: [],
            toggle: false,
            searchInput: '',
            openAddServiceModal: false
        }
    },
    methods:
    {
        ...mapActions('satelite', ['setMyServices']),
        ...mapActions('practitioner', ['setAssociatedServices', 'getAssociatedServices']),
        ...mapActions('mainClinic', ['getUserSateliteClinics']),
        textSearch(Searchtext)
        {
          this.searchInput = Searchtext
        },
        loadServices()
        {
            this.setMyServices()
            .then(res => {
                this.services = res.data;
                this.filteredServices = res.data;
            }).catch(error => {
                console.log(error)
            })

        },
        toggleSelected(id) {
            if (this.selectedIds.includes(id)) {
                // Remove id from selected ids array if it's already selected
                this.selectedIds = this.selectedIds.filter((selectedId) => selectedId !== id);
            } else {
                // Add id to selected ids array if it's not already selected
                if (!Array.isArray(this.selectedIds)) {
                this.selectedIds = [];
                }
                this.selectedIds.push(id);
            }
            this.setAssociatedServices({
                ref: this.practitionerRef,
                servicesList: this.selectedIds
            })
        },
        onToggleChange(toggle) {
            if(toggle) {
                this.selectedIds = this.filteredServices.map(service => service.ref);
            }else {
                this.selectedIds = [];
            }
            this.setAssociatedServices({
                ref: this.practitionerRef,
                servicesList: this.selectedIds
            })
        },
        goToServices()
        {
          window.location.href = '/dashboard/services';
        }
    },
    watch: {
        searchInput: {
            handler(newValue) {
                this.filteredServices = this.services
                this.filteredServices = this.services.filter(service => {
                const searchLower = this.searchInput.toLowerCase();
                const hasName = service.main_service_name && service.main_service_name.toLowerCase().includes(searchLower);
                const hasRef = service.ref && service.ref.toLowerCase().includes(searchLower);

                return hasName || hasRef;
                });

            }
        }
    },
    mounted()
    {
        this.getUserSateliteClinics();
        this.loadServices();
    },
    created()
    {
        this.getAssociatedServices(this.practitionerRef)
        .then(response => {
            this.selectedIds = response.data
        }).catch(err => {
            console.log(err)
        })
    },
    computed: {
        allSelected() {
            if(this.selectedIds.length === this.filteredServices.length){
                this.toggle = true
            }else {
                this.toggle = false
            }
            return this.selectedIds.length === this.filteredServices.length;
        },
    }
}
</script>

<style lang="scss" scoped>
.roundImage{
    width: 50px  !important;
}
.form-check-label {
    font-weight: bold;
    text-transform: uppercase;
  }
  .form-check-input:checked {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
  }
  .form-check-input:checked + .form-check-label {
    color: #0d6efd !important;
  }
</style>
