import { render, staticRenderFns } from "./ResendReviewRequest.vue?vue&type=template&id=d749b956&scoped=true"
import script from "./ResendReviewRequest.vue?vue&type=script&lang=js"
export * from "./ResendReviewRequest.vue?vue&type=script&lang=js"
import style0 from "./ResendReviewRequest.vue?vue&type=style&index=0&id=d749b956&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d749b956",
  null
  
)

export default component.exports