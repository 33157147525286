<script>
export default {
  name: "SinglePractitionerFE",
  props: ['dbvars'],
  methods: {
    redirectTo(satelite)
    {
      window.open('/view-profile/'+satelite.ref+'/'+satelite.slug, '_blank');
    }
  }
}
</script>

<template>
  <div class="position-relative h-100" style="margin-top: 100px;">
    <div class="w-full">
      <div class="lg:flex">
        <div class="lg:w-1/3 mr-5">
            <div class="card rounded shadow p-4">
              <div class="flex rounded">
                <img :src="dbvars.practitioner.img" class="shadow mx-auto h-64" style="border-radius: 50%">
              </div>
              <div class="details-section">
                <div class="mt-4">
                  <h3 class="text-center">{{dbvars.practitioner.full_name}}</h3>
                  <p class="mt-1 italic text-lg text-center">({{dbvars.practitioner.profession}})</p>
                </div>
                <div class="mt-4">
                  <div class="flex">
                    <div class="mx-auto">
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="lg:ml-5 lg:w-2/3 sm:mt-5 md:mt-0">
          <div class="mb-5" v-if="dbvars.practitioner.bio">
            <div class="profile-main-content-section mt-3" style="border-bottom: 2px solid #0BD2A4">
              <div class="flex-wrap flex">
                <h3 class="my-auto m-0 text-center">About {{ dbvars.practitioner.first_name }}</h3>
              </div>
            </div>
            <div class="mt-3">
              <div class="" v-html="dbvars.practitioner.bio"></div>
            </div>
          </div>
          <div class="mb-5" v-if="dbvars.practitioner.qualifications && JSON.parse(dbvars.practitioner.qualifications).length >= 1">
            <div class="profile-main-content-section mt-3" style="border-bottom: 2px solid #0BD2A4">
              <div class="flex-wrap flex">
                <h3 class="my-auto m-0 text-center">Qualifications</h3>
              </div>
            </div>
            <div class="mt-3">
              <div class="service-list-box flex-wrap flex">
                <li class="flex service-list-item cursor-pointer my-3" v-for="qualification in JSON.parse(dbvars.practitioner.qualifications)">
                  <h5 class="my-auto m-0 mx-auto">{{ qualification.value }}</h5>
                </li>
              </div>
            </div>
          </div>
          <div class="mb-5" v-if="dbvars.practitioner.association_name && JSON.parse(dbvars.practitioner.association_name).length >= 1">
            <div class="profile-main-content-section mt-3" style="border-bottom: 2px solid #0BD2A4">
              <div class="flex-wrap flex">
                <h3 class="my-auto m-0 text-center">Associations</h3>
              </div>
            </div>
            <div class="mt-3">
              <div class="">
                <div class="">
                  <div class="service-list-box flex-wrap flex">
                    <li class="flex service-list-item cursor-pointer my-3" v-for="association in JSON.parse(dbvars.practitioner.association_name)">
                      <h5 class="my-auto m-0 mx-auto">{{ association.title }}</h5>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-5" v-if="dbvars.practitioner.professional_body_name">
            <div class="profile-main-content-section mt-3" style="border-bottom: 2px solid #0BD2A4">
              <div class="flex-wrap flex">
                <h3 class="my-auto m-0 text-center">Professional Body</h3>
              </div>
            </div>
            <div class="mt-3">
              <div class="">
                <p>Professional Body: {{dbvars.practitioner.professional_body_name}}</p>
                <p>Registration Number: {{dbvars.practitioner.professional_body_ID}}</p>
              </div>
            </div>
          </div>
          <div class="mb-5" v-if="dbvars.practitioner.clinics.length >= 1">
            <div class="profile-main-content-section mt-3" style="border-bottom: 2px solid #0BD2A4">
              <div class="flex-wrap flex">
                <h3 class="my-auto m-0 text-center">Clinics</h3>
              </div>
            </div>
            <div class="mt-3">
              <div class="">
                <div class="satelites-list-box w-full">
                  <ul class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <li class=" flex satelites-list-item cursor-pointer my-3 px-2 py-3 shadow rounded-md" v-for="satelite in dbvars.practitioner.clinics" @click.prevent="redirectTo(satelite)">
                      <div class="img-box my-auto flex w-1/4">
                        <box-icon class="my-auto mx-auto cursor-pointer relative" type="solid" name='clinic' color="#ffff"></box-icon>
                      </div>
                      <div class="w-3/4 my-auto">
                        <div class="top-section">
                          <div class="my-auto mb-2 ml-auto"><small class="satelite-tag main-tag" v-if="satelite.head_office">Main clinic</small><small class="satelite-tag sat-tag" v-else>Satelite clinic</small></div>
                          <h5 class="my-auto mb-0 mr-4" >{{ satelite.name }}</h5>
                        </div>
                        <div class="satelite-card-subtitle"> <small class="italic">Based in {{ satelite.city }}</small></div>
                      </div>
                    </li>
                  </ul>

                </div>
              </div>
            </div>
          </div>
          <div class="mb-5" v-if="dbvars.practitioner.services.length >= 1">
            <div class="profile-main-content-section mt-3" style="border-bottom: 2px solid #0BD2A4">
              <div class="flex-wrap flex">
                <h3 class="my-auto m-0 text-center">Services</h3>
              </div>
            </div>
            <div class="mt-3">
              <div class="">
                <div class="service-list-box flex-wrap flex">
                  <li class="flex service-list-item cursor-pointer my-3" v-for="service in dbvars.practitioner.services">
                    <h5 class="my-auto m-0 mx-auto">{{ service.main_service_name }}</h5>
                  </li>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-5" v-if="dbvars.practitioner.years_of_experience">
            <div class="profile-main-content-section mt-3" style="border-bottom: 2px solid #0BD2A4">
              <div class="flex-wrap flex">
                <h3 class="my-auto m-0 text-center">Years of experience</h3>
              </div>
            </div>
            <div class="mt-3">
              <div class="">
                <p>{{ dbvars.practitioner.years_of_experience }}</p>
              </div>
            </div>
          </div>
          <div class="mb-5" v-if="dbvars.practitioner.publications && dbvars.practitioner.publications.length >= 1">
            <div class="profile-main-content-section mt-3" style="border-bottom: 2px solid #0BD2A4">
              <div class="flex-wrap flex">
                <h3 class="my-auto m-0 text-center">Publications</h3>
              </div>
            </div>
            <div class="mt-3">
              <div class="">
                <div class="service-list-box">
                  <li class="cursor-pointer my-3" v-for="publication in dbvars.practitioner.publications">
                    <a :href="publication.publication_url" target="_blank" class="my-auto m-0 mx-auto text-[17px] !text-teal-500">{{ publication.publication_name }}</a>
                  </li>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.service-list-box {

  .service-list-item {
    margin-right: 30px;
    background-color: #ffff;
    border-radius: 10px;
    padding: 5px 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 4px 0px;




    h5 {
      color: #717170;
      font-size: 15px;
      font-weight: 600;
    }
  }

}
</style>
