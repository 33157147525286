<script>
export default {
    name: "PrimaryButton",
    props: {
        type: {
            type: String,
            default: 'submit'
        },
        isDelete: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    }
}

</script>
<template>
    <button
        :type="type"
        class="inline-flex items-center px-4 py-2 bg-[#0EE2B1] border border-transparent rounded-md font-semibold text-md text-white uppercase tracking-widest hover:bg-[#0EE2B1] focus:bg-[#0EE2B1] active:bg-[#0EE2B1] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150"
        :class="{'bg-red-700 hover:bg-red-900': isDelete}"
        :disabled="isDisabled"
    >
        <slot />
    </button>
</template>
